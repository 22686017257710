import { useCustom, useIsAuthenticated } from "@refinedev/core";
import { useMatch } from "react-router-dom";

type NoOrgRouteDecision = {
  to: string | null;
  element: React.FC | null;
};
const useNoOrgRoute = () => {
  const { data: authenticatedData } = useIsAuthenticated();
  const { data: userData, isLoading: isLoadingUser } = useCustom({
    url: `api/users/me`,
    method: "get",
    queryOptions: {
      enabled: !!authenticatedData?.authenticated,

      retry: false,
    },
  });
  const isLoading = isLoadingUser;
  const hasOrg = userData?.data?.organizations.length > 0;
  const onboardingStatusIs1 = userData?.data?.onboarding_status === 1;
  const isCreateOrg = useMatch("/organization/create");
  const shouldGoToCreateOrg = !onboardingStatusIs1 && !hasOrg && !isCreateOrg;

  return isLoading || !authenticatedData?.authenticated
    ? false
    : shouldGoToCreateOrg;
};

export default useNoOrgRoute;
