import { useApiUrl, useCustom } from "@refinedev/core";
import { getLanguageName } from "pages/media/utils";
import { useTranslation } from "react-i18next";

interface SupportedLanguagesResponse {
  "admin.media.supported_locales": string[];
}

interface LanguageOption {
  label: string;
  value: string;
  description: string;
}

export const useSupportedLanguages = () => {
  const apiUrl = useApiUrl();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { data, isLoading, isError } = useCustom<SupportedLanguagesResponse>({
    url: `${apiUrl}/api/system/config/media`,
    method: "get",
  });

  const supportedLanguages = data?.data["admin.media.supported_locales"] || [];

  const supportedLanguagesOptions: LanguageOption[] = supportedLanguages.map(
    (locale) => {
      const languageName = getLanguageName(locale, currentLanguage, true);
      return {
        label: languageName ?? locale,
        value: locale,
        description: locale,
      };
    }
  );

  return {
    supportedLanguages,
    supportedLanguagesOptions,
    isLoading,
    isError,
  };
};
