import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
// @ts-nocheck
import { Card, Flex, Typography } from "antd";
import { ResponsiveBarCanvas } from "@nivo/bar";

/* select
    *,
    d.approved + d.rejected as total ,
    FLOOR( d.rejected * 100 / (d.approved + d.rejected)) as rejection_rate
from
    (
    SELECT
        sub_category as ticker ,
        MAX(CASE WHEN approved = 1 THEN total ELSE NULL END) as approved,
        MAX(CASE WHEN approved = 0 THEN total ELSE NULL END) as rejected
    FROM
        (
        select
            sub_category,
            count(*) as total,
            approved
        from
            media.asset
        WHERE
            reviewed is not NULL
            and category = 'AssetImage'
         -- and reviewer >= 3952
        group by
            approved,
            sub_category
) asset
    GROUP BY
        sub_category) d
order by
    rejection_rate desc
*/

export const MediaAssetsReviewStats = () => {
  const t = useTranslate();
  const raw = require("../data/asset_reviews.json");
  const data = (raw as any[]).map((x) => {
    return { ...x, ticker: `${x.ticker} (${x.rejection_rate}%)` };
  });

  // https://dmitripavlutin.com/javascript-array-group/
  const groupByAspectRatio = data?.reduce(
    (group: Record<string, any>, item) => {
      const { aspect_ratio } = item;
      group[aspect_ratio] = group[aspect_ratio] ?? [];
      group[aspect_ratio].push(item);
      return group;
    },
    {}
  );

  return (
    <>
      <Typography.Title>{t("media.assets.stats.reviewStats")}</Typography.Title>
      <Flex gap={10} vertical>
        {Object.entries(groupByAspectRatio).map(([group, data]) => {
          return (
            <Card title={group}>
              <GroupStats data={data} />
            </Card>
          );
        })}
      </Flex>
    </>
  );
};

const GroupStats = ({ data }: { data: any[] }) => {
  return (
    <div style={{ width: "100%", height: data.length * 25 }}>
      <ResponsiveBarCanvas
        data={data}
        theme={{ text: { fill: "#999" } }}
        keys={["approved", "rejected"]}
        indexBy="ticker"
        margin={{ top: 60, right: 100, bottom: 50, left: 100 }}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        borderColor={{
          from: "color",
          modifiers: [["brighter", 3]],
        }}
        axisBottom={null}
        axisRight={null}
        axisTop={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "reviews",
          legendPosition: "middle",
          legendOffset: -50,
          truncateTickAt: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: "ticker",
          legendPosition: "middle",
          legendOffset: -50,
          truncateTickAt: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["brighter", 3]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        pixelRatio={2}
        padding={0.4}
        innerPadding={0}
        minValue="auto"
        maxValue="auto"
        groupMode="stacked"
        layout="horizontal"
        enableGridX={true}
        enableGridY={false}
      />
    </div>
  );
};

