import { useState } from "react";
import { Button, Row, Col, Flex, Space } from "antd";
import {
  AlertOutlined,
  CheckCircleOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import { useOrganization } from "hooks/useOrganization";
import AssetDetailsCard from "./AssetDetailsCard";
import { CompanyEarningsCalendar } from "types";
import dayjs from "dayjs";
import { useList } from "@refinedev/core";

const CheckAssetsData = () => {
  const [showCards, setShowCards] = useState(false);
  const [showErrorsAndWarningsOnly, setShowErrorsAndWarningsOnly] =
    useState(false);
  const { assets, isLoadingAssets } = useOrganization({});
  const {
    data: fetchedEarningsData,
    isLoading: fetchedEarningsLoading,
    error: fetchedEarningsError,
  } = useList<CompanyEarningsCalendar>({
    resource: `api/assets/earnings_calendar?from_date=${dayjs()
      .add(-6, "M")
      .format(
        "YYYY-MM-DD"
      )}&to_date=${dayjs().add(7, "d").format("YYYY-MM-DD")}`,
    queryOptions: {
      enabled: showCards,
    },
  });
  const getAssetEarningCalendar = (ticker: string) => {
    return fetchedEarningsData?.data.find((item) => item.ticker === ticker);
  };
  const handleToggleCards = () => {
    setShowCards(!showCards);
  };

  const handleToggleFilter = () => {
    setShowErrorsAndWarningsOnly(!showErrorsAndWarningsOnly);
  };

  return (
    <div>
      <Space size={"small"}>
        <Button
          onClick={handleToggleCards}
          icon={<CheckCircleOutlined />}
          size="middle"
          type="primary"
        >
          {showCards ? "Hide checks" : "Check all assets"}
        </Button>
        {showCards && (
          <Button
            size="middle"
            onClick={handleToggleFilter}
            icon={<AlertOutlined />}
          >
            {showErrorsAndWarningsOnly
              ? "Show all"
              : "Show errors and warnings only"}
          </Button>
        )}
      </Space>
      {showCards && (
        <div style={{ marginTop: "10px" }}>
          <Row gutter={[10, 10]}>
            {assets?.map((asset, idx) => (
              <AssetDetailsCard
                key={asset.ticker}
                ticker={asset.ticker}
                earningCalendar={getAssetEarningCalendar(asset.ticker)}
                showErrorsAndWarningsOnly={showErrorsAndWarningsOnly}
              />
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default CheckAssetsData;
