import React, { useState, useEffect, useContext } from "react";
import { AutoComplete, Input, Typography } from "antd";
import { useList } from "@refinedev/core";
import { sortBy } from "lodash";
import { Asset } from "types";
import { AppContext } from "appContext";

interface Props {
  onChange: (value: string) => void;
  size?: "large" | "middle" | "small";
  placeholder?: string;
  allowClear?: boolean;
  onClear?: Function;
  strict?: boolean;
}

const TickerAutoComplete: React.FC<Props> = ({
  onChange,
  size = "large",
  placeholder,
  allowClear,
  onClear,
  strict = true, // strict will allow values from the list
}) => {
  const {
    state: { organization },
  } = useContext(AppContext);

  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const { data: allTickers, isSuccess } = useList<Asset>({
    resource: `api/assets/Equity/clients_tickers?organization_id=${organization?.id}`,
    queryOptions: {
      enabled: Boolean(organization),
    },
  });

  const filterOptions = () => {
    if (!allTickers?.data) return;
    const filteredTickers = sortBy(
      allTickers?.data?.filter(
        (ticker) =>
          ticker.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          ticker.ticker.toLowerCase().includes(searchValue.toLowerCase())
      ),
      "name"
    );
    const result = filteredTickers.map((item) => ({
      value: item.ticker,
      label: (
        <>
          <Typography.Text>
            {item.friendly_name?.length > 0 ? item.friendly_name : item.name}{" "}
          </Typography.Text>
          <Typography.Text type="secondary">• {item.ticker}</Typography.Text>
        </>
      ),
    }));
    setOptions(result);
  };

  useEffect(() => {
    if (isSuccess || (allTickers?.data && allTickers?.data?.length > 0)) {
      filterOptions();
    }
  }, [isSuccess, searchValue]);

  const handleSelect = (value: string) => {
    onChange && onChange(value);
  };

  const handleClear = () => {
    onClear && onClear();
  };

  return (
    <AutoComplete
      size={size}
      style={{ maxWidth: 500 }}
      options={options}
      onSelect={handleSelect}
      onChange={setSearchValue}
      onKeyDown={(event) => {
        if (!strict && event.key === "Enter") {
          // when submitting a manual value
          handleSelect(searchValue);
        }
      }}
      value={searchValue}
      onClear={handleClear}
    >
      <Input.Search
        size={size}
        placeholder={placeholder ?? "Ticker"}
        onSearch={filterOptions}
        allowClear={allowClear}
      />
    </AutoComplete>
  );
};

export default TickerAutoComplete;
