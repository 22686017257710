import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Badge, Card, Flex, Tag, Typography } from "antd";
import { MediaAsset } from "../../types";
import { DateField } from "@refinedev/antd";
import { useList } from "@refinedev/core";
import { UUID } from "components/UUID";
import DownloadButton from "./DownloadButton";
import FileIcon from "../../../../components/FileIcon";
import PreviewVideoButton from "./PreviewVideoButton";
import { addDateTz } from "pages/media/utils";

const ProjectAsset = ({
  asset,
  projectId,
}: {
  asset: MediaAsset;
  projectId: string;
}) => {
  // const { mutate } = useCustomMutation({});
  // const invalidate = useInvalidate();

  // const convertToMedia = () => {
  //   mutate(
  //     {
  //       url: `media/projects/${projectId}/medias?asset_id=${asset.id}`,
  //       method: "post",
  //       values: {},
  //     },
  //     {
  //       onSuccess() {
  //         invalidate({
  //           resource: `media/projects/${projectId}/assets`,
  //           invalidates: ["list"],
  //         });
  //         invalidate({
  //           resource: `media/projects`,
  //           id: projectId,
  //           invalidates: ["detail"],
  //         });
  //       },
  //     }
  //   );
  // };

  return (
    <Card
      style={{ width: 300 }}
      bordered
      size="small"
      actions={[
        <DownloadButton url={asset.path} />,
        asset.asset_type === "Video" ? (
          <PreviewVideoButton url={asset.path || ""} />
        ) : null,
        // asset.asset_type === "Video" && (
        //   <Tooltip title="Convert to Media">
        //     <VideoCameraAddOutlined
        //       key="convertToMedia"
        //       onClick={convertToMedia}
        //     />
        //   </Tooltip>
        // ),
      ]}
    >
      <Card.Meta
        // todo depending on filetype
        avatar={<FileIcon url={asset.path} />}
        title={<UUID id={asset.id} copyable={false} />}
        description={
          <>
            <div>{asset.description}</div>
            <Flex justify="space-between">
              <DateField
                type="secondary"
                value={addDateTz(asset.created)}
                format="LLL"
              />
              <Tag color="default">{asset.asset_type}</Tag>
            </Flex>
          </>
        }
      />
    </Card>
  );
};
export const ProjectAssets = ({ projectId }: { projectId: string }) => {
  const t = useTranslate();
  const { Title } = Typography;
  const { data } = useList<MediaAsset>({
    resource: `media/projects/${projectId}/assets?size=50`,
  });
  const assets = data?.data ?? [];

  return (
    <Flex vertical>
      <Title level={5} type="secondary">
        {t("projects.components.ProjectAssets.attachedAssets")}{" "}
        <Badge showZero={false} count={assets.length} color="#999" />
      </Title>
      <Flex gap={10} wrap="wrap">
        {assets.map((asset) => (
          <ProjectAsset asset={asset} key={asset.id} projectId={projectId} />
        ))}
      </Flex>
    </Flex>
  );
};

