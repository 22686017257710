import React from "react";
import { FileIcon, defaultStyles } from "react-file-icon";

interface FileIconProps {
  url: string;
  size?: number;
}
const getFileExtension = (url: string): string => {
  const parts = url.split(".");
  const lastPart = parts[parts.length - 1];
  const extension = lastPart.split("?")[0];
  return extension.toLowerCase();
};

const ProjectFileIcon: React.FC<FileIconProps> = ({
  url,
  size = 32,
}): React.ReactElement => {
  const extension = getFileExtension(url);
  return (
    <div style={{ width: size, height: size }}>
      <FileIcon extension={extension} {...defaultStyles[extension]} />
    </div>
  );
};

export default ProjectFileIcon;
