import { Cuepoint } from "pages/media/components/binder/types";
import { create } from "zustand";
import { produce } from "immer";

type State = {
  cuepoints: Cuepoint[];
  currentCuepoint?: Cuepoint;
  updateCount: number;
};

type Action = {
  setCuepoints: (cuepoints: State["cuepoints"]) => void;
  setUpdateCount: (updateCount: State["updateCount"]) => void;
  addCuepoint: (newCuepoint: Cuepoint) => void;
  deleteCuepoint: (highlightId: string, start: number) => void;
  deleteAllCuepoints: () => void;
  setCurrentCuepoint: (highlightId: string) => void;
  updateCuepoint: (updatedProperties: any) => void;
  updateCuepoints: (color: string) => void;
};

export const useCuepointsStore = create<State & Action>((set) => ({
  cuepoints: [],
  currentCuepoint: undefined,
  updateCount: 0,
  setCuepoints: (cuepoints) => set(() => ({ cuepoints })),
  setUpdateCount: (updateCount) => set(() => ({ updateCount })),
  setCurrentCuepoint: (highlightId: string) =>
    set(
      produce((state) => {
        state.currentCuepoint = state.cuepoints.find(
          (cuepoint: Cuepoint) => cuepoint.highlightId === highlightId
        );
      })
    ),
  addCuepoint: (newCuepoint) =>
    set(
      produce((state) => {
        state.cuepoints.push(newCuepoint);
        state.updateCount++;
      })
    ),
  updateCuepoint: (updatedProperties) =>
    set(
      produce((state) => {
        const cuepoint = state.cuepoints.find(
          (cuepoint: Cuepoint) =>
            cuepoint.highlightId === state.currentCuepoint?.highlightId &&
            cuepoint.start === state.currentCuepoint?.start
        );

        if (cuepoint) {
          cuepoint.roughNotation = {
            ...cuepoint.roughNotation,
            ...updatedProperties,
          };

          state.updateCount++;
        }
      })
    ),
  updateCuepoints: (color) =>
    set(
      produce((state) => {
        state.cuepoints.forEach((cuepoint: Cuepoint) => {
          cuepoint.roughNotation.color = color;
        });
        state.updateCount++;
      })
    ),
  deleteCuepoint: (highlightId, start) =>
    set(
      produce((state) => {
        state.cuepoints = state.cuepoints.filter(
          (cuepoint) =>
            !(cuepoint.highlightId === highlightId && cuepoint.start === start)
        );
        state.updateCount++;
      })
    ),

  deleteAllCuepoints: () =>
    set(
      produce((state) => {
        state.cuepoints = [];
        state.updateCount++;
      })
    ),
}));
