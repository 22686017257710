import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Row, Col, Typography } from "antd";
import { useParams } from "react-router-dom";
import { Authenticated, useOne } from "@refinedev/core";
import type { MediaAsset, VoiceProfileDetail } from "../types";
import { useOrganization } from "hooks/useOrganization";
import { DateField, Show } from "@refinedev/antd";
import { addDateTz } from "../utils";

const VoiceProfileAudioAsset = ({
  assetId,
  assetType,
}: {
  assetId: string;
  assetType: string;
}) => {
  const { data } = useOne<MediaAsset>({
    resource: "media/media/assets",
    id: assetId,
  });

  if (!data) {
    return null;
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Typography.Text strong>{assetType}:</Typography.Text>
      <audio
        src={data.data.path}
        controls
        style={{ display: "block", marginTop: 8 }}
      />
    </div>
  );
};

const VoiceProfileDetailPage = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const { id } = useParams<{
    id: string;
  }>();
  const { data, isLoading } = useOne<VoiceProfileDetail>({
    resource: `media/${organization?.id}/voice_profiles`,
    id,
    queryOptions: {
      enabled: Boolean(organization),
    },
  });

  const voiceProfile = data?.data;
  const assets = voiceProfile?.assets ?? {};

  return (
    <Authenticated key="voice_detail">
      <Show
        title={voiceProfile?.name}
        isLoading={isLoading}
        headerButtons={<></>}
      >
        <Row justify="center">
          <Col span={16}>
            {voiceProfile && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Typography.Text strong>
                    {t("media.voices.detail.type")}
                  </Typography.Text>{" "}
                  <Typography.Text>{voiceProfile.category}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>
                    {t("media.voices.detail.creationDate")}
                  </Typography.Text>{" "}
                  <DateField
                    value={addDateTz(voiceProfile.created)}
                    format="LLL"
                  />
                </Col>
                {Object.entries(assets).map(([assetId, assetType]) => (
                  <Col span={24} key={assetId}>
                    <VoiceProfileAudioAsset
                      assetId={assetId}
                      assetType={assetType}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </Show>
    </Authenticated>
  );
};

export default VoiceProfileDetailPage;

