import { useApiUrl, useCustomMutation, useList } from "@refinedev/core";
import { nanoid } from "nanoid";
import type { MediaAsset, MediaAssetType } from "pages/media/types";

export const useMediaAssetsStorage = ({
  organizationId,
  projectId,
  mediaId,
  enabled = false,
  invalidateOnMutationSuccess = true,
  assetType,
  mediaAssetsPage = 1,
  organizationAssetsPage = 1,
  pageSize = 10,
}: {
  organizationId?: string;
  projectId?: string;
  mediaId?: string;
  enabled?: boolean;
  invalidateOnMutationSuccess?: boolean;
  assetType?: MediaAssetType;
  mediaAssetsPage?: number;
  organizationAssetsPage?: number;
  pageSize?: number;
}) => {
  const source = "UIGenerated"; // hardcoded to improve
  const assetTypes: Partial<Record<MediaAssetType, string>> = {
    Picture: "picture",
    Video: "video",
    Script: "script",
    Speech: "speech",
    SpeechTimestamps: "speechtimestamps",
    Audio: "audio",
    Avatar: "avatar",
    Keywords: "keywords",
    Text: "text",
    Music: "music",
    CuePoints: "cuepoints",
    Highlights: "highlights",
    Branding: "branding",
    CallToActions: "ctas",
    Storyboard: "storyboard",
  };
  const apiUrl = useApiUrl();
  const { mutateAsync, isLoading: isMutateLoading } = useCustomMutation({});

  const {
    data: projectAssets,
    isLoading: isLoadingProjectAssets,
    // error,
    refetch: refetchProjectAssets,
  } = useList<MediaAsset>({
    resource: `media/projects/${projectId}/assets`,
    queryOptions: {
      enabled: enabled && Boolean(projectId),
    },
  });

  const {
    data: mediaAssets,
    isLoading: isLoadingMediaAssets,
    // error,
    refetch: refetchMediaAssets,
  } = useList<MediaAsset>({
    resource: `media/projects/${projectId}/medias/${mediaId}/assets`,
    queryOptions: {
      enabled: enabled && Boolean(projectId) && Boolean(mediaId),
    },
    pagination: {
      pageSize, // current api max
      current: mediaAssetsPage,
    },
  });

  const organizationAssets = useList<MediaAsset>({
    resource: `media/media/assets?asset_type=${assetType}&organization=${organizationId}`,
    queryOptions: {
      enabled: enabled && Boolean(assetType) && Boolean(organizationId),
    },
    pagination: {
      pageSize, // current api max
      current: organizationAssetsPage,
    },
  });

  const filterAssets = <T extends MediaAsset = MediaAsset>(
    assets: T[],
    {
      asset_type,
      id,
      asset_id,
      document_id,
    }: {
      asset_type?: MediaAssetType;
      document_id?: string;
      id?: string;
      asset_id?: string; // NOTE: Storyboards have asset_id not id! I suspect it's subject to change
    }
  ): T[] | undefined => {
    return assets?.filter((x) => {
      let result = true;
      if ("asset_id" in x && asset_id) {
        result = result && x.asset_id === asset_id;
      }
      if (id) {
        result = result && x.id === id;
      }
      if (asset_type) {
        result = result && x.asset_type === asset_type;
      }
      if (document_id) {
        result = result && x.document_id === document_id;
      }
      return result;
    });
  };

  // if (error) {
  //   console.error(error);
  // }

  // EX : upload("CuePoints", projectId!, cuepoints, currentAssets?.cuepoints?.id , { media_id: media!.id });
  const saveProjectAsset = async (
    type: MediaAssetType,
    // projectId: string,
    data: any,
    assetId?: string,
    urlParams?: Record<string, string>
  ) => {
    // the asset already exists => update with patch
    if (assetId !== undefined) {
      console.debug("currentAssets to patch", assetId);
      await mutateAsync({
        url: `${apiUrl}/media/media/assets/${assetId}`,
        method: "patch",
        values: {
          [assetTypes[type] as string]: data,
        },
      });
    }
    // the asset doesn't exist => create with post
    else {
      const params = new URLSearchParams({
        source,
        ...urlParams,
      });
      // console.debug("currentAssets to post", currentAssets);
      await mutateAsync({
        url: `${apiUrl}/media/projects/${projectId}/assets/${type}/${nanoid()}.json?${params.toString()}`,
        method: "post",
        values: {
          [assetTypes[type] as string]: data,
        },
      });

      // to handle manual invalidation in upper code
      if (invalidateOnMutationSuccess === true) {
        // todo : prettier logic
        if (urlParams?.media_id) {
          refetchMediaAssets();
        } else {
          refetchProjectAssets();
        }
      }
    }
  };

  const saveOrganizationAsset = async (
    type: MediaAssetType,
    // projectId: string,
    data: any,
    assetId?: string,
    urlParams?: Record<string, string>
  ) => {
    if (!organizationId) {
      return;
    }

    // the asset already exists => update with patch
    if (assetId !== undefined) {
      const params = new URLSearchParams({
        ...urlParams,
      }); // console.debug("currentAssets to patch", currentAssets);
      await mutateAsync({
        url: `${apiUrl}/media/media/assets/${assetId}?${params.toString()}`,
        method: "patch",
        values: {
          [assetTypes[type] as string]: data,
        },
      });
    }
    // the asset doesn't exist => create with post
    else {
      const params = new URLSearchParams({
        source,
        organization_id: organizationId,
        ...urlParams,
      });
      // console.debug("currentAssets to post", currentAssets);
      const response = await mutateAsync({
        url: `${apiUrl}/media/media/assets/${type}/${nanoid()}.json?${params.toString()}`,
        method: "post",
        values: {
          [assetTypes[type] as string]: data,
        },
      });

      return response.data.asset_id;

      // todo
      // to handle manual invalidation in upper code
      // if (invalidateOnMutationSuccess === true) {
      //   // todo : prettier logic
      //   if (urlParams?.media_id) {
      //     refetchMediaAssets();
      //   } else {
      //     refetchProjectAssets();
      //   }
      // }
    }
  };

  const fetchJsonAsset = async (path: string) => {
    const response = await fetch(path);
    return await response.json();
  };

  return {
    projectAssets: projectAssets?.data,
    mediaAssets: mediaAssets?.data,
    organizationAssets,
    saveProjectAsset,
    filterAssets,
    isLoadingProjectAssets,
    isLoadingMediaAssets,
    isMutateLoading,
    fetchJsonAsset,
    refetchProjectAssets,
    refetchMediaAssets,
    saveOrganizationAsset,
  };
};
