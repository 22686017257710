import { useTranslate } from "@refinedev/core";
import { Space, Table, Typography } from "antd";
import {
  CreateButton,
  DateField,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import { Organization } from "types";
import { Authenticated } from "@refinedev/core";
import { addDateTz } from "../utils";

export const MediaDocumentList = ({
  organization,
}: {
  organization?: Organization;
}) => {
  const t = useTranslate();
  const { tableProps, sorter } = useTable<any>({
    resource: `media/documents/${organization?.id}`,
    // sorters: {
    //   mode: "off",
    //   initial: [
    //     {
    //       field: "version",
    //       order: "desc",
    //     },
    //   ],
    // },
    queryOptions: {
      enabled: !!organization,
    },
  });

  return (
    <Authenticated key="documentList">
      <List
        headerButtons={({ createButtonProps }) => (
          <>
            {createButtonProps && (
              <CreateButton {...createButtonProps}>
                {t("media.documents.list.upload")}
              </CreateButton>
            )}
          </>
        )}
      >
        <Table {...tableProps} rowKey="address" pagination={{ pageSize: 100 }}>
          <Table.Column<{
            id: string;
          }>
            render={(value, record) => (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
          <Table.Column
            dataIndex="id"
            title={t("media.documents.list.id")}
            render={(value: string) => (
              <Typography.Text code>{value}</Typography.Text>
            )}
          />
          <Table.Column
            dataIndex="description"
            title={t("media.documents.list.description")}
          />
          <Table.Column
            dataIndex="status"
            title={t("media.documents.list.status")}
            render={(text) => (
              <Typography.Text>
                {t(`media.documents.list.statuses.${text}`)}
              </Typography.Text>
            )}
          />
          <Table.Column
            dataIndex="created"
            title={t("media.documents.list.createdAt")}
            render={(value) => <DateField value={addDateTz(value)} />}
          />
        </Table>
      </List>
    </Authenticated>
  );
};
