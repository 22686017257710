import { StateCreator } from "zustand";

import { sliceResetFns } from "store/utils";
import { Organization } from "types";
import { CombinedSlice } from "store/types";

export type AppState = {
  organization?: Organization;
};
export interface AppSlice {
  setAppState: (state: Partial<AppState>) => void;
  resetAppState: () => void;
  appState: AppState;
}
const initialState = {
  organization: undefined,
};

export const createAppSlice: StateCreator<
  CombinedSlice,
  //https://github.com/pmndrs/zustand/issues/980#issuecomment-1162289836
  [
    ["zustand/devtools", never],
    ["zustand/persist", unknown],
    ["zustand/immer", never],
  ],
  [],
  AppSlice
> = (set) => {
  sliceResetFns.add(() => set({ appState: initialState }));
  return {
    appState: initialState,
    resetAppState: () => set({ appState: initialState }),
    setAppState: (newState) =>
      set((state) => ({
        appState: { ...state.appState, ...newState },
      })),
  };
};
