import { CombinedSlice } from "store/types";
import { StateCreator } from "zustand";
import {
  MultiStepWithDescription,
  MultiStepWithDescriptionKey,
  OnboardingSlice,
  OnboardingState,
  Step,
  StepWithDescription,
  StepWithSingleAnswerAndDescription,
  Steps,
  Usage,
} from "./types";
import { getNextStep, getPreviousStep } from "./helpers";
import {
  FIRST_STEP,
  SINGLE_ANSWER_STEPS,
  MULTIPLE_ANSWER_STEPS,
} from "./constants";

const unanswered = {
  answers: null,
  other: null,
};
export const initialState: OnboardingState = {
  department: unanswered,
  usage: {
    answers: [],
    other: null,
  },
  business: unanswered,
  website: unanswered,
  source: unanswered,
  finish: unanswered,
  organization: null,
  currentStep: FIRST_STEP,
};
export const createOnboardingSlice: StateCreator<
  CombinedSlice,
  //https://github.com/pmndrs/zustand/issues/980#issuecomment-1162289836
  [
    ["zustand/devtools", never],
    ["zustand/persist", unknown],
    ["zustand/immer", never],
  ],
  [],
  OnboardingSlice
> = (set) => {
  return {
    onboardingState: initialState,
    resetOnboardingState: () => set({ onboardingState: initialState }),
    setOnboardingState: (value) =>
      set({ onboardingState: value }, undefined, "setOnboardingState"),

    setStep: (value) =>
      set(
        (state) => {
          setStepValue(value, state.onboardingState);
        },
        undefined,
        "setStep"
      ),
    goBack: () =>
      set(
        (state) => {
          const currentStep = state.onboardingState.currentStep;
          state.onboardingState.currentStep = getPreviousStep(currentStep);
        },
        undefined,
        "goBack"
      ),
    goForward: () =>
      set(
        (state) => {
          const currentStep = state.onboardingState.currentStep;
          state.onboardingState.currentStep = getNextStep(currentStep);
        },
        undefined,
        "goForward"
      ),
    setCurrentStep: (value) =>
      set(
        (state) => {
          state.onboardingState.currentStep = value;
        },
        undefined,
        "setCurrentStep"
      ),
  };
};

export function setStepValue(
  value: Partial<Steps[keyof Steps]> | string | null,
  state: Partial<OnboardingState> & { currentStep: Step }
) {
  const { currentStep } = state;
  const stateValue = state[currentStep];
  if (currentStep === "organization") {
    state.organization = value as string;
  } else if (
    isSingleStepWithDescription(stateValue, currentStep) &&
    isSingleStepWithDescription(value, currentStep)
  ) {
    const stateValue = state[currentStep as StepWithSingleAnswerAndDescription];
    setStepWithDescriptionValue(value, stateValue);
  } else if (isMultiStepWithDescription(stateValue, currentStep)) {
    const stateValue = state[currentStep as MultiStepWithDescriptionKey];
    setMultiStepValue(value as MultiStepWithDescription["answers"], stateValue);
  } else if (isOtherValue(value) && isOtherValue(stateValue)) {
    stateValue.other = value.other;
  }
}

function setMultiStepValue(
  value: MultiStepWithDescription["answers"],
  stateValue?: MultiStepWithDescription
) {
  if (!stateValue) return;
  if (typeof value === "string") {
    const usageValue = value as Usage;
    if (stateValue.answers === null) {
      stateValue.answers = [usageValue];
    } else if (stateValue.answers.includes(usageValue)) {
      stateValue.answers.splice(stateValue.answers.indexOf(usageValue), 1);
    } else {
      stateValue.answers.push(usageValue);
    }
  } else if (isOtherValue(value)) {
    stateValue.other = value.other;
  }
}

function setStepWithDescriptionValue(
  value: StepWithDescription,
  stateValue?: StepWithDescription
) {
  if (!stateValue) return;
  if (value.answers) {
    stateValue.answers = value.answers;
  }
  if (value.other) stateValue.other = value.other;
}

function isSingleStepWithDescription(
  obj: unknown,
  currentStep: string
): obj is StepWithDescription {
  if (obj === null || typeof obj !== "object") return false;
  if (typeof obj === "object") {
    if (
      "answers" in obj &&
      (typeof obj.answers === "string" || obj.answers === null) &&
      SINGLE_ANSWER_STEPS.includes(currentStep)
    ) {
      return true;
    }
  }
  return false;
}

function isMultiStepWithDescription(
  obj: unknown,
  currentStep: string
): obj is MultiStepWithDescription {
  if (typeof obj !== "object" || obj === null || obj === undefined)
    return false;
  if (
    typeof obj === "object" &&
    "answers" in obj &&
    MULTIPLE_ANSWER_STEPS.includes(currentStep)
  ) {
    return "answers" in obj && Array.isArray(obj.answers);
  }
  return false;
}

function isOtherValue(obj: unknown): obj is { other: string } {
  if (typeof obj !== "object" || obj === null || obj === undefined)
    return false;
  if (typeof obj === "object" && "other" in obj) {
    return typeof obj.other === "string" || obj.other === null;
  }
  return false;
}
