import { t } from "i18next";
import { Avatar, Badge, Card, GlobalToken, Space } from "antd";
import { ChatMessage } from "./types";
import { LoadingOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";

import { useAntTheme } from "hooks/useAntTheme";
import { Suggestion } from "./Suggestion";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "styled-components";
import { memo } from "react";

export const Message: React.FC<{
  message: ChatMessage;
  sendQuery: (text: string) => void;
}> = memo(function Message({ message, sendQuery }) {
  const { theme } = useAntTheme();
  return (
    <Card
      title={
        message.query?.length > 0 ? (
          message.user_query && message.user_query !== message.query ? (
            <>
              <span>{message.user_query}</span>{" "}
              <span style={{ fontSize: 12, opacity: 0.5 }}>
                {t("components.chat.Message.reformulated")}
              </span>
            </>
          ) : (
            message.query
          )
        ) : null
      }
      // extra={!message.isFinal ? <LoadingOutlined spin /> : null}
      bordered={false}
      // type="inner"
      // size="small"
      // https://dev.to/cassidoo/make-line-breaks-work-when-you-render-text-in-a-react-or-vue-component-4m0n
      style={{ padding: 0, background: "none", boxShadow: "none" }}
      styles={{
        body: {
          padding: 0,
          border: 0,
          whiteSpace: "pre-wrap",
          position: "relative",
        },
        header: { border: 0 },
        title: {
          overflow: "visible",
          whiteSpace: "normal",
          marginLeft: -24,
          marginBottom: 10,
          fontSize: 18,
          fontWeight: "normal",
        }, // disable ellipsis to show all the query text
      }}
    >
      {/* {htmlContent} */}
      <StyledSpace align="start">
        <Avatar style={{ backgroundColor: theme.colorPrimary }} size={"small"}>
          {t("components.chat.Message.ai")}
        </Avatar>
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          {!message.isFinal && (
            <LoadingOutlined spin style={{ fontSize: 24 }} />
          )}
        </div>
        <div>
          <Markdown remarkPlugins={[remarkGfm]}>
            {message.message.replaceAll(/<br\/?>/gi, "\n")}
          </Markdown>
          {!message.isFinal && message.message?.length > 0 && (
            <Badge dot color={theme.colorText} style={{ marginLeft: 10 }} />
          )}
          <QueueAnim
            delay={600}
            style={{
              gap: 8,
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            {message?.suggested_queries?.map((suggestion, index) => (
              <div key={index}>
                <Suggestion question={suggestion} onSelect={sendQuery} />
              </div>
            ))}
          </QueueAnim>
        </div>
      </StyledSpace>
    </Card>
  );
});

const StyledSpace = styled(Space)<{
  theme: GlobalToken;
}>`
  ul,
  ol {
    margin-top: 1em;
    margin-bottom: 0;
    padding-left: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    li::marker {
      // todo
    }
  }

  // prevent line break at start of message
  p:first-child {
    display: inline;
  }
  p {
    margin: 0;
  }

  // prevent line break at end of message
  div {
    display: inline;
  }

  table {
    border-collapse: collapse;
    margin: 0.8em 0;
    border: 1px solid ${(props) => props.theme.colorBorder}99;

    thead tr {
      background-color: ${(props) => props.theme.colorBgContainer};
      text-align: left;
    }
    th,
    td {
      padding: 12px 10px;
    }
    td {
      border: 1px solid ${(props) => props.theme.colorBorder}99;
    }
  }
`;

