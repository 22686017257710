import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useState, useEffect } from "react";
import {
  Drawer,
  Spin,
  Typography,
  Select, Form, Flex
} from "antd";
import { useCustom } from "@refinedev/core";
import { Organization } from "types";
import TickerAutoComplete from "components/TickerAutoComplete";
const { Option } = Select;

// hardcoded for now
const chapters = [
  "Introduction",
  "CompanyInfo",
  "Consensus",
  "TechnicalAnalysis",
  "Agenda",
  "Legal",
];

interface BatchScriptListProps {
  organization: Organization;
  date: string;
  visible: boolean;
  onClose: () => void;
  mediaTypes: string[];
}

const BatchScriptList: React.FC<BatchScriptListProps> = ({
  organization,
  date,
  visible,
  onClose,
  mediaTypes,
}) => {
  const t = useTranslate();
  const [selectedChapter, setSelectedChapter] = useState(chapters[0]);
  const [selectedTicker, setSelectedTicker] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedMediaType, setSelectedMediaType] = useState<string | null>(
    null
  );

  const { data, isLoading, isError, isFetching } = useCustom({
    url: `media/${organization.id}/batches/${selectedMediaType}/execution/${date}/${selectedChapter}`,
    method: "get",
    queryOptions: {
      enabled: Boolean(selectedMediaType && selectedChapter && organization.id),
    },
  });

  useEffect(() => {
    if (data?.data) {
      const filtered = Object.entries(data.data)
        .filter(
          ([ticker]) => !selectedTicker || ticker.includes(selectedTicker)
        )
        .sort(([tickerA], [tickerB]) => tickerA.localeCompare(tickerB));
      setFilteredData(filtered);
    }
  }, [data, selectedTicker]);

  const handleChangeChapter = (value: string) => {
    setSelectedChapter(value);
    setSelectedTicker(null);
  };

  const handleTickerSelect = (value: string) => {
    setSelectedTicker(value);
  };

  const handleClearTicker = () => {
    setSelectedTicker(null);
  };

  const handleMediaTypeChange = (value: string) => {
    setSelectedMediaType(value);
  };

  return (
    <Drawer
      title={`Scripts for Batch ${date}`}
      placement="right"
      onClose={onClose}
      size="large"
      open={visible}
    >
      <Flex vertical gap={10}>
        <Flex gap={30}>
          <Form.Item label={t("media.components.BatchScriptList.chapter")}>
            <Select
              style={{ minWidth: 150 }}
              defaultValue={selectedChapter}
              onChange={handleChangeChapter}
            >
              {chapters.map((chapter, index) => (
                <Option key={index} value={chapter}>
                  {chapter}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label={t("media.components.BatchScriptList.type")}>
            <Select
              style={{ minWidth: 150 }}
              defaultValue={mediaTypes?.[0]}
              onChange={handleMediaTypeChange}
            >
              {mediaTypes.map((type, index) => (
                <Option key={index} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Flex>
        <TickerAutoComplete
          size="middle"
          allowClear={true}
          onChange={handleTickerSelect}
          onClear={handleClearTicker}
          placeholder={t("media.components.BatchScriptList.searchTicker")}
        />
        {isFetching && <Spin />}
        {isError && (
          <Typography.Text>
            {t("media.components.BatchScriptList.errorFetchingData")}
          </Typography.Text>
        )}
        {!isLoading && !isError && (
          <div>
            {filteredData.map(([ticker, content]) => (
              <div key={ticker}>
                <Typography.Text strong type="secondary">
                  {ticker}
                </Typography.Text>
                <Typography.Paragraph>{content as string}</Typography.Paragraph>
              </div>
            ))}
          </div>
        )}
      </Flex>
    </Drawer>
  );
};

export default BatchScriptList;


