import { useTranslate } from "@refinedev/core";
import { Flex } from "antd";
import { SingleAnswerStep } from "./types";
import { OtherInput } from "./OtherInput";
import React from "react";
import { useBoundStore } from "store";
import { ORDERED_BUTTONS } from "./constants";
import { placeholders } from "./helpers";
import { TextAndDescription } from "./TextAndDescription";
import { Buttons } from "./Buttons";

const Step = <StepType extends SingleAnswerStep[keyof SingleAnswerStep]>() => {
  const t = useTranslate();
  const { setStepValue, currentStep, onboardingState } = useBoundStore(
    (state) => ({
      setStepValue: state.setStep,
      currentStep: state.onboardingState.currentStep as keyof SingleAnswerStep,
      onboardingState: state.onboardingState,
    })
  );
  // For showing the text in the text input when there's existing state,
  // e.g. when going back a step.
  const [textInputVisible, setTextInputVisible] = React.useState(
    !!onboardingState[currentStep].other
  );

  const handleClick = (value: string) => {
    if (
      value ===
      ORDERED_BUTTONS[currentStep as keyof typeof ORDERED_BUTTONS][
        ORDERED_BUTTONS[currentStep as keyof typeof ORDERED_BUTTONS].length - 1
      ]
    ) {
      setTextInputVisible(true);
    } else {
      setTextInputVisible(false);
    }
    setStepValue({
      answers: value,
    });
  };
  const onChangeOtherValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStepValue({
      other: e.target?.value,
    });
  };
  return (
    <Flex gap={50} vertical justify="center" align="center">
      <TextAndDescription />
      <Buttons<StepType>
        handleClick={handleClick}
        compareFn={(a, b) => a.answers?.includes(b) ?? false}
      />
      {textInputVisible && (
        <OtherInput
          placeholder={t(placeholders[currentStep] as string)}
          onChange={onChangeOtherValue}
        />
      )}
    </Flex>
  );
};

export default Step;
