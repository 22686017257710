import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { MinusCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Flex, List } from "antd";
import React, { useEffect, useState } from "react";
import type { Cuepoint } from "./types";
import { BinderClickable } from "./BinderClickable";
import { BinderDrawerConfig } from "./BinderDrawerConfig";
import { UUID } from "components/UUID";

export const BinderCuepoints: React.FC<{
  highlightId?: string; // highlightId to set up
  cuepoints: Cuepoint[];
  removeCuepoint: (id: string, start: number) => void;
  handleScroll: (id: string) => void;
  updateCuepoint: (cuepoint: Cuepoint) => void;
}> = ({
  highlightId,
  cuepoints,
  removeCuepoint,
  handleScroll,
  updateCuepoint,
}) => {
  const t = useTranslate();
  const secToTs = (sec: number) => {
    const min = Math.floor(sec / 60);
    const secs = sec % 60;
    return (min < 10 ? "0" : "") + min + ":" + (secs < 10 ? "0" : "") + secs;
  };

  const hanldeRemoveCuepoint = (id: string, start: number) => {
    removeCuepoint(id, start);
  };

  const [openDrawerConf, setOpenDrawerConf] = useState(false);
  const [currentCuepointToConf, setCurrentCuepointToConf] =
    useState<Cuepoint>();

  const showDrawer = (highlightId: string) => {
    setCurrentCuepointToConf(
      cuepoints.filter((cuepoint) => {
        return cuepoint.highlightId === highlightId;
      })[0]
    );
    setOpenDrawerConf(true);
  };
  const handleHighlightDrawerClose = (updated?: Cuepoint) => {
    setOpenDrawerConf(false);
  };

  const handleScrollRequest = (id: string) => {
    handleScroll(id);
  };

  useEffect(() => {
    if (highlightId) showDrawer(highlightId);
  }, [highlightId]);

  return (
    <Flex vertical gap={10}>
      {cuepoints?.length > 0 && (
        <List size="small" bordered>
          {cuepoints
            .sort((a, b) => (a.start > b.start ? 1 : -1))
            .map(
              (cuepoint, index) =>
                cuepoint.highlightId && (
                  <BinderClickable
                    key={index}
                    onClick={handleScrollRequest}
                    actions={[
                      <Button
                        type="text"
                        shape="circle"
                        icon={<MinusCircleOutlined />}
                        size="small"
                        onClick={() =>
                          hanldeRemoveCuepoint(
                            cuepoint.highlightId!,
                            cuepoint.start
                          )
                        }
                      />,
                      <Button
                        type="text"
                        shape="circle"
                        icon={<SettingOutlined />}
                        size="small"
                        onClick={() => showDrawer(cuepoint.highlightId!)}
                      />,
                    ]}
                    id={cuepoint.highlightId}
                    value={
                      <>
                        {secToTs(cuepoint.start)}
                        {t("components.binder.BinderCuepoints.id")}
                        <UUID id={cuepoint.highlightId} copyable={false} />
                      </>
                    }
                  />
                )
            )}
        </List>
      )}
      <BinderDrawerConfig
        currentCuepointToConf={currentCuepointToConf}
        onClose={handleHighlightDrawerClose}
        open={openDrawerConf}
        updateCuepoint={updateCuepoint}
      />
    </Flex>
  );
};
