import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { DateField, useForm } from "@refinedev/antd";
import {
  Authenticated,
  CanAccess,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useList,
  useOne,
} from "@refinedev/core";
import {
  Button,
  Card,
  Descriptions,
  Flex,
  Form,
  Input,
  Select,
  Typography,
} from "antd";
import { components } from "api";
import { useEffect, useState } from "react";
import { Organization } from "types";
import { countries } from "countries-list";
import { JsonPreview } from "pages/media/components/JsonPreview";
import { addDateTz } from "pages/media/utils";

type UserProps = {
  userId: number;
};
// const countryList = Object.entries(countries);
const USER_ROLES = [
  "Admin",
  "Company Admin",
  "Devops",
  "Free User",
  "Fund creator",
  "Libertify Asset Reviewer",
  "Organization Media Viewer",
  "Organization Owner",
  "Organization Project Creator",
  "Partner",
  "Portfolio Analyst",
  "Premium",
  "Service",
  "Subscriber",
  "Support",
  "Unverified User",
  "User",
  "Vault Admin",
];
const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};
const UserAddRole = ({ userId }: UserProps) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const { mutateAsync } = useCustomMutation({});
  const invalidate = useInvalidate();

  const addRole = async (userId: number, role: string) => {
    await mutateAsync({
      url: `${apiUrl}/api/users/${userId}/role?role_name=${encodeURIComponent(role)}`,
      method: "post",
      values: {},
    });
    invalidate({
      resource: "/api/users",
      id: userId,
      invalidates: ["detail"],
    });
    invalidate({
      resource: `/api/users/${userId}/organizations`,
      invalidates: ["list"],
    });
  };

  const handleFinish = (values: any) => {
    // const role = "Libertify Asset Reviewer";
    addRole(userId, values.role);
  };

  return (
    <Card title={t("pages.users.admin.addRole")} size="small" type="inner">
      <Form onFinish={handleFinish}>
        <Form.Item<number> name="role">
          <Input
            placeholder={t("pages.users.admin.libertifyAssetReviewer")}
            required
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          {t("pages.users.admin.submit")}
        </Button>
      </Form>
    </Card>
  );
};
const UserDetails = ({ userId }: UserProps) => {
  const t = useTranslate();
  const { data: orgs } = useList({
    resource: `/api/users/${userId}/organizations`,
  });
  const { data: user } = useOne<components["schemas"]["User"]>({
    resource: `/api/users`,
    id: userId,
  });
  return (
    <Card title={t("pages.users.admin.details")} size="small" type="inner">
      {Object.entries(user?.data?.accesses?.accesses ?? {}).map(
        ([access, value]) => (
          <Descriptions key={access} title={access}>
            <Descriptions.Item>{value[0]}</Descriptions.Item>
          </Descriptions>
        )
      )}

      <Descriptions title={t("pages.users.admin.roles")}>
        <Descriptions.Item>{user?.data?.roles?.join(", ")}</Descriptions.Item>
      </Descriptions>

      <Descriptions title={t("pages.users.admin.organizations")}>
        <Descriptions.Item>
          {orgs?.data?.map((org) => org.name).join(", ")}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title={t("pages.users.admin.userDetails")} column={1}>
        <Descriptions.Item label={t("pages.users.admin.name")}>
          {user?.data?.first_name} {user?.data.last_name}
        </Descriptions.Item>
        <Descriptions.Item label={t("pages.users.admin.email")}>
          {user?.data?.email}
        </Descriptions.Item>
        <Descriptions.Item label={t("pages.users.admin.country")}>
          {user?.data?.country}
        </Descriptions.Item>
        <Descriptions.Item label={t("pages.users.admin.creationDate")}>
          {user?.data?.created && (
            <DateField value={addDateTz(user?.data?.created)} format="LLL" />
          )}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export const CreateUser = () => {
  const t = useTranslate();
  const { data: organizations, isLoading: isLoadingOrganizations } =
    useList<Organization>({
      resource: `api/users/me/organizations`,
    });

  const { onFinish, formProps, saveButtonProps } = useForm<{
    code: string;
    first_name: string;
    last_name: string;
    roles: string[];
  }>({
    action: "create",
    redirect: false,
    resource: `api/users`,
    onMutationSuccess: async (data, variables: any) => {},
  });
  const handleFinish = (values: any) => {
    onFinish({
      ...values,
    });
  };
  const organizationsOptions = organizations?.data.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };

  const rolesOptions = USER_ROLES.map((role) => {
    return {
      label: role,
      value: role,
    };
  });
  const countriesOptions = Object.entries(countries).map(([key, value]) => {
    return {
      label: value.name,
      value: key, // country code iso2
    };
  });

  return (
    <Card>
      <Form
        layout="vertical"
        {...formProps}
        onFinish={handleFinish}
        style={{ width: 300 }}
        validateMessages={validateMessages}
      >
        <Form.Item
          label={t("pages.users.admin.firstName")}
          name="firstname"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label={t("pages.users.admin.lastName")}
          name="lastname"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label={t("pages.users.admin.email")}
          name="email"
          rules={[
            {
              type: "email",
              required: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label={t("pages.users.admin.roles")}
          name="roles"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder={t("pages.users.admin.pleaseSelect")}
            options={rolesOptions}
          />
        </Form.Item>
        <Form.Item
          label={t("pages.users.admin.organization")}
          name="organization_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            loading={isLoadingOrganizations}
            allowClear
            style={{ width: "100%" }}
            placeholder={t("pages.users.admin.pleaseSelect")}
            options={organizationsOptions}
          />
        </Form.Item>
        <Form.Item
          label={t("pages.users.admin.countries")}
          name="country"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={t("pages.users.admin.pleaseSelect")}
            onChange={onChange}
            onSearch={onSearch}
            options={countriesOptions}
            filterOption={(inputValue, option) => {
              if (!option) return false;
              const normalizedInput = inputValue.toLowerCase();
              const label = option.label?.toLowerCase() || "";
              const value = option.value?.toLowerCase() || "";
              return (
                label.includes(normalizedInput) ||
                value.includes(normalizedInput)
              );
            }}
          />
        </Form.Item>
        <Button {...saveButtonProps} type="primary">
          {t("pages.users.admin.createAccount")}
        </Button>
      </Form>
    </Card>
  );
};

export const UserEdit = () => {
  const t = useTranslate();
  const [userId, setUserId] = useState<number>();
  const [userApiKey, setUserApiKey] = useState<string | null>(null);
  const [showJson, setShowJson] = useState(false);

  const { data: user } = useList({
    resource: `/api/users/${userId}`,
    queryOptions: {
      enabled: Boolean(userId),
    },
  });
  const apiUrl = useApiUrl();
  const { mutateAsync } = useCustomMutation<never>({});

  useEffect(() => {
    setUserApiKey(null);
  }, [userId]);

  const handleGenerateApiKey = async () => {
    try {
      const response = await mutateAsync({
        url: `${apiUrl}/api/users/${userId}/api_key`,
        method: "post",
        values: {},
      });
      setUserApiKey(response.data as string);
    } catch (error) {
      console.error("Error generating API key:", error);
      // setUserApiKey(null);
    }
  };

  const handleFinish = (values: any) => {
    setUserId(+values.userId);
  };

  const toggleJsonPreview = () => {
    setShowJson(!showJson);
  };
  return (
    <Authenticated key="users_edit">
      <CanAccess
        resource="users"
        action="edit"
        fallback={
          <Typography.Title>{t("pages.users.admin.403Not")}</Typography.Title>
        }
      >
        <div style={{ marginBottom: 20 }}>
          <Form onFinish={handleFinish} layout="inline">
            <Form.Item<number>
              name="userId"
              label={t("pages.users.admin.showUserDetails")}
            >
              <Input required placeholder={t("pages.users.admin.userId")} />
            </Form.Item>
            <Form.Item>
              {/* <Button type="primary" onClick={handleSearch}>
    Search
  </Button> */}
            </Form.Item>
          </Form>
        </div>
        {userId && (
          <Card
            title={t("pages.users.admin.userDetails")}
            extra={
              <Button onClick={toggleJsonPreview}>
                {showJson ? "Hide JSON" : "Show JSON"}
              </Button>
            }
          >
            <Flex vertical gap={10}>
              <UserDetails userId={userId} />
              {showJson && (
                <JsonPreview data={user} style={{ width: "100%" }} />
              )}
              <UserAddRole userId={userId} />
              <Card
                title={t("pages.users.admin.apiKey")}
                size="small"
                type="inner"
              >
                <Button
                  type="primary"
                  onClick={handleGenerateApiKey}
                  style={{ marginTop: 20 }}
                >
                  {t("pages.users.admin.generateApiKey")}
                </Button>
                {userApiKey !== null && (
                  <div style={{ marginTop: 20 }}>
                    <strong>
                      {t("pages.users.admin.apiKey")}
                      {userApiKey}
                    </strong>
                  </div>
                )}
              </Card>
            </Flex>
          </Card>
        )}
        <CreateUser />
      </CanAccess>
    </Authenticated>
  );
};
