import { useTranslate } from "@refinedev/core";
import {
  Alert,
  Button,
  Flex,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import { Authenticated, useGo } from "@refinedev/core";
import type { MediaAsset } from "../types";
import { useOrganization } from "hooks/useOrganization";
import { UUID } from "../../../components/UUID";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { CreateButton, DateField } from "@refinedev/antd";
import { List } from "@refinedev/antd";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import { Target } from "@phosphor-icons/react";
import { StyledMarkdownFieldWrapper } from "components/StyledMarkdownFieldWrapper";
import { addDateTz } from "../utils";
import { useState, useEffect } from "react";

export const CallToActionsListPage = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 20;
  const [total, setTotal] = useState(pageSize);

  const {
    organizationAssets: { data, isLoading, refetch, isRefetching },
  } = useMediaAssetsStorage({
    organizationId: organization?.id,
    assetType: "CallToActions",
    enabled: true,
    organizationAssetsPage: currentPage,
    pageSize,
  });

  const go = useGo();

  useEffect(() => {
    const dataLength = data?.data.length ?? 0;
    const totalLength = dataLength + pageSize * (currentPage - 1);
    setTotal(dataLength < pageSize ? totalLength : totalLength + pageSize);
  }, [data]);

  const columns: TableColumnsType<MediaAsset> = [
    {
      title: t("media.ctas.index.id"),
      dataIndex: "id",
      key: "id",
      render: (id: string) => <UUID id={id} />,
    },
    {
      title: t("media.ctas.index.name"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("media.ctas.index.creationDate"),
      dataIndex: "created",
      key: "created",
      render: (text) => <DateField value={addDateTz(text)} format="LLL" />,
    },
  ];

  return (
    <Authenticated key="ctas_list">
      <List
        title={t("media.ctas.index.callToActions")}
        headerButtons={({ createButtonProps }) => (
          <Space>
            <Button
              icon={<SyncOutlined />}
              onClick={() => refetch()}
              disabled={isRefetching}
              shape="circle"
              type="text"
            />
            {/* todo check accesses */}
            {createButtonProps && (
              <CreateButton
                {...createButtonProps}
                icon={<PlusOutlined />}
                shape="round"
              >
                {t("media.ctas.index.createCallTo")}
              </CreateButton>
            )}
          </Space>
        )}
      >
        <Flex vertical gap={30}>
          <Alert
            icon={<Target size={60} />}
            showIcon
            description={
              <StyledMarkdownFieldWrapper>
                <Typography.Text>
                  {t("media.ctas.index.creatingGroupsInfoTitle")}
                </Typography.Text>
                <Flex vertical style={{ margin: "20px 0" }}>
                  <Typography.Text>
                    <span style={{ fontWeight: "bold" }}>
                      &middot; {t("media.ctas.index.groupCreationTitle")}
                    </span>{" "}
                    {t("media.ctas.index.groupCreationDescription")}
                  </Typography.Text>
                  <Typography.Text>
                    <span style={{ fontWeight: "bold" }}>
                      &middot; {t("media.ctas.index.callToActionsTitle")}
                    </span>{" "}
                    {t("media.ctas.index.callToActionsDescription")}
                  </Typography.Text>
                  <Typography.Text>
                    <span style={{ fontWeight: "bold" }}>
                      &middot; {t("media.ctas.index.automaticParametersTitle")}
                    </span>{" "}
                    {t("media.ctas.index.automaticParametersDescription")}
                  </Typography.Text>
                </Flex>
                <Typography.Text>
                  <span style={{ fontWeight: "bold" }}>
                    {t("media.ctas.index.optionalTitle")}
                  </span>{" "}
                  {t("media.ctas.index.optionalDescription")}
                </Typography.Text>
              </StyledMarkdownFieldWrapper>
            }
          />
          <Table<MediaAsset>
            loading={isLoading}
            dataSource={data?.data}
            columns={columns}
            pagination={{
              current: currentPage,
              pageSize,
              total: total,
              onChange: (page) => setCurrentPage(page),
            }}
            onRow={(record) => ({
              onClick: (event) => {
                go({
                  to: {
                    resource: "media_ctas",
                    id: record.id,
                    action: "edit",
                  },
                });
              },
              style: { cursor: "pointer" },
            })}
          />
        </Flex>
      </List>
    </Authenticated>
  );
};

export default CallToActionsListPage;
