import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  Alert,
  Button,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Steps,
  Typography,
} from "antd";
import {
  Create,
  MarkdownField,
  SaveButton,
  useForm,
  useSelect,
  useStepsForm,
} from "@refinedev/antd";
import { useOrganization } from "hooks/useOrganization";
import { useGo, useList } from "@refinedev/core";
import {
  FaceProfileResponse,
  PresenterProfileResponse,
  VoiceProfileResponse,
} from "../types";
import { UserList, UserSquare } from "@phosphor-icons/react";
import { useState } from "react";
import { FaceProfileCreateForm } from "../components/FaceProfileCreateForm";
import { FaceProfilePicker } from "../components/FaceProfilePicker";
import { PresenterProfilePicker } from "../components/PresenterProfilePicker";
import { SolutionOutlined, UserOutlined } from "@ant-design/icons";
import { StyledMarkdownFieldWrapper } from "components/StyledMarkdownFieldWrapper";
import styled from "styled-components";
import { SimpleCreateContactPage } from "pages/media/components/ContactWithPresenter/PageComponent";

const PresenterCreateForm = ({
  onSuccess,
  onCancel,
  ...rest
}: {
  onSuccess?: (value: string) => void;
  onCancel?: () => void;
  name?: string;
  nameDisabled?: boolean;
}) => {
  const t = useTranslate();
  const [newFace, setNewFace] = useState(true);
  const { organization } = useOrganization({});
  const { formProps, form, saveButtonProps } =
    useForm<PresenterProfileResponse>({
      resource: `media/${organization?.id}/presenter_profiles`,
      action: "create",
      onMutationSuccess(data) {
        onSuccess && onSuccess(data.data.id);
      },
    });
  const { selectProps: voiceSelectProps } = useSelect<VoiceProfileResponse>({
    resource: `media/${organization?.id}/voice_profiles`,
    optionLabel: "name",
  });
  const name = Form.useWatch("name", form);
  const { data: faceProfilesData } = useList<FaceProfileResponse>({
    resource: `media/${organization?.id}/face_profiles`,
  });
  return (
    <Form {...formProps} layout="vertical" initialValues={{ name: rest.name }}>
      <Typography.Title level={5}>
        {t("media.contacts.create.createACustom")}
      </Typography.Title>
      <Form.Item
        style={{ display: rest.nameDisabled ? "none" : "block" }}
        label={t("media.contacts.create.name")}
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Flex vertical gap={0} style={{ marginBottom: 30 }}>
        <Form.Item
          label={t("media.contacts.create.face")}
          name="face_profile_id"
        >
          {newFace && <FaceProfileCreateForm name={name} />}
          {faceProfilesData?.data && !newFace && (
            <FaceProfilePicker items={faceProfilesData?.data} />
          )}
        </Form.Item>
        {newFace && (
          <Space>
            <Button
              size="large"
              onClick={() => setNewFace(false)}
              shape="round"
              icon={
                <span className="anticon">
                  <UserList size={16} />
                </span>
              }
            >
              {t("media.contacts.create.useAnExistingFace")}
            </Button>
          </Space>
        )}
        {faceProfilesData?.data && !newFace && (
          <Space>
            <Button
              size="small"
              onClick={() => setNewFace(true)}
              type="text"
              icon={<UserOutlined />}
            >
              {t("media.contacts.create.createANew")}
            </Button>
          </Space>
        )}
      </Flex>
      <Form.Item
        label={t("media.contacts.create.voice")}
        name="voice_profile_id"
        rules={[
          {
            required: true,
          },
        ]}
      >
        {/* <VoiceProfileCreateForm /> */}
        <Select {...voiceSelectProps} size="large" />
      </Form.Item>
      <Flex justify="space-between" style={{ marginTop: 60 }}>
        {onCancel && (
          <Button shape="round" size="large" onClick={onCancel}>
            {t("media.contacts.create.useAnExistingPresenter")}
          </Button>
        )}
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          size="large"
          {...saveButtonProps}
        >
          {t("media.contacts.create.next")}
        </Button>
      </Flex>
    </Form>
  );
};

export const ContactCreateForm = ({
  onSuccess,
  redirect = true,
}: {
  onSuccess?: (value: string) => void;
  redirect?: boolean;
}) => {
  const t = useTranslate();
  const [createPresenter, setCreatePresenter] = useState(true);
  const { organization } = useOrganization({});
  const go = useGo();
  const {
    form,
    current,
    gotoStep,
    stepsProps,
    submit,
    formProps,
    saveButtonProps,
    // queryResult,
  } = useStepsForm<any>({
    resource: `media/${organization?.id}/contacts`,
    action: "create",
    redirect: false, // not working :(
    onMutationSuccess: (data) => {
      onSuccess?.(data.data.id); // todo waiting backend update
      if (redirect) {
        go({ to: { resource: "media_contacts", action: "list" } });
      }
    },
    // submit(formValues) {
    //   console.debug({ formValues });
    // },
  });
  const { data: presenters } = useList<PresenterProfileResponse>({
    resource: `media/${organization?.id}/presenter_profiles`,
    queryOptions: { enabled: !!organization?.id },
  });
  const formList = [
    <>
      <Form.Item
        label={t("media.contacts.create.firstName")}
        name="firstname"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("media.contacts.create.lastName")}
        name="lastname"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>,
    <Flex style={{ marginBottom: 50 }} vertical gap={30} align="start">
      <Alert
        icon={<UserSquare size={60} />}
        showIcon
        description={
          <StyledMarkdownFieldWrapper>
            <MarkdownField
              value={`What is a Presenter?

- **Role**: The presenter is the person who presents the AI experience.
- **Appearance**: Usually, it’s a realistic avatar with a face and a voice.
**Optional** : The avatar can be faceless (transparent) but must have a voice to narrate the movie storyboard.
`}
            />
          </StyledMarkdownFieldWrapper>
        }
      />
      <Form.Item
        label={t("media.contacts.create.presenter")}
        name="presenter_id"
        rules={[
          {
            required: true,
          },
        ]}
      >
        {presenters?.data && (
          <PresenterProfilePicker items={presenters?.data} />
        )}
      </Form.Item>
      <Button
        // type="text"
        shape="round"
        size="large"
        icon={
          <span className="anticon">
            <UserSquare size={16} />
          </span>
        }
        onClick={() => setCreatePresenter(true)}
      >
        {t("media.contacts.create.orCreateA")}
      </Button>
    </Flex>,
  ];
  return (
    <StyledEditWrapper>
      <Create
        headerProps={{ title: "", backIcon: <></> }}
        footerButtons={
          createPresenter && current === 1 ? (
            <></>
          ) : (
            <Space size={"large"}>
              {current > 0 && (
                <Button
                  shape="round"
                  size="large"
                  onClick={() => {
                    gotoStep(current - 1);
                  }}
                >
                  {t("media.contacts.create.back")}
                </Button>
              )}
              {current < formList.length - 1 && (
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={() => {
                    gotoStep(current + 1);
                  }}
                >
                  {t("media.contacts.create.next")}
                </Button>
              )}
              {current === formList.length - 1 && (
                <SaveButton
                  {...saveButtonProps}
                  type="primary"
                  shape="round"
                  size="large"
                  icon={<></>}
                  style={{ marginRight: 10 }}
                  onClick={() => submit()}
                >
                  {t("media.contacts.create.createContact")}
                </SaveButton>
              )}
            </Space>
          )
        }
      >
        <Steps {...stepsProps} style={{ marginBottom: 30 }}>
          <Steps.Step
            title={t("media.contacts.create.personalDetails")}
            icon={<SolutionOutlined />}
          />
          <Steps.Step
            title={t("media.contacts.create.presenterFace")}
            icon={<UserOutlined />}
          />
        </Steps>
        {createPresenter && current === 1 && (
          <PresenterCreateForm
            name={(
              (form.getFieldValue("firstname") ?? "") +
              " " +
              (form.getFieldValue("lastname") ?? "")
            ).trim()}
            nameDisabled={true}
            onCancel={() => {
              setCreatePresenter(false);
            }}
            onSuccess={(value: string) => {
              setCreatePresenter(false);
              form.setFieldValue("presenter_id", value);
            }}
          />
        )}

        {!(createPresenter && current === 1) && (
          <Form {...formProps} layout="vertical">
            {formList[current]}
          </Form>
        )}
      </Create>
    </StyledEditWrapper>
  );
};
export const ContactCreatePage = () => {
  // if (isStudio) {
  //   return <SimpleCreateContactPage />;
  // }
  // return <ContactCreateForm />;
  return <SimpleCreateContactPage />;
};

export const StyledEditWrapper = styled.div`
  .ant-card-actions {
    border: 0;
    > li > span > .ant-space {
      display: flex;
      padding: 24px;
      float: none !important;
      width: 100%;
      justify-content: flex-end;
      cursor: default;
    }
  }
`;


