import React, { useState } from "react";
import "./HighlightZoom.css";
import { Position } from "react-pdf-highlighter";

const HighlightZoom = ({
  src,
  position,
}: {
  src: string;
  position: Position;
}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleAnimation = () => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 5000);
  };

  return (
    <div
      style={{
        position: "absolute",
        left: position.boundingRect.left,
        top: position.boundingRect.top,
        zIndex: 1002,
      }}
      className={`container ${isAnimating ? "animate" : ""}`}
      onClick={handleAnimation}
    >
      <img src={src} alt="" />
    </div>
  );
};

export default HighlightZoom;
