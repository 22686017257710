import { useTranslate } from "@refinedev/core";
import React, { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { useGo, useCustomMutation } from "@refinedev/core";
import styled from "styled-components";

const { Title, Text } = Typography;

export const ForgotPasswordPage: React.FC = () => {
  const t = useTranslate();
  const [email, setEmail] = useState("");
  const [step, setStep] = useState<"forgot" | "reset">("forgot");
  const go = useGo();

  const { mutate: forgotPasswordMutate, isLoading: forgotPasswordLoading } =
    useCustomMutation();

  const {
    mutate: confirmForgotPasswordMutate,
    isLoading: resetPasswordLoading,
  } = useCustomMutation();

  const handleForgotPassword = (values: { email: string }) => {
    forgotPasswordMutate(
      {
        url: "/api/users/forgot_password",
        method: "post",
        values: values,
      },
      {
        onSuccess: () => {
          setEmail(values.email);
          setStep("reset");
        },
      }
    );
  };

  const handleConfirmForgotPassword = (values: {
    code: string;
    password: string;
    confirmPassword: string;
  }) => {
    confirmForgotPasswordMutate(
      {
        url: "/api/users/confirm_forgot_password",
        method: "post",
        values: {
          email,
          confirm_code: values.code,
          password: values.password,
        },
        successNotification: {
          type: 'success',
          message: t("pages.auth.forgot-password.passwordReset"),
        }
      },
      {
        onSuccess: () => {
          go({ to: "/login" });
        },
      }
    );
  };

  return (
    (<StyledFormWrapper style={{ maxWidth: 500, margin: "0 auto" }}>
      {step === "forgot" && (
        <>
          <Title level={3}>{t("Forgot Password")}</Title>
          <Text type="secondary" style={{ marginBottom: 24, display: "block" }}>
            {t("pages.auth.forgot-password.verifyIdentity")}
          </Text>
          <Form
            name="forgot-password"
            onFinish={handleForgotPassword}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label={t("Email")}
              rules={[
                {
                  required: true,
                  message: t("pages.auth.new-register.pleaseEnterYour"),
                },
                {
                  type: "email",
                  message: t("pages.auth.new-register.pleaseEnterA"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={forgotPasswordLoading}
                block
              >
                {t("continue")}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {step === "reset" && (
        <>
          <Title level={3}>{t("Reset Password")}</Title>
          <Text type="secondary" style={{ marginBottom: 24, display: "block" }}>
            {t("pages.auth.forgot-password.enterCode")}
          </Text>
          <Form
            name="reset-password"
            onFinish={handleConfirmForgotPassword}
            layout="vertical"
          >
            <Form.Item name="code">
              <Input.OTP  />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  min: 8,
                  message: t("pages.auth.new-register.passwordMustBe"),
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
                  message: t("pages.auth.new-register.passwordMustContain"),
                },
              ]}
            >
              <Input.Password placeholder={t("Enter your password")} />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t("pages.auth.new-register.pleaseConfirmYour"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("pages.auth.new-register.passwordsDoNot"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t("pages.auth.new-register.confirmYourPassword")} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={resetPasswordLoading}
                block
              >
                {t("continue")}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </StyledFormWrapper>)
  );
};

export default ForgotPasswordPage;

const StyledFormWrapper = styled.div`
  .ant-otp-input {
    height: 80px;
    font-size: 36px;
    margin: 0 5px; /* Adjust spacing */
  }
`;
