import { useCustom } from "@refinedev/core";
import useSessionData from "hooks/useSessionData";
import { NormalizedAnswer } from "../types";
import { NON_STEP_QUESTIONS } from "../constants";
import { getUniqueAnswers, ORDERED_STEPS } from "../helpers";
import { useList } from "@refinedev/core";
import { API_URL } from "services/api";
export default function useOnboardingAnswers() {
  const { userData, isLoading: isLoadingUserData } = useSessionData();
  const { data: projects, isLoading: isLoadingProjects } = useList({
    resource: "media/projects",
  });
  const { data, isLoading: isLoadingAnswers } = useCustom<NormalizedAnswer[]>({
    url: `${API_URL}/media/onboarding/answer`,
    method: "get",
    config: {
      query: {
        user_id: userData?.data?.id,
      },
    },
    queryOptions: {
      enabled: !!userData?.data?.id,
    },
  });
  // NB: This is for admins and some special users so that they can skip onboarding. It's not the actual onboarding status.
  const skipOnboarding = userData?.data?.onboarding_status === 1;

  // This is the real onboarding status for regular users. If they have answered all questions they will not see onboarding anymore. Right now we do not load their previous answers so they will have to start onboarding from scratch every time they go there. If they completed onboarding and they go to it directly, they will see the finish screen.
  const hasCompletedOnboarding =
    getUniqueAnswers(data?.data).size >=
    Object.keys(ORDERED_STEPS).length - NON_STEP_QUESTIONS.length;

  const shouldGoToOnboarding = !skipOnboarding && !hasCompletedOnboarding;

  return {
    data,
    isLoading: isLoadingUserData || isLoadingAnswers || isLoadingProjects,
    hasCompletedOnboarding,
    shouldGoToOnboarding,
  };
}
