import { t } from "i18next";
import { CanAccess, useCustom, useGo, useOne } from "@refinedev/core";
import { useContext, useEffect, useRef, useState } from "react";
import { AnimationVideo } from "./AnimationVideo";
import { AnimationPdf } from "./AnimationPdf";
import {
  Button,
  Flex,
  Space,
  Spin,
  Typography,
  notification,
  Select,
  Tabs,
  ColorPicker,
  Popconfirm,
  Drawer,
  Tooltip,
  Modal,
} from "antd";
import { IHighlight, NewHighlight } from "react-pdf-highlighter";
import type { Cuepoint } from "../binder/types";
import { JsonPreview } from "../JsonPreview";
import { AppContext } from "appContext";
import {
  BrandKit,
  ContactResponse,
  MediaDocument,
  MediaProjectResponse,
} from "pages/media/types";
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import { useParams, Link } from "react-router-dom";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "pages/media/utils";
import { TimelineState } from "@xzdarcy/react-timeline-editor";
import { useAntTheme } from "hooks/useAntTheme";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useCuepointsStore } from "./useCuepointsStore";
import { TimelineComponent } from "./Timeline";
import { TimestampedScript } from "./TimestampedScript";
import { useHighlightsStore } from "./useHighlightsStore";
import { StyledVerticalPanelResizeHandle } from "components/StyledPanelResizeHandle";
import { BugBeetle } from "@phosphor-icons/react";

export const Animation = () => {
  const { dispatch: appDispatch } = useContext(AppContext);
  const [ts, setTs] = useState(0);
  const go = useGo();
  const {
    cuepoints,
    setCuepoints,
    addCuepoint,
    setUpdateCount,
    currentCuepoint,
    setCurrentCuepoint,
    deleteAllCuepoints,
    updateCuepoints,
  } = useCuepointsStore();

  const updateCount = useCuepointsStore((state) => state.updateCount);

  const {
    highlights,
    setHighlights,
    setHighlightIdToSetup,
    currentHighlight,
    setCurrentHighlight,
    addHighlight,
    deleteAllHighlights,
  } = useHighlightsStore();

  const [scrollTo, setScrollTo] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const { theme } = useAntTheme();
  const timelinePlayer = useRef<TimelineState | null>(null);
  const [duration, setDuration] = useState(100);
  // const [sections, setSections] = useState<TDetailedSection[]>();
  const [saving, setSaving] = useState(false);

  const params = useParams<{ id: string; mediaId: string }>(); // Add mediaId to the type
  const { id: projectId, mediaId } = params ?? {};
  const { data: projectData, isLoading: isLoadingProject } =
    useOne<MediaProjectResponse>({
      resource: `media/projects`,
      id: projectId,
    });
  const [debug, setDebug] = useState(false);

  const organizationId = projectData?.data?.organization_id;
  const brandKitId = projectData?.data?.settings.brand_kit_id;

  const { data: brandKit } = useOne<BrandKit>({
    resource: `media/${organizationId}/brand_kits`,
    id: brandKitId,
  });

  const documentId =
    projectData?.data.settings.primary_document_id ??
    projectData?.data?.documents?.[0]?.id;
  const { data, isLoading: isLoadingDocument } = useOne<MediaDocument>({
    resource: `media/documents/${organizationId}`,
    id: documentId,
    queryOptions: {
      enabled: Boolean(organizationId) && Boolean(documentId),
    },
  });

  const { data: stepsData } = useCustom<any>({
    url: `media/media/${mediaId}/timings`,
    method: "get",
  });

  const hasDuration = stepsData?.data?.chapters.every((step) => step.duration);

  const projectDocument = projectData?.data?.documents.find(
    (x) => x.id === documentId
  );
  const documentAsset = data?.data;
  const media = projectData?.data.media.find(
    (mediaItem) => mediaItem.id === mediaId
  );
  const {
    filterAssets,
    saveProjectAsset,
    refetchMediaAssets,
    refetchProjectAssets,
    projectAssets,
    mediaAssets,
    fetchJsonAsset,
    isLoadingMediaAssets,
    isLoadingProjectAssets,
  } = useMediaAssetsStorage({
    organizationId: organizationId,
    projectId,
    mediaId,
    enabled: Boolean(projectId && mediaId),
    invalidateOnMutationSuccess: false,
  });
  // legacy

  const highlightAssetManual = projectAssets
    ? filterAssets(projectAssets, {
        asset_type: "Highlights",
        document_id: documentAsset?.id,
      })?.[0]
    : null;
  const highlightAsset = projectDocument?.highlights_asset_id
    ? {
        id: projectDocument?.highlights_asset_id,
        path: projectDocument?.highlights_asset_path,
      }
    : highlightAssetManual;
  const cuepointsAsset = mediaAssets
    ? filterAssets(mediaAssets, {
        asset_type: "CuePoints",
      })?.[0]
    : null;
  const isLoading =
    isLoadingMediaAssets ||
    isLoadingProjectAssets ||
    isLoadingProject ||
    isLoadingDocument;

  const lang = media ? media.language : null;
  const storyboardRelatedMedias = projectData?.data.media.filter(
    (media) => media.language === lang
  );

  const normalizeHighlightsForBackend = (highlights: IHighlight[]) => {
    return transformKeysToSnakeCase(
      highlights.map((x) => ({
        ...x,
        position: {
          ...x.position,
          // backend has a default usePdfCoordinates = true, so keep false if sending client side highlights
          usePdfCoordinates: x.position.usePdfCoordinates ?? false,
        },
      }))
    );
  };
  useEffect(() => {
    const loadHighlights = async () => {
      try {
        if (highlightAsset?.path) {
          const json = await fetchJsonAsset(highlightAsset.path);
          if (json.data) {
            setHighlights(transformKeysToCamelCase(json.data));
          }
        }
      } catch (error) {
        console.error("Issue while loading JSON:", error);
      }
    };
    loadHighlights();
  }, [highlightAsset?.id]);

  useEffect(() => {
    if (!mediaAssets || !cuepointsAsset) {
      return;
    }

    const loadCuepoints = async () => {
      try {
        if (cuepointsAsset?.path) {
          const json = await fetchJsonAsset(cuepointsAsset.path);
          if (json.data) {
            setCuepoints(transformKeysToCamelCase(json.data));
          }
        }
      } catch (error) {
        console.error("Issue while loading JSON:", error);
      }
    };
    loadCuepoints();
  }, [cuepointsAsset?.id]);

  const startAlreadyUsed = (startTime: number) => {
    return cuepoints.some((cuepoint) => cuepoint.start === startTime);
  };

  //Make sure the player is ready and the event is applied.
  // const [, setPlayerReady] = useState(false);

  useEffect(() => {
    const checkVideoReady = () => {
      const videoElement = document.querySelector("video");

      if (videoElement && videoElement.readyState >= 1) {
        console.debug("video is ready", {
          duration: videoElement.duration,
          currentTime: videoElement.currentTime,
        });

        const handleTimeUpdate = () => {
          timelinePlayer.current.setTime(videoElement.currentTime);
          setTs(parseFloat(videoElement.currentTime.toFixed(3)));
        };

        videoElement.addEventListener("timeupdate", handleTimeUpdate);

        timelinePlayer.current?.setPlayRate(videoElement.duration);
        setDuration(videoElement.duration);
        clearInterval(intervalId);
      }
    };
    const intervalId = setInterval(checkVideoReady, 100);
  }, [cuepoints, highlights]);

  // drag timeline cursor when currentCuepoint changes
  useEffect(() => {
    if (!currentCuepoint) return;

    const videoElement = document.querySelector("video");
    if (videoElement && currentCuepoint) {
      videoElement.currentTime = currentCuepoint.start;
    }
  }, [currentCuepoint]);

  const handleDragEnd = (time: number) => {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.currentTime = time;
    }
    setTs(parseFloat(time.toFixed(3)));
  };

  const handleDragStart = () => {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.pause();
    }
  };
  const handleNewCuepoint = (update: Partial<Cuepoint>) => {
    let startTime =
      Math.floor(ts) === 0
        ? stepsData.data.chapters[0].subtitles.words[0].start_time / 1000
        : Math.floor(ts); // if timeline on 0, set cuepoint to the first word in the first chapter

    if (startAlreadyUsed(startTime)) {
      api.error({
        message: t(`components.binder.index.startTimeInUse`),
        description: t("components.binder.index.youAreTrying"),
        placement: "top",
      });

      setCurrentHighlight(undefined);

      return;
    }
    if (currentHighlight?.id) {
      addCuepoint({
        start: startTime,
        highlightId: currentHighlight.id,
        documentId: documentAsset?.id!,
        title: "", // todo
        page: 0, // todo
        top: 0, // todo
        roughNotation: {
          animate: true,
          type: update?.roughNotation?.type ?? "underline",
          animationDelay: 500,
          animationDuration: 800,
          strokeWidth: update?.roughNotation?.strokeWidth ?? 1,
          padding: 5,
          color:
            (brandKit?.data?.palette.highlight_color ||
              brandKit?.data?.palette.primary_color) ??
            "#1677FF",
          brackets: ["left"],
          iterations: 2,
          multiline: true,
        },
      });
      setHighlightIdToSetup(currentHighlight.id);
      setCurrentCuepoint(currentHighlight.id);
      // api.success({
      //   message: t(`components.binder.index.animationCreated`),
      //   placement: "top",
      // });
    }
  };
  const handleAddHighlight = (highlight: NewHighlight) => {
    // console.debug("adding highlight", highlight);
    const createdHighlight = addHighlight(highlight);
    setCurrentHighlight(createdHighlight);
    setScrollTo(createdHighlight.id);
  };

  const removeAll = () => {
    deleteAllCuepoints();
    deleteAllHighlights();
  };

  const handleScroll = (id: string, effects?: boolean) => {
    const curHighlight = highlights.find((h) => h.id === id);
    if (!effects) {
      setCurrentHighlight(curHighlight);
      setCurrentCuepoint(id);
    }

    setScrollTo(id);
  };

  const handleChangeAll = (color: string) => {
    updateCuepoints(color);
  };

  useEffect(() => {
    appDispatch({ type: "setSidebarCollapsed", payload: true });
    return () => {
      appDispatch({ type: "setSidebarCollapsed", payload: false });
    };
  }, []);

  const handleBackClick = () => {
    if (updateCount > 0) {
      Modal.confirm({
        content: t(`components.binder.index.unsavedChanges`),
        onOk: () => {
          go({ to: `/media/projects/${projectData?.data.id}` });
        },
        okText: t(`components.ConfirmModal.ConfirmModal.yes`),
        cancelText: t(`components.ConfirmModal.ConfirmModal.no`),

        okButtonProps: {
          style: {
            backgroundColor: theme.colorPrimary,
          },
        },
        cancelButtonProps: {
          style: {
            borderColor: theme.colorPrimary,
            color: theme.colorPrimary,
          },
        },
      });
    } else {
      go({ to: `/media/projects/${projectData?.data.id}` });
    }
  };

  const handleSave = async () => {
    if (!media) {
      return; // todo handle error
    }

    if (updateCount === 0) {
      setSaving(true);
      setTimeout(() => {
        setSaving(false);
      }, 500);
      return;
    }

    try {
      setSaving(true);
      // save in parallel and then invalidate cache to avoid race conditions
      // and duplicate fetching of data
      await Promise.all([
        saveProjectAsset(
          "CuePoints",
          {
            data: transformKeysToSnakeCase(cuepoints),
          },
          cuepointsAsset?.id,
          {
            media_id: media.id!,
          }
        ),
        saveProjectAsset(
          "Highlights",
          {
            data: normalizeHighlightsForBackend(highlights),
            document_id: documentId,
          },
          highlightAsset?.id,
          {
            document_id: documentId!,
          }
        ),
      ]);
      refetchMediaAssets();
      refetchProjectAssets();
      // Show success notification
      api.success({
        message: t("components.binder.index.saveSuccessful"),
        description: t("components.binder.index.changesHaveBeen"),
        placement: "bottomRight",
      });

      setSaving(false);
      setUpdateCount(0);
      setCurrentHighlight(undefined);
      localStorage.setItem("currentSettings", "");
    } catch (error) {
      // Show error notification
      api.error({
        message: t("components.binder.index.saveFailed"),
        description: t("components.binder.index.failedToSave"),
        placement: "bottomRight",
      });
    }
  };
  return (
    <div
      style={{
        height: "100vh",
        position: "relative",
      }}
    >
      <Spin spinning={isLoading} fullscreen tip={t(`loading`)} />
      {contextHolder}

      <Flex vertical style={{ margin: -24 }}>
        <LiveHintWindow ts={ts} />
        <Flex
          style={{
            width: "100%",
            padding: 8,
            borderBottom: "1px solid",
            borderColor: theme.colorBorder,
          }}
          align="center"
          justify="space-between"
          gap={20}
        >
          <Button
            type="text"
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={handleBackClick}
          >
            {t("components.binder.index.backToProject")}
          </Button>
          <Space wrap={true}>
            <Typography.Text type="secondary">
              {t(`src.App.projects`)} /{" "}
            </Typography.Text>
            <Link
              to={"/media/projects/" + projectData?.data.id}
              style={{ color: theme.colorText }}
            >
              {projectData?.data.title}
            </Link>
            <Typography.Text type="secondary">
              / {t("components.binder.index.animationEditor")}
            </Typography.Text>
            {media?.id && (
              <Select
                size="middle"
                options={storyboardRelatedMedias?.map((media) => ({
                  value: media?.id,
                  label: (
                    <Typography.Text>
                      {t("components.binder.index.mediaFor")}{" "}
                      <ContactName
                        organizationId={organizationId}
                        contactId={media.contact_id}
                      />
                      {/* Media <UUID id={media.id} tooltip={false} /> */}
                    </Typography.Text>
                  ),
                }))}
                defaultValue={media?.id}
              />
            )}
          </Space>
          <Flex gap={20} align="center">
            <Tooltip title={t(`components.binder.index.toCreateArea`)}>
              <Button
                icon={<InfoCircleOutlined />}
                size="middle"
                type="text"
              ></Button>
            </Tooltip>

            <Space size={"small"}>
              {/* <Typography.Title
                  level={3}
                  type="secondary"
                  style={{ margin: 0, marginBottom: 0 }}
                  onClick={() =>
                    currentHighlight?.id &&
                    handleScroll(currentHighlight?.id)
                  }
                >
                  {currentHighlight?.id
                    ? t(
                        "src.pages.media.components.binder.index.selectedHighlight",
                        {
                          id: currentHighlight?.id,
                          pageNumber: currentHighlight?.position.pageNumber,
                        }
                      )
                    : t(`components.binder.index.noHighlightSelected`)}
                </Typography.Title> */}

              <CanAccess resource="media_generation_steps" action="show">
                <Tooltip title="Debug">
                  <Button
                    type="text"
                    icon={<BugBeetle size={20} />}
                    onClick={() => setDebug((value) => !value)}
                  />
                </Tooltip>
              </CanAccess>
              <ColorPicker
                size="middle"
                defaultValue={currentCuepoint?.roughNotation?.color || "#000"}
                presets={[
                  {
                    label: t("components.binder.BinderDrawerConfig.palette"),
                    colors: [],
                  },
                ]}
                onChange={(value) => {
                  handleChangeAll(value.toHexString());
                }}
              >
                <Button type="text" icon={<EditOutlined />} size="middle">
                  {t(`components.binder.index.changeAll`)}
                </Button>
              </ColorPicker>

              <Popconfirm
                title={t(`buttons.confirm`)}
                onConfirm={removeAll}
                okText={t(`components.ConfirmModal.ConfirmModal.yes`)}
                cancelText={t(`components.ConfirmModal.ConfirmModal.no`)}
                okType="danger"
              >
                <Button type="text" size="middle" icon={<DeleteOutlined />}>
                  {t(`buttons.deleteAll`)}
                </Button>
              </Popconfirm>

              <Button
                type="primary"
                size="middle"
                onClick={handleSave}
                disabled={saving}
                loading={saving}
              >
                {saving ? (
                  <span style={{ marginLeft: 8 }}>
                    {t("components.binder.index.saving")}
                  </span>
                ) : updateCount === 0 ? (
                  t("buttons.save")
                ) : (
                  t("components.binder.index.save", {
                    count: updateCount,
                  })
                )}
              </Button>
            </Space>
          </Flex>
        </Flex>
        <Flex vertical gap={10}>
          <PanelGroup direction="horizontal">
            <Panel
              defaultSize={40}
              minSize={20}
              style={
                {
                  // paddingRight: theme.paddingContentHorizontal,
                  // paddingLeft: theme.paddingContentHorizontal,
                }
              }
            >
              {media && (
                <Flex
                  vertical
                  gap={10}
                  style={{
                    height: "calc(100vh - 200px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {stepsData && hasDuration ? (
                    <Tabs
                      size="small"
                      items={[
                        {
                          key: "1",
                          label: (
                            <span
                              // hack to avoid parent padding
                              style={{
                                paddingLeft: theme.paddingContentHorizontal,
                              }}
                            >
                              {t(`components.binder.index.video`)}
                            </span>
                          ),
                          children: <AnimationVideo media={media} />,
                          forceRender: true,
                        },
                        {
                          key: "2",
                          label: t(`components.binder.index.script`),
                          children: (
                            <TimestampedScript
                              setTs={setTs}
                              ts={ts}
                              stepsData={stepsData}
                              handleScroll={handleScroll}
                              timeline={timelinePlayer.current}
                            />
                          ),
                        },
                      ]}
                      defaultActiveKey="2"
                      // style={{ overflow: "auto" }}
                    />
                  ) : (
                    <Flex
                      style={{ width: "100%", height: "100%" }}
                      justify="center"
                      align="center"
                    >
                      <AnimationVideo media={media} />
                    </Flex>
                  )}
                </Flex>
              )}
            </Panel>
            <PanelResizeHandle>
              <StyledVerticalPanelResizeHandle />
            </PanelResizeHandle>
            <Panel defaultSize={60} minSize={40}>
              <Flex style={{ width: "100%" }} justify="center">
                {documentAsset && (
                  <AnimationPdf
                    document={documentAsset}
                    handleNewCuepoint={handleNewCuepoint}
                    startScrollTo={scrollTo}
                    addHighlight={handleAddHighlight}
                    handleOnSave={handleSave}
                    brandKitPalette={brandKit?.data?.palette}
                  />
                )}
              </Flex>
            </Panel>
            {/* <Flex vertical gap={10} style={{ width: 300, marginRight: 10 }}>
            <Typography.Title
              level={3}
              style={{ margin: 0, marginBottom: -5 }}
            >
              {t("components.binder.index.cuePoints")}
            </Typography.Title>
            <BinderCuepoints
              highlightId={highlightIdToSetup}
              handleScroll={handleScroll}
            />
          </Flex> */}
          </PanelGroup>
          {media?.url && (
            <TimelineComponent
              ref={timelinePlayer}
              handleDragStart={handleDragStart}
              handleDragEnd={handleDragEnd}
              handleScroll={handleScroll}
              duration={duration}
              setTs={setTs}
              ts={ts}
            />
          )}
        </Flex>
      </Flex>

      <Drawer open={debug} onClose={() => setDebug(false)} size="large">
        <Flex gap={30} style={{ padding: 10 }} justify="space-between">
          <JsonPreview data={cuepoints} />
          <JsonPreview data={highlights} />
        </Flex>
      </Drawer>
    </div>
  );
};

const CheckedIcon = ({ checked }: { checked: boolean }) => {
  const { theme } = useAntTheme();
  if (checked)
    return (
      <CheckCircleOutlined
        style={{
          color: "#fff",
        }}
      />
    );

  return (
    <CheckCircleFilled
      style={{
        color: theme.colorPrimary,
      }}
    />
  );
};

const LiveHintWindow = ({ ts }: { ts: number }) => {
  const { updateCount } = useCuepointsStore((store) => ({
    updateCount: store.updateCount,
  }));
  const [close, setClose] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setClose(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (updateCount > 0) {
      setTimeout(() => {
        setClose(true);
      }, 3000);
    }
  }, [updateCount]);

  return (
    <Flex
      style={{
        position: "fixed",
        top: 70,
        right: 20,
        width: 400,
        backgroundColor: "#000",
        padding: "10px",
        borderRadius: "8px",
        boxSizing: "border-box",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        zIndex: 1000,
        opacity: close ? 0 : 1,
        transition: "opacity 0.3s ease",
      }}
      vertical
      gap={10}
    >
      <Flex justify="space-between">
        <Typography.Text strong style={{ color: "#fff" }}>
          {t("components.binder.index.howToCreate")}
        </Typography.Text>
        <Button
          type="text"
          size="small"
          icon={<CloseOutlined style={{ color: "#fff" }} />}
          onClick={() => setClose(true)}
        />
      </Flex>
      <Typography.Text style={{ color: "#fff" }}>
        <Space>
          <CheckedIcon checked={ts === 0} />
          <span>{t("components.binder.index.toStart")}</span>
        </Space>
      </Typography.Text>

      <Typography.Text style={{ color: "#fff" }}>
        <Space>
          <CheckedIcon checked={updateCount === 0} />
          <span>{t("components.binder.index.toChoose")}</span>
        </Space>
      </Typography.Text>
    </Flex>
  );
};

const ContactName = ({
  organizationId,
  contactId,
}: {
  organizationId: string;
  contactId: string;
}) => {
  const { data: contact } = useOne<ContactResponse>({
    resource: `media/${organizationId}/contacts`,
    id: contactId,
  });

  if (!contact) return;

  return (
    <Typography.Text>
      {contact.data.firstname} {contact.data.lastname}
    </Typography.Text>
  );
};
