import {
  Article,
  BagSimple,
  BeachBall,
  Books,
  Certificate,
  Confetti,
  CornersOut,
  Crown,
  HandHeart,
  Icon,
  Medal,
  Spiral,
} from "@phosphor-icons/react";
import { ChatsCircle } from "@phosphor-icons/react/dist/ssr";

export const TONES: Record<
  string,
  {
    icon: Icon;
    description: string;
  }
> = {
  Formal: {
    icon: Certificate,
    description: "src.data.tones.Formal",
  },
  Persuasive: {
    icon: Spiral,
    description: "src.data.tones.Persuasive",
  },
  Instructive: {
    icon: Books,
    description: "src.data.tones.Instructive",
  },
  Motivational: {
    icon: Medal,
    description: "src.data.tones.Motivational",
  },
  Conversational: {
    icon: ChatsCircle,
    description: "src.data.tones.Conversational",
  },
  Professional: {
    icon: BagSimple,
    description: "src.data.tones.Professional",
  },
  Empathetic: {
    icon: HandHeart,
    description: "src.data.tones.Empathetic",
  },
  Humorous: {
    icon: BeachBall,
    description: "src.data.tones.Humorous",
  },
  Authoritative: {
    icon: Crown,
    description: "src.data.tones.Authoritative",
  },
  Storytelling: {
    icon: Article,
    description: "src.data.tones.Storytelling",
  },
  Enthusiastic: {
    icon: Confetti,
    description: "src.data.tones.Enthusiastic",
  },
  Neutral: {
    icon: CornersOut,
    description: "src.data.tones.Neutral",
  },
} as const;
