import { Flex, Tooltip, Typography } from "antd";
import { BrandKit } from "../types";
import { useAntTheme } from "hooks/useAntTheme";

const Color = ({ color, size = 20 }: { color: string; size?: number }) => {
  const { theme } = useAntTheme();
  return (
    <Tooltip title={color} showArrow={false} mouseEnterDelay={1}>
      <div
        style={{
          width: size,
          height: size,
          backgroundColor: color,
          border: "1px solid",
          borderColor: theme.colorBorder,
          borderRadius: 4,
        }}
      />
    </Tooltip>
  );
};
export const BrandKitPreview = ({
  brandkit,
  showPalette = true,
  showName = true,
}: {
  brandkit: BrandKit;
  showPalette?: boolean;
  showName?: boolean;
}) => {
  // const assets = brandkit?.assets as | BrandKitAssetResponseWithTypedPurpose[]
  // | undefined;
  // const fonts = assets?.filter((asset) => asset.type === "Font");
  return (
    <Flex gap={5} align="center">
      {showPalette && (
        <Flex style={{ width: 40 }} gap={2}>
          <Color color={brandkit.palette.primary_color} />
          <Color color={brandkit.palette.secondary_color ?? "transparent"} />
        </Flex>
      )}
      {showName && <Typography.Text>{brandkit.name}</Typography.Text>}
    </Flex>
  );
};
