import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { ConfigProvider, theme, ThemeConfig } from "antd";
import { useTranslation } from "react-i18next";
import { getLanguageVariant } from "helpers/utils";
import dayjs from "dayjs";

type ColorModeContextType = {
  mode: string;
  setMode: (mode: string) => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const colorModeFromLocalStorage = localStorage.getItem("colorMode");

  const DEFAULT_COLOR_MODE = "light"; // light default theme forced, via MED-1316
  const [mode, setMode] = useState(
    colorModeFromLocalStorage || DEFAULT_COLOR_MODE
  );

  useEffect(() => {
    window.localStorage.setItem("colorMode", mode);
  }, [mode]);

  const setColorMode = () => {
    if (mode === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };

  const { darkAlgorithm, defaultAlgorithm } = theme;
  const colors = {
    primary: "#3BD4CA", // lify logo color
    white: mode === "dark" ? "#fff" : "#000",
    black: mode !== "dark" ? "#fff" : "#000",
  };
  // const dark = {
  //   colorBgBase: "#202225",
  // };
  // const light = {
  //   colorBgBase: "#E9ECF0",
  // };

  interface CustomThemeConfig extends ThemeConfig {
    token: ThemeConfig["token"];
  }
  const customTheme: CustomThemeConfig = {
    algorithm: mode === "light" ? defaultAlgorithm : darkAlgorithm,
    // ...(mode === "light" ? light : dark),
    token: {
      colorPrimary: mode !== "light" ? colors.primary : "#3BC6BD", // better contrast color (cf crypto app)
      fontFamily: "GTWalsheim",
      // fontSize: 20,
    },
    components: {
      Tree: {
        // directoryNodeSelectedColor: colors.white,
        // nodeSelectedBg: colors.primary,
        // directoryNodeSelectedColor: "red",
      },
      Badge: {
        statusSize: 8,
      },
    },
  };
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState({ locale: "en" });

  const loadLocale = async (language: string) => {
    const lang = getLanguageVariant(language);
    const antdLocaleModule = await import(
      `antd/es/locale/${lang.replace("-", "_")}.js`
    );
    const dayjsLocaleModule = await import(
      `dayjs/locale/${lang.split("-").shift()}.js`
    );
    const { default: antdLocale } = antdLocaleModule;
    const { default: dayjsLocale } = dayjsLocaleModule;
    return { antdLocale, dayjsLocale };
  };

  useEffect(() => {
    (async () => {
      if (locale.locale !== i18n.language) {
        const { antdLocale, dayjsLocale } = await loadLocale(i18n.language);
        setLocale(antdLocale);
        dayjs.locale(dayjsLocale);
      }
    })();
  }, [i18n.language, locale.locale]);

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode,
      }}
    >
      <ConfigProvider locale={locale} theme={customTheme}>
        {children}
      </ConfigProvider>
    </ColorModeContext.Provider>
  );
};
