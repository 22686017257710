import { Moon, SunDim } from "@phosphor-icons/react";
import { useTranslate } from "@refinedev/core";
import { Button, Flex, Typography } from "antd";
import { ColorModeContext } from "contexts";
import { useContext } from "react";

export const ThemeSwitch = () => {
  const t = useTranslate();
  const { mode, setMode } = useContext(ColorModeContext);

  return (
    <Flex align="center" justify="center" style={{ marginTop: 30 }}>
      <Button
        icon={
          <span className="anticon">
            {mode === "dark" ? (
              <Moon weight="bold" />
            ) : (
              <SunDim weight="bold" />
            )}
          </span>
        }
        shape="round"
        onClick={() => setMode("light")}
        style={{ cursor: "pointer" }}
      >
        <Typography.Text type="secondary">
          {mode === "dark"
            ? t("src.components.ThemeSwitch.darkMode")
            : t("src.components.ThemeSwitch.lightMode")}
        </Typography.Text>
      </Button>
    </Flex>
  );
};
