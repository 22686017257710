import { useTranslate } from "@refinedev/core";
import React, { useEffect } from "react";
import { Flex } from "antd";
import { useBoundStore } from "store";
import {
  MultiStepWithDescription,
  MultiStepWithDescriptionKey,
  Usage,
} from "./types";
import { OtherInput } from "./OtherInput";
import { TextAndDescription } from "./TextAndDescription";
import { Buttons } from "./Buttons";
import { placeholders } from "./helpers";

const MultipleChoiceStep: React.FC = () => {
  const t = useTranslate();
  const { setStepValue, onboardingState, currentStep } = useBoundStore(
    (state) => ({
      setStepValue: state.setStep,
      currentStep: state.onboardingState
        .currentStep as keyof MultiStepWithDescriptionKey,
      onboardingState: state.onboardingState,
    })
  );
  const [textInputVisible, setTextInputVisible] = React.useState(
    !!onboardingState[currentStep]?.other
  );
  const handleClick = (value: string) => {
    setStepValue(value);
  };
  const onChangeOtherValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStepValue({
      other: e.target?.value,
    });
  };

  useEffect(() => {
    const answers = onboardingState.usage.answers ?? [];
    if (answers.includes(Usage.OTHER)) {
      setTextInputVisible(true);
    } else {
      setTextInputVisible(false);
    }
  }, [onboardingState.usage.answers]);

  return (
    <Flex gap={50} vertical justify="center" align="center">
      <TextAndDescription />
      <Buttons<MultiStepWithDescription>
        handleClick={handleClick}
        compareFn={(a, b) => a.answers?.includes(b as Usage) ?? false}
      />
      {textInputVisible && (
        <OtherInput
          placeholder={t(
            placeholders[currentStep as MultiStepWithDescriptionKey] as string
          )}
          onChange={onChangeOtherValue}
        />
      )}
    </Flex>
  );
};

export default MultipleChoiceStep;
