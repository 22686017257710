import { useOne } from "@refinedev/core";
import { FaceProfileResponse } from "../types";
import { CSSProperties } from "react";
import { useOrganization } from "hooks/useOrganization";
import FaceProfilePreviewView from "./FaceProfilePreviewView";

export const FaceProfilePreview = ({
  id,
  assetId,
  assetPath,
  name,
  style,
  onClick,
  ghost,
  selected,
}: {
  id?: string;
  assetId?: string;
  assetPath?: string;
  name?: string;
  style?: CSSProperties;
  onClick?: () => void;
  ghost?: boolean;
  selected?: boolean;
}) => {
  const { organization } = useOrganization({});
  const { data } = useOne<FaceProfileResponse>({
    resource: `media/${organization?.id}/face_profiles`,
    id,
    queryOptions: {
      enabled: !!id && !assetPath,
      refetchInterval(data) {
        // check until we have a path to show
        return data?.data.category === "Premade"
          ? false
          : data?.data.extracted_asset_path || data?.data.custom_asset_path
            ? false
            : 3000;
        // 3s
      },
    },
    errorNotification() {
      return false;
    }, // hide errors
  });

  const isProcessing = !data?.data && !assetId && !assetPath && !ghost;
  const path =
    data?.data.thumbnail_asset_path ??
    data?.data.extracted_asset_path ??
    data?.data.custom_asset_path ??
    assetPath;

  return (
    <FaceProfilePreviewView
      name={name}
      path={assetPath ?? path}
      style={style}
      onClick={onClick}
      isProcessing={isProcessing}
      selected={selected}
      ghost={ghost}
    />
  );
};
