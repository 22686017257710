import { Card, CardProps, Flex, Form, Select, Tooltip, Typography } from "antd";
import { AudioSample } from "../../components/AudioSample";
import VideoPreview from "./VideoPreview";
import { FontPreviewCard } from "./FontPreview";
import { PicturePreview } from "./PicturePreview";
import { useEffect, useState } from "react";
import { BrandKitAssetResponse } from "../../types";
import {
  AssetPurpose,
  BrandKitAssetResponseWithTypedPurpose,
  purposes,
} from "./BrandKitAssets";
import { DeleteButton } from "@refinedev/antd";
import { useInvalidate, useResource } from "@refinedev/core";
import { useOrganization } from "hooks/useOrganization";
interface BrandKitAssetPreviewProps<T> extends CardProps {
  asset: T;
  assets?: BrandKitAssetResponse[]; // TODO: mark as required;
  brandKitId?: string;
  setStagedAssets?: React.Dispatch<
    React.SetStateAction<(Partial<BrandKitAssetResponse> & { uid: string })[]>
  >;
  stagedAssets?: (Partial<BrandKitAssetResponseWithTypedPurpose> & {
    uid: string;
  })[];
  isNew?: boolean; // New assets' purpose can be edited
}
type FileOrPath = { file: File; path?: never } | { file?: never; path: string };

export const BrandKitAssetPreview = <
  T extends {
    type: string;
    name: string;
    purpose?: AssetPurpose;
    uid?: string;
    asset_id?: string;
  } & FileOrPath,
>({
  asset,
  assets,
  stagedAssets,
  setStagedAssets,
  isNew = false,
  ...props
}: BrandKitAssetPreviewProps<T>) => {
  const type = asset.type;
  // Track the purpose changes in this variable derived from parent state
  const currentAsset = stagedAssets?.find((a) => a.uid === asset.uid);
  const { organization } = useOrganization({});
  const currentPurpose = currentAsset?.purpose ?? asset.purpose;
  const [purposeValue, setPurposeValue] = useState(currentPurpose);
  const invalidate = useInvalidate();
  const checkIfPurposeAllowed = (option: string, assetPurpose?: string) => {
    return !(
      stagedAssets?.some((asset) => asset.purpose === option) ||
      assets?.some((asset) => asset.purpose === option)
    );
  };
  const { id: brandKitId } = useResource();

  // Set the purpose for music as it doesn't have a select
  useEffect(() => {
    setStagedAssets?.((prev) => {
      if (asset?.type === "Music") {
        return prev.map((a) =>
          a.uid === currentAsset?.uid ? { ...a, purpose: "Music" } : a
        );
      }
      return prev;
    });
  }, [setStagedAssets]);

  const invalidateBrandkit = async () => {
    invalidate({
      resource: `media_brandkits`,
      id: brandKitId,
      invalidates: ["all"],
    });
  };
  const purposeItem = !isNew ? (
    <Flex align="center" justify="space-between">
      <>{purposeValue}</>
      <DeleteButton
        accessControl={{ enabled: false }}
        hideText
        recordItemId={asset.asset_id}
        resource={`/media/${organization?.id}/brand_kits/${brandKitId ?? props.brandKitId}/assets`}
        onSuccess={invalidateBrandkit}
      />
    </Flex>
  ) : (
    <Form.Item style={{ marginBottom: 0 }}>
      <Select
        key={stagedAssets?.length || 0}
        value={purposeValue}
        onChange={(value) => {
          setPurposeValue(value);
          setStagedAssets?.((assets) =>
            assets.map((a) =>
              a.uid === asset.uid ? { ...a, purpose: value } : a
            )
          );
        }}

        // disabled={!isNew}
      >
        {purposes[asset.type].map((purpose) => {
          const disabled = !checkIfPurposeAllowed?.(purpose, currentPurpose);
          return (
            <Select.Option
              required
              disabled={disabled}
              key={purpose}
              value={purpose}
            >
              {purpose}
              {/* not sure we need this */}
              {/* {" "}{disabled && !asset.path && purpose !== purposeValue
                ? "(Already exists)"
                : ""} */}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <Flex vertical gap={10}>
      {type === "Picture" && (
        <PicturePreview
          style={{ flex: 1 }}
          fileName={asset.name}
          image={asset?.file ?? asset.path}
          actions={[purposeItem]}
        />
      )}
      {type === "Music" && (
        <Card size="small" style={{ minWidth: 200 }}>
          <Flex gap={10} justify="space-between" align="center">
            <AudioSample canDelete={false} audio={asset?.file ?? asset.path}>
              <Tooltip title={asset.name} placement="bottom" arrow={false}>
                <Typography.Text
                  ellipsis
                  style={{ flex: "0 1 105px", width: 105 }}
                >
                  {asset.name}
                </Typography.Text>
              </Tooltip>
            </AudioSample>
            <DeleteButton
              accessControl={{ enabled: false }}
              hideText
              recordItemId={asset.asset_id}
              resource={`/media/${organization?.id}/brand_kits/${brandKitId ?? props.brandKitId}/assets`}
              onSuccess={invalidateBrandkit}
            />
          </Flex>
        </Card>
      )}
      {type === "Video" && (
        <VideoPreview
          fileName={asset.name}
          video={asset?.file ?? asset.path}
          actions={[purposeItem]}
        />
      )}
      {type === "Font" && (
        <FontPreviewCard
          title={asset.name}
          font={asset?.file ?? asset.path}
          actions={[purposeItem]}
        />
      )}
    </Flex>
  );
};
