import { Flex, Typography } from "antd";
import { ContactResponse, PresenterProfileResponse } from "pages/media/types";
import { PresenterProfilePreviewView } from "./PresenterProfilePreviewView";
import { useOne } from "@refinedev/core";

export const PresenterProfilePreview = ({
  organizationId,
  presenterId,
  contact,
  showContactInfo,
}: {
  organizationId: string;
  presenterId: string;
  contact: ContactResponse;
  showContactInfo?: boolean;
}) => {
  const { data: presenter } = useOne<PresenterProfileResponse>({
    resource: `media/${organizationId}/presenter_profiles`,
    id: presenterId,
  });

  return (
    <Flex vertical align="center" gap={10}>
      {presenter && (
        <PresenterProfilePreviewView
          presenter={presenter.data}
          style={{ width: 100, height: 100 }}
        />
      )}
      <Flex vertical align="center">
        <Typography.Text>
          {contact.firstname} {contact.lastname}
        </Typography.Text>
        {!showContactInfo && (
          <Typography.Text type="secondary">
            {presenter?.data.name}
          </Typography.Text>
        )}
        {showContactInfo && (
          <>
            <Typography.Text type="secondary">{contact.email}</Typography.Text>
            <Typography.Text type="secondary">{contact.phone}</Typography.Text>
          </>
        )}
      </Flex>
    </Flex>
  );
};
