import { Authenticated, useOne } from "@refinedev/core";
import { useParams } from "react-router-dom";
import type { MediaAsset } from "../types";
import { MediaAssetPreview } from "../components/MediaAssetPreview";
import { Show } from "@refinedev/antd";

export const MediaAssetDetail = () => {
  const { id } = useParams();
  console.debug("media asset", id);
  const { data } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id,
  });
  return (
    <Authenticated key="mediaAssetDetail">
      <Show title={id}>{data && <MediaAssetPreview asset={data.data} />}</Show>
    </Authenticated>
  );
};
