import {
  Timeline,
  TimelineAction,
  TimelineRow,
  TimelineState,
} from "@xzdarcy/react-timeline-editor";
import { forwardRef, useEffect, useState } from "react";
import { useCuepointsStore } from "./useCuepointsStore";
import { Flex, Space, Typography } from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import { Pause, Play, SkipBack, SkipForward } from "@phosphor-icons/react";
import { useHighlightsStore } from "./useHighlightsStore";
import styled from "styled-components";
import { AiStarIcon } from "components/icons";

type TimelineComponentProps = {
  handleDragStart: () => void;
  handleDragEnd: (time: number) => void;
  handleScroll: (id: string, effect?: boolean) => void;
  duration: number;
  setTs: (time: number) => void;
  ts: number;
};

export const TimelineComponent = forwardRef<
  TimelineState,
  TimelineComponentProps
>(
  (
    { handleDragStart, handleDragEnd, handleScroll, duration, setTs, ts },
    ref
  ) => {
    const { theme } = useAntTheme();

    const [cuepointsTimelineItems, setCuepointsTimelineItems] = useState<
      TimelineRow[]
    >([]);

    const cuepoints = useCuepointsStore((state) => state.cuepoints);
    const { currentHighlight } = useHighlightsStore();
    const [isPlaying, setIsPlaying] = useState(false);
    const videoElement = document.querySelector("video");

    const handleVideoPlay = () => {
      setIsPlaying(true);
    };

    const handleVideoPause = () => {
      setIsPlaying(false);
    };

    useEffect(() => {
      if (videoElement) {
        videoElement.addEventListener("play", handleVideoPlay);
        videoElement.addEventListener("pause", handleVideoPause);

        return () => {
          videoElement.removeEventListener("play", handleVideoPlay);
          videoElement.removeEventListener("pause", handleVideoPause);
        };
      }
    }, [videoElement]);

    useEffect(() => {
      const actions =
        cuepoints.length === 0
          ? []
          : cuepoints.map((cuepoint, index) => ({
              id: cuepoint.highlightId,
              start: cuepoint.start,
              end: cuepoint.start + 1,
              flexible: true,
              effectId: "effect0",
            }));

      const row = [
        {
          id: "0",
          actions,
        },
      ];

      setCuepointsTimelineItems(row);
    }, [cuepoints]);

    const handlePlayPause = () => {
      if (!videoElement) return;
      if (!isPlaying) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
      setIsPlaying((prev) => !prev);
    };

    const fastForward = () => {
      if (videoElement) {
        videoElement.currentTime += 10;
      }
    };

    const rewind = () => {
      if (videoElement) {
        videoElement.currentTime -= 10;
      }
    };

    const setTime = (time: number) => {
      if (videoElement) {
        videoElement.currentTime = time;
        setTs(parseFloat(time.toFixed(3)));
      }
    };

    const getScaleTime = (time: number, mills?: boolean) => {
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;
      let milliseconds;

      if (mills) {
        seconds = Math.floor(seconds);
        milliseconds = ((time % 1) * 100).toFixed(0).padStart(2, "0");

        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}.${milliseconds}`;
      }

      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const effects = {
      effect0: {
        id: "effect0",
        source: {
          enter: (params) => {
            // needs to be commented out unless we stop getting more than one cue point on the same time from the backend. Otherwise we will have a scroll to the wrong highlight.
            // if (params.action.id != currentHighlight.id) {
            //   handleScroll(params.action.id, true);
            // }f
          },
        },
      },
    };

    const CuepointRegular = ({
      action,
      row,
    }: {
      action: TimelineAction;
      row: TimelineRow;
    }) => {
      const { highlights } = useHighlightsStore();
      const highlight = highlights.find((h) => h.id === action.id);
      const highlightIndex = highlights.indexOf(highlight);
      const generated = highlight?.position?.usePdfCoordinates === true;

      return (
        <Flex
          align="center"
          justify="center"
          className={"effect0"}
          style={{
            backgroundColor:
              action.id === currentHighlight?.id
                ? theme.colorPrimary
                : theme.colorBgSpotlight,
            color: "rgba(255, 255, 255, 0.8)",
            border: "1px solid",
            borderColor: "rgba(255, 255, 255, 0.6)",
            height: 50,
            borderRadius: theme.borderRadius,
            cursor: "pointer",
            position: "relative",
          }}
        >
          {generated && (
            <div
              style={{
                position: "absolute",
                top: 5,
                right: 5,
              }}
            >
              <AiStarIcon
                width={15}
                height={"auto"}
                color={
                  action.id === currentHighlight?.id
                    ? "#fff"
                    : "url(#paint0_linear_282_445)"
                }
                defs={
                  action.id === currentHighlight?.id ? (
                    ""
                  ) : (
                    <linearGradient
                      id="paint0_linear_282_445"
                      x1="0%"
                      y1="100%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#EE00C2" />
                      <stop offset="100%" stopColor="#5F00E3" />
                    </linearGradient>
                  )
                }
              />
            </div>
          )}
          {highlightIndex + 1}
        </Flex>
      );
    };

    return (
      <Flex
        vertical
        style={{
          position: "absolute",
          bottom: 0,
          width: "calc(100% + 48px)",
          zIndex: 10, // to show on top of documents and highlights
          borderTop: "1px solid",
          borderColor: theme.colorBorder,
        }}
      >
        {/* <Flex justify="center">
              <Typography.Title level={3} type="secondary" style={{ margin: 0, marginBottom: 3 }}>
                {secToHHMMSS(Math.floor(playedSeconds))}
              </Typography.Title>
            </Flex> */}
        <Flex
          gap={20}
          justify="center"
          align="center"
          style={{
            width: "100%",
            height: 40,
            backgroundColor: theme.colorBgBase,
            cursor: "pointer",
          }}
        >
          <SkipBack size={18} onClick={rewind} weight="fill" />
          {isPlaying ? (
            <Pause size={28} onClick={handlePlayPause} weight="fill" />
          ) : (
            <Play size={28} onClick={handlePlayPause} weight="fill" />
          )}
          <SkipForward size={18} onClick={fastForward} weight="fill" />
          <Space style={{ width: 150 }} size={"small"}>
            <Typography.Text style={{ color: theme.colorPrimary }}>
              {getScaleTime(Number(ts.toFixed(2)), true)}
            </Typography.Text>
            <Typography.Text type="secondary">/</Typography.Text>
            <Typography.Text>
              {getScaleTime(Number(duration.toFixed(2)), true)}
            </Typography.Text>
          </Space>
        </Flex>
        <TimelineWrapper>
          <Timeline
            editorData={cuepointsTimelineItems}
            scaleSplitCount={10}
            autoReRender
            autoScroll={true}
            effects={effects}
            onCursorDragStart={handleDragStart}
            onCursorDragEnd={handleDragEnd}
            minScaleCount={duration}
            scaleWidth={50}
            disableDrag
            getScaleRender={(scale) => getScaleTime(scale)}
            getActionRender={(action, row) => {
              return <CuepointRegular action={action} row={row} />;
            }}
            onClickAction={(e, { action }) => handleScroll(action.id)}
            onClickRow={(e, { time }) => setTime(time)}
            onClickTimeArea={(time, e) => {
              setTime(time);
              return true;
            }}
            hideCursor={false}
            ref={ref}
            style={{ height: 100, width: "100%" }}
          />
        </TimelineWrapper>
      </Flex>
    );
  }
);

const TimelineWrapper = styled.div`
  .timeline-editor {
    width: 100%;
    height: 120px;
  }

  // .timeline-editor-edit-row {
  //   top: 0 !important;
  // }

  .timeline-editor-action {
    background-color: transparent;
    z-index: 9999;
    cursor: pointer;
  }

  .timeline-editor-action-left-stretch,
  .timeline-editor-action-right-stretch {
    display: none;
  }

  .timeline-editor-cursor-area {
    background-color: ${({ theme }) => theme.colorPrimary};
    width: 2px;
  }

  .timeline-editor-cursor svg path {
    fill: ${({ theme }) => theme.colorPrimary};
  }
  .timeline-editor-edit-row {
    //  dots todo
  }
`;
