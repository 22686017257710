import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Create, SaveButton, useForm } from "@refinedev/antd";
import {
  Authenticated,
  useApiUrl,
  useCustomMutation,
  useGo,
  useNotification,
} from "@refinedev/core";
import { Form, Input, Select, Upload } from "antd";
import axios from "axios";
import { useState } from "react";
import { Organization } from "types";

import { InputHelperText } from "components/InputHelperText";
import TickerAutoComplete from "components/TickerAutoComplete";

const { Dragger } = Upload;

type FormData = {
  document_type: string;
  sub_category: string;
  source_url: string;
  filename: string;
};

export const MediaDocumentCreate = ({
  organization,
}: {
  organization?: Organization;
}) => {
  const t = useTranslate();
  const [isUploading, setIsUploading] = useState(false);
  const [callback, setCallback] = useState<any>({
    file: { name: "external" },
  });
  const go = useGo();
  const { open } = useNotification();

  const { formProps, saveButtonProps, form } = useForm<FormData>({
    // won't be used, the form will be submitted manually to support
    // custom file upload
    action: "create",
    resource: "media/documents",
    onMutationSuccess: (data: any) => {},
  });
  const document_type = Form.useWatch("document_type", form);

  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation({});

  const createDocument = ({
    filename,
    sub_category,
    source_url,
  }: FormData): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      mutate(
        {
          url: `${apiUrl}/media/documents/${organization?.id}/${encodeURIComponent(filename)}`,
          method: "post",
          values: {
            name: filename,
            description: filename,
            document_type,
            sub_category,
            source_url,
          },
        },
        {
          onError: (error, variables, context) => {
            // An error occurred!
          },
          onSuccess: (data, variables, context) => {
            resolve(data.data);
          },
        }
      );
    });
  };

  const customRequest = async ({
    // filename,
    file,
    onSuccess,
  }: {
    // filename?: string;
    file: any;
    onSuccess?: (body: any, xhr?: XMLHttpRequest | undefined) => void;
  }) => {
    setCallback({ file, onSuccess });

    // if (!filename) return false;

    // const uploadData = await getUploadData({ filename: file.name });
    // const url = uploadData.target.url;
    // const formData = new FormData();

    // for (const name in uploadData.target.fields) {
    //   formData.append(name, uploadData.target.fields[name]);
    // }
    // formData.append("file", file);

    // // use axios to support progress
    // await axios.post(url, formData, {
    //   // not being called :(
    //   onUploadProgress: (progressEvent) => {
    //     console.debug({ progressEvent });
    //   },
    // });
    // onSuccess && onSuccess(file);
  };

  const handleFinish = async (values: object) => {
    const { file, onSuccess } = callback;

    const uploadData = await createDocument({
      ...(values as FormData),
      filename: file.name,
    });

    if (file.name !== "external") {
      // prepare upload to S3
      const url = uploadData.target.url;
      const formData = new FormData();

      for (const name in uploadData.target.fields) {
        formData.append(name, uploadData.target.fields[name]);
      }
      formData.append("file", file);

      // upload to S3
      setIsUploading(true);
      // use axios to support progress (not working :())
      await axios.post(url, formData, {
        // not being called :(
        onUploadProgress: (progressEvent) => {
          console.debug({ progressEvent });
        },
      });
      // update the file upload status in the upload component
      onSuccess && onSuccess(file);
    }

    open?.({
      type: "success",
      message: t("media.documents.create.uploadSuccessful"),
      description: `Starting processing document ${file.name}`,
      key: file.name,
    });
    // manual redirect
    setTimeout(() => {
      go({ to: { resource: "media_documents", action: "list" } });
    }, 1000);
  };

  return (
    <Authenticated key="documentCreate">
      <Create
        saveButtonProps={saveButtonProps}
        title={t("media.documents.create.uploadDocument")}
        footerButtons={({ saveButtonProps }) => (
          <>
            <SaveButton
              {...saveButtonProps}
              disabled={isUploading}
              type="primary"
            >
              {t("media.documents.create.submit")}
            </SaveButton>
          </>
        )}
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={handleFinish}
          disabled={isUploading}
        >
          <Form.Item
            label={t("media.documents.create.documentType")}
            name="document_type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={t("media.documents.create.pickAType")}
              options={[
                {
                  value: "BankResearch",
                  label: t("media.documents.create.bankResearch"),
                },
                {
                  value: "FundsPresentation",
                  label: t("media.documents.create.fundProspectus"),
                },
                {
                  value: "IpoDocument",
                  label: t("media.documents.create.ipoProspectus"),
                },
                {
                  value: "PortfolioReview",
                  label: t("media.documents.create.portfolioReview"),
                },
                {
                  value: "EquityResearch",
                  label: t("media.documents.create.equityResearch"),
                },
                {
                  value: "CompanyFinancialPublication",
                  label: t(
                    "media.documents.create.companyFinancialPublication"
                  ),
                },
                { value: "Unknown", label: t("media.documents.create.other") },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="sub_category"
            label={t("media.documents.create.tickerSubCategory")}
          >
            <TickerAutoComplete onChange={() => true} size="middle" />
          </Form.Item>
          <Form.Item label={t("media.documents.create.sourceUrl")}>
            <Form.Item name="source_url" rules={[{ type: "url" }]} noStyle>
              <Input />
            </Form.Item>
            <InputHelperText>
              {t("media.documents.create.urlWhereThe")}
            </InputHelperText>
          </Form.Item>
          <div>
            <Dragger
              maxCount={1}
              accept="application/pdf"
              name={t("media.documents.create.file")}
              multiple={false}
              customRequest={customRequest}
            >
              {/* <p className="ant-upload-drag-icon">
  </p> */}
              <p className="ant-upload-text">
                {t("media.documents.create.clickOrDrag")}
              </p>
              <p className="ant-upload-hint">
                {t("media.documents.create.acceptedFormats")}
              </p>
            </Dragger>
            <InputHelperText>
              {t("media.documents.create.uploadWillStart")}
            </InputHelperText>
          </div>
        </Form>
      </Create>
    </Authenticated>
  );
};


