import React, { useState, useEffect } from "react";

import {
  IResourceComponentsProps,
  useApiUrl,
  useList,
  useShow,
  useGetIdentity,
  BaseKey,
  useNotification,
} from "@refinedev/core";

import { useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
  Form,
  Input,
  Select,
  Upload,
  Table,
  Switch,
  Checkbox,
  InputNumber,
  Button,
  Radio,
  Row,
  Col,
  Spin,
  Space,
  TreeSelect,
  Typography,
} from "antd";

import type { ColumnsType } from "antd/es/table";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { balanceToken } from "../../../helpers/utils";
import { FUND_MAX_TICKER_LENGTH } from "../../../constants";
import { User } from "authProvider";

const { PlusOutlined, SearchOutlined } = Icons;
const { Text } = Typography;

interface DataType {
  key: string;
  active: React.ReactElement;
  name: string;
  ticker: string;
  rank: string;
}

interface ExchangeSelectionType {
  tokens: string[];
  _restoredTokens: string[];
  exchange: string;
}

export const FundCreate: React.FC<IResourceComponentsProps> = () => {
  const params = useParams();
  const isEditPage = !!params?.id;
  const { open, close } = useNotification();
  const [exchangesNumberOfItems, setExchangesNumberOfItems] = useState<any>({});
  const [listTokensWeight, setListTokensWeight] = useState<any>({});
  const [listCurrenciesWeight, setListCurrenciesWeight] = useState<any>({});
  const [listTokensSearched, setListTokensSearched] = useState<any>([]);
  const [listTokensSelected, setListTokensSelected] = useState<string[]>([]);
  const [viewListTokensSelected, setViewListTokensSelected] =
    useState<boolean>(false);
  const [showColumnWeight, setShowColumnWeight] = useState<boolean>(false);
  const [isFirstGetDataSearch, setIsFirstGetDataSearch] =
    useState<boolean>(true);

  const columnsDefault = [
    {
      title: "Active",
      dataIndex: "active",
      align: "center" as "center",
      className: "column-name",
    },
    {
      title: "Name",
      className: "column-name",
      dataIndex: "name",
      align: "left" as "left",
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      align: "left" as "left",
      className: "column-name",
    },
    {
      title: "Rank (Market Cap)",
      dataIndex: "rank",
      align: "center" as "center",
      className: "column-name",
    },
  ];

  const {
    queryResult: { data: dataFund, isSuccess: dataFundSuccess },
  } = useShow({
    resource: "api/funds",
    id: params?.id,
    queryOptions: {
      enabled: !!params?.id,
      retry: !!params?.id,
      cacheTime: 0,
    },
  });

  // const isFormReady = (isEditPage && dataFundLoaded) || !isEditPage;

  const API_URL = useApiUrl();
  const { data: dataIdentity } = useGetIdentity<User>();
  const accessToken = dataIdentity?.token?.access_token;
  const [categorySelected, setCategorySelected] = useState<any>(0);
  const [subCategorySelected, setSubCategorySelected] = useState<any>(0);

  const [treeData, setTreeData] = useState<any>("");

  const { data: listExchange } = useList({
    resource: "api/exchanges",
  });

  const { data: listCategoriesTree } = useList({
    resource: "api/funds/categories?depth=1",
  });

  const { selectProps: categorySelectProps } = useSelect({
    resource: "api/funds/categories",
    optionLabel: "name",
    optionValue: "id",

    pagination: {
      mode: "off",
    },
  });

  const { selectProps: subCategorySelectProps } = useSelect({
    resource: `api/funds/categories?parent_id=${categorySelected}`,
    optionLabel: "name",
    optionValue: "id",

    queryOptions: {
      enabled: !!categorySelected,
      retry: !!categorySelected,
    },

    pagination: {
      mode: "off",
    },
  });

  const { data: listTokens, isFetching: isLoadingListCurrencies } = useList({
    resource: `${
      !subCategorySelected
        ? "api/currencies"
        : `api/currencies?category_id=${subCategorySelected}`
    }`,
    queryOptions: {
      retry: !!subCategorySelected,
    },
  });

  const [data, setData] = useState<DataType[]>();
  const [trailingStopLoss, setTrailingStopLoss] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState<any>(10);
  const [listTokensDisabled, setListTokensDisabled] = useState<string[]>([]);
  const [listExchangesSelected, setListExchangesSelected] = useState<any>([]);
  // const [exchangeDefault, setExchangeDefault] = useState("");
  const [valueAtRisk, setValueAtRisk] = useState(false);
  // const [tags, setTags] = useState({ value: "A1", label: "A1" });
  // ColumnsType<DataType>
  const [columns, setColumns] = useState<any>(columnsDefault);

  const [valueTokenSearch, setValueTokenSearch] = useState<string>("");
  const [debouncedStringSearch] = useDebounce(valueTokenSearch, 500);
  const [optionsSearchToken, setOptionsSearchToken] = useState<any[]>([]);

  const imageUploadHeaders = {
    Authorization: `Bearer ${accessToken}`,
  };

  const [formValues, setFormValues] = useState<any>({
    strategy: {
      name: "",
      description: "",
      timeframe: "1M",
      threshold: 0.05,
      ticker: "",
      logo: "",
      banner: "",
      banner_mobile: "",
      image: "",
      categories: [],
      subcategories: [],
    },
    allocation: {
      id: 0,
      name: "Admin",
      allocation_method: "market_cap",
      min_allocation: 3,
      max_allocation: 100,
      tokens: [],
      default_exchange: "",
      category_id: 0,
      subcategory_id: 0,
    },
    exchanges: [],
  });

  const { data: selectListExchangesSearched, isFetching: fetchingSearch } =
    useList({
      resource: `api/currencies/like/${debouncedStringSearch}`,
      queryOptions: {
        enabled: !!debouncedStringSearch,
        retry: !!debouncedStringSearch,
      },
    });

  const handleExchangesNumberOfItems = (key: any, value: any) => {
    setExchangesNumberOfItems({
      ...exchangesNumberOfItems,
      [key]: value,
    });
  };

  const onSearchToken = (value: React.SetStateAction<string>) => {
    setValueTokenSearch(value);
  };

  const onChangeField = (
    keyParent: string,
    keyChild: string | null,
    data: any
  ) => {
    if (keyChild) {
      if (keyChild === "categories") {
        setFormValues({
          ...formValues,
          [keyParent]: {
            ...formValues[keyParent],
            subcategories: [],
            [keyChild]: data[0] ? data : [],
          },
        });
      } else if (keyChild === "subcategories") {
        setFormValues({
          ...formValues,
          [keyParent]: {
            ...formValues[keyParent],
            [keyChild]: data[0] ? data : [],
          },
        });
      } else {
        setFormValues({
          ...formValues,
          [keyParent]: {
            ...formValues[keyParent],
            [keyChild]: data,
          },
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [keyParent]: data,
      });
    }
  };

  const onUploadedImage = (files: any) => {
    const objLinkImage: any = {};
    files?.forEach((file: any) => {
      if (file.key === "original") {
        objLinkImage["image"] = file.url;
      } else {
        objLinkImage[file.key] = file.url;
      }
    });
    setFormValues({
      ...formValues,
      strategy: {
        ...formValues.strategy,
        ...objLinkImage,
      },
    });
  };

  const getParamsUploadImages = (
    purposeParam: string[] = ["logo", "banner", "banner_mobile"]
  ) => {
    const decodedParam = purposeParam.map((value) => decodeURIComponent(value));
    const searchParams = new URLSearchParams();
    decodedParam.forEach((value) => searchParams.append("purpose", value));
    return searchParams.toString();
  };

  const handleChangeViewTable = (
    value: boolean | ((prevState: boolean) => boolean)
  ) => {
    setViewListTokensSelected(value);
  };

  const handleListTokensSelected = (data: any[] | undefined) => {
    const dataFiltered = data
      ?.filter((currencyRow: any) =>
        listTokensSelected.includes(currencyRow.ticker)
      )
      .sort((a, b) => {
        return +(a.rank === null) - +(b.rank === null) || a.rank - b.rank;
      });

    return dataFiltered;
  };

  const handleChangeSwitch = (token: string, value: boolean) => {
    let newListTokensDisabled: string[] = listTokensDisabled;
    if (value) {
      newListTokensDisabled = newListTokensDisabled.filter(
        (item) => item !== token
      );
    } else {
      newListTokensDisabled.push(token);
    }
    setListTokensDisabled([...newListTokensDisabled]);
  };

  const { formProps, onFinish, formLoading, form } = useForm({
    redirect: false,
    meta: {
      method: `${isEditPage ? "put" : "post"}`,
    },
    resource: `${isEditPage ? `api/funds/${params?.id}` : "api/funds/create"}`,
    onMutationError: (data: any, variables: any, context: any) => {
      open?.({
        key: "create-fund",
        type: "error",
        message: data?.response?.data?.detail?.message,
        description: `Error ${data?.response?.status}`,
        undoableTimeout: 5,
        cancelMutation: () => {
          // when undo button is clicked, run this callback
          close?.("create-fund");
        },
      });
    },
    errorNotification: false,
  });

  const handleSubmit = () => {
    const newFormValues = {
      ...formValues,
      allocation: {
        ...formValues.allocation,
        min_allocation: formValues.allocation.min_allocation / 100,
        max_allocation: formValues.allocation.max_allocation / 100,
      },
    };

    onFinish(newFormValues);
  };
  const handleChangeExchangeToken = (
    exchange: BaseKey | undefined,
    token: string
  ) => {
    return (e: { target: { checked: boolean } }) => {
      handleChangeSwitch(token + "@" + exchange, e.target.checked);
    };
  };

  const isTokenDisabled = (exchange: string, token: string) => {
    return !!listTokensDisabled.find(
      (item) => item === token || item === token + "@" + exchange
    );
  };

  const isExchangeTokenSelected = React.useCallback(
    (exchange: any, token: any) => {
      const result = listExchangesSelected
        .find((x: { exchange: any }) => x.exchange === exchange)
        ?.tokens?.includes(token);
      return result;
    },
    [listExchangesSelected]
  );

  const isExchangeDefault = (value: string) => {
    return formValues.allocation.default_exchange === value;
  };

  const onHandleSearchToken = (value: string | any[]) => {
    const listTokensFiltered = listTokensSearched.filter(
      (item: { ticker: string }) => value.includes(item.ticker)
    );
    const dataListExchangesSearched: any = selectListExchangesSearched?.data;
    const newListTokensSearched = dataListExchangesSearched?.currencies.filter(
      (currency: { ticker: string }) => value.includes(currency.ticker)
    );
    const data = [...listTokensFiltered, ...newListTokensSearched];
    const dataFiltered = [
      ...new Map(data.map((item) => [item.ticker, item])).values(),
    ];
    setListTokensSearched(dataFiltered);
  };

  const handleChangeNumberOfItems = (value: Number) => {
    setNumberOfItems(value);
    const newListExchanges: any = {};
    listExchange?.data?.forEach((exchange: any) => {
      if (exchange.id) {
        newListExchanges[exchange.id] = value;
      }
    });
    setExchangesNumberOfItems(newListExchanges);
  };

  useEffect(() => {
    if (selectListExchangesSearched?.data) {
      const newOptions: any[] = [];
      const dataListExchangesSearched: any = selectListExchangesSearched?.data;
      dataListExchangesSearched?.currencies?.forEach(
        (itemToken: { ticker: any; info: { name: any } }) => {
          newOptions.push({
            value: itemToken.ticker,
            label: itemToken.info.name,
          });
        }
      );
      setOptionsSearchToken([...newOptions]);
      if (dataFund?.data && listTokens?.data && isFirstGetDataSearch) {
        const arrayTokens = valueTokenSearch.split(",");
        onHandleSearchToken(arrayTokens);
        setIsFirstGetDataSearch(false);
      }
    }
  }, [selectListExchangesSearched?.data]);

  useEffect(() => {
    if (listCategoriesTree?.data) {
      const newTreeData: any[] = [];
      listCategoriesTree.data.forEach((cate: any) => {
        const itemCate: {
          value: any;
          title: string;
          children?: any;
          status: string;
        } = {
          value: cate.id,
          title: cate.name,
          status: cate.status,
        };

        const newChild: any[] = [];
        cate?.subcategories?.forEach(
          (childCate: { id: any; name: any; status: string }) => {
            newChild.push({
              value: childCate.id,
              title: `${childCate.name} ${
                childCate.status === "publish" ? "" : "[Private]"
              }`,
            });
          }
        );
        itemCate.children = [...newChild];
        newTreeData.push(itemCate);
      });
      setTreeData([...newTreeData]);
    }
  }, [listCategoriesTree?.data]);

  // useEffect(() => {
  //   if (dataFund) {
  //     console.debug("dataFund changed", dataFund);
  //   }
  // }, [dataFund?.data]);

  // restore data from existing config to init listExchangesSelected
  useEffect(() => {
    if (dataFund?.data) {
      setListExchangesSelected(
        dataFund?.data.allocation.tokens.map((x: any) => {
          return { ...x, _restoredTokens: x.tokens };
        })
      );
    }
  }, [dataFund?.data]);

  // restore searched tokens on edit
  useEffect(() => {
    if (
      dataFund?.data &&
      listTokens?.data &&
      dataFund?.data?.allocation?.subcategory_id === subCategorySelected
    ) {
      const listTokenSearched: string[] = [];
      const dataListTokens: any = listTokens.data;
      dataFund?.data.allocation.tokens.forEach((token: any) => {
        token.tokens.forEach((itemToken: any) => {
          const filterResult = dataListTokens.currencies?.filter(
            (currency: any) => currency.ticker === itemToken
          );
          if (
            filterResult.length === 0 &&
            !listTokenSearched.includes(itemToken)
          ) {
            listTokenSearched.push(itemToken);
          }
        });
      });
      const searchString = listTokenSearched.join(",");
      setValueTokenSearch(searchString);
    }
  }, [dataFund?.data, listTokens?.data, subCategorySelected]);

  useEffect(() => {
    if (listExchange?.data) {
      const newColumnsTable: any = [];
      const newListExchanges: any = [];
      const newListExchangesSelected: any[] = []; // [...listExchangesSelected];
      const newListExchangesNumberItems: any = {};
      listExchange?.data?.forEach((exchange: any, indexExchange: number) => {
        newListExchangesNumberItems[exchange.id] = numberOfItems;

        if (
          !newColumnsTable.filter(
            (itemColumn: { dataIndex: any }) =>
              itemColumn.dataIndex === exchange.id
          ).length
        ) {
          if (
            indexExchange === 0 &&
            !formValues.allocation.default_exchange &&
            !isEditPage
          ) {
            // force first exchange as default
            onChangeField("allocation", "default_exchange", exchange.id);
          }
          newColumnsTable.push({
            title: (
              <>
                <Radio
                  onChange={(e) => {
                    onChangeField(
                      "allocation",
                      "default_exchange",
                      e.target.value
                    );
                  }}
                  checked={isExchangeDefault(exchange.id)}
                  value={exchange.id}
                >
                  {exchange.name}
                </Radio>
                <InputNumber
                  key={`${exchange.name}-${
                    exchangesNumberOfItems[exchange.id]
                  }-${numberOfItems}`}
                  autoFocus
                  size="large"
                  defaultValue={numberOfItems}
                  value={exchangesNumberOfItems[exchange.id]}
                  min={1}
                  placeholder={""}
                  onChange={(value) =>
                    handleExchangesNumberOfItems(exchange.id, value)
                  }
                />
              </>
            ),
            dataIndex: exchange.id,
            align: "center",
          });
          newListExchangesSelected.push({
            exchange: exchange.id,
            tokens: [],
          });

          if (!isEditPage) {
            newListExchanges.push({
              exchange: exchange.id,
              status: "draft",
            });
          }
        }
      });
      if (listExchangesSelected.length === 0) {
        // first time
        if (!isEditPage) {
          // in edit, it will be initialize when dataFund is loaded
          setListExchangesSelected(newListExchangesSelected);
        }

        setExchangesNumberOfItems(newListExchangesNumberItems);
      }
      if (!isEditPage && !formValues.exchanges.length) {
        onChangeField("exchanges", null, [...newListExchanges]);
      }
      const newColumns = columnsDefault;

      setColumns(newColumns.concat(newColumnsTable));
    }
  }, [listExchange?.data, formValues, listExchangesSelected, dataFund?.data]);

  useEffect(() => {
    if (listTokens?.data) {
      const itemToken: any[] = [];
      const dataListTokens: any = listTokens.data;
      const newListTokens = dataListTokens?.currencies
        ?.filter(
          (row: { ticker: any }) =>
            !listTokensSearched.filter(
              (token: { ticker: any }) => token.ticker === row.ticker
            ).length
        )
        .sort(
          (
            a: { info: { cmc_rank: number } },
            b: { info: { cmc_rank: number } }
          ) => {
            return (
              +(a.info.cmc_rank === null) - +(b.info.cmc_rank === null) ||
              a.info.cmc_rank - b.info.cmc_rank
            );
          }
        );

      const newDataTable = [...listTokensSearched, ...newListTokens];

      newDataTable?.forEach((token: any, index: number) => {
        const objExchange: any = {};
        listExchange?.data?.forEach((exchange: any) => {
          objExchange[exchange.id] = token.availability.includes(
            exchange.id
          ) ? (
            showColumnWeight ? (
              listTokensWeight[exchange.id]?.includes(token.ticker) ? (
                `${Math.round(
                  balanceToken(
                    listCurrenciesWeight[exchange.id],
                    listTokensWeight[exchange.id],
                    formValues.allocation.allocation_method ===
                      "market_cap_sqrt" ||
                      formValues.allocation.allocation_method === "market_cap",
                    formValues.allocation.min_allocation / 100,
                    formValues.allocation.max_allocation / 100,
                    formValues.allocation.allocation_method === "equally"
                  ).filter(
                    (allocationItem) => allocationItem.ticker === token.ticker
                  )[0]?.weight * 100
                )}%`
              ) : null
            ) : (
              <Checkbox
                disabled={isTokenDisabled("-", token.ticker)}
                checked={isExchangeTokenSelected(exchange.id, token.ticker)}
                onChange={handleChangeExchangeToken(exchange.id, token.ticker)}
              />
            )
          ) : null;
        });

        itemToken.push({
          key: `${index}-${token.ticker}`,
          active: (
            <Switch
              checked={!listTokensDisabled.includes(token.ticker)}
              onChange={(value) => handleChangeSwitch(token.ticker, value)}
            />
          ),
          name: token.info.name,
          ticker: token.ticker,
          rank: token.info.cmc_rank,
          ...objExchange,
        });
      });
      setData(itemToken);
    }
  }, [
    listTokens?.data,
    listTokensDisabled,
    listExchangesSelected,
    listTokensSearched.length,
    listTokensWeight,
    listCurrenciesWeight,
    formValues,
    showColumnWeight,
    listExchange?.data,
  ]);

  useEffect(() => {
    if (listTokens?.data) {
      let tokens: any = {};
      let currencies: any = {};
      const newListTokensSelected: any[] = [];
      const dataListTokens: any = listTokens.data;
      const newListTokens = dataListTokens?.currencies
        ?.filter(
          (row: { ticker: any }) =>
            !listTokensSearched.filter(
              (token: { ticker: any }) => token.ticker === row.ticker
            ).length
        )
        .sort(
          (
            a: { info: { cmc_rank: number } },
            b: { info: { cmc_rank: number } }
          ) => {
            return (
              +(a.info.cmc_rank === null) - +(b.info.cmc_rank === null) ||
              a.info.cmc_rank - b.info.cmc_rank
            );
          }
        );
      const newDataTable = [...listTokensSearched, ...newListTokens];
      // && numberOfItems && listExchangesSelected.length) {
      // console.debug("listTokensDisabled", listTokensDisabled);
      // console.debug("listExchangesSelected debug1", listExchangesSelected);
      const newListExchangesSelected = listExchangesSelected;

      const qualifyToken = (
        exchangeSelection: ExchangeSelectionType,
        currencyRow: any
      ) => {
        // check validity
        const isValidCurrency =
          currencyRow.availability.includes(exchangeSelection.exchange) &&
          exchangeSelection.tokens.length <
            exchangesNumberOfItems[exchangeSelection.exchange] &&
          !isTokenDisabled(exchangeSelection.exchange, currencyRow.ticker) &&
          !exchangeSelection.tokens.includes(currencyRow.ticker);

        if (!isValidCurrency) {
          return;
        }

        exchangeSelection.tokens.push(currencyRow.ticker);
        if (
          !newListTokensSelected.filter((token) => token === currencyRow.ticker)
            .length
        ) {
          newListTokensSelected.push(currencyRow.ticker);
        }
        tokens = {
          ...tokens,
          [exchangeSelection.exchange]: Array.isArray(
            tokens[exchangeSelection.exchange]
          )
            ? tokens[exchangeSelection.exchange].concat(currencyRow.ticker)
            : [currencyRow.ticker],
        };
        currencies = {
          ...currencies,
          [exchangeSelection.exchange]: Array.isArray(
            currencies[exchangeSelection.exchange]
          )
            ? currencies[exchangeSelection.exchange].concat({
                ticker: currencyRow.ticker,
                market_cap: currencyRow.info.market_cap,
              })
            : [
                {
                  ticker: currencyRow.ticker,
                  market_cap: currencyRow.info.market_cap,
                },
              ],
        };
      };

      newListExchangesSelected.forEach(
        (exchangeSelection: ExchangeSelectionType) => {
          exchangeSelection.tokens = []; // reset list

          // restore tokens from existing fund first
          // if they have not been manually disabled in the form
          newDataTable.forEach((currencyRow) => {
            if (
              !exchangeSelection?._restoredTokens?.includes(currencyRow.ticker)
            ) {
              return;
            }
            qualifyToken(exchangeSelection, currencyRow);
          });

          newDataTable?.forEach((currencyRow) => {
            qualifyToken(exchangeSelection, currencyRow);
          });
        }
      );
      setListTokensWeight(tokens);
      setListCurrenciesWeight(currencies);
      setListTokensSelected([...newListTokensSelected]);
      // setListExchangesSelected([...newListExchangesSelected]);
      // console.debug("setListExchangesSelected", newListExchangesSelected);
      onChangeField("allocation", "tokens", [...newListExchangesSelected]);
    }
  }, [
    numberOfItems,
    listTokens?.data,
    listExchangesSelected.length,
    listTokensDisabled,
    listTokensSearched.length,
    exchangesNumberOfItems,
  ]);

  // useEffect(() => {
  //   console.debug("listExchangesSelected changed", listExchangesSelected);
  // }, [listExchangesSelected]);

  // useEffect(() => {
  //   console.debug("formValues changed", formValues);
  // }, [formValues]);

  useEffect(() => {
    if (dataFund?.data) {
      const newListExchangesNumberItems: any = {};
      setFormValues({
        strategy: {
          name: dataFund?.data?.strategy?.name,
          description: dataFund?.data?.strategy?.description,
          timeframe: dataFund?.data?.strategy?.timeframe,
          threshold: 0.05,
          ticker: dataFund?.data?.strategy?.ticker,
          logo: dataFund?.data?.strategy?.logo,
          banner: dataFund?.data?.strategy?.banner,
          banner_mobile: dataFund?.data?.strategy?.banner_mobile,
          categories: dataFund?.data?.strategy?.categories.concat(
            dataFund?.data?.strategy?.subcategories
          ),
          subcategories: dataFund?.data?.strategy?.subcategories,
        },
        allocation: {
          id: 0,
          name: "Admin",
          allocation_method: dataFund?.data?.allocation?.allocation_method,
          min_allocation: dataFund?.data?.allocation?.min_allocation * 100,
          max_allocation: dataFund?.data?.allocation?.max_allocation * 100,
          tokens: dataFund?.data?.allocation?.tokens,
          default_exchange: dataFund?.data?.allocation?.default_exchange,
          category_id: dataFund?.data?.allocation?.category_id,
          subcategory_id: dataFund?.data?.allocation?.subcategory_id,
        },
        exchanges: dataFund?.data?.exchanges,
      });
      form.setFieldsValue({
        "Fund Name": dataFund?.data?.strategy?.name,
        "Fund Ticker": dataFund?.data?.strategy?.ticker,
        "Fund Category": dataFund?.data?.strategy?.categories.concat(
          dataFund?.data?.strategy?.subcategories
        ),
        content: dataFund?.data?.strategy?.description,
        logo: dataFund?.data?.strategy?.logo,
        banner: dataFund?.data?.strategy?.banner,
        "banner mobile": dataFund?.data?.strategy?.banner_mobile,
        "Asset Category": !!dataFund?.data?.allocation?.category_id
          ? dataFund?.data?.allocation?.category_id
          : undefined,
        "Asset Sub-Category": !!dataFund?.data?.allocation?.subcategory_id
          ? dataFund?.data?.allocation?.subcategory_id
          : undefined,
      });
      // setFundCategorySelected(dataFund?.data?.strategy?.categories[0]);
      setCategorySelected(dataFund?.data?.allocation?.category_id);
      setSubCategorySelected(dataFund?.data?.allocation?.subcategory_id);
      // if (dataFund?.data?.strategy?.subcategories?.length) {
      //   setSubCategorySelected(dataFund?.data?.strategy?.subcategories[0]);
      // }
      // setExchangeDefault(dataFund?.data?.allocation?.default_exchange);
      let indexOfItemMaxLength = 0;
      dataFund?.data?.allocation?.tokens.forEach((listTokens: any) => {
        newListExchangesNumberItems[listTokens.exchange] =
          listTokens.tokens.length;
        if (listTokens.tokens.length > indexOfItemMaxLength) {
          indexOfItemMaxLength = listTokens.tokens.length;
        }
      });
      setExchangesNumberOfItems(newListExchangesNumberItems);
      setNumberOfItems(indexOfItemMaxLength);
    }
  }, [dataFund?.data, form]);

  return (
    <div>
      <Form
        {...formProps}
        form={form}
        name="dynamic_rule"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <Typography.Title level={2}>Listing</Typography.Title>

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12} xl={6}>
            <Form.Item name="Fund Creator Name">
              <Input
                disabled
                size="large"
                placeholder="Fund Creator Name"
                defaultValue="Admin"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} xl={6}>
            <Form.Item
              name="Fund Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                allowClear
                maxLength={64}
                key={formValues.strategy.name}
                autoFocus
                size="large"
                placeholder="Fund Name"
                defaultValue={formValues.strategy.name}
                onChange={(e) =>
                  onChangeField("strategy", "name", e.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} xl={6}>
            <Form.Item
              name="Fund Ticker"
              rules={[
                {
                  required: true,
                },
                {
                  validator: async (_, value) => {
                    if (!value) return;
                    const regex = /[A-Z0-9]/g;
                    const regexCharSpecial = /\W/g;
                    const matches = value.match(regex);
                    if (matches === null) {
                      return Promise.reject(
                        new Error("Only uppercase chars and numbers")
                      );
                    }
                    const countUpperCaseAndDigits = matches
                      ? matches.length
                      : 0;
                    const lastChar = value.charAt(value.length - 1);
                    const specialChars = lastChar.match(regexCharSpecial);
                    if (countUpperCaseAndDigits > FUND_MAX_TICKER_LENGTH) {
                      return Promise.reject(
                        new Error(
                          `Maximum ${FUND_MAX_TICKER_LENGTH} uppercase chars and numbers`
                        )
                      );
                    }
                    if (specialChars?.length) {
                      return Promise.reject(
                        new Error("Do not allow special char at the end!")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                allowClear
                maxLength={FUND_MAX_TICKER_LENGTH}
                key={formValues.strategy.ticker}
                autoFocus
                size="large"
                placeholder="Fund Ticker"
                disabled={isEditPage}
                defaultValue={formValues.strategy.ticker}
                onChange={(e) =>
                  onChangeField(
                    "strategy",
                    "ticker",
                    e.target.value.replaceAll(" ", "")
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xl={12} xs={24}>
            <Form.Item
              name="content"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea
                defaultValue={formValues.strategy?.description}
                autoFocus
                rows={5}
                placeholder="Description"
                onChange={(e) => {
                  onChangeField("strategy", "description", e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item
              name="Fund Category"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TreeSelect
                size="large"
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Fund Categories"
                allowClear
                multiple
                treeDefaultExpandAll
                onChange={(value) => {
                  onChangeField("strategy", "categories", value);
                }}
                treeData={treeData}
                treeLine={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[0, 0]}>
          <Col span={6}>
            <Form.Item name="logo">
              <Upload
                action={`${API_URL}/api/files/upload/fund?${getParamsUploadImages()}`}
                key="uploadAllImages"
                maxCount={1}
                onChange={({ file }) =>
                  file.status === "done" && onUploadedImage(file.response.files)
                }
                listType="picture-card"
                headers={imageUploadHeaders}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>
                    Upload Image
                    <br />
                    <Typography.Text type="secondary">
                      &lt; 10Mo
                    </Typography.Text>
                  </div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
          {formValues.strategy?.logo ? (
            <Col span={6}>
              <Form.Item name="logo">
                <Upload
                  action={`${API_URL}/api/files/upload/fund?${getParamsUploadImages(
                    ["logo"]
                  )}`}
                  key={formValues.strategy?.logo}
                  defaultFileList={
                    formValues.strategy?.logo
                      ? [
                          {
                            uid: formValues.strategy?.logo,
                            name: formValues.strategy?.logo,
                            url: formValues.strategy?.logo,
                          },
                        ]
                      : []
                  }
                  maxCount={1}
                  onChange={({ file }) =>
                    file.status === "done" &&
                    onUploadedImage(file.response.files)
                  }
                  listType="picture-card"
                  headers={imageUploadHeaders}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>
                      Upload Logo
                      <br />
                      <Typography.Text type="secondary">
                        100x100
                      </Typography.Text>
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          ) : null}
          {formValues.strategy?.banner ? (
            <Col span={6}>
              <Form.Item name="banner">
                <Upload
                  action={`${API_URL}/api/files/upload/fund?${getParamsUploadImages(
                    ["banner"]
                  )}`}
                  key={formValues.strategy?.banner}
                  defaultFileList={
                    formValues.strategy?.banner
                      ? [
                          {
                            uid: formValues.strategy?.banner,
                            name: formValues.strategy?.banner,
                            url: formValues.strategy?.banner,
                          },
                        ]
                      : []
                  }
                  maxCount={1}
                  onChange={({ file }) =>
                    file.status === "done" &&
                    onUploadedImage(file.response.files)
                  }
                  listType="picture-card"
                  headers={imageUploadHeaders}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>
                      Upload Banner Desktop
                      <br />
                      <Typography.Text type="secondary">
                        1600x900
                      </Typography.Text>
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          ) : null}
          {formValues.strategy?.banner_mobile ? (
            <Col span={6}>
              <Form.Item name="banner mobile">
                <Upload
                  key={formValues.strategy?.banner_mobile}
                  action={`${API_URL}/api/files/upload/fund?${getParamsUploadImages(
                    ["banner_mobile"]
                  )}`}
                  defaultFileList={
                    formValues.strategy?.banner_mobile
                      ? [
                          {
                            uid: formValues.strategy?.banner_mobile,
                            name: formValues.strategy?.banner_mobile,
                            url: formValues.strategy?.banner_mobile,
                          },
                        ]
                      : []
                  }
                  maxCount={1}
                  onChange={({ file }) =>
                    file.status === "done" &&
                    onUploadedImage(file.response.files)
                  }
                  listType="picture-card"
                  headers={imageUploadHeaders}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>
                      Upload Banner Mobile
                      <br />
                      <Typography.Text type="secondary">
                        752x836
                      </Typography.Text>
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          ) : null}
        </Row>

        <Typography.Title level={2}>Allocation</Typography.Title>

        <Row gutter={[16, 0]}>
          <Col lg={6} sm={12} xs={24}>
            <Form.Item name="Asset Category">
              <Select
                defaultValue={formValues.allocation?.category_id || null}
                allowClear
                key={formValues.allocation?.category_id}
                size="large"
                onChange={(value) => {
                  setCategorySelected(value);
                  onChangeField("allocation", "category_id", value);
                  form.setFieldsValue({
                    "Asset Sub-Category": null,
                  });
                }}
                {...categorySelectProps}
                placeholder="Asset Category"
              />
            </Form.Item>
          </Col>
          <Col lg={6} sm={12} xs={24}>
            <Form.Item name="Asset Sub-Category">
              <Select
                defaultValue={formValues.allocation?.subcategory_id || null}
                allowClear
                key={formValues.allocation?.subcategory_id}
                size="large"
                disabled={!categorySelected}
                onChange={(value) => {
                  setSubCategorySelected(value);
                  onChangeField("allocation", "subcategory_id", value);
                }}
                {...subCategorySelectProps}
                placeholder="Asset Sub-Category"
              />
            </Form.Item>
          </Col>
          <Col lg={6} sm={12} xs={24}>
            <Form.Item name="How many assets in the Fund?">
              <InputNumber
                key={numberOfItems}
                autoFocus
                size="large"
                defaultValue={numberOfItems}
                value={numberOfItems}
                min={1}
                placeholder={""}
                onChange={(value) => handleChangeNumberOfItems(value)}
                addonBefore={"Number of items"}
              />
            </Form.Item>
          </Col>
          <Col lg={6} sm={12} xs={24}>
            <Select
              size="large"
              mode="multiple"
              style={{ width: "100%" }}
              onSearch={onSearchToken}
              loading={fetchingSearch}
              notFoundContent={
                fetchingSearch ? (
                  <Spin
                    size="small"
                    style={{ justifyContent: "center", display: "flex" }}
                  />
                ) : null
              }
              options={optionsSearchToken}
              placeholder="Search Asset"
              suffixIcon={<SearchOutlined />}
              defaultValue={
                isEditPage && valueTokenSearch.length > 0
                  ? valueTokenSearch.split(",")
                  : []
              }
              key={isFirstGetDataSearch.toString()}
              onChange={(value) => onHandleSearchToken(value)}
              showSearch
            />
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={{ marginBottom: 16 }}>
          <Space size={[8, 16]}>
            <Text>View</Text>
            <Switch
              checkedChildren="Selected"
              unCheckedChildren="All"
              onChange={(value) => handleChangeViewTable(value)}
            />
            <Text style={{ paddingLeft: 20 }}>Weights</Text>
            <Switch
              checkedChildren="Show"
              unCheckedChildren="Hide"
              onChange={(value) => setShowColumnWeight(value)}
            />
          </Space>
        </Row>
        <Form.Item name="table-tokens">
          <Table
            scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
            loading={isLoadingListCurrencies}
            columns={columns}
            dataSource={
              viewListTokensSelected ? handleListTokensSelected(data) : data
            }
            bordered
            size="small"
            // rowClassName={(record, index) =>
            //   index % 2 === 0 ? "table-row-odd" : "table-row-even"
            // }
            pagination={{ defaultPageSize: 50 }}
          />
        </Form.Item>

        <Row gutter={[16, 0]}>
          <Col md={12} xs={24}>
            <Form.Item
              name="Asset Allocation Method"
              label="Asset Allocation Method"
            >
              <Select
                key={formValues.allocation.allocation_method}
                size="large"
                placeholder="Asset Allocation Method"
                defaultValue={formValues.allocation.allocation_method}
                onChange={(value) => {
                  onChangeField("allocation", "allocation_method", value);
                }}
                options={[
                  { value: "market_cap", label: "Market Cap Weight" },
                  { value: "market_cap_sqrt", label: "Square Root Market Cap" },
                  { value: "equally", label: "Equal Weight" },
                ]}
              />
              {["market_cap_sqrt", "market_cap"].includes(
                formValues.allocation.allocation_method
              ) && (
                <>
                  <InputNumber
                    key={`min_allocation_${formValues.allocation.min_allocation}`}
                    min={3}
                    max={formValues.allocation.max_allocation}
                    onChange={(value) => {
                      onChangeField("allocation", "min_allocation", value);
                    }}
                    defaultValue={formValues.allocation.min_allocation}
                    addonBefore="Minimum Allocation"
                    addonAfter="%"
                    autoFocus
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  />
                  <InputNumber
                    key={`max_allocation_${formValues.allocation.max_allocation}`}
                    autoFocus
                    max={100}
                    min={formValues.allocation.min_allocation}
                    addonAfter="%"
                    onChange={(value) => {
                      onChangeField("allocation", "max_allocation", value);
                    }}
                    defaultValue={formValues.allocation.max_allocation}
                    addonBefore="Max Allocation"
                  />
                </>
              )}
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="Asset Rebalancing"
              label="Asset Rebalancing (every)"
            >
              <Select
                key={formValues.strategy.timeframe}
                size="large"
                defaultValue={formValues.strategy.timeframe}
                onChange={(value) => {
                  onChangeField("strategy", "timeframe", value);
                }}
                options={[
                  { value: "in", label: "No" },
                  { value: "1M", label: "1 month" },
                  { value: "2M", label: "2 months" },
                  { value: "3M", label: "3 months" },
                  { value: "4M", label: "4 months" },
                  { value: "5M", label: "5 months" },
                  { value: "6M", label: "6 months" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col md={12} xs={24}>
            <Form.Item name="Trailing Stop Loss" label="Trailing Stop Loss (%)">
              <Select
                size="large"
                defaultValue={trailingStopLoss}
                onChange={(value) => setTrailingStopLoss(value)}
                options={[
                  { value: 0, label: "No" },
                  { value: 5, label: "5%" },
                  { value: 30, label: "30%" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item name="Value At Risk" label="Value At Risk">
              <Select
                size="large"
                defaultValue={valueAtRisk}
                onChange={(value) => setValueAtRisk(value)}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* <Form.Item name={"tags"}>
          <Select
            size="large"
            mode="multiple"
            style={{ width: 200 }}
            placeholder="Add Tags (risk profiles)"
            defaultValue={tags}
            options={[
              {
                label: "A1",
                value: "A1",
              },
              {
                label: "A12",
                value: "A12",
              },
            ]}
            onChange={(value) => setTags(value)}
            allowClear
            // {...tagsSelectProps}
          />
        </Form.Item> */}
        <Button
          loading={formLoading}
          type="primary"
          size="large"
          htmlType="submit"
          style={{ display: "flex", margin: "auto" }}
        >
          {isEditPage ? "Save changes" : "Create"}
        </Button>
      </Form>
    </div>
  );
};
