import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Authenticated,
  HttpError,
  useGo,
  useInvalidate,
  useNotification,
  useUpdate,
} from "@refinedev/core";
import { useOrganization } from "hooks/useOrganization";
import { Edit, useForm } from "@refinedev/antd";
import {
  Card,
  Col, Flex,
  Input,
  Row,
  Form,
  FormInstance,
  FormProps
} from "antd";
import { BrandKit } from "../types";
import { UploadFilesRef } from "/components/UploadFiles";
import {
  BrandKitAssetResponseWithTypedPurpose,
  BrandKitAssets,
} from "./components/BrandKitAssets";
import { PaletteResponse } from "pages/media/types";
import { t } from "i18next";
import { colorKeys } from ".";
import { BrandKitMockup } from "./components/BrandKitMockup";
import { ColorPickerCard } from "./components/ColorPickerCard";

// type UploadImage = {
//   asset_id?: string;
//   name: string;
//   file?: File;
//   status: string;
//   url: string;
//   purpose: string;
// };

export const BrandKitEdit: React.FC = () => {
  const params = useParams();
  const brandKitId = params.id;
  const { organization } = useOrganization({});
  const {
    saveButtonProps,
    form,
    formProps,
    uploadMusicRef,
    uploadFontsRef,
    uploadVideoRef,
    uploadPicturesRef,
    refs,
    setSaving,
    saving,
    brandKit,
    brandkitAssets,
    queryResult,
  } = useBrandKitEdit(brandKitId, organization?.id);

  return (
    <Authenticated key="brandkit_edit">
      <Edit
        isLoading={queryResult?.isLoading || queryResult?.isRefetching}
        title={brandKit?.name}
        saveButtonProps={{
          ...saveButtonProps,
          shape: "round",
          size: "large",
          icon: null,
          loading: saving,
        }}
        headerButtons={<></>}
      >
        <BaseBrandKitEdit
          brandKitId={brandKitId}
          form={form}
          formProps={formProps}
          uploadMusicRef={uploadMusicRef}
          uploadFontsRef={uploadFontsRef}
          uploadVideoRef={uploadVideoRef}
          uploadPicturesRef={uploadPicturesRef}
          refs={refs}
          setSaving={setSaving}
          brandKit={brandKit}
          brandkitAssets={brandkitAssets}
        />
      </Edit>
    </Authenticated>
  );
};

const BaseBrandKitEdit = ({
  brandKitId,
  onSuccess,
  setSaveCallback,
  mockupStyle,
  style,
  form,
  formProps,
  uploadMusicRef,
  uploadFontsRef,
  uploadVideoRef,
  uploadPicturesRef,
  refs,
  setSaving,
  brandKit,
  brandkitAssets,
}: {
  mockupStyle?: React.CSSProperties;
  onSuccess?: () => void;
  setSaveCallback?: (save: () => () => Promise<void>) => void;
  style?: React.CSSProperties;
  form: FormInstance;
  formProps: FormProps;
  uploadMusicRef: React.RefObject<UploadFilesRef>;
  uploadFontsRef: React.RefObject<UploadFilesRef>;
  uploadVideoRef: React.RefObject<UploadFilesRef>;
  uploadPicturesRef: React.RefObject<UploadFilesRef>;
  refs: React.RefObject<UploadFilesRef>[];
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  brandKit: BrandKit;
  brandkitAssets: BrandKitAssetResponseWithTypedPurpose[];
  brandKitId?: string;
}) => {
  const { organization } = useOrganization({});
  const [colors, setColors] = useState<PaletteResponse>();
  const { mutate: updateBrandKit } = useUpdate<BrandKit>();
  const [validFiles, setValidFiles] = useState(false);
  const invalidate = useInvalidate();
  const go = useGo();
  const { open } = useNotification();

  useEffect(() => {
    if (brandKit) {
      setColors(brandKit?.palette);
    }
    validateFiles(brandkitAssets);
  }, [brandKit]);

  function validateFiles(
    assets: {
      purpose?: string;
    }[]
  ) {
    if (assets?.length === 0) {
      setValidFiles(true);
    }
    setValidFiles(!assets?.some((asset) => !asset.purpose));
  }
  const handleSave = useCallback(
    async (values: any) => {
      try {
        if (!validFiles) {
          throw new Error(t("media.brandkit.edit.pleaseSetA"));
        }
        const brandKitAssetUrl = `media/${organization?.id}/brand_kits/${brandKit?.id}/assets`;
        // Create binary assets and upload to S3
        setSaving(true);

        const uploadResult = await Promise.all([
          uploadPicturesRef.current?.upload(brandKitAssetUrl),
          uploadMusicRef.current?.upload(brandKitAssetUrl),
          uploadFontsRef.current?.upload(brandKitAssetUrl),
          uploadVideoRef.current?.upload(brandKitAssetUrl),
        ]);

        await updateBrandKit({
          resource: `media/${organization?.id}/brand_kits`,
          id: brandKitId!,
          values: {
            name: values.name,
            palette: values.colors,
          },
        });
        const uploadResultLength = uploadResult
          .flat()
          .filter((result): result is number => !!result)
          .reduce((acc, count) => acc + count, 0);
        if (uploadResultLength) {
          // const multiple = uploadResultLength > 1;
          open!({
            type: "success",
            message: t("media.brandkit.edit.assetsUploaded"),
          });
        }
        await invalidate({
          resource: `media/${organization?.id}/brand_kits`,
          id: brandKitId,
          invalidates: ["all"],
        });
        if (onSuccess) {
          onSuccess?.();
        } else {
          go({
            to: {
              resource: "media_brandkits",
              id: brandKitId!,
              action: "show",
            },
          });
        }
      } catch (error) {
        console.error(error);
        open!({
          type: "error",
          message:
            (
              error as {
                message: string;
              }
            )?.message ?? t("media.brandkit.edit.failedToSave"),
        });
        throw error;
      } finally {
        setSaving(false);
      }
    },
    [validFiles]
  );

  useEffect(() => {
    if (setSaveCallback) {
      setSaveCallback(
        () => async () => await handleSave(form.getFieldsValue())
      );
    }
  }, [form, handleSave]);

  return (
    <>
      <Flex vertical style={{ paddingRight: 380, ...style }}>
        <Form
          layout={"vertical"}
          {...formProps}
          form={form}
          onFinish={handleSave}
        >
          {/* <StyledWrapper> */}
          <Form.Item
            label={t("media.brandkit.edit.title")}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Flex vertical gap={16}>
            <Card
              title={t("media.brandkit.edit.colorPalette")}
              type="inner"
              key={brandKit?.palette?.primary_color}
            >
              <Row gutter={[16, 16]}>
                {colorKeys.map((colorKey) => {
                  const isPrimary = colorKey === "primary_color";
                  return (
                    <Col
                      xl={8}
                      lg={24}
                      md={24}
                      sm={24}
                      key={colorKey}
                      style={{ width: "100%", display: "flex" }}
                    >
                      <ColorPickerCard
                        key={colorKey}
                        colorKey={colorKey}
                        allowClear={!isPrimary}
                        form={form}
                        defaultValue={brandKit?.palette?.[colorKey] ?? ""}
                        setColors={setColors}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Card>

            <BrandKitAssets
              brandKitId={brandKitId}
              validateFiles={validateFiles}
              refs={refs}
              assets={brandkitAssets}
            />
          </Flex>
        </Form>
      </Flex>
      <BrandKitMockup
        style={mockupStyle}
        assets={brandkitAssets}
        colors={colors}
      />
    </>
  );
};

export function BrandKitEditWithoutLayout({
  brandKitId,
  organizationId,
  onSuccess,
  setSaveCallback,
  mockupStyle,
  style,
}: {
  readonly organizationId: string;
  readonly brandKitId: string;
  readonly onSuccess: () => void;
  readonly setSaveCallback: (save: () => () => Promise<void>) => void;
  readonly mockupStyle?: React.CSSProperties;
  readonly style?: React.CSSProperties;
}) {
  const {
    saveButtonProps,
    form,
    formProps,
    uploadMusicRef,
    uploadFontsRef,
    uploadVideoRef,
    uploadPicturesRef,
    refs,
    setSaving,
    brandKit,
    brandkitAssets,
    queryResult,
  } = useBrandKitEdit(brandKitId, organizationId);
  return (
    <BaseBrandKitEdit
      style={style}
      mockupStyle={mockupStyle}
      onSuccess={onSuccess}
      setSaveCallback={setSaveCallback}
      brandKitId={brandKitId}
      form={form}
      formProps={formProps}
      uploadMusicRef={uploadMusicRef}
      uploadFontsRef={uploadFontsRef}
      uploadVideoRef={uploadVideoRef}
      uploadPicturesRef={uploadPicturesRef}
      refs={refs}
      setSaving={setSaving}
      brandKit={brandKit}
      brandkitAssets={brandkitAssets}
    />
  );
}

function useBrandKitEdit(brandKitId: string, organizationId: string) {
  const uploadMusicRef = React.useRef<UploadFilesRef>(null);
  const uploadFontsRef = React.useRef<UploadFilesRef>(null);
  const uploadVideoRef = React.useRef<UploadFilesRef>(null);
  const uploadPicturesRef = React.useRef<UploadFilesRef>(null);
  const refs = [
    uploadMusicRef,
    uploadFontsRef,
    uploadVideoRef,
    uploadPicturesRef,
  ];

  const { mutate: updateBrandKit } = useUpdate<BrandKit>();

  const { formProps, form, saveButtonProps, queryResult, formLoading } =
    useForm<BrandKit, HttpError, PaletteResponse>({
      resource: `media/${organizationId}/brand_kits`,
      id: brandKitId,
      action: "edit",
    });
  const [saving, setSaving] = useState(false);
  const brandKit = queryResult?.data?.data;
  const brandkitAssets = brandKit?.assets as
    | BrandKitAssetResponseWithTypedPurpose[]
    | undefined;

  return {
    form,
    formLoading,
    formProps,
    saveButtonProps,
    uploadMusicRef,
    uploadFontsRef,
    uploadVideoRef,
    uploadPicturesRef,
    refs,
    queryResult,
    saving,
    setSaving,
    updateBrandKit,
    brandKit,
    brandkitAssets,
  };
}
