import React, { useState, useEffect } from "react";
import { Card, CardProps, Typography } from "antd";
import { CSSProperties } from "react";

interface ImagePreviewCardProps extends CardProps {
  image: string | File;
  fileName: string;
}

export const PicturePreview: React.FC<ImagePreviewCardProps> = ({
  image,
  fileName,
  style,
  actions,
}) => {
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    if (image instanceof File) {
      const url = URL.createObjectURL(image);
      setImageUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setImageUrl(image);
    }
  }, [image]);

  return (
    <Card
      style={{ ...style, width: 200 }}
      // hoverable
      cover={<img src={imageUrl} alt="Preview" />}
      styles={{
        cover: { height: 200, overflow: "hidden" },
        actions: { padding: 10 },
      }}
      size="small"
      actions={actions}
    >
      <Card.Meta
        description={
          <Typography.Text style={{ width: "100%" }} ellipsis type="secondary">
            {fileName}
          </Typography.Text>
        }
      />
    </Card>
  );
};
