
import { Typography } from "antd";
import styled from "styled-components";

export const MiniLabel = styled(Typography.Text)`
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: normal;

  white-space: nowrap;
`;
