import { useCreate, useInvalidate, useTranslate } from "@refinedev/core";
import { AppContext } from "appContext";
import useSessionData from "hooks/useSessionData";
import { useSupportedLanguages } from "hooks/useSupportedLanguages";
import i18n from "i18n";
import { useContext, useCallback } from "react";
import { Organization } from "types";

export default function useOrganizationCreate() {
  const {
    mutateAsync,
    isLoading: isLoadingCreateOrganization,
    error,
    isError,
  } = useCreate<Organization>();
  const invalidate = useInvalidate();
  const t = useTranslate()
  const { userData, isLoading: isLoadingUserData } = useSessionData();
  const { dispatch } = useContext(AppContext);
  const { supportedLanguages, isLoading: isLoadingLanguage } =
    useSupportedLanguages();
  const createOrganization = useCallback(
    async (name) => {
      if (isLoadingLanguage) return null;
      if (!userData?.data?.organizations.length) {
        try {
          const organization = await mutateAsync({
            resource: "media/organizations",
            values: {
              name: name,
              city: "",
              country: "",
              language:
                supportedLanguages.find((language) =>
                  language.startsWith(i18n.language)
                ) ?? "en-US",
            },
            successNotification: {
              type: "success",
              message: t("pages.onboarding.createOrganization.successMessage"),
              description: t(
                "pages.onboarding.createOrganization.successDescription"
              ),
            },
          });
          dispatch({
            type: "setOrganization",
            payload: organization?.data,
          });
          await invalidate({
            resource: "api/users/me/organizations",
            invalidates: ["all"],
          });
          return organization;
        } catch (e) {
          console.debug(e);
        }
      }
    },
    [isLoadingLanguage, supportedLanguages]
  );

  return {
    isLoading:
      isLoadingUserData || isLoadingCreateOrganization || isLoadingLanguage,
    error,
    isError,
    createOrganization,
  };
}
