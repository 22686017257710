import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  Authenticated,
  useApiUrl,
  useCustomMutation,
  useList,
} from "@refinedev/core";
import { useState, CSSProperties, useEffect, createRef } from "react";
import {
  Empty,
  Grid,
  Spin,
  Tooltip,
  Typography,
  theme as antdTheme,
} from "antd";
import { Button, Flex } from "antd";
import { CheckCircleFilled, ReloadOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { useForm } from "@refinedev/antd";
import { MediaAsset } from "../types";
import { useParams } from "react-router-dom";
import { MediaAssetReview } from "../components/MediaAssetReview";

const MediaAssets = ({
  data,
  setCurrentItem,
  currentIndex,
  height,
}: {
  data: Array<MediaAsset>;
  setCurrentItem: (index: number) => void;
  currentIndex: number;
  height: string;
}) => {
  const { useToken } = antdTheme;
  const { token: theme } = useToken();
  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const handleItemClick = () => setCurrentItem(index);
    const asset = data[index];
    const isUpdating = asset.reviewed === "-";
    const isApproved = !isUpdating && Boolean(asset.reviewed) && asset.approved;
    const isRejected =
      !isUpdating && Boolean(asset.reviewed) && !asset.approved;
    const ref = createRef<HTMLAnchorElement>();
    const isCurrent = currentIndex === index;

    useEffect(() => {
      if (isCurrent && ref.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, [isCurrent, ref]);

    // console.debug({ index });
    return (
      <a
        ref={ref}
        style={{
          borderWidth: 5,
          borderRadius: 10,
          borderStyle: "solid",
          borderColor: isCurrent ? theme.colorPrimary : "transparent",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
          height: 100,
          width: isMobile ? 100 : 170,
          position: "relative",
        }}
        className={index % 2 ? "ListItemOdd" : "ListItemEven"}
        onClick={handleItemClick}
      >
        <div
          style={{
            borderRadius: 5,
            height: "100%",
            width: "100%",
            background: `url("${asset.path}")`,
            filter: isApproved || isRejected ? "grayscale(100%)" : "none",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        {isUpdating && (
          <Spin
            style={{
              color: "#24BB75",
              position: "absolute",
              top: 10,
              left: 10,
            }}
          />
        )}
        {isApproved && (
          <CheckCircleFilled
            style={{
              color: "#24BB75",
              position: "absolute",
              top: 10,
              left: 10,
              fontSize: 20,
            }}
          />
        )}
        {isRejected && (
          <CheckCircleFilled
            style={{
              color: "#E01B36",
              position: "absolute",
              top: 10,
              left: 10,
              fontSize: 20,
            }}
          />
        )}
      </a>
    );
  };

  return (
    <div
      style={{
        height,
        // width: isMobile ? 160 : 220,
        overflowY: "auto",
        marginTop: -20,
        paddingTop: 20,
      }}
    >
      {data.map((item, index) => (
        <Row key={item.id} index={index} style={{}} />
      ))}
    </div>
  );
};

export const MediaAssetsReview: React.FC = () => {
  const t = useTranslate();
  const API_URL = useApiUrl();
  const [data, setData] = useState<Array<MediaAsset>>([]);
  const [currentItem, setCurrentItem] = useState<number>(0);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [queryParams, setQueryParams] = useState("");
  const category = "AssetImage";
  const params = useParams();
  const [sub_category, setSubCategory] = useState(params.sub_category ?? "");
  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const { formProps, saveButtonProps } = useForm<{
    // category: string;
    sub_category: string;
  }>();

  const {
    data: mediaAssetsData,
    isFetched,
    isSuccess,
    isFetching,
    isRefetching,
    refetch,
  } = useList<MediaAsset>({
    resource: `media/media/assets/to_review?${new URLSearchParams({
      category,
      ...(sub_category?.length > 0 ? { sub_category } : null),
    }).toString()}`,
  });

  const handleFinish = (values: Record<string, string>) => {
    const query = new URLSearchParams(values);
    setData([]); // reset list
    setSubCategory(values.sub_category); //(query.toString());
  };

  const handleRefreshAssets = () => {
    refetch();
  };

  const { mutate, isLoading: isUpdating } = useCustomMutation();
  const asset = data[currentItem];

  const handleReview = (id: string, status: boolean) => {
    setCurrentItem((value) => value + 1);
    const action = status ? "validate" : "reject";
    setData((current) =>
      current.map((x, idx) =>
        x.id === id
          ? {
              ...x,
              reviewed: "-",
            }
          : x
      )
    );
    mutate(
      {
        url: `${API_URL}/media/media/assets/${asset.id}/${action}`,
        method: "post",
        values: {},
      },
      {
        onSuccess: (data, variables, context) => {
          const now = new Date();
          // update local data
          setData((current) =>
            current.map((x, idx) =>
              x.id === id
                ? {
                    ...x,
                    reviewed: now.toISOString(),
                    approved: status,
                  }
                : x
            )
          );
          setLastUpdate(now.getTime());
        },
      }
    );
  };

  useEffect(() => {
    if (
      mediaAssetsData?.data &&
      !data.find((x) => x.id === mediaAssetsData?.data?.[0]?.id)
    ) {
      // console.debug("new assets", mediaAssetsData);
      // setCurrentItem(value + 1);
      setData((current) => [...current, ...mediaAssetsData.data]);
    }
  }, [mediaAssetsData?.data]);

  useEffect(() => {
    // init to first new item
    setCurrentItem(data.findIndex((x) => !x.reviewed));
  }, [data?.length]);

  useEffect(() => {
    // all reviewed ?
    if (data.every((x) => Boolean(x.reviewed) && x.reviewed !== "-")) {
      refetch();
    }
  }, [lastUpdate, refetch]);

  return (
    <Authenticated key={"mediaAssetsReview"}>
      {isFetching && (
        <Spin
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
          size="large"
        />
      )}
      <Flex gap={isMobile ? "small" : "large"}>
        <div style={{ width: isMobile ? 160 : 260 }}>
          <Flex gap={10}>
            <Form {...formProps} onFinish={handleFinish}>
              <Form.Item
                name="sub_category"
                initialValue={sub_category}
                style={{ maxWidth: 180 }}
              >
                <Input placeholder={t("media.assets.review.subCategoryAapl")} />
              </Form.Item>
            </Form>
            <Tooltip title={t("media.assets.review.reloadAssets")}>
              <Button
                icon={<ReloadOutlined />}
                type="link"
                onClick={handleRefreshAssets}
              />
            </Tooltip>
          </Flex>
          {isSuccess && mediaAssetsData.data.length === 0 && (
            <Flex
              justify="center"
              vertical
              style={{ width: "80vw", height: "50vh" }}
            >
              <Empty
                description={
                  <Typography.Text>
                    {t("media.assets.review.noNewAssets")}
                  </Typography.Text>
                }
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </Flex>
          )}
          <MediaAssets
            height="calc(100vh - 100px)"
            data={data}
            setCurrentItem={setCurrentItem}
            currentIndex={currentItem}
          />
        </div>
        {asset && (
          <MediaAssetReview
            asset={asset}
            handleReview={handleReview}
            isUpdating={isUpdating}
            compact
          />
        )}
      </Flex>
    </Authenticated>
  );
};

