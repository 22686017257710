import { useApiUrl, useCustomMutation } from "@refinedev/core";
import { AssetUploadResponse, MediaAssetType } from "pages/media/types";
import { useState } from "react";
import axios from "axios";
import { useRef } from "react";
// type FormData = {
//   file_name: string;
// };

type EmptyString<T extends string> = T extends "" ? T : never;

/**
 * Uploads a file to an S3 bucket.
 *
 * This function takes a file object, a pre-signed URL, and the content type of the file,
 * then uploads the file to the S3 bucket using the PUT HTTP method. The pre-signed URL should
 * be obtained from your backend server, which has the necessary permissions to generate such URLs
 * for S3 operations. The content type is used in the request headers to indicate the media type of the file.
 *
 * @param {File} file - The file to be uploaded. This should be a File object, typically obtained from an input element of type "file".
 * @param {EmptyString} url - The pre-signed URL to which the file will be uploaded. This URL points to the intended location in the S3 bucket.
 * @param {""} contentType - HACK: Must be an empty string or S3 will fail with SignatureDoesNotMatch!
 * @returns {Promise<Response>} A promise that resolves with the response from the fetch operation. This can be used to check the status of the upload.
 * @throws {Error} If the fetch operation fails, an error is thrown.
 */
// HACK: This should not be public!
export async function uploadFileToS3(
  file: File,
  url: string,
  contentType: EmptyString<"">
) {
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": contentType,
    },
    body: file,
  });
  return response;
}

export const useMediaAssetUpload = ({
  assetType,
  organization,
  buildCreateAssetParams,
  buildCreateAssetEndpoint,
  buildCreateAssetPayload,
  parseAsset,
  method = "put",
}: {
  assetType: MediaAssetType;
  organization?: string;
  buildCreateAssetParams?: Function;
  buildCreateAssetEndpoint?: Function;
  buildCreateAssetPayload?: Function;
  parseAsset?: Function;
  method?: "post" | "put";
  onSuccess?: Function;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [completed, setCompleted] = useState(0);
  const callback = useRef<
    {
      file?: File;
      filename?: string;
      onSuccess?: Function;
      additionalFields?: Record<string, string>; // Probably should be generic
    }[]
  >([]);

  // const source = "Uploaded"; // todo in backend
  // const roots: Record<string, string> = {
  //   CuePoints: "cuepoints",
  //   Highlights: "highlights",
  // };
  // const isJson = Object.keys(roots).includes(asset_type);
  const isJson = false;

  const apiUrl = useApiUrl();
  const { mutateAsync } = useCustomMutation<AssetUploadResponse>({});

  const getFileJson = async (file: File): Promise<unknown> => {
    return new Promise(async (resolve, reject) => {
      function onReaderLoad(event: ProgressEvent<FileReader>) {
        resolve(JSON.parse(String(event?.target?.result)));
      }
      const reader = new FileReader();
      reader.onload = onReaderLoad;
      return reader.readAsText(file);
    });
  };

  const createAsset = async function <T, P>({
    // file_name,
    endpoint,
    payload,
  }: T & { endpoint: string; payload?: object }) {
    return new Promise<P>(async (resolve, reject) => {
      //   const params = new URLSearchParams({ source });
      // const data = isJson ? await getFileJson(callback.file!) : {};
      // console.debug({ data });
      // const root = isJson ? roots[String(asset_type)] : "";
      const { data: result } = await mutateAsync({
        url: `${apiUrl}${endpoint}`,
        method: "post",
        // values: { [root]: { data } },
        values: payload ?? {},
      });
      resolve(result as P);
    });
  };

  //   https://stackoverflow.com/questions/71936308/saving-an-audio-mediastream-into-a-file
  const createFileFromBlob = (
    blob: Blob,
    filename: string,
    mimeType: string
  ) => {
    // const blob = new Blob(recordedData , {type: "audio/ogg"});
    const file = new File([blob], filename, { type: mimeType });
    return file;
    /* then upload oder directly download your file / blob depending on your needs */
  };

  const uploadFiles = async function <T, P = AssetUploadResponse>() {
    const files = callback.current;
    // Otherwise this doesn't reset properly when uploading another media
    // after one was uploaded without a page refresh.
    callback.current = [];
    return Promise.all(
      files.map(async (item) => {
        return await uploadFile<T, P>(
          (buildCreateAssetParams && buildCreateAssetParams(item.file)) ?? {},
          item.file!,
          buildCreateAssetEndpoint && buildCreateAssetEndpoint(item.file),
          buildCreateAssetPayload &&
            buildCreateAssetPayload(item.file, item.additionalFields)
        );
      })
    );
  };

  const uploadFile = async function <T, P = AssetUploadResponse>(
    assetParams: T,
    file: File,
    endpoint: string,
    payload?: object
  ) {
    const newAsset = await createAsset<T, P>({
      ...assetParams, // TODO: This appears to be unused for now
      // file_name: file.name,
      endpoint,
      payload,
    });
    // get the custom asset data fields
    const uploadData = parseAsset ? parseAsset(newAsset) : newAsset;
    console.debug({ uploadData, newAsset });
    return new Promise<P>(async (resolve, reject) => {
      // TODO: will be deprecated soon
      if (method === "post") {
        if (!isJson && uploadData?.target_signed_dict?.fields) {
          const target = uploadData.target_signed_dict;
          // prepare upload to S3
          const url = target.url;
          const formData = new FormData();

          for (const name in target.fields as object) {
            formData.append(name, target.fields[name]);
          }
          formData.append("file", file);
          // upload to S3
          await axios.post(url, formData, {
            //   setIsUploading(true);
            // use axios to support progress (not working :())
            // not being called :(
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              //   todo
              //   setCompleted(percentCompleted);
            },
          });
        }
      } else {
        const contentType = "";

        // TODO: Tell Seb to rename this
        const url = uploadData.target_signed_dict;
        try {
          await uploadFileToS3(file, url, contentType);
        } catch {
          reject("Failed to upload file to S3");
        }
      }

      resolve(newAsset);
    });
  };

  const customRequest = ({
    // filename,
    file,
    onSuccess,
    additionalFields,
  }: {
    // filename?: string;
    file: any;
    onSuccess?: (body: any, xhr?: XMLHttpRequest | undefined) => void;
    additionalFields?: Record<string, string>;
  }) => {
    console.debug("customRequest", file);
    callback.current.push({
      filename: file.name,
      file: file.file ?? file,
      onSuccess,
      additionalFields,
    });
  };

  return { createFileFromBlob, uploadFile, uploadFiles, customRequest };
};
