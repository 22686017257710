import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { Flex, Form, FormInstance, Input } from "antd";
import { useBoundStore } from "store";
import { Link } from "@phosphor-icons/react";
import { TextAndDescription } from "./TextAndDescription";
import { RULES, placeholders } from "./helpers";
import { AppContext } from "appContext";

const FreeTextStep: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  const t = useTranslate();
  const { setStepValue, currentStep } = useBoundStore((state) => ({
    setStepValue: state.setStep,
    currentStep: state.onboardingState.currentStep,
  }));

  const rules = RULES[currentStep];

  // Set up the i18ned validation error messages
  useEffect(() => {
    if (rules) {
      for (const rule of rules) {
        // Language detection seem to only work inside a component
        // so I have to put that here
        rule.message = t(rule.message as string);
      }
    }
  }, []);

  const {
    state: { user },
  } = useContext(AppContext);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentStep === "organization") {
      setStepValue(e.target.value);
    } else {
      setStepValue({
        answers: e.target.value,
      });
    }
    form.setFieldValue("website", e.target.value);
  };
  return (
    <Flex gap={50} vertical justify="center" align="center">
      <TextAndDescription />

      <Flex justify="center" align="center" style={{ width: "100%" }}>
        <Form form={form} style={{ flex: "1" }}>
          <Form.Item
            validateDebounce={500}
            name={currentStep}
            rules={rules ?? undefined}
          >
            <Input
              size="large"
              prefix={
                currentStep !== "organization" && (
                  <span className="anticon">
                    <Link />{" "}
                    {/* TODO: Not generic, but we only have 2 such steps ATM */}
                  </span>
                )
              }
              autoFocus
              placeholder={t(
                (currentStep === "organization"
                  ? (placeholders["organization"] as Function)(user)
                  : placeholders[currentStep]) as string
              )}
              onChange={onChange}
              name={"website"}
            />
          </Form.Item>
        </Form>
      </Flex>
    </Flex>
  );
};

export default FreeTextStep;
