import { useCustom, useList } from "@refinedev/core";
import { ChatHistoryResponse } from "types";

export const useChatHistory = ({
  projectId,
  params,
  enabled = true,
}: {
  projectId: string;
  enabled?: boolean;
  params: {
    page: number;
    size: number;
    from_datetime?: string;
    to_datetime?: string;
  };
}) => {
  const queryParams = Object.entries(params)
    .filter(
      ([key, param]) => param !== undefined && key !== "page" && key !== "size"
    )
    .map(([_, param]) => [_, String(param)]); // cast for URLSearchParams
  const queryString = new URLSearchParams(queryParams);

  const { data, isLoading, isFetching } = useList<ChatHistoryResponse>({
    resource: `media/projects/${projectId}/chat_history?${queryString}`,
    queryOptions: {
      enabled,
    },
    pagination: {
      pageSize: params.size,
      current: params.page,
    },
  });

  return {
    data: data?.data,
    isLoading,
    isFetching,
  };
};
