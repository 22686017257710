import { useTranslate } from "@refinedev/core";
import React, { useContext } from "react";
import { Flex, List, Typography } from "antd";
import { useList } from "@refinedev/core";
import { Asset } from "types";
import { sortBy } from "lodash";
import { AppContext } from "appContext";

interface Props {
  onTickerSelect: (ticker: string) => void;
}

const OrganizationTickerList: React.FC<Props> = ({ onTickerSelect }) => {
  const t = useTranslate();
  const {
    state: { organization },
  } = useContext(AppContext);

  const {
    data: tickersResponse,
    isFetching,
    error,
  } = useList<Asset>({
    resource: `api/assets/Equity/clients_tickers?organization_id=${organization?.id}`,
    queryOptions: {
      enabled: Boolean(organization),
    },
  });

  // friendly_name can be empty
  const tickers = sortBy(tickersResponse?.data, "name");

  const handleTickerSelect = (ticker: string) => {
    onTickerSelect(ticker);
  };

  return (
    <Flex gap={5} vertical>
      <Typography.Text type="secondary" style={{ paddingLeft: 16 }}>
        {tickers?.length}{" "}
        {t("src.components.OrganizationTickerList.assets")}
      </Typography.Text>
      <List
        size="small"
        style={{ cursor: "pointer" }}
        loading={isFetching}
        dataSource={tickers}
        renderItem={(item: Asset) => {
          return (
            <List.Item
              onClick={() => {
                return handleTickerSelect(item.ticker);
              }}
            >
              {item.friendly_name?.length > 0 ? item.friendly_name : item.name}{" "}
              <Typography.Text type="secondary">
                • {item.ticker}
              </Typography.Text>
            </List.Item>
          );
        }}
      />
    </Flex>
  );
};

export default OrganizationTickerList;

