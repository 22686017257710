import { useApiUrl, useCustomMutation } from "@refinedev/core";
import { MediaProjectResponse } from "pages/media/types";

export type MediaContactLanguage = { contactId: string; language: string };

export const useProjectMedia = ({
  project,
}: {
  project: MediaProjectResponse;
}) => {
  const API_URL = useApiUrl();
  const projectId = project?.id;
  const { mutateAsync, isLoading: isCreatingMedia } = useCustomMutation();
  const mediaContactsSet = new Set(project?.media?.map((x) => x.contact_id));

  const requestNewMedia = async (values: MediaContactLanguage[]) => {
    await mutateAsync({
      url: `${API_URL}/media/projects/${projectId}/media`,
      method: "post",
      values: values.map(({ language, contactId: contact_id }) => ({
        language,
        contact_id,
      })),
    });
  };

  return {
    requestNewMedia,
    isCreatingMedia,
    mediaContactIds: [...mediaContactsSet],
  };
};
