import { useTranslate } from "@refinedev/core";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib";
export default function DeleteAccount({
  form,
  onChange,
}: {
  form: FormInstance;
  onChange: () => void;
}) {
  const t = useTranslate();

  return (
    <div>
      <Form form={form} onChange={onChange}>
        <Form.Item name="password" label={t("pages.login.fields.password")}>
          <Input.Password />
        </Form.Item>
      </Form>
    </div>
  );
}
