import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React from "react";
import { Authenticated, IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";

export const ProjectEdit: React.FC<IResourceComponentsProps> = (
  project_id: any
) => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm({
    resource: "media/projects",
    id: project_id,
  });

  // const projectData = queryResult?.data?.data;

  return (
    <Authenticated key="project_edit">
      <Edit saveButtonProps={saveButtonProps}>
        <Form {...formProps} layout="vertical">
          <Form.Item
            label={t("media.projects.edit.id")}
            name={'id'}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly disabled />
          </Form.Item>
          <Form.Item
            label={t("media.projects.edit.projectsTitle")}
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("media.projects.edit.type")}
            name={["type"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Edit>
      {/* <ProjectDocuments /> */}
    </Authenticated>
  );
};


