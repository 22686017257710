export const languages = [
  {
    value: "en-US",
    name: "src.data.languages.english",
    description: "src.data.languages.enUs",
    hasLocale: true,
  },
  {
    value: "fr-FR",
    name: "src.data.languages.french",
    description: "src.data.languages.frFr",
    hasLocale: true,
  },
  {
    value: "de-DE",
    name: "src.data.languages.german",
    description: "src.data.languages.deDe",
  },
  {
    value: "it-IT",
    name: "src.data.languages.italian",
    description: "src.data.languages.itIt",
  },
  {
    value: "es-ES",
    name: "src.data.languages.spanish",
    description: "src.data.languages.esEs",
  },
  {
    value: "ar-AE",
    name: "src.data.languages.arabic",
    description: "src.data.languages.arAe",
  },
];
