import { Button, GlobalToken } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { useAntTheme } from "../../../../hooks/useAntTheme";

export const Suggestion: React.FC<{
  question: string;
  onSelect: (text: string) => void;
}> = ({ question, onSelect }) => {
  const [picked, setPicked] = useState(false);
  const { theme } = useAntTheme();

  const handleSuggestionChoose = () => {
    setPicked(true);
    onSelect(question);
  };
  return (
    <StyledButton
      active={picked}
      size="middle"
      onClick={handleSuggestionChoose}
      theme={theme}
    >
      {question}
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{ active: boolean; theme: GlobalToken }>`
  white-space: normal;
  height: auto;
  text-align: left;
  border-radius: 10px !important;
  // workaround for videojs in frontend : force border colors
  border: 1px solid ${(props) => props.theme.colorBorder} !important;
  ${({ active }) => (active ? "border-color: #1890ff !important" : "")};
`;
