import { useTranslate } from "@refinedev/core";
import { Authenticated, CanAccess, useGo } from "@refinedev/core";
import { Flex, Input, Result } from "antd";
import { useCallback } from "react";
import { CreateButton, List } from "@refinedev/antd";

// interface DataType {
//   key: React.Key;
//   ticker: string;
//   name: string;
//   category: string;
//   subCat: string;
//   holders: string;
//   aum: string;
//   status: string;
//   exchanges: string | Array<string>;
//   createdAt: string;
//   actions: number;
// }

export const MediaList: React.FC = () => {
  const t = useTranslate();
  const go = useGo();
  // const [dataTable, setDataTable] = useState<Array<DataType>>([]);

  // const { tableProps } = useTable({
  //   resource: "api/funds/list",
  // });
  const handleShowMedia = useCallback(
    (id: string) => {
      go({
        to: {
          resource: "media_media",
          action: "show",
          id,
        },
      });
    },
    [go]
  );

  const handleShowMediaAsset = useCallback(
    (id: string) => {
      go({
        to: {
          resource: "media_assets",
          action: "show",
          id,
        },
      });
    },
    [go]
  );

  return (
    <Authenticated key={"mediaList"}>
      <List headerButtons={<CreateButton />}>
        <Flex vertical>
          <Input.Search
            size="large"
            placeholder={t("media.media.list.lookupMediaBy")}
            onSearch={handleShowMedia}
            style={{ marginBottom: 30, maxWidth: 500 }}
          />
          <Input.Search
            size="large"
            placeholder={t("media.media.list.lookupMediaAsset")}
            onSearch={handleShowMediaAsset}
            style={{ marginBottom: 30, maxWidth: 500 }}
          />
        </Flex>
        {/* <Table
      // {...tableProps}
      scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
      pagination={{ pageSize: 20 }}
      dataSource={dataTable}
      // loading={tableProps?.loading}
    ></Table> */}
      </List>
    </Authenticated>
  );
};
