import { DownloadOutlined } from "@ant-design/icons";
import { Authenticated } from "@refinedev/core";
import { Avatar, Button, Card, Col, Flex, Grid, Row, Typography } from "antd";
import boursoramaLogo from "assets/boursorama.svg";
import { createRef, memo, useMemo } from "react";

function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

// ALSTOM: ALO.PA,  SANOFI: SAN.PA,  UNIBAIL RODAMCO W: URW.PA,   TOTAL ENERGIES: TTE.PA,
//  ARCELOR MITTAL: MT.AS,
//    DASSAULT SYSTEMES:  DSY.PA,    DANONE: BN.PA,   SAFRAN: SAF.PA,
//     L'OREAL:  OR.PA,   ESSILORLUXOTTICA: EL.PA,
//       TESLA: TSLA,   APPLE: AAPL
const videos = [
  {
    name: "DANONE (new)",
    ticker: "BN.PA",
    description: "",
    url: "danone_2024-01-15.mp4",
  },
  {
    ticker: "TSLA",
    name: "TESLA",
    description: "",
    url: "tesla.mp4",
  },
  {
    ticker: "ALO.PA",
    name: "ALSTOM",
    description: "",
    url: "alstom.mp4",
  },
  {
    ticker: "SAN.PA",
    name: "SANOFI",
    description: "",
    url: "sanofi.mp4",
  },
  {
    ticker: "URW.PA",
    name: "UNIBAIL RODAMCO W",
    description: "",
    url: "unibail.mp4",
  },
  {
    name: "TOTAL ENERGIES",
    ticker: "TTE.PA",
    description: "",
    url: "total.mp4",
    logo: "",
  },
  {
    name: "ARCELOR MITTAL",
    ticker: "MT.AS",
    description: "",
    url: "arcelor_mittal.mp4",
  },
  {
    ticker: "DSY.PA",
    name: "DASSAULT SYSTEMES",
    description: "",
    url: "dassault systemes.mp4",
  },
  {
    ticker: "SAF.PA",
    name: "SAFRAN",
    description: "",
    url: "safran.mp4",
  },
  {
    ticker: "OR.PA",
    name: "L'OREAL",
    description: "",
    url: "loreal.mp4",
  },
  {
    ticker: "EL.PA",
    name: "ESSILORLUXOTTICA",
    description: "",
    url: "essilor.mp4",
  },

  {
    ticker: "AAPL",
    name: "APPLE",
    description: "",
    url: "apple.mp4",
  },
];
type Video = (typeof videos)[number];

const VideoCard = ({ video }: { video: Video }) => {
  const url = `https://cdn.dev.libertify.com/media/boursorama/${video.url}`;
  const linkRef = createRef<HTMLAnchorElement>();
  const handleDownload = () => {
    // window.open(url, "_blank");
    return linkRef.current?.click();
  };
  return (
    <Card
      title={video.name}
      hoverable
      extra={
        <Button
          onClick={handleDownload}
          icon={<DownloadOutlined />}
          shape="circle"
        ></Button>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 15,
          marginTop: -24,
        }}
      >
        <a
          ref={linkRef}
          rel="noreferrer"
          target="_blank"
          style={{ display: "none" }}
          download={video.url}
          href={url}
        >
          Download
        </a>
        <video controls width={360} height={636} preload="none">
          <source src={url} type="video/mp4" />
        </video>
      </div>
      <Card.Meta
        // avatar={
        //   <Avatar src="https://r.lvmh-static.com/uploads/2010/01/lvmh_logotype_simple_n-1.jpg" />
        // }
        title={video.ticker}
        description={video.description || "Generated on 14/01/2024"}
      />
    </Card>
  );
};
export const BoursoramaVideoList = () => {
  const breakpoint = Grid.useBreakpoint();

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const videoList = useMemo(
    () => videos.filter((x) => Boolean(x.url)),
    // .sort((a, b) => a.name.localeCompare(b.name))
    []
  );
  const chunkSize = breakpoint.xl ? 3 : 2;
  //   console.debug([...chunks(videoList, chunkSize)]);

  return (
    <Authenticated key="extranet_boursorama_demo">
      <Flex
        vertical={isMobile}
        justify="space-between"
        style={{ marginBottom: 20 }}
      >
        <div>
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            Libertify Extranet
          </Typography.Title>
          <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
            Boursorama Video Builder{" "}
            <Typography.Text style={{ opacity: 0.6 }}>
              v0.1 beta
            </Typography.Text>
          </Typography.Title>
          <Typography.Text style={{ opacity: 0.6 }}>
            {videoList.length} videos available:
          </Typography.Text>
        </div>
        <div>
          <img src={boursoramaLogo} />
        </div>
      </Flex>

      <Flex vertical gap={30}>
        {[...chunks(videoList, chunkSize)].map((chunk, i) => (
          <Row gutter={[30, 30]} key={"chunk" + i}>
            {chunk.map((video) => (
              <Col key={video.url} span={24} md={24 / chunkSize}>
                <VideoCard video={video} />
              </Col>
            ))}
          </Row>
        ))}
      </Flex>
    </Authenticated>
  );
};
