import { t } from "i18next";
import React from "react";
import { PictureOutlined } from "@ant-design/icons";
import { Segmented } from "antd";
import { SegmentedValue } from "antd/es/segmented";

export type ThumbnailSize = "small" | "medium" | "large";

interface IThumbnailSizeSegmentedProps {
  value: ThumbnailSize;
  onChange: (value: SegmentedValue) => void;
}

const ThumbnailSizeSegmented: React.FC<IThumbnailSizeSegmentedProps> = (
  props
) => (
  <Segmented
    size="middle"
    options={[
      {
        label: t("assets.components.ThumbnailSizeSegmented.small"),
        value: "small",
        icon: (
          <PictureOutlined
            style={{ fontSize: 10, transform: "rotate(90deg)" }}
          />
        ),
      },
      {
        label: t("assets.components.ThumbnailSizeSegmented.medium"),
        value: "medium",
        icon: (
          <PictureOutlined
            style={{ fontSize: 13, transform: "rotate(90deg)" }}
          />
        ),
      },
      {
        label: t("assets.components.ThumbnailSizeSegmented.large"),
        value: "large",
        icon: (
          <PictureOutlined
            style={{ fontSize: 16, transform: "rotate(90deg)" }}
          />
        ),
      },
    ]}
    value={props.value}
    onChange={props.onChange}
  />
);

export default ThumbnailSizeSegmented;

