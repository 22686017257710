import { useTranslate } from "@refinedev/core";
import React, { useEffect, useRef, useState } from "react";
import { Slider, Flex, ConfigProvider, Button, Space, Radio } from "antd";
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import { BrandKitAssetResponseWithTypedPurpose } from "./BrandKitAssets";

import { FontPreviewText } from "./FontPreview";
import iPhoneFrame from "../../../../assets/iPhoneMockup.png";

import { SpeakerHigh, SpeakerSlash } from "@phosphor-icons/react";
import { PaletteResponse } from "pages/media/types";

interface BrandKitMockupProps {
  assets?: BrandKitAssetResponseWithTypedPurpose[];
  colors?: PaletteResponse;
  style?: React.CSSProperties;
}

type VideoView = "Intro" | "Transition";

export const BrandKitMockup: React.FC<BrandKitMockupProps> = ({
  assets,
  colors,
  style = {
    right: 30,
  },
}) => {
  const t = useTranslate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showIntro, setShowIntro] = useState(true);

  const [videoView, setVideoView] = useState<VideoView>("Intro");

  useEffect(() => {
    const handleVideoEnd = () => {
      setShowIntro(false);
    };

    if (videoRef.current) {
      // reset video to start when videoView is changed
      videoRef.current.currentTime = 0;
      videoRef.current.addEventListener("ended", handleVideoEnd);
    }
  }, [videoView]);

  const logo = assets?.find((asset) => asset.purpose === "Logo");
  const titleFont = assets?.find((asset) => asset.purpose === "Title");
  const textFont = assets?.find((asset) => asset.purpose === "Text");
  const audio = assets?.find((asset) => asset.purpose === "Music");
  const background = assets?.find((asset) => asset.purpose === "Background");
  const intro = assets?.find((asset) => asset.purpose === "Introduction");
  const transition = assets?.find((asset) => asset.purpose === "Transition");

  if (!iPhoneFrame) return;

  return (
    <Flex
      align="center"
      justify="center"
      style={{
        width: "380px",
        height: "768px",
        // smaller for smaller screens
        transform: "scale(0.88)",
        position: "fixed",
        top: 90,
        ...style,
      }}
    >
      <Flex
        align="center"
        justify="center"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <img height="100%" src={iPhoneFrame} alt="" />
      </Flex>
      <Flex
        vertical
        style={{
          height: "700px",
          width: "339px",
          marginBottom: "-35px",
          zIndex: 2,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: -35,
            height: "80px",
            width: "100%",
            textAlign: "center",
            padding: 2,
            zIndex: 0,
            borderRadius: "40px 40px 0px 0px",
            backgroundColor: colors?.secondary_color ?? "",
          }}
        >
          {logo?.path && (
            <img src={logo?.path} height="100%" width="auto" alt="logo" />
          )}
        </div>
        <Flex
          align="center"
          justify="center"
          vertical
          style={{
            position: "relative",
            display: "flex",
            backgroundColor: colors?.primary_color,
            backgroundImage: background ? `url(${background?.path})` : "",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {(intro || transition) && (
            <div
              style={{
                position: "absolute",
                top: 0,
                visibility: showIntro ? "visible" : "hidden",
                zIndex: videoView === "Transition" ? 1 : 3,
                height: "100%",
                width: "100%",
              }}
            >
              <video
                src={
                  videoView === "Transition" ? transition?.path : intro?.path
                }
                ref={videoRef}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
          <Flex
            vertical
            align="center"
            justify="center"
            style={{
              position: "absolute",
              top: 0,
              zIndex: 2,
              width: "100%",
              height: "100%",
            }}
          >
            <FontPreviewText
              font={titleFont?.path ?? ""}
              text={t("media.brandkit.mockup.title")}
              style={{
                textAlign: "center",
                width: 200,
                height: 60,
                letterSpacing: 2,
                color: colors?.title_color ?? "#000",
                WebkitTextStroke: `2px ${colors?.title_stroke_color ?? ""}`,
              }}
            />
            <FontPreviewText
              style={{
                fontSize: "30px",
                width: "300px",
                color: colors?.subtitle_color ?? "#000",
                WebkitTextStroke: `1px ${colors?.subtitle_stroke_color ?? ""}`,
              }}
              font={textFont?.path ?? ""}
              text={t("media.brandkit.mockup.exampleText")}
            />
          </Flex>
        </Flex>
        {
          <PlayerWrapper
            src={audio?.path}
            video={videoRef.current}
            setShowIntro={setShowIntro}
            videoView={videoView}
          />
        }
      </Flex>
      {intro && transition && (
        // show switch only if there are both intro and transition
        <Radio.Group
          size="small"
          defaultValue="Intro"
          optionType="button"
          buttonStyle="solid"
          style={{
            position: "absolute",
            bottom: -40,
          }}
          onChange={(e) => {
            setVideoView(e.target.value);
          }}
        >
          {["Intro", "Transition"].map((option) => (
            <Radio.Button
              key={option}
              value={option}
              style={{ width: 100, textAlign: "center" }}
            >
              {t(`brandkit.components.BrandKitMockup.${option}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      )}
    </Flex>
  );
};

const PlayerWrapper = ({
  src,
  video,
  setShowIntro,
  videoView,
}: {
  src?: string;
  video?: HTMLVideoElement;
  setShowIntro?: (show: boolean) => void;
  videoView: VideoView;
}) => {
  const player = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const hasAudio = src !== undefined;
  const noVideoAndAudio = src === undefined && !video;
  const hasOnlyVideo = src === undefined && video;

  useEffect(() => {
    if (!video) return;

    if (currentTime < video.duration) {
      setShowIntro(true);
      video.currentTime = currentTime;
    } else if (currentTime > video.duration) {
      setShowIntro(false);
    }
  }, [currentTime]);

  useEffect(() => {
    // if the user changes the videoView without pausing the video or audio
    setIsPlaying(false);

    const handleEnded = () => {
      if (player.current) {
        player.current.pause();
        setCurrentTime(0);
        setIsPlaying(false);
        setShowIntro(true);
      }
    };

    if (player.current) {
      // reset audio to start when videoView is changed
      player.current.currentTime = 0;
      player.current.addEventListener("ended", handleEnded);
    }

    return () => {
      if (player.current) {
        player.current.removeEventListener("ended", handleEnded);
      }
    };
  }, [videoView]);

  const handlePlayerClick = () => {
    if (player.current) {
      if (isPlaying) {
        setIsPlaying(false);
        player.current.pause();
        video?.pause();
      } else {
        setIsPlaying(true);
        player.current.play();
        video?.play();
      }
    }

    if (!player.current && hasOnlyVideo) {
      if (isPlaying) {
        setIsPlaying(false);
        video.pause();
      } else {
        setIsPlaying(true);
        video.play();
      }
    }
  };

  const handleMute = () => {
    if (player.current) {
      player.current.muted = !player.current.muted;
    }
  };

  const handleTimeUpdate = () => {
    if (player.current) {
      setCurrentTime(player.current.currentTime);
    }
  };

  const handleSliderChange = (value: number) => {
    if (player.current) {
      player.current.currentTime = value;
    }
  };

  const handleDuration = () => {
    if (player.current) {
      setDuration(player.current.duration);
    }
  };

  // show time in min:sec
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <Flex
      justify="center"
      align="center"
      vertical
      style={{
        height: "fit-content",
        width: "100%",
        background: "#000",
        padding: 10,
        boxSizing: "border-box",
        borderRadius: "0px 0px 20px 20px",
      }}
      gap={2}
    >
      {(hasAudio || noVideoAndAudio) && (
        <>
          <Flex
            align="center"
            justify="space-between"
            style={{ width: "100%" }}
          >
            <Flex align="center" gap={5} style={{ color: "#fff" }}>
              {src && (
                <audio
                  ref={player}
                  src={src}
                  onTimeUpdate={handleTimeUpdate}
                  onLoadedMetadata={handleDuration}
                />
              )}
              <Button
                type="text"
                onClick={src === "" ? undefined : handlePlayerClick}
                icon={
                  isPlaying ? (
                    <PauseOutlined style={{ fontSize: "20px" }} />
                  ) : (
                    <CaretRightOutlined style={{ fontSize: "25px" }} />
                  )
                }
                style={{ color: "#fff" }}
              />
              <div>
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
            </Flex>
            {src && (
              <Space
                align="center"
                onClick={handleMute}
                style={{ color: "#fff", fontSize: "20px", cursor: "pointer" }}
              >
                {player.current?.muted ? <SpeakerSlash /> : <SpeakerHigh />}
                {/* <FullscreenOutlined /> */}
                {/* <MoreOutlined /> */}
              </Space>
            )}
          </Flex>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  railBg: "rgba(255,255,255,0.3)",
                },
              },
            }}
          >
            <div style={{ width: "90%" }}>
              <Slider
                value={currentTime}
                onChange={handleSliderChange}
                max={duration}
                tooltip={{ open: false }}
                styles={{
                  tracks: {
                    background: "#fff",
                  },
                }}
              />
            </div>
          </ConfigProvider>
        </>
      )}
      {hasOnlyVideo && (
        <Flex align="center" justify="center" style={{ width: "100%" }}>
          <Button
            type="text"
            onClick={src === "" ? undefined : handlePlayerClick}
            icon={
              isPlaying ? (
                <PauseOutlined style={{ fontSize: "20px" }} />
              ) : (
                <CaretRightOutlined style={{ fontSize: "25px" }} />
              )
            }
            style={{ color: "#fff" }}
          />
        </Flex>
      )}
    </Flex>
  );
};
