import { GlobalToken } from "antd";
import styled from "styled-components";


export const StyledVerticalPanelResizeHandle = styled.div<{ theme: GlobalToken }>`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.colorBorder};
  margin-left: 1px;
  margin-right: 1px;
`;

export const StyledHorizontalPanelResizeHandle = styled.div<{
  theme: GlobalToken;
}>`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colorBorder};
  margin-top: 1px;
  margin-bottom: 1px;
`;
