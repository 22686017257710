import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  BookOpenText,
  Exclude,
  Files,
  SealCheck,
  TextAlignLeft,
  UsersThree,
} from "@phosphor-icons/react";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import {
  Swatches,
  HourglassHigh,
  MaskHappy,
  Target,
  Translate,
  Warning,
} from "@phosphor-icons/react";
import { Flex, Typography } from "antd";
import { useGo, useOne, useList } from "@refinedev/core";
import { useState } from "react";
import {
  BrandKit,
  MediaAsset,
  MediaProjectResponse,
  ContactResponse,
} from "pages/media/types";
import {
  ProjectSettingForm,
  ProjectSettingsItemType,
} from "./ProjectSettingForm";
import { SettingsItem } from "./SettingsItem";
import { secToHHMMSS } from "pages/media/utils";
import { useSupportedLanguages } from "../../../../hooks/useSupportedLanguages";
import { BrandKitPreview } from "pages/media/components/BrandKitPreview";
import { ContactsPopover } from "pages/media/components/ContactsPopover";

export const ProjectSettings = ({
  project,
  setEditing,
  items,
}: {
  project: MediaProjectResponse;
  setEditing: (type: ProjectSettingsItemType) => void;
  items?: ProjectSettingsItemType[];
}) => {
  const t = useTranslate();
  const { data: brandkit } = useOne<BrandKit>({
    resource: `media/${project?.organization_id}/brand_kits`,
    id: project?.settings.brand_kit_id,
    queryOptions: {
      enabled: !!project?.settings.brand_kit_id,
    },
  });
  const { data: ctaGroup } = useOne<MediaAsset>({
    resource: "media/media/assets",
    id: project?.settings.cta_group_id,
    queryOptions: {
      enabled: !!project?.settings.cta_group_id,
    },
  });

  const { data: contactsList } = useList<ContactResponse>({
    resource: `media/${project?.organization_id}/contacts`,
  });
  const mediaContactsSet = new Set(project.media.map((x) => x.contact_id));
  if (project.settings.contact_id) {
    mediaContactsSet.add(project.settings.contact_id);
  }
  const mediaContacts = [...mediaContactsSet];
  const mediaContactsDetails = contactsList?.data.filter((contact: any) =>
    mediaContacts.includes(contact.id)
  );
  const canEditStoryboardSettings = project.settings.status === "Draft";

  return (
    <Flex gap={10} vertical>
      {items?.includes("Brief") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.brief")}
          icon={<TextAlignLeft size={20} />}
          content={project?.description}
          onClick={() => setEditing("Brief")}
          editable
          locked={!canEditStoryboardSettings}
        />
      )}
      {items?.includes("Language") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.language")}
          icon={<Translate size={20} />}
          content={project?.settings.language}
          onClick={() => setEditing("Language")}
          editable
          locked={!canEditStoryboardSettings}
        />
      )}
      {items?.includes("Duration") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.duration")}
          icon={<HourglassHigh size={20} />}
          content={
            project?.settings.duration > 0
              ? secToHHMMSS(project?.settings.duration)
              : ""
          }
          onClick={() => setEditing("Duration")}
          editable
          locked={!canEditStoryboardSettings}
        />
      )}
      {items?.includes("Tone") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.tone")}
          icon={<MaskHappy size={20} />}
          content={t(`src.data.tones.${project.settings.tone_id}.name`, {
            defaultValue: project.settings.tone_id ?? "",
          })}
          onClick={() => setEditing("Tone")}
          editable
          locked={!canEditStoryboardSettings}
        />
      )}
      {items?.includes("Pages") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.keyPages")}
          icon={<Files size={20} />}
          content={
            project.settings.selected_pages ??
            t("projects.components.ProjectSettings.keyPagesAll")
          }
          editable
          onClick={() => setEditing("Pages")}
          locked={!canEditStoryboardSettings}
        />
      )}
      {items?.includes("BrandKit") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.brandKit")}
          icon={<Swatches size={20} />}
          content={
            brandkit?.data && <BrandKitPreview brandkit={brandkit?.data} />
          }
          onClick={() => setEditing("BrandKit")}
          editable
        />
      )}
      {items?.includes("Transitions") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.transition")}
          icon={<Exclude size={20} />}
          content={
            project.settings.transitions
              ? t(
                  `projects.components.ProjectSettings.${project.settings.transitions}`
                )
              : ""
          }
          onClick={() => setEditing("Transitions")}
          editable
        />
      )}
      {items?.includes("Disclaimers") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.disclaimers")}
          icon={<Warning size={20} />}
          content={"N/A"}
        />
      )}
      {items?.includes("CallToActions") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.callToActions")}
          icon={<Target size={20} />}
          content={ctaGroup?.data.description}
          editable
          onClick={() => setEditing("CallToActions")}
        />
      )}
      {items?.includes("Contacts") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.contacts")}
          icon={<UsersThree size={20} />}
          editable
          content={
            <ContactsPopover contacts={mediaContactsDetails}>
              {mediaContacts.length}
            </ContactsPopover>
          }
          onClick={() => setEditing("Contacts")}
        />
      )}
      {items?.includes("PreserveDocument") && (
        <SettingsItem
          title={t("projects.components.ProjectSettings.contentExtraction")}
          icon={<SealCheck size={20} />}
          editable
          onClick={() => setEditing("PreserveDocument")}
          content={
            project.settings.preserve_original_doc
              ? t("projects.components.ProjectSettings.extractive")
              : t("projects.components.ProjectSettings.abstractive")
          }
        />
      )}
    </Flex>
  );
};

export const ProjectSettingsPanel = ({
  project,
}: {
  project: MediaProjectResponse;
}) => {
  const t = useTranslate();
  const go = useGo();
  const [editing, setEditing] = useState<ProjectSettingsItemType>();
  const { supportedLanguagesOptions } = useSupportedLanguages();
  const mediaContactsSet = new Set(project.media.map((x) => x.contact_id));
  project.settings.contact_id &&
    mediaContactsSet.add(project.settings.contact_id);
  const mediaContacts = [...mediaContactsSet];

  if (editing === "CallToActions") {
    return (
      <ProjectSettingForm<string>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.callToActions")}
        subtitle={
          <>
            {t("projects.components.ProjectSettings.pickAnExisting")}{" "}
            <Typography.Text
              style={{ cursor: "pointer" }}
              underline
              type="secondary"
              onClick={() =>
                go({ to: { resource: "media_ctas", action: "create" } })
              }
            >
              {t("projects.components.ProjectSettings.createANew")}
            </Typography.Text>
          </>
        }
        icon={<Target size={20} />}
        value={project.settings.cta_group_id}
        type="CallToActions"
        onClose={() => setEditing(undefined)}
      />
    );
  } else if (editing === "BrandKit") {
    return (
      <ProjectSettingForm<string>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.brandKit")}
        subtitle={t("projects.components.ProjectSettings.chooseYourBrand")}
        icon={<Swatches size={20} />}
        value={project.settings.brand_kit_id}
        type="BrandKit"
        onClose={() => setEditing(undefined)}
      />
    );
  } else if (editing === "Duration") {
    return (
      <ProjectSettingForm<number>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.duration")}
        subtitle={t("projects.components.ProjectSettings.chooseTheDuration")}
        icon={<HourglassHigh size={20} />}
        value={project.settings.duration}
        type="Duration"
        onClose={() => setEditing(undefined)}
      />
    );
  } else if (editing === "Tone") {
    return (
      <ProjectSettingForm<string>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.tone")}
        subtitle={t("projects.components.ProjectSettings.chooseTheTone")}
        icon={<MaskHappy size={20} />}
        value={project.settings.tone_id}
        type="Tone"
        onClose={() => setEditing(undefined)}
      />
    );
  } else if (editing === "Language") {
    return (
      <ProjectSettingForm<string>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.language")}
        subtitle={
          <>
            {t("projects.components.ProjectSettings.chooseTheLanguage")}
            <br />
            {t("projects.components.ProjectSettings.youCanAddAdditional")}
          </>
        }
        icon={<Translate size={20} />}
        value={project.settings.language}
        type="Language"
        onClose={() => setEditing(undefined)}
        options={supportedLanguagesOptions}
      />
    );
  } else if (editing === "Brief") {
    return (
      <ProjectSettingForm<string>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.brief")}
        subtitle={<>{t("projects.components.ProjectSettings.theBriefHelps")}</>}
        icon={<TextAlignLeft size={20} />}
        value={project.description}
        type="Brief"
        onClose={() => setEditing(undefined)}
      />
    );
  } else if (editing === "Contacts") {
    return (
      <ProjectSettingForm<string[]>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.contacts")}
        subtitle={
          <>
            {t("projects.components.ProjectSettings.chooseTheContacts")}
            {project.settings.status === "Draft" && (
              <>
                <br />
                {t("projects.components.ProjectSettings.youCanAddMultiple")}
              </>
            )}
          </>
        }
        icon={<UsersThree size={20} />}
        value={mediaContacts}
        type="Contacts"
        onClose={() => setEditing(undefined)}
      />
    );
  } else if (editing === "Pages") {
    return (
      <ProjectSettingForm<string>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.keyPages")}
        subtitle={t("projects.components.ProjectSettings.chooseThePages")}
        icon={<Files size={20} />}
        // default to '' to be able to accept empty field
        value={project.settings.selected_pages ?? ""}
        type="Pages"
        onClose={() => setEditing(undefined)}
      />
    );
  } else if (editing === "NewStoryboard") {
    return (
      <ProjectSettingForm<string | null>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.addALanguage")}
        subtitle={t("projects.components.ProjectSettings.chooseALanguage")}
        icon={<BookOpenText size={20} />}
        value={null}
        type="NewStoryboard"
        onClose={() => setEditing(undefined)}
        options={supportedLanguagesOptions}
      />
    );
  } else if (editing === "PreserveDocument") {
    return (
      <ProjectSettingForm<boolean>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.contentExtraction")}
        subtitle={t("projects.components.ProjectSettings.chooseYourType")}
        icon={<SealCheck size={20} />}
        value={project.settings.preserve_original_doc}
        type="PreserveDocument"
        onClose={() => setEditing(undefined)}
      />
    );
  } else if (editing === "Transitions") {
    return (
      <ProjectSettingForm<string>
        projectId={project.id}
        title={t("projects.components.ProjectSettings.transition")}
        subtitle={t("projects.components.ProjectSettings.chooseTransition")}
        icon={<Exclude size={20} />}
        value={project.settings.transitions}
        type="Transitions"
        onClose={() => setEditing(undefined)}
      />
    );
  }

  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={8}>
        <Typography.Text type="secondary">
          {t("projects.components.ProjectSettings.storyboardSettings")}
        </Typography.Text>
        {project && (
          <ProjectSettings
            project={project}
            setEditing={setEditing}
            items={[
              "Language",
              "Duration",
              "Tone",
              "Brief",
              "Pages",
              "PreserveDocument",
            ]}
          />
        )}
      </Flex>
      <Flex vertical gap={8}>
        <Typography.Text type="secondary">
          {t("projects.components.ProjectSettings.videoSettings")}
        </Typography.Text>
        {project && (
          <ProjectSettings
            project={project}
            setEditing={setEditing}
            items={[
              "BrandKit",
              "Transitions",
              "Disclaimers",
              "Contacts",
              "CallToActions",
            ]}
          />
        )}
      </Flex>
      {/* <Flex vertical> */}
      {/* <Flex vertical gap={8} id="storyboards">
            <Typography.Text type="secondary">Creative</Typography.Text>
            <Flex gap={15} vertical>
              {project?.storyboards.map((storyboard, index) => (
                <SettingsItem
                  key={index}
                  icon={<BookOpenText size={20} />}
                  onClick={
                    !storyboard.asset_id
                      ? null
                      : () =>
                          go({
                            to: `/media/projects/${project.id}/storyboard/${storyboard.asset_id}`,
                          })
                  }
                  title="Storyboard"
                  content={
                    <Space size="small">
                      <Tooltip
                        title={storyboard.language}
                        showArrow={false}
                        placement="left"
                        mouseEnterDelay={1}
                      >
                        <div
                          className={`fi-${getLocaleCountryCode(
                            storyboard.language
                          )}`}
                          style={{
                            width: "24px",
                            height: "14px",
                            backgroundSize: "cover",
                          }}
                        />
                      </Tooltip>
                      <StoryboardStatusTag
                        status={storyboard.status}
                        tooltip={
                          storyboard.status === "Reviewing"
                            ? "Click to review your Storyboard"
                            : null
                        }
                      />
                    </Space>
                  }
                />
              ))}
              <Space>
                <Button
                  size="middle"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setEditing("NewStoryboard");
                  }}
                  disabled={isLoadingLanguages}
                >
                  Add a language
                </Button>
              </Space>
            </Flex>
          </Flex> */}
      {/* </Flex> */}
    </Flex>
  );
};
