import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";
import React from "react";
import { useAntTheme } from "hooks/useAntTheme";

interface FaceProfilePreviewViewProps {
  name?: string;
  isProcessing: boolean;
  ghost?: boolean;
  path?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  selected?: boolean;
}

const FaceProfilePreviewView: React.FC<FaceProfilePreviewViewProps> = ({
  name,
  isProcessing,
  ghost,
  path,
  style,
  onClick,
  selected,
}) => {
  const { theme } = useAntTheme();
  const [borderColor, setBorderColor] = React.useState(theme.colorPrimary);
  return (
    <Flex
      align="center"
      justify="center"
      aria-label={name}
      style={{
        minHeight: 100,
        minWidth: 100,
        height: "100%",
        width: "100%",
        borderRadius: 100,
        overflow: "hidden",
        border: "3px",
        borderStyle: isProcessing ? "dotted" : "solid",
        borderColor: selected ? borderColor : "transparent",
        ...style,
      }}
      onClick={() => {
        setBorderColor(theme.colorPrimary);
        onClick?.();
      }}
    >
      <Flex
        align="center"
        justify="center"
        style={{
          height: "calc(100% - 6px)",
          width: "calc(100% - 6px)",
          borderRadius: 100,
          backgroundImage: !ghost
            ? "linear-gradient(0deg, #999 0%, #eee 100%)"
            : `linear-gradient(150deg, #DBFFB8 0%, #FEEDA4 100%)`,
        }}
      >
        {isProcessing ? (
          <Spin
            spinning
            size="large"
            indicator={
              <LoadingOutlined
                style={{ color: theme.colorBgBase, opacity: 0.3 }}
              />
            }
          />
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              borderRadius: 100,
              backgroundImage: path ? `url(${path})` : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        )}
      </Flex>
    </Flex>
  );
};

export default FaceProfilePreviewView;
