import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { SaveButton, useForm } from "@refinedev/antd";
import { useApiUrl, useCustomMutation, useNotification } from "@refinedev/core";
import { Flex, Form, Progress, Select, Typography, Upload } from "antd";
import axios from "axios";
import { useRef, useState } from "react";

import { InputHelperText } from "components/InputHelperText";
import { PaperClipOutlined } from "@ant-design/icons";
import {
  AssetUploadResponse,
  MediaAssetType,
  MediaProject,
} from "pages/media/types";
import { UploadProps } from "antd/es/upload";
import { useMediaAssetUpload } from "hooks/useMediaAssetUpload";

const { Dragger } = Upload;

type FormData = {
  asset_type: string;
  category: string;
  filename: string;
};

export const ProjectAssetUpload = ({
  projectData,
  onUploadSuccess,
  assetType,
  onSuccess,
  ...props
}: {
  projectData?: MediaProject;
  onUploadSuccess?: () => Promise<void>;
  assetType?: MediaAssetType;
  onSuccess?: () => Promise<void>;
  handleFinish?: (values: object) => void;
  customRequest?: any;
}) => {
  const t = useTranslate();
  const [isUploading, setIsUploading] = useState(false);
  const [completed, setCompleted] = useState(0);
  const [rerenderKey, setRerenderKey] = useState(0);
  const [fileJson, setFileJson] = useState<Promise<JSON>>();
  const [callback, setCallback] = useState<{
    file?: File;
    onSuccess?: Function;
  }>({});
  const mediaId = useRef<string>();

  const { uploadFiles, customRequest } = useMediaAssetUpload({
    assetType: assetType!,
    // organization: projectId.organization_id,
    buildCreateAssetEndpoint: (file?: File) =>
      `/media/projects/${projectData?.id}/assets`,
    buildCreateAssetParams: (file?: File) => ({}),
    buildCreateAssetPayload: (
      file: File,
      additionalFields?: Record<string, string>
    ) => ({
      type:
        form.getFieldValue("asset_type") === "CuePoints"
          ? "CreateMediaAsset"
          : "CreateDocumentAsset",
      organization_id: projectData!.id,
      category: "AssetImage",
      source: "UiGenerated",
      asset: {
        type: form.getFieldValue("asset_type"),
        [form.getFieldValue("asset_type").toLowerCase()]: fileJson,
      },
      media_id: mediaId.current,
    }),
    onSuccess: async () => {
      setRerenderKey((prev) => prev + 1);
    },
  });

  const { formProps, saveButtonProps, form } = useForm<FormData>({
    // won't be used, the form will be submitted manually to support
    // custom file upload
    action: "create",
    resource: "media/documents",
    onMutationSuccess: (data: any) => {},
  });
  const customAssetType = Form.useWatch("asset_type", form);
  const asset_type = assetType ?? customAssetType;
  const source = "Uploaded"; // todo in backend
  const roots: Record<string, string> = {
    CuePoints: "cuepoints",
    Highlights: "highlights",
  };
  const isJson = Object.keys(roots).includes(asset_type);

  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation<AssetUploadResponse>({});

  const getFileJson = async (file: File): Promise<unknown> => {
    return new Promise(async (resolve, reject) => {
      function onReaderLoad(event: ProgressEvent<FileReader>) {
        resolve(JSON.parse(String(event?.target?.result)));
      }
      const reader = new FileReader();
      reader.onload = onReaderLoad;
      return reader.readAsText(file);
    });
  };

  const handleFinish = async (values: object) => {
    const uploadData = await uploadFiles();
    // update the file upload status in the upload component
    onUploadSuccess && onUploadSuccess();

    // open?.({
    //   type: "success",
    //   message: "Upload successful",
    //   description: `Starting processing document ${file.name}`,
    //   key: file.name,
    // });
    // invalidate on end
    onSuccess && onSuccess();

    setIsUploading(false);
    setCompleted(0);
    form.resetFields();
  };

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={props?.handleFinish || handleFinish}
      disabled={isUploading}
      style={{ maxWidth: 350 }}
    >
      {!assetType && (
        <Form.Item
          name="asset_type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={t(
              "projects.components.ProjectAssetUpload.pickAnAsset"
            )}
            options={[
              {
                value: "CuePoints",
                label: t("projects.components.ProjectAssetUpload.cuePoints"),
              },
              {
                value: "Highlights",
                label: t("projects.components.ProjectAssetUpload.highlights"),
              },
            ]}
          />
        </Form.Item>
      )}
      <Flex vertical key={rerenderKey}>
        <Dragger
          style={{ marginTop: -15 }}
          maxCount={1}
          // accept="application/pdf" // TODO

              name="file"

          iconRender={(_file) => null}
          multiple={false}
          customRequest={async ({ file, onSuccess }) => {
            if (assetType !== "Video") {
              mediaId.current = projectData!.media?.[0]?.id!;
              setFileJson((await getFileJson(file as File)) as Promise<JSON>);
            }
            props?.customRequest
              ? props?.customRequest({ file: file as File, onSuccess })
              : await customRequest({ file, onSuccess });
          }}
        >
          <Flex align="center" justify="center" vertical>
            <Typography.Text>
              {t("projects.components.ProjectAssetUpload.clickOrDrag")}
            </Typography.Text>
            {/* <Typography.Text type="secondary">
          Accepted formats : PDF, DOCX
        </Typography.Text> */}
          </Flex>
          {isUploading && <Progress percent={completed} size="small" />}
        </Dragger>
        <InputHelperText>
          {t("projects.components.ProjectAssetUpload.uploadWillStart")}
        </InputHelperText>
      </Flex>
      <SaveButton
        {...saveButtonProps}
        disabled={isUploading}
        type="default"
        style={{ marginTop: 10 }}
        icon={<PaperClipOutlined />}
      >
        {t("projects.components.ProjectAssetUpload.attach")}
      </SaveButton>
    </Form>
  );
};

