import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { useGo, useOne } from "@refinedev/core";
import { useContext, useEffect, useState } from "react";
import { BinderVideo } from "./BinderVideo";
import { BinderPdf } from "./BinderPdf";
import {
  Button,
  Flex,
  Space,
  Spin,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { BinderAction } from "./BinderAction";
import { BinderCuepoints } from "./BinderCuepoints";
import { IHighlight, NewHighlight } from "react-pdf-highlighter";
import type { Cuepoint } from "./types";
import { JsonPreview } from "../JsonPreview";
import { AppContext } from "appContext";
import { MediaDocument, MediaProjectResponse } from "pages/media/types";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { customAlphabet } from "nanoid";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import { useParams } from "react-router-dom";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "pages/media/utils";

export const Binder = () => {
  const t = useTranslate();
  const [saving, setSaving] = useState(false); // State to track saving status
  const { dispatch: appDispatch } = useContext(AppContext);
  const [ts, setTs] = useState(0);
  const go = useGo();
  const [currentHighlight, setCurrentHighlight] = useState<IHighlight>();
  const [highlightIdToSetup, setHighlightIdToSetup] = useState<string>("");

  const [scrollTo, setScrollTo] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const params = useParams<{
    id: string;
    mediaId: string;
  }>(); // Add mediaId to the type
  const { id: projectId, mediaId } = params ?? {};

  const { data: projectData, isLoading: isLoadingProject } =
    useOne<MediaProjectResponse>({
      resource: `media/projects`,
      id: projectId,
    });
  const organizationId = projectData?.data?.organization_id;

  const documentId =
    projectData?.data.settings.primary_document_id ??
    projectData?.data?.documents?.[0]?.id;

  const { data, isLoading: isLoadingDocument } = useOne<MediaDocument>({
    resource: `media/documents/${organizationId}`,
    id: documentId,
    queryOptions: {
      enabled: Boolean(organizationId) && Boolean(documentId),
    },
  });

  const projectDocument = projectData?.data?.documents.find(
    (x) => x.id === documentId
  );
  const document = data?.data;
  const media = projectData?.data.media.find(
    (mediaItem) => mediaItem.id === mediaId
  );

  const {
    filterAssets,
    saveProjectAsset,
    refetchMediaAssets,
    refetchProjectAssets,
    projectAssets,
    mediaAssets,
    fetchJsonAsset,
    isLoadingMediaAssets,
    isLoadingProjectAssets,
  } = useMediaAssetsStorage({
    organizationId: organizationId,
    projectId,
    mediaId,
    enabled: Boolean(projectId && mediaId),
    invalidateOnMutationSuccess: false,
  });

  const [cuepoints, setCuepoints] = useState<Cuepoint[]>([]);
  const [highlights, setHighlights] = useState<IHighlight[]>([]);

  // legacy
  const highlightAssetManual = projectAssets
    ? filterAssets(projectAssets, {
        asset_type: "Highlights",
        document_id: document?.id,
      })?.[0]
    : null;

  const highlightAsset = projectDocument?.highlights_asset_id
    ? {
        id: projectDocument?.highlights_asset_id,
        path: projectDocument?.highlights_asset_path,
      }
    : highlightAssetManual;

  const cuepointsAsset = mediaAssets
    ? filterAssets(mediaAssets, {
        asset_type: "CuePoints",
      })?.[0]
    : null;
  const isLoading =
    isLoadingMediaAssets ||
    isLoadingProjectAssets ||
    isLoadingProject ||
    isLoadingDocument;

  // useEffect(() => {
  //   console.debug({ cuepointsAsset, highlightAsset });
  // }, [highlightAsset, cuepointsAsset]);

  // useEffect(() => {
  //   console.debug({ highlights, cuepoints });
  // }, [highlights, cuepoints]);

  const normalizeHighlightsForBackend = (highlights: IHighlight[]) => {
    return transformKeysToSnakeCase(
      highlights.map((x) => ({
        ...x,
        position: {
          ...x.position,
          // backend has a default usePdfCoordinates = true, so keep false if sending client side highlights
          usePdfCoordinates: x.position.usePdfCoordinates ?? false,
        },
      }))
    );
  };
  useEffect(() => {
    const loadHighlights = async () => {
      try {
        if (highlightAsset?.path) {
          const json = await fetchJsonAsset(highlightAsset.path);
          if (json.data) {
            setHighlights(transformKeysToCamelCase(json.data));
          }
        }
      } catch (error) {
        console.error("Issue while loading JSON:", error);
      }
    };
    loadHighlights();
  }, [highlightAsset?.id]);

  useEffect(() => {
    if (!mediaAssets) {
      return;
    }
    const loadCuepoints = async () => {
      try {
        if (cuepointsAsset?.path) {
          const json = await fetchJsonAsset(cuepointsAsset.path);
          if (json.data) {
            setCuepoints(transformKeysToCamelCase(json.data));
          }
        }
      } catch (error) {
        console.error("Issue while loading JSON:", error);
      }
    };

    loadCuepoints();
  }, [cuepointsAsset?.id]);

  const startAlreadyUsed = () => {
    return (
      cuepoints.filter((cuepoint) => cuepoint.start === Math.floor(ts)).length >
      0
    );
  };

  const handleNewCuepoint = () => {
    if (startAlreadyUsed()) {
      api.error({
        message: t(`components.binder.index.startTimeInUse`),
        description: t("components.binder.index.youAreTrying"),
        placement: "top",
      });
      return;
    }
    if (currentHighlight?.id) {
      const scaledHighlight = highlights.find((hgl) => {
        return currentHighlight.id === hgl.id;
      });
      const scaledBoudingRect = {
        x1: scaledHighlight!.position.boundingRect.x1,
        y1: scaledHighlight!.position.boundingRect.y1,
        x2: scaledHighlight!.position.boundingRect.x2,
        y2: scaledHighlight!.position.boundingRect.y2,
        width: scaledHighlight!.position.boundingRect.width,
        height: scaledHighlight!.position.boundingRect.height,
      };
      currentHighlight.position.boundingRect = {
        ...currentHighlight.position.boundingRect,
        ...scaledBoudingRect,
      };

      const scaleRects = scaledHighlight?.position.rects.map((rect) => {
        return {
          x1: rect.x1,
          y1: rect.y1,
          x2: rect.x2,
          y2: rect.y2,
          width: rect.width,
          height: rect.height,
        };
      });
      currentHighlight.position.rects = currentHighlight.position.rects.map(
        (rect, index) => {
          return { ...rect, ...scaleRects![index] };
        }
      );

      setCuepoints((cuepoints) => [
        ...cuepoints,
        {
          start: Math.floor(ts),
          highlightId: currentHighlight.id,
          documentId: document?.id!,
          title: "", // todo
          page: 0, // todo
          top: 0, // todo
        },
      ]);
      setHighlightIdToSetup(currentHighlight.id);
    }
  };

  const getNextId = () => {
    //improve readability
    const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const nanoid = customAlphabet(alphabet, 8);
    return nanoid();
  };

  const addHighlight = (highlight: NewHighlight) => {
    // console.debug("adding highlight", highlight);
    setHighlights((value) => {
      const id = getNextId();
      const newHighlight = { ...highlight, id };
      setCurrentHighlight(newHighlight);
      return [...value, newHighlight];
    });
  };

  const removeCuepointsFromHighlight = (highlightId: string) => {
    cuepoints.map((cue: Cuepoint) => {
      if (cue.highlightId === highlightId) {
        removeCuepoint(highlightId, cue.start);
      }
    });
  };

  const removeCuepoint = (highlightId: string, start: number) => {
    setCuepoints((old) => {
      return old.filter(
        (cuepoint) =>
          !(cuepoint.highlightId === highlightId && cuepoint.start === start)
      );
    });
  };

  const updateHighlight = (
    highlightId: string,
    position: object,
    content: object
  ) => {
    // console.log("Updating highlight", highlightId, position, content);

    setHighlights((value) =>
      value.map((h) => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      })
    );
  };

  const handleScroll = (id: string) => {
    setScrollTo(id);
  };

  const removeHighlight = (highlight: IHighlight) => {
    removeCuepointsFromHighlight(String(highlight.id));
    setHighlights((value) => [...value.filter((x) => x.id !== highlight.id)]);
    setCurrentHighlight(undefined);
  };

  const updateCuepoint = (newCuepoint: Cuepoint) => {
    setCuepoints(
      cuepoints.map((cuepoint) => {
        if (cuepoint.start === newCuepoint.start) {
          return newCuepoint;
        }
        return cuepoint;
      })
    );
  };

  useEffect(() => {
    appDispatch({ type: "setSidebarCollapsed", payload: true });

    return () => {
      appDispatch({ type: "setSidebarCollapsed", payload: false });
    };
  }, []);

  const handleSave = async () => {
    if (!media) {
      return; // todo handle error
    }

    try {
      setSaving(true); // Set saving status to true to disable UI

      // save in parallel and then invalidate cache to avoid race conditions
      // and duplicate fetching of data
      await Promise.all([
        saveProjectAsset(
          "CuePoints",
          {
            data: transformKeysToSnakeCase(cuepoints),
          },
          cuepointsAsset?.id,
          {
            media_id: media.id!,
          }
        ),
        saveProjectAsset(
          "Highlights",
          {
            data: normalizeHighlightsForBackend(highlights),
            document_id: documentId,
          },
          highlightAsset?.id,
          {
            document_id: documentId!,
          }
        ),
      ]);
      refetchMediaAssets();
      refetchProjectAssets();

      // Show success notification
      api.success({
        message: t("components.binder.index.saveSuccessful"),
        description: t("components.binder.index.changesHaveBeen"),
        placement: "bottomRight",
      });
    } catch (error) {
      // Show error notification
      api.error({
        message: t("components.binder.index.saveFailed"),
        description: t("components.binder.index.failedToSave"),
        placement: "bottomRight",
      });
    } finally {
      setSaving(false); // Reset saving status
    }
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen tip="Loading data" />

      {contextHolder}
      <Flex vertical gap={10} style={{ margin: -24 }}>
        <Flex
          style={{ padding: 10, borderBottom: "1px solid #99999933" }}
          align="center"
          justify="space-between"
        >
          <Flex vertical gap={0}>
            <Typography.Text strong>
              {t("components.binder.index.mediaCuepointsTo")}
            </Typography.Text>
            <Typography.Text type="secondary">
              {t("components.binder.index.toCreateArea")}
            </Typography.Text>
          </Flex>
          <Space>
            <Button
              size="large"
              icon={<ArrowLeftOutlined />}
              onClick={() => go({ to: `/media/projects/${projectId}` })}
            >
              {t("components.binder.index.backToProject")}
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={handleSave}
              disabled={saving}
            >
              {saving ? (
                <>
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 16 }} spin />
                    }
                  />
                  <span style={{ marginLeft: 8 }}>
                    {t("components.binder.index.saving")}
                  </span>
                </>
              ) : (
                t("save")
              )}
            </Button>
          </Space>
        </Flex>
        <Flex vertical gap={10}>
          <Flex vertical={false} gap="middle" justify="space-between">
            {media && <BinderVideo setTs={setTs} media={media} />}

            <Flex vertical gap={5}>
              <Flex justify="space-between">
                <Typography.Title
                  level={3}
                  type="secondary"
                  style={{ margin: 0, marginBottom: 0 }}
                  onClick={() =>
                    currentHighlight?.id && handleScroll(currentHighlight?.id)
                  }
                >
                  {currentHighlight?.id
                    ? t(
                        "src.pages.media.components.binder.index.selectedHighlight",
                        {
                          id: currentHighlight.id,
                          page: currentHighlight.position.pageNumber,
                        }
                      )
                    : t(
                        "src.pages.media.components.binder.index.noHighlightSelected"
                      )}
                </Typography.Title>
                <Space>
                  {currentHighlight && (
                    <Tooltip
                      title={t("components.binder.index.deleteHighlight")}
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => removeHighlight(currentHighlight)}
                        type="text"
                      />
                    </Tooltip>
                  )}
                  <BinderAction
                    addBookmark={handleNewCuepoint}
                    currentHighlight={currentHighlight}
                  />
                </Space>
              </Flex>

              {document && (
                <BinderPdf
                  document={document}
                  setCurrentHighlight={setCurrentHighlight}
                  highlights={highlights}
                  cuepoints={cuepoints}
                  updateHighlight={updateHighlight}
                  startScrollTo={scrollTo}
                  addHighlight={addHighlight}
                />
              )}
            </Flex>

            <Flex vertical gap={10} style={{ width: 300, marginRight: 10 }}>
              <Typography.Title
                level={3}
                style={{ margin: 0, marginBottom: -5 }}
              >
                {t("components.binder.index.cuePoints")}
              </Typography.Title>
              <BinderCuepoints
                highlightId={highlightIdToSetup}
                updateCuepoint={updateCuepoint}
                cuepoints={cuepoints}
                handleScroll={handleScroll}
                removeCuepoint={removeCuepoint}
              />
            </Flex>
          </Flex>
          <Flex gap={30} style={{ padding: 10 }} justify="space-between">
            <JsonPreview data={cuepoints} />
            <JsonPreview data={highlights} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
