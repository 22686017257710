import { Card, CardProps, GlobalToken } from "antd";
import styled from "styled-components";

export const HoverableCard = styled(Card)<CardProps & { theme: GlobalToken }>`
  &.ant-card-hoverable:hover {
    border-color: ${({ theme }) => theme.colorPrimary};
    box-shadow: none;

    .hoverable-button-text {
      opacity: 1;
    }
  }
  .hoverable-button-text {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

}
`;
