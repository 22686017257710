import { Button, Flex, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import type { TKeyInsight } from "pages/media/types";
import { HoverableCard } from "components/HoverableCard";
import { useAntTheme } from "hooks/useAntTheme";
import { DotsSix } from "@phosphor-icons/react";

type KeyInsightProps = {
  changeKeyInsight: (indice: number, keyInsight: TKeyInsight) => void;
  keyInsight: TKeyInsight;
  id: string;
  index: number;
};

export const KeyInsight: React.FC<KeyInsightProps> = ({
  index,
  id,
  changeKeyInsight,
  keyInsight,
}) => {
  const { Paragraph } = Typography;
  const { theme } = useAntTheme();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
      data: { type: "key_insights" },
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <HoverableCard
        size="small"
        // type="inner"
        // , borderColor: theme.colorWarningBorder
        style={{ borderRadius: 0, borderLeft: 0, borderRight: 0 }}
        styles={{
          header: {
            borderRadius: 0,
            border: 0,
            paddingRight: 0,
            alignItems: "flex-start",
            minHeight: 34,
          },
          body: { paddingRight: 0, paddingTop: 0, paddingBottom: 0 },
          extra: { display: "flex", alignItems: "center" },
        }}
        // hoverable
        extra={[
          <DotsSix {...listeners} size={24} style={{ cursor: "move" }} />,
          <Button
            size="middle"
            shape="circle"
            danger
            onClick={() =>
              changeKeyInsight(index, {
                main_idea: "",
                id: "",
                description: "",
              })
            }
            icon={<DeleteOutlined />}
            type="text"
          ></Button>,
        ]}
        // title={
        //   <Flex {...listeners}>
        //     <Label type="secondary">Key Insight {index + 1}</Label>
        //   </Flex>
        // }
      >
        <Flex vertical>
          <Paragraph
            style={{
              padding: 0,
              margin: 0,
              background: "none",
              fontWeight: "bold",
              marginBottom: 8,
              // color: theme.colorWarning,
            }}
            editable={{
              enterIcon: null,
              onChange: (value) => {
                changeKeyInsight(index, {
                  main_idea: value,
                  id: keyInsight.id,
                  description: keyInsight.description,
                });
              },
            }}
          >
            {keyInsight.main_idea}
          </Paragraph>
          <Paragraph
            // style={{ color: theme.colorWarning }}
            editable={{
              onChange: (value) => {
                changeKeyInsight(index, {
                  description: value,
                  id: keyInsight.id,
                  main_idea: keyInsight.main_idea,
                });
              },
            }}
          >
            {keyInsight.description}
          </Paragraph>
        </Flex>
      </HoverableCard>
    </div>
  );
};
