import React, { useContext } from "react";
import { useGetLocale, useSetLocale, useGetIdentity } from "@refinedev/core";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
  Layout as AntdLayout,
  Space,
  Button,
  Dropdown,
  Avatar,
  Typography,
  Switch,
  MenuProps,
} from "antd";

import { useTranslation } from "react-i18next";
import { ColorModeContext } from "contexts";
import { User } from "authProvider";

const { DownOutlined } = Icons;
const { Text } = Typography;

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity<User>();
  const { mode, setMode } = useContext(ColorModeContext);

  const handleChangeLanguage: MenuProps["onClick"] = (e) => {
    changeLanguage(e.key);
  };

  const currentLocale = locale();

  const items: MenuProps["items"] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      label: lang === "en" ? "English" : "German",
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
    }));

  const menuProps = {
    items,
    onClick: handleChangeLanguage,
  };

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        background: "#1A1D26",
      }}
    >
      <Switch
        checkedChildren="🌛"
        unCheckedChildren="🔆"
        onChange={() => setMode(mode === "light" ? "dark" : "light")}
        defaultChecked={mode === "dark"}
      />
      <Dropdown menu={menuProps}>
        <Button type="link">
          <Space>
            <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
            {currentLocale === "en" ? "English" : "German"}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Space style={{ marginLeft: "8px" }}>
        {user?.name && (
          <Text ellipsis strong>
            {user.name}
          </Text>
        )}
        {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
      </Space>
    </AntdLayout.Header>
  );
};
