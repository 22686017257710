import { useTranslate } from "@refinedev/core";
import { Button as AntButton } from "antd";
import { Steps } from "./types";
import React from "react";
import { useBoundStore } from "store";
import { I18N_PREFIX } from "./constants";

type ButtonProps<T extends Steps[keyof Steps]> = {
  handleClick: (value: string) => void;
  currentValue: T;
  value: string;
  compareFn?: (currentValue: T, value: string) => boolean;
  style: React.CSSProperties;
} & React.ComponentProps<typeof AntButton>;

export const Button = <T extends Steps[keyof Steps]>({
  handleClick,
  currentValue,
  value,
  compareFn,
  style,
}: ButtonProps<T>) => {
  const t = useTranslate();
  const { currentStep } = useBoundStore((state) => ({
    currentStep: state.onboardingState.currentStep,
  }));
  return (
    <AntButton
      type={compareFn?.(currentValue, value) ? "primary" : "default"}
      style={{
        ...style,
      }}
      size="large"
      onClick={() => handleClick(value)}
    >
      {t(`${I18N_PREFIX}.buttons.${currentStep}.${value}`)}
    </AntButton>
  );
};
