import { useEffect, useMemo, useRef } from "react";
import { useList } from "@refinedev/core";
import { VoiceProfileResponse } from "pages/media/types";
import { useOrganization } from "hooks/useOrganization";
import { sortByCreated } from "helpers/utils";
import { useContactPage } from "./useContactPage";

const useVoiceSelector = (selectedVoice?: VoiceProfileResponse) => {
  const { organization } = useOrganization({});
  const origSelectedVoice = useRef(selectedVoice);
  const isEdit = useContactPage().id !== undefined;

  useEffect(() => {
    if (!origSelectedVoice.current) {
      origSelectedVoice.current = selectedVoice;
    }
  }, [selectedVoice]);

  const { data } = useList<VoiceProfileResponse>({
    resource: `media/${organization?.id}/voice_profiles`,
  });

  const voices = data?.data ?? [];
  const { premadeVoices, customVoices } = useMemo(
    () => splitByCategoryAndSortByCreated(voices),
    [data?.data.length]
  );

  const { data: premadeVoicesWithoutOrg } = useList<VoiceProfileResponse>({
    resource: `media/voice_profiles/premade`,
  });

  const { premadeVoicesCopy, customVoicesCopy } =
    unshiftVoiceFromCurrentlyEditedContact(
      premadeVoices,
      customVoices,
      origSelectedVoice,
      premadeVoicesWithoutOrg?.data,
      isEdit
    );

  return {
    isLoading: !data?.data,
    items: {
      premadeVoices: premadeVoicesCopy,
      customVoices: customVoicesCopy,
    },
  };
};

export default useVoiceSelector;

/**
 * Insert the voice from the currently edited contact to the top of the list.
 * This is done only once on loading the contact that we're editing.
 *
 * @param premadeVoices
 * @param customVoices
 * @param origSelectedVoice A ref so that we only do that once on
 * loading the contact that we're editing
 * @param premadeVoicesWithoutOrg
 * @returns
 */
function unshiftVoiceFromCurrentlyEditedContact(
  premadeVoices: VoiceProfileResponse[],
  customVoices: VoiceProfileResponse[],
  origSelectedVoice: React.MutableRefObject<VoiceProfileResponse | undefined>,
  premadeVoicesWithoutOrg: VoiceProfileResponse[] = [],
  isEdit: boolean
) {
  const allPremadeVoices = [...premadeVoices, ...premadeVoicesWithoutOrg];
  const customVoicesCopy = [...customVoices];
  if (isEdit && origSelectedVoice.current) {
    const list =
      origSelectedVoice.current.category === "Premade"
        ? allPremadeVoices
        : customVoicesCopy;
    const selectedVoiceIndex = list.findIndex(
      (voice) => origSelectedVoice.current?.id === voice.id
    );
    list.splice(selectedVoiceIndex, 1);
    list.unshift(origSelectedVoice.current);
  }
  return { premadeVoicesCopy: allPremadeVoices, customVoicesCopy };
}

/**
 * Split the voices by category and sort them by created date.
 *
 * @param voices
 * @returns
 */
function splitByCategoryAndSortByCreated(voices: VoiceProfileResponse[]) {
  const premadeVoices: VoiceProfileResponse[] = [];
  const customVoices: VoiceProfileResponse[] = [];
  voices.forEach((voice) => {
    if (voice.category === "Premade") {
      premadeVoices.push(voice);
    } else {
      customVoices.push(voice);
    }
  });
  return {
    premadeVoices: sortByCreated({ items: premadeVoices }),
    customVoices: sortByCreated({ items: customVoices }), //: filterVoicesWithoutPreview(customVoices ?? []),
  };
}
