import { Badge, BadgeProps, Tooltip } from "antd";
import { ReactNode } from "react";
import type { ProjectSettingsStatus } from "../types";

const STATUS_COLORS: Record<ProjectSettingsStatus, BadgeProps["status"]> = {
  Draft: "default",
  Approved: "success",
};

export const ProjectSettingsStatusBadge = ({
  status,
  label,
}: {
  status: ProjectSettingsStatus;
  label?: ReactNode;
}) => {
  const color = STATUS_COLORS[status];
  
  return (
    <>
      {label ? (
        <Tooltip title={status}>
          <Badge status={color} text={label} />
        </Tooltip>
      ) : (
        <Badge status={color} text={label} />
      )}
    </>
  );
};
