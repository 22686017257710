import { useTranslate } from "@refinedev/core";
export const Retool = () => {
  const t = useTranslate();
  return (
    <iframe
      title={t("src.pages.retool.retool")}
      style={{ width: "100%", height: "100vh", border: 0, margin: -24 }}
      src="https://libertify.retool.com/apps/bd2018ee-fb72-11ec-9dce-b3e396003539/Libertify%20Backoffice"
    ></iframe>
  );
};

