import { useTranslate } from "@refinedev/core";
import { Flex, Typography } from "antd";
import { titles, descriptions } from "./helpers";
import { useBoundStore } from "store";
import { useAntTheme } from "hooks/useAntTheme";

export function TextAndDescription() {
  const t = useTranslate();
  const { currentStep } = useBoundStore((state) => ({
    currentStep: state.onboardingState.currentStep,
  }));
  const { Title, Text } = Typography;
  const { isMobile } = useAntTheme();
  const isFirstStep = Object.keys(titles).indexOf(currentStep) === 0;
  // const styles = {
  //   top: 10,
  //   maxWidth: "800px",
  // };
  // if (!isMobile) {
  //   styles["position"] = "absolute";
  //   styles["flex"] = "1 0 75%";
  //   styles["maxWidth"] = "50%";
  // }

  return (
    <Flex
      vertical
      justify="center"
      align="center"
      style={{
        textAlign: "center",
      }}
    >
      {/* Uncomment to remove the text after the first step */}
      {isFirstStep && (
        <Flex justify="center" style={{ marginTop: isMobile ? 0 : -100 }}>
          <Typography.Title level={4}>
            {t("pages.onboarding.introText")}
          </Typography.Title>
        </Flex>
      )}
      <Title
        style={{
          marginBottom: 0,
          background: "linear-gradient(to right, #15A4A2, #1B3BF6)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
        level={1}
      >
        {t(titles[currentStep])}
      </Title>
      <Text type="secondary">{t(descriptions[currentStep])}</Text>
    </Flex>
  );
}
