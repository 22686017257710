import { Flex, Typography } from "antd";
import { ReactNode } from "react";

export const WizardStepHeader = ({
  title,
  subtitle,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
}) => {
  return (
    <Flex vertical gap={0}>
      <Typography.Title level={2} style={{ margin: 0 }}>
        {title}
      </Typography.Title>
      {subtitle && (
        <Typography.Text type="secondary" style={{ fontSize: "1.1rem" }}>
          {subtitle}
        </Typography.Text>
      )}
    </Flex>
  );
};
