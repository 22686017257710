import { t } from "i18next";
import { useState, forwardRef, ReactNode, useImperativeHandle } from "react";
import { Flex, FlexProps, Progress, Typography, Upload, message } from "antd";
import { UploadFile, UploadProps } from "antd/es/upload/interface";
import { useCustomMutation, BaseRecord, CreateResponse } from "@refinedev/core";
import { CreateUploadableAssetRequest } from "/pages/media/types";
import { InputHelperText } from "./InputHelperText";
import { DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";

const { Dragger } = Upload;
type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
      ? RecursivePartial<T[P]>
      : T[P];
};
type UploadFilesProps = {
  action?: string;
  children?: ReactNode;
  onAbort?: () => void;
  onUploadComplete?: (files: UploadFile[]) => void;
  itemRequestProperties: RecursivePartial<CreateUploadableAssetRequest>;
  onFileAdded?: (fileList: UploadFile[]) => void;
  acceptedMimeTypes: string;
  preview?: (
    file: UploadFile,
    stagedItems?: {
      uid: string;
    }[],
    setStagedItems?: React.Dispatch<
      React.SetStateAction<
        {
          uid: string;
        }[]
      >
    >,
    assets?: unknown[]
  ) => JSX.Element;
  required?: boolean;
  stagedItems?: {
    uid: string;
  }[];
  setStagedItems?: React.Dispatch<
    React.SetStateAction<
      {
        uid: string;
      }[]
    >
  >;
  defaultItems?: unknown[];
  uploadInstructionsProps?: FlexProps;
} & UploadProps;

export interface UploadFilesRef {
  upload: (
    customUrl?: string | ((fileName: string) => string)
  ) => Promise<number | undefined>;
}

const UploadFiles = forwardRef<UploadFilesRef, UploadFilesProps>(
  function UploadFiles(
    {
      action,
      onAbort,
      children,
      onUploadComplete,
      itemRequestProperties: assetRequestProperties,
      onFileAdded,
      acceptedMimeTypes,
      preview,
      required = false,
      stagedItems: stagedAssets,
      setStagedItems: setStagedAssets,
      defaultItems: assets,
      uploadInstructionsProps = {
        vertical: true,
        justify: "center",
        style: {
          minHeight: 100,
        },
      },
      ...props
    },
    ref
  ) {
    const method = "put"; // S3 is configured to only accept PUT
    const [fileList, setFileList] = useState<
      (UploadFile & {
        abort?: () => void;
      })[]
    >([]);
    const { mutateAsync } = useCustomMutation<BaseRecord>();

    const customRequest = ({
      file,
      url,
      onProgress,
      onSuccess,
      onError,
    }: {
      file: UploadFile;
      url: string;
      onProgress?: (event: { percent: number }) => void;
      onSuccess?: (response: any, xhr: XMLHttpRequest) => void;
      onError?: (error: Error) => void;
    }) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      // NB: S3 requires this header to be empty, but that is subject to change since that is against the HTTP spec.
      xhr.setRequestHeader("Content-Type", "");

      xhr.upload.onprogress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
          const percent = (event.loaded / event.total) * 100;
          onProgress!({ percent });
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          onSuccess!(xhr.response, xhr);
        } else {
          onError!(new Error(t("src.components.UploadFiles.uploadFailed")));
        }
      };

      xhr.onerror = () => {
        onError!(new Error(t("src.components.UploadFiles.uploadFailed")));
      };

      xhr.send(file.originFileObj as Blob);

      return () => xhr.abort();
    };

    const handleUpload = async (
      customUrl?: string | ((fileName: string) => string)
    ) => {
      if (!action && !customUrl) {
        throw new TypeError("Either action or customUrl must be provided.");
      }
      if (fileList.length === 0 && required) {
        message.warning(t("src.components.UploadFiles.pleaseSelect"));
        return;
      }

      let url: string;
      if (typeof customUrl === "function") {
        url = customUrl(fileList[0].name);
      } else {
        url = (customUrl ?? action) as string;
      }
      const apiPromises = fileList.map((file) => {
        const values = {
          ...assetRequestProperties,
          name: file.name,
          asset: {},
          ...stagedAssets?.find((asset) => asset.uid === file.uid),
        };
        if ("asset" in assetRequestProperties && assetRequestProperties.asset) {
          values.asset = {
            ...assetRequestProperties.asset,
            file_name: file.name,
          };
        }
        return mutateAsync({
          url: encodeURI(url),
          method: "post",
          values,
        });
      });
      let responses: CreateResponse<BaseRecord>[] = [];
      try {
        responses = await Promise.all(apiPromises);
      } catch (error) {
        message.error("src.components.UploadFiles.uploadFailed");
        throw error;
      }

      const uploadPromises = fileList.map((file, index) => {
        return new Promise((resolve, reject) => {
          file.abort = customRequest({
            file,
            url:
              responses[index].data.target ||
              responses[index].data.target_signed_dict,
            onProgress: (event: { percent: number }) => {
              const updatedFile = { ...file, percent: event.percent };

              setFileList((prevList) =>
                prevList.map((item) =>
                  item.uid === file.uid ? updatedFile : item
                )
              );
            },
            onSuccess: (_response: any) => {
              const updatedFile = {
                ...file,
                percent: 100,
                status: "done" as const,
              };
              setFileList((prevList) =>
                prevList.map((item) =>
                  item.uid === file.uid ? updatedFile : item
                )
              );
              resolve(updatedFile);
            },
            onError: (error: Error) => {
              const updatedFile = { ...file, status: "error" as const };
              setFileList((prevList) =>
                prevList.map((item) =>
                  item.uid === file.uid ? updatedFile : item
                )
              );
              reject(error);
            },
          } as any);
        });
      });

      return Promise.all(uploadPromises).then((uploads) => {
        onUploadComplete?.(fileList);
        return uploads.flat().filter((asset) => !!asset).length;
      });
    };

    useImperativeHandle(ref, () => ({
      upload: handleUpload,
    }));

    const draggerProps: UploadProps = {
      name: "file",
      fileList,
      multiple: true,
      accept: acceptedMimeTypes,
      onChange: (info) => {
        onFileAdded && onFileAdded([info.file]);
        // Antd will replace a file if maxCount = 1
        // but will not do anything if maxCount > 1
        if (props.maxCount === 1) {
          setStagedAssets?.((prev) => {
            const isRemoving = info.file.status === "removed";

            if (isRemoving) {
              // Remove the previous item if it's there
              // add the new one if it's not
              return prev.filter((asset) => asset.uid !== info.file.uid);
            } else {
              return [...prev, { uid: info.file.uid }];
            }
          });
        }
        setFileList([...info.fileList]);
      },

      itemRender: (originNode, file, _fileList, { remove }) => {
        return (
          <div>
            {preview
              ? preview(file, stagedAssets, setStagedAssets, assets)
              : originNode}
            <Flex align="center">
              <Progress
                trailColor="primary"
                percent={Math.round(file.percent ?? 0)}
              />
              {(file.percent ?? 0) < 100 && (
                <DeleteOutlined
                  style={{
                    fontSize: 16,
                  }}
                  onClick={() => {
                    (
                      file as {
                        abort?: () => void;
                      }
                    )?.abort?.();
                    onAbort?.();
                    remove();
                  }}
                />
              )}
            </Flex>
          </div>
        );
      },
      beforeUpload: (file) => {
        setStagedAssets?.((prev) => {
          if (prev) {
            return [...prev, { uid: file.uid }];
          }
          return [{ uid: file.uid }];
        });
        return false;
      },
      onRemove: (file) => {
        setStagedAssets?.((prev) =>
          prev.filter((asset) => asset.uid !== file.uid)
        );
        setFileList((prevList) =>
          prevList.filter((item) => item.uid !== file.uid)
        );
      },
      ...props,
    };

    return (
      <div className="flex p-4">
        <StyledDragger hasPreview={!!preview} {...draggerProps}>
          <>
            {children}
            <Flex {...uploadInstructionsProps}>
              <Typography.Text>
                {t("src.components.UploadFiles.clickOrDrag")}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t("src.components.UploadFiles.acceptedFormats")}
                {formatAcceptedFormats()}
              </Typography.Text>
            </Flex>
          </>
        </StyledDragger>
        <InputHelperText>
          {t("src.components.UploadFiles.uploadWillStart")}
        </InputHelperText>
      </div>
    );

    function formatAcceptedFormats(): string {
      const formats = acceptedMimeTypes.split(", ").map((type) => {
        if (type.includes("/")) {
          return type.split("/")[1].toUpperCase();
        }
        return type.match(/\.\w+/)?.[0].toUpperCase().slice(1);
      });
      const uniqFormats = Array.from(new Set(formats));
      return uniqFormats.join(", ");
    }
  }
);

export default UploadFiles;

function MyStyledDragger({
  hasPreview,
  ...props
}: {
  readonly hasPreview: boolean;
} & UploadProps) {
  return <Dragger {...props} />;
}

const StyledDragger = styled(MyStyledDragger)`
  & .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 16px;
  }
  ${({ hasPreview }) =>
    hasPreview
      ? ""
      : `& .ant-upload-list-item-container {
    width: 100%;
  }`}}`;

