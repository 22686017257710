import { useTranslate } from "@refinedev/core";
import { useState } from "react";
import { Authenticated, useCustomMutation } from "@refinedev/core";
import { useApiUrl, useList } from "@refinedev/core";
import { Button, Space, Spin, Table, Tag, Tooltip, Typography } from "antd";
import { AssetChange } from "types";
import { DateField, List } from "@refinedev/antd";
import { JsonPreview } from "pages/media/components/JsonPreview";
import { green } from "@ant-design/colors";
import type { TableColumnsType } from "antd";
import { addDateTz } from "pages/media/utils";

export const AssetChangesReview = () => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const [updates, setUpdates] = useState<
    {
      id: string;
      status: string;
    }[]
  >([]);
  const [updating, setUpdating] = useState(new Set());

  const { data: changes, isLoading } = useList<AssetChange>({
    resource: `/api/assets/changes?list_status=Pending`,
    queryOptions: {
      enabled: true,
    },
  });

  const updateMutation = useCustomMutation();

  const acceptChange = async (id: string) => {
    try {
      await updateChangeStatus(id, "Accepted");
    } catch (error) {
      console.error("Accept mutation error:", error);
    }
  };

  const rejectChange = async (id: string) => {
    try {
      await updateChangeStatus(id, "Rejected");
    } catch (error) {
      console.error("Reject mutation error:", error);
    }
  };

  const getFieldFilters = (field: keyof AssetChange) => {
    const values = changes?.data.map((x) => x[field]);
    const unique = new Set(values);
    return [...unique].sort().map((x) => ({
      text: x,
      value: x,
    }));
  };

  const updateChangeStatus = async (id: string, status: string) => {
    try {
      setUpdating((value) => {
        const more = new Set([...value]);
        more.add(id);
        console.debug({ more });
        return new Set([...more]);
      });

      await updateMutation.mutate(
        {
          url: `${apiUrl}/api/assets/changes/${id}?status=${status}`,
          values: {},
          method: "patch",
        },
        {
          onSuccess: () => {
            setUpdates((values) => {
              return [...values, { id, status }];
            });
            setUpdating((value) => {
              const less = new Set([...value]);
              less.delete(id);
              return new Set([...less]);
            });
          },
          onError: () => {
            setUpdating((value) => {
              const less = new Set([...value]);
              less.delete(id);
              return new Set([...less]);
            });
          },
        }
      );
    } catch (error) {
      console.error("Update change status error:", error);
    }
  };

  const columns: TableColumnsType<AssetChange> = [
    {
      title: t("pages.assets.changes.ticker"),
      dataIndex: "ticker",
      key: "ticker",
      sorter: {
        compare: (a, b) => a.ticker.localeCompare(b.ticker),
        multiple: 1,
      },
      sortDirections: ["ascend", "descend"],
      // defaultSortOrder: "descend",
      // showSorterTooltip: true,
      filters: getFieldFilters("ticker"),
      filterSearch: true,
      onFilter: (value, record) => record.ticker.startsWith(value as string),
    },
    {
      title: t("pages.assets.changes.date"),
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: (value: string) => (
        <DateField format="LLL" value={addDateTz(value)} />
      ),
    },
    {
      title: t("pages.assets.changes.objectType"),
      dataIndex: "object_type",
      key: "object_type",
      filters: getFieldFilters("object_type"),
      onFilter: (value, record) => record.object_type === value,
      sorter: {
        compare: (a, b) => a.date.localeCompare(b.date),
        multiple: 2,
      },
    },
    {
      title: t("pages.assets.changes.field"),
      dataIndex: "field",
      filters: getFieldFilters("field"),
      onFilter: (value, record) => record.field === value,
      render: (value: string) => (
        <Typography.Text code>{value}</Typography.Text>
      ),
    },
    {
      title: t("pages.assets.changes.value"),
      dataIndex: "new_value",
      key: "new_value",
      render: (value: string) => {
        try {
          if (value.charAt(0) !== "{") {
            return value;
          }
          return (
            <Tooltip title={<JsonPreview data={JSON.parse(value)} />}>
              {value}
            </Tooltip>
          );
        } catch (e) {
          return value;
        }
      },
    },
    {
      title: t("pages.assets.changes.source"),
      dataIndex: "source",
      key: "source",
      filters: getFieldFilters("source"),
      onFilter: (value, record) => record.source === value,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.source.localeCompare(b.source),
    },
    {
      title: t("pages.assets.changes.status"),
      dataIndex: "status",
      key: "status",
      render: (_value: string, _record: AssetChange) => {
        const isUpdating = updating.has(_record.id);
        if (isUpdating) return <Spin />;
        const update = updates.find((x) => x.id === _record.id);
        const status = update ? update?.status : _record.status;
        return <Tag color="blue">{status}</Tag>;
      },
    },
    {
      title: t("pages.assets.changes.action"),
      key: "action",
      render: (_record: AssetChange) => {
        const updated = Boolean(updates.find((x) => x.id === _record.id));
        return (
          <Space size="middle">
            <Button
              style={{ background: green[6] }}
              type="primary"
              onClick={() => acceptChange(_record.id)}
              disabled={updating.has(_record.id) || updated}
            >
              {t("pages.assets.changes.accept")}
            </Button>
            <Button
              danger
              type="primary"
              onClick={() => rejectChange(_record.id)}
              disabled={updating.has(_record.id) || updated}
            >
              {t("pages.assets.changes.reject")}
            </Button>
          </Space>
        );
      },
    },
  ];
  const data = changes?.data;

  return (
    <Authenticated key="assets_changes">
      <List title={t("pages.assets.changes.assetsChanges")}>
        <Table<AssetChange>
          sticky={{ offsetHeader: 0 }}
          pagination={{ pageSize: 30 }}
          loading={isLoading}
          dataSource={data}
          columns={columns}
          size="large"
        />
      </List>
    </Authenticated>
  );
};

