import { useTranslate } from "@refinedev/core";
import { Button, Space, Table, TableColumnsType } from "antd";
import { useGo, useList } from "@refinedev/core";
import { ContactResponse } from "../types";
import { useOrganization } from "hooks/useOrganization";
import { UUID } from "../../../components/UUID";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { CreateButton } from "@refinedev/antd";
import { List } from "@refinedev/antd";

export const ContactListPage = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});

  const { data, isLoading, refetch, isRefetching } = useList<ContactResponse>({
    resource: `media/${organization?.id}/contacts`,
    pagination: { mode: "off" },
  });
  const go = useGo();

  const columns: TableColumnsType<ContactResponse> = [
    {
      title: t("media.contacts.index.id"),
      dataIndex: "id",
      key: "id",
      render: (id: string) => <UUID id={id} />,
    },
    {
      title: t("media.contacts.index.name"),
      dataIndex: "firstname",
      key: "firstname",
      render: (_: string, record: ContactResponse) =>
        record.firstname + " " + record.lastname,
    },
    // {
    //   title: "Preview",
    //   dataIndex: "extracted_asset_id",
    //   key: "extracted_asset_id",
    //   render: (value: string, record: FaceProfileResponse) => {
    //     return (
    //       <FaceProfilePreview
    //         assetId={value}
    //         name={record.name}
    //         style={{ maxWidth: 200, borderRadius: 100 }}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: "Creation date",
    //   dataIndex: "created",
    //   key: "created",
    //   render: (text) => <DateField value={text} format="LLL" />,
    // },
  ];

  return (
    <List
      headerButtons={({ createButtonProps }) => (
        <Space>
          <Button
            icon={<SyncOutlined />}
            onClick={() => refetch()}
            disabled={isRefetching}
            shape="circle"
            type="text"
          />
          {/* todo check accesses */}
          {createButtonProps && (
            <CreateButton
              {...createButtonProps}
              icon={<PlusOutlined />}
              shape="round"
            >
              {t("media.contacts.index.createAContact")}
            </CreateButton>
          )}
        </Space>
      )}
    >
      <Table<ContactResponse>
        loading={isLoading}
        dataSource={data?.data}
        columns={columns}
        pagination={{ pageSize: 50 }}
        onRow={(record) => ({
          onClick: (event) => {
            go({
              to: {
                resource: "media_contacts",
                id: record.id,
                action: "edit",
              },
            });
          },
          style: { cursor: "pointer" },
        })}
      />
    </List>
  );
};
