import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Table } from "antd";
import { List, useTable } from "@refinedev/antd";

interface DataType {
  key: React.Key;
  ticker: string;
  name: string;
  category: string;
  subCat: string;
  holders: string;
  aum: string;
  status: string;
  exchanges: string | Array<string>;
  createdAt: string;
  actions: number;
}

export const MediaTemplateList: React.FC = () => {
  const t = useTranslate();
  const { tableProps } = useTable({
    resource: "media/templates",
  });

  return (
    <List>
      <Table
        {...tableProps}
        // scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
        pagination={{ pageSize: 20 }}
      >
        <Table.Column dataIndex="id" title={t("media.templates.list.id")} />
        <Table.Column dataIndex="name" title={t("media.templates.list.name")} />
        <Table.Column
          dataIndex="account_id"
          title={t("media.templates.list.account")}
        />
        <Table.Column
          dataIndex="parent_id"
          title={t("media.templates.list.parentId")}
        />
      </Table>
    </List>
  );
};

