import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { IHighlight } from "react-pdf-highlighter";

export const BinderAction: React.FC<{
  addBookmark: () => void;
  currentHighlight?: IHighlight;
}> = ({ addBookmark, currentHighlight }) => {
  const t = useTranslate();
  const [messageApi, contextHolder] = message.useMessage();
  const showError = () => {
    messageApi.open({
      type: "error",
      content: t("components.binder.BinderAction.noSelection"),
    });
  };

  return (
    <>
      {contextHolder}
      <Button
        size="middle"
        shape="round"
        onClick={currentHighlight ? addBookmark : showError}
        icon={<PlusOutlined type="text" />}
      >
        {t("components.binder.BinderAction.createCuePoint")}
      </Button>
    </>
  );
};
