import {
  DocumentExtract,
  MediaProject,
  TDetailedStoryboard,
} from "pages/media/types";
import React, {
  createContext,
  useContext,
  ReactNode,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from "react";
import { IHighlight } from "react-pdf-highlighter";

// Define the context type
interface StoryboardContextType {
  project: MediaProject;
  handleDisplaySource: (source: DocumentExtract) => void;
  storyboard: TDetailedStoryboard | undefined;
  setStoryboard: React.Dispatch<
    React.SetStateAction<TDetailedStoryboard | undefined>
  >;
  highlights?: IHighlight[];
  handleClickHighlight: (sourceId: string) => void;
  sourcesRef: MutableRefObject<Record<string, HTMLElement>>;
  highlightedSource?: string;
  setHighlightedSource: Dispatch<SetStateAction<string | undefined>>;
}

// Create the context with a default value
const StoryboardContext = createContext<StoryboardContextType | undefined>(
  undefined
);

// Create a provider component
const StoryboardProvider: React.FC<{
  children: ReactNode;
  value: StoryboardContextType;
}> = ({ value, children }) => {
  return (
    <StoryboardContext.Provider value={value}>
      {children}
    </StoryboardContext.Provider>
  );
};

// Create a custom hook to use the context
const useStoryboard = (): StoryboardContextType => {
  const context = useContext(StoryboardContext);
  if (context === undefined) {
    // Give back some noop functions for the PDF highlighter to work
    return {
      project: {} as MediaProject,
      handleDisplaySource: () => {},
      storyboard: undefined,
      setStoryboard: () => {},
      handleClickHighlight: () => {},
      sourcesRef: { current: {} },
      setHighlightedSource: () => {},
    };
  }
  return context;
};

export { StoryboardProvider, useStoryboard };
