import { t } from "i18next";
import { useGo, useList } from "@refinedev/core";
import { useState } from "react";
import { Button, DatePicker, Flex, TimeRangePickerProps } from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { List } from "antd";
import VirtualList from "rc-virtual-list";
import { MediaControl, RangeValue } from "../types";
import { ArrowRightOutlined } from "@ant-design/icons";
import { sortByDate } from "helpers/utils";
import { UUID } from "components/UUID";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/fr"; // Import French locale
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.locale("fr"); // Set dayjs to use French locale

const { RangePicker } = DatePicker;

const rangePresets: TimeRangePickerProps["presets"] = [
  {
    label: t("media.components.MediaAlertList.last24H"),
    value: [dayjs().add(-1, "d"), dayjs()],
  },
  {
    label: t("media.components.MediaAlertList.last7Days"),
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: t("media.components.MediaAlertList.last14Days"),
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: t("media.components.MediaAlertList.last30Days"),
    value: [dayjs().add(-30, "d"), dayjs()],
  },
];

export const MediaAlertList = () => {
  const go = useGo();
  const [dateRange, setDateRange] = useState<RangeValue<Dayjs>>([
    dayjs().add(-1, "d"),
    dayjs(),
  ]);
  const { data, isFetching, error } = useList<MediaControl>({
    resource: `media/media/alerts?from_time=${dateRange?.[0]?.format("YYYY-MM-DD HH:mm:ss")}&to_time=${dateRange?.[1]?.format("YYYY-MM-DD HH:mm:ss")}`,
    queryOptions: {
      enabled: dateRange !== null,
    },
  });

  const handleDateRangeChange = (values: RangeValue<Dayjs> | null) => {
    setDateRange(values);
  };

  const gotoMedia = (id: string) => {
    go({ to: { resource: "media_media", action: "show", id } });
  };

  const sortedData = sortByDate<MediaControl, "updated">(
    data?.data as MediaControl[],
    "updated",
    "DESC"
  ).map((x, idx) => ({
    ...x,
    id: idx, // fake unique id to avoid key warnings in List
  }));

  return (
    <Flex vertical gap={10}>
      <RangePicker
        size="middle"
        showTime
        defaultValue={dateRange}
        format="YYYY/MM/DD HH:mm:ss"
        presets={rangePresets}
        onChange={handleDateRangeChange}
      />

      <List size="small" loading={isFetching}>
        <VirtualList data={sortedData} height={450} itemKey={"id"}>
          {(item: MediaControl, index) => (
            <List.Item
              style={{ cursor: "pointer" }}
              onClick={() => gotoMedia(item.media_id)}
              key={index}
              actions={[<Button icon={<ArrowRightOutlined />} type="text" />]}
            >
              <List.Item.Meta
                title={`${t("media.components.MediaAlertList.newsGeneratedStart")} ${item.control.split(" ")[1]} ${t("media.components.MediaAlertList.newsGeneratedEnd")}`}
                description={
                  <>
                    {dayjs(item.updated).format("LLLL")} •{" "}
                    {dayjs(item.updated).fromNow()}
                  </>
                }
              />
              <UUID id={item.media_id} />
            </List.Item>
          )}
        </VirtualList>
      </List>
    </Flex>
  );
};
