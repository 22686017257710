import { Authenticated } from "@refinedev/core";
import { Binder } from "../components/binder";
import { Animation } from "../components/animator";

export const MediaCuepointsEditor: React.FC = () => {
  return (
    <Authenticated key={"mediaCuepointsEditor"}>
      <Binder />
    </Authenticated>
  );
};

export const MediaCuepointsAnimation: React.FC = () => {
  return (
    <Authenticated key={"mediaCuepointsEditor"}>
      <Animation />
    </Authenticated>
  );
};
