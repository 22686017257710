import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React from "react";
import { Drawer, Spin, Timeline, Tooltip, Typography } from "antd";
import { useList } from "@refinedev/core";
import { MediaControl } from "../types";

interface MediaControlListProps {
  mediaId: string;
  visible: boolean;
  onClose: () => void;
}

export const MediaControlList: React.FC<MediaControlListProps> = ({
  mediaId,
  visible,
  onClose,
}) => {
  const t = useTranslate();
  const { data, isLoading } = useList<MediaControl>({
    resource: `media/media/controls?media_id=${mediaId}`,
    queryOptions: {
      enabled: visible,
    },
  });

  const colors: Record<MediaControl["status"], string> = {
    Success: "green",
    Failed: "orange", // control fails, but not blockling, can be a normal impossible check at this specific time
    Alert: "red",
    Skipped: "gray",
  };

  const sortedControls = (data?.data || []).sort((a, b) =>
    a.updated.localeCompare(b.updated)
  );

  return (
    <Drawer
      title={t("media.components.MediaControlList.mediaControls")}
      placement="right"
      onClose={onClose}
      size="large"
      open={visible}
    >
      {isLoading && <Spin />}
      <Timeline mode="left">
        {sortedControls?.map((control, index) => (
          <Timeline.Item
            color={colors[control.status]}
            key={index}
            label={
              <Typography.Text type="secondary">
                {control.updated}
              </Typography.Text>
            }
          >
            <Tooltip title={control.status}>{control.control}</Tooltip>
          </Timeline.Item>
        ))}
      </Timeline>
    </Drawer>
  );
};

export default MediaControlList;

