import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Flex } from "antd";
import { Steps } from "./types";
import { Button } from "./Button";
import { useBoundStore } from "store";
import { PropsWithChildren } from "react";
import { ORDERED_BUTTONS } from "./constants";
import { useAntTheme } from "hooks/useAntTheme";

type StepsWithButtons = {
  [K in keyof Steps as K extends keyof typeof ORDERED_BUTTONS
    ? K
    : never]: Steps[K];
};
type StepsWithButtonsKey = keyof StepsWithButtons;
export interface ButtonsProps<T extends Steps[keyof Steps]>
  extends PropsWithChildren {
  readonly handleClick: (value: string) => void;
  readonly compareFn: (currentValue: T, value: string) => boolean;
}

export function Buttons<T extends Steps[keyof Steps]>({
  children,
  handleClick,
  compareFn,
}: ButtonsProps<T>) {
  const t = useTranslate();
  const { stepValue, currentStep } = useBoundStore((state) => {
    const currentStep = state.onboardingState.currentStep;
    return {
      currentStep,
      stepValue: state.onboardingState[currentStep],
    };
  });

  if (!isStepWithButtons(currentStep)) {
    throw new Error(t("pages.onboarding.Buttons.invalidStep"));
  }

  const { isMobile } = useAntTheme();
  const style = isMobile
    ? { flex: "0 1 100%" }
    : ORDERED_BUTTONS[currentStep].length > 3
      ? { flex: "0 1 calc(50% - 16px)" }
      : { flex: "0 1 calc(33% - 16px)" };

  return (
    <Flex
      gap={16}
      justify="space-between"
      wrap={isMobile ? "nowrap" : "wrap"}
      style={{ width: "100%" }}
      vertical={isMobile}
    >
      {ORDERED_BUTTONS[currentStep].map((value) => (
        <Button<T>
          handleClick={handleClick}
          currentValue={stepValue as T}
          value={value}
          key={value}
          compareFn={compareFn}
          style={style}
        />
      ))}
      {children}
    </Flex>
  );
}

function isStepWithButtons(step: string): step is StepsWithButtonsKey {
  return step in ORDERED_BUTTONS;
}

