import { Flex } from "antd";
import { FaceProfileCreateForm } from "../components/FaceProfileCreateForm";
import { Authenticated, useGo } from "@refinedev/core";

export const FaceProfileCreatePage = () => {
  const go = useGo();
  return (
    <Authenticated key="face_create">
      <Flex align="center" justify="center">
        <FaceProfileCreateForm
          onChange={() =>
            go({
              to: {
                resource: "media_faces",
                action: "list",
              },
            })
          }
        />
      </Flex>
    </Authenticated>
  );
};
