import { STORYBOARD_WS_URL } from "../constants";
import { nanoid } from "nanoid";
import {
  StoryboardMessage,
  WebSocketMessage,
} from "pages/media/components/chat/types";
import { SectionUpdate, TScript } from "pages/media/types";
import { useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Maybe } from "types";
import { useAuth } from "./useAuth";

export const useStoryboardWs = ({
  contentType,
  documentIds,
  organizationId,
  onMessage,
}: {
  contentType?: string[];
  documentIds: string[];
  organizationId: string;
  stock?: string;
  onMessage?: (message: WebSocketMessage) => void;
}) => {
  const { accessToken } = useAuth();
  const socketUrl = `${STORYBOARD_WS_URL}?token=${accessToken}&user_id=123&script_id=test`;
  const { sendJsonMessage, readyState } = useWebSocket(socketUrl, {
    onMessage: (event) => {
      handleMessage(event);
    },
  });
  const [messageHistory, setMessageHistory] = useState<StoryboardMessage[]>([]);
  const messageHistoryRef = useRef<StoryboardMessage[]>([]);
  const [isBusy, setIsBusy] = useState(false);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const init = ({
    storyboardId,
    projectId,
  }: {
    storyboardId: string;
    projectId: string;
  }) => {
    const data = {
      query_id: nanoid(),
      action: "SelectDocument",
      organization_id: organizationId,
      document_id: documentIds[0],
      project_id: projectId,
      storyboard_id: storyboardId,
      // content_type: contentType ?? [
      //   "texts_raw",
      //   "tables_raw",
      //   "imgs_base64_raw",
      // ],
      // stock,
    };
    // console.debug("Sending message");
    sendJsonMessage(data);
  };

  const addMessage = (message: any) => {
    setMessageHistory((messagesList) => [...messagesList, message]);
    messageHistoryRef.current = [...messageHistoryRef.current, message];
  };

  // @deprecated action
  const requestStructureDocument = () => {
    const query_id = nanoid();
    const data = {
      action: "StructureDocument",
      query_id,
    };
    addMessage({ ...data, is_final: false });
    sendJsonMessage(data);
    setIsBusy(true);
  };

  const requestComputeMetrics = (storyboard: unknown) => {
    const query_id = nanoid();
    const data = {
      action: "ComputeMetrics",
      query_id,
      storyboard,
    };
    addMessage({ ...data, is_final: false });
    sendJsonMessage(data);
    setIsBusy(true);
  };

  const requestTextToSpeech = (sectionId: string) => {
    const query_id = nanoid();
    const data = {
      action: "TextToSpeech",
      query_id,
      section_id: sectionId,
    };
    addMessage({ ...data, is_final: false });
    sendJsonMessage(data);
    setIsBusy(true);
    return query_id;
  };

  const requestGenerateScript = (
    storyboard: unknown, // TODO
    sectionId: string
  ) => {
    const query_id = nanoid();
    const data = {
      action: "GenerateScript",
      query_id,
      storyboard,
      section_id: sectionId,
    };
    addMessage({ ...data, is_final: false });
    sendJsonMessage(data);
    setIsBusy(true);
    return query_id;
  };

  const requestImproveScript = (
    sectionId: string,
    script: TScript,
    message: string
  ) => {
    const query_id = nanoid();
    const data = {
      action: "ImproveScript",
      query_id,
      script,
      message,
      section_id: sectionId,
    };
    addMessage({ ...data, is_final: false });
    sendJsonMessage(data);
    setIsBusy(true);
    return query_id;
  };

  const requestCreateSection = (topic: string, storyboard: unknown) => {
    const query_id = nanoid();
    const data = {
      action: "CreateSection",
      query_id,
      topic,
      storyboard,
    };
    addMessage({ ...data, is_final: false });
    sendJsonMessage(data);
    setIsBusy(true);
  };

  const requestUpdateSection = (
    section_id: string,
    section_update: SectionUpdate
  ) => {
    const query_id = nanoid();
    const data = {
      action: "UpdateSection",
      query_id,
      section_id,
      section_update,
    };
    addMessage({ ...data, is_final: false });
    sendJsonMessage(data);
    setIsBusy(true);
  };

  const requestRegenerateSection = (
    section_id: string,
    topic: string,
    storyboard: unknown
  ) => {
    const query_id = nanoid();
    const data = {
      action: "RegenerateSection",
      query_id,
      topic,
      section_id,
      storyboard,
    };
    addMessage({ ...data, is_final: false });
    sendJsonMessage(data);
    setIsBusy(true);
  };

  const requestRetrieveSectionData = (id: string) => {
    const query_id = nanoid();
    const data = {
      action: "RetrieveSectionData",
      query_id,
      section_id: id,
      needed_fields: ["KeyInsights", "DetailedSummary", "Sources"],
    };
    addMessage({ ...data, is_final: false });
    sendJsonMessage(data);
    setIsBusy(true);
  };

  const handleMessage = (lastMessage: MessageEvent<any> | null) => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      const existingMessageIndex = messageHistoryRef.current.findIndex(
        (message) => message.query_id === data.query_id
      );
      if (existingMessageIndex >= 0) {
        const updatedMessage = {
          ...messageHistoryRef.current[existingMessageIndex], // current value
          ...data, // new value
          is_final: true, // no streaming needed
        };
        const newMessagesList = [...messageHistoryRef.current];
        newMessagesList[existingMessageIndex] = updatedMessage;

        messageHistoryRef.current = newMessagesList;
        setMessageHistory(messageHistoryRef.current);
        setIsBusy(messageHistoryRef.current.some((x) => x.is_final === false));
        onMessage?.(updatedMessage);
      } else {
        // new id
      }
      // console.debug({ lastMessage, existingMessageIndex, messageHistoryRef });
    }
  };

  const getConnectionStatus = () => {
    return connectionStatus;
  };
  const isConnected = getConnectionStatus() === "Open";

  const lastMessage = messageHistory[messageHistory.length - 1];
  const lastResponse = (
    lastMessage?.is_final === true ? lastMessage : null
  ) as Maybe<WebSocketMessage>;

  return {
    init,
    // requestStructureDocument,
    requestRegenerateSection,
    requestCreateSection,
    requestUpdateSection,
    requestGenerateScript,
    requestImproveScript,
    requestComputeMetrics,
    requestRetrieveSectionData,
    requestTextToSpeech,
    messageHistory,
    lastResponse,
    lastMessage,
    getConnectionStatus,
    isConnected,
    isBusy: isBusy && isConnected,
  };
};
