import { useTranslate } from "@refinedev/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Authenticated,
  CanAccess,
  IResourceComponentsProps,
  useGo,
  useInvalidate,
  useShow,
  useUpdate,
} from "@refinedev/core";
import { DateField, DeleteButton } from "@refinedev/antd";
import {
  Avatar,
  Button,
  Empty,
  Flex,
  Form,
  GlobalToken,
  Input,
  Modal,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { MediaProjectResponse } from "../types";
import { Show } from "@refinedev/antd";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  CheckOutlined,
  CloseOutlined,
  PaperClipOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import styled from "styled-components";

import { ProjectAssets } from "./components/ProjectAssets";
import { ProjectAssetUpload } from "./components/ProjectAssetUpload";
import { ProjectMedias } from "./components/ProjectMedias";
import { ProjectKB } from "./components/ProjectKB";

import { useAntTheme } from "hooks/useAntTheme";
import { AiStarIcon } from "components/icons";
import { ProjectSettingsPanel } from "./components/ProjectSettings";
import { ProjectSettingsStatusBadge } from "../components/ProjectSettingsStatus";
import { addDateTz } from "../utils";
import { useOne } from "@refinedev/core";
import { User } from "types";
import { ProjectStoryboards } from "./components/ProjectStoryboards";

import { ProgressHelp } from "./components/ProgressHelp";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { ChatTeardropText } from "@phosphor-icons/react";
import {
  StyledHorizontalPanelResizeHandle,
  StyledVerticalPanelResizeHandle,
} from "components/StyledPanelResizeHandle";
import { Sparkle } from "@phosphor-icons/react";
import {
  MediaContactLanguage,
  useProjectMedia,
} from "./components/useProjectMedia";
import { ProjectChatHistory } from "./components/ProjectChatHistory";

const MAX_GENERATION_REFETCH_TIMEOUT = 1800000; // 30 min

export type ProjectModal = "AssetUpload" | "DocumentUpload" | "MediaUpload";

export const ProjectDetail: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const params = useParams();
  const go = useGo();
  const invalidate = useInvalidate();
  const loadTime = useRef<number>(Date.now());
  const { mutateAsync: updateMutationAsync, isLoading: updateMutationLoading } =
    useUpdate<MediaProjectResponse>({});
  const { theme } = useAntTheme();
  const projectId = params.id;
  const { queryResult } = useShow<MediaProjectResponse>({
    resource: "media/projects",
    id: projectId,
    errorNotification: {
      type: "error",
      message: t("media.projects.detail.errorLoadingProject"),
      description: t("media.projects.detail.pleaseTryAgain"),
    },
    queryOptions: {
      enabled: !!projectId,
      refetchInterval: (data) => {
        const isProcessingDocument = data?.data.documents.some((document) =>
          ["Uploaded", "Stored", "Expected", "ExtractingContent"].includes(
            document.status
          )
        );

        const isProcessingStoryboard =
          data?.data.storyboards.some((storyboard) =>
            ["Generating", "Pending"].includes(storyboard.status)
          ) && data?.data.documents[0].status === "ContentExtracted";

        const isProcessingMedia = data?.data.media.some((media) => {
          const isProcessing = [
            "Pending",
            "Draft",
            "Requested",
            "Generating",
          ].includes(media.status);
          const storyboardReady = data?.data.storyboards.some(
            (sb) => sb.language === media.language && sb.status === "Approved"
          );

          return isProcessing && storyboardReady;
        });

        const getProcessingTime = () => {
          if (isProcessingDocument || isProcessingStoryboard) return 5000; // 5 sec
          if (isProcessingMedia) return 10000; // 10sec
          return false;
        };

        // Stop refetching after 30 minutes or when we don't have any
        // generating/pending storyboards/media.
        if (loadTime) {
          if (Date.now() - loadTime.current > MAX_GENERATION_REFETCH_TIMEOUT) {
            return false;
          }
        }
        return getProcessingTime();
      },
    },
  });

  const {
    data,
    isLoading,
    isError,
    refetch: refetchProjectDetail,
  } = queryResult;
  const project = data?.data;
  const { requestNewMedia, isCreatingMedia, mediaContactIds } = useProjectMedia(
    { project }
  );
  const storyboardLanguages = project?.storyboards
    ?.map((x) => x.language)
    .sort();
  const missingMultilanguageMedia = useMemo(() => {
    const result: MediaContactLanguage[] = [];
    if (!project || !mediaContactIds || !storyboardLanguages) return result;

    mediaContactIds?.forEach((contactId) => {
      const contactMedia = project.media.filter(
        (y) => y.contact_id === contactId
      );
      storyboardLanguages.forEach((language) => {
        if (!contactMedia.find((y) => y.language === language)) {
          result.push({ contactId, language });
        }
      });
    });
    return result;
  }, [project, storyboardLanguages, mediaContactIds]);

  const [isEditing, setIsEditing] = useState(false);
  const [openedModal, setOpenedModal] = useState<ProjectModal>();
  const [form] = Form.useForm();
  // const { dispatch: appDispatch } = useContext(AppContext);

  dayjs.extend(duration);
  dayjs.extend(utc);

  // Statuses
  const settingsApproved = project?.settings.status === "Approved";

  // Calculate time since creation
  const now = dayjs();
  const projectCreationDate = dayjs(addDateTz(project?.created));
  const projectDiffDurationTime = dayjs.duration(
    -now.diff(projectCreationDate)
  );

  // useEffect(() => {
  //   appDispatch({ type: "setSidebarCollapsed", payload: true });
  //   return () => {
  //     appDispatch({ type: "setSidebarCollapsed", payload: false });
  //   };
  // }, []);

  // const handleApprove = async () => {
  //   await approveSettings({
  //     url: `media/projects/${projectId}/settings/approve`,
  //     method: "post",
  //     values: {},
  //   });
  //   invalidate({
  //     resource: "media/projects",
  //     id: project?.id,
  //     invalidates: ["detail"],
  //   });
  // };

  // const handleEdit = () => {
  //   setIsEditing(true);
  //   // setProjectData(project);
  // };

  // www.geeksforgeeks.org/how-to-convert-3-digit-color-code-to-6-digit-color-code-using-javascript/
  const colorBgBase6 = theme.colorBgBase
    .split("")
    .map((item) => {
      if (item == "#") {
        return item;
      }
      return item + item;
    })
    .join("");

  const handleCancel = () => {
    setIsEditing(false);
  };

  const openModal = (modal: ProjectModal) => {
    setOpenedModal(modal);
  };
  const closeModal = () => setOpenedModal(undefined);

  const handleGenerateMultilanguageMedia = useCallback(async () => {
    await requestNewMedia(missingMultilanguageMedia);
    invalidate({
      resource: "media/projects",
      id: project?.id,
      invalidates: ["detail"],
    });
  }, [missingMultilanguageMedia]);

  const handleSave = async (values: MediaProjectResponse) => {
    if (!project?.id) return;
    await updateMutationAsync({
      id: project?.id,
      resource: "media/projects",
      values: { ...project, ...values },
    });
    setIsEditing(false);
  };

  useEffect(() => {
    if (project && form) {
      // https://stackoverflow.com/questions/75416804/ant-design-v5-2-0-date-locale-is-not-a-function
      form.setFieldsValue({
        ...project,
        deadline: project.deadline ? dayjs(project.deadline) : null,
      });
    }
  }, [project, form]);

  const handleEditCuePoints = (mediaId: string) => {
    // go({ to: `./media/${mediaId}/binder` });
    go({ to: `./media/${mediaId}/animation` });
  };
  const handleDeleteSuccess = () => {
    go({ to: `../` });
  };

  const headerButtons = isLoading ? (
    <></>
  ) : (
    <Space>
      {/* <Tooltip
          title={settingsApproved ? "Cannot edit settings after approval!" : ""}
        > */}
      {!settingsApproved && (
        <Button
          size="large"
          shape="round"
          disabled={settingsApproved}
          icon={<SettingOutlined />}
          onClick={() => go({ to: "./setup" })}
        >
          {t("media.projects.detail.setup")}
        </Button>
      )}
      {/* </Tooltip> */}
      {/* {!isEditing && (
          <Button size="large" shape="round" onClick={handleEdit}>
            Edit
          </Button>
        )}
        {isEditing && (
          <>
            <Button
              loading={updateMutationLoading}
              size="large"
              shape="round"
              type="primary"
              onClick={() => project && handleSave(project!)}
            >
              Save
            </Button>
          </> */}
      {/* )} */}
    </Space>
  );

  const { data: owner } = useOne<User>({
    resource: `/api/users`,
    id: project?.owner_id,
    queryOptions: {
      enabled: !!project?.owner_id,
    },
  });

  const ownerDetails = owner?.data
    ? `${owner.data.first_name?.charAt(0) ?? ""}${owner.data.last_name?.charAt(0) ?? ""}`.toLocaleUpperCase()
    : project?.owner_id;

  const editingMedia =
    project?.media.filter((media) => media.status !== "Published") || [];
  const publishedMedia =
    project?.media.filter((media) => media.status === "Published") || [];

  const areDocumentsReady = useMemo(() => {
    if (!project?.documents || project.documents.length === 0) return false;
    return project.documents.some(
      (document) => document.status === "ContentExtracted"
    );
  }, [project?.documents]);
  return (
    <Authenticated key="project_detail">
      <StyledWrapper>
        <Show
          goBack={null}
          // wrapperProps={{ className: "show-wrapper" }}
          contentProps={{
            style: {
              background: "transparent",
              borderTop: "1px solid",
              borderColor: theme.colorBorder,
              padding: 0,
              borderRadius: 0,
            },
          }}
          isLoading={isLoading}
          // breadcrumb={null}
          // breadcrumb={
          //   <div
          //     style={{
          //       paddingLeft: 24,
          //     }}
          //   >
          //     <Breadcrumb />
          //   </div>
          // }
          title={
            <Flex
              vertical
              style={{
                fontWeight: "normal",
                paddingLeft: 24,
                marginTop: 5,
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Space>
                <Avatar
                  style={{
                    backgroundColor: "orange",
                    verticalAlign: "middle",
                  }}
                  size="large"
                  gap={5}
                >
                  <Tooltip
                    arrow={false}
                    placement="bottom"
                    title={
                      owner?.data ? (
                        <>
                          {owner.data.first_name} {owner.data.last_name}
                          <Typography.Text type="secondary">
                            {" "}
                            • {project?.owner_id}
                          </Typography.Text>
                        </>
                      ) : null
                    }
                  >
                    {ownerDetails}
                  </Tooltip>
                </Avatar>
                {isEditing ? (
                  // custom editable ui prettier than default antd one
                  <Form
                    layout="vertical"
                    form={form}
                    onFinish={handleSave}
                    style={{ position: "relative", height: 40 }}
                  >
                    <Form.Item
                      name="title"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input
                        size="large"
                        style={{
                          height: 40,
                          fontSize: 26,
                          fontWeight: "bold",
                          width: "40vw",
                        }}
                      />
                    </Form.Item>
                    <Space
                      style={{ position: "absolute", right: 0, bottom: -26 }}
                    >
                      <Button
                        size="small"
                        htmlType="submit"
                        loading={updateMutationLoading}
                        icon={<CheckOutlined />}
                      />
                      <Button
                        size="small"
                        onClick={handleCancel}
                        icon={<CloseOutlined />}
                      />
                    </Space>
                  </Form>
                ) : (
                  <Typography.Text
                    data-testid="ProjectTitle"
                    style={{
                      fontSize: 26,
                      fontWeight: "bold",
                      display: "inline-block",
                      padding: "0px 12px",
                    }}
                    editable={{ tooltip: false, icon: <></> }}
                    onClick={() => setIsEditing(true)}
                  >
                    {project?.title}
                  </Typography.Text>
                )}
              </Space>

              {/* <Space size={"small"}> */}
              <Flex
                align="center"
                style={{ paddingLeft: 60, marginTop: -5 }}
                gap={20}
              >
                {project?.created && (
                  <Tooltip
                    title={
                      <DateField
                        value={addDateTz(project.created)}
                        format="LLL"
                        style={{ color: "#fff" }}
                      />
                    }
                  >
                    <Typography.Text type="secondary">
                      {t("media.projects.detail.created")}
                      {projectDiffDurationTime.humanize(true)}
                    </Typography.Text>
                  </Tooltip>
                )}

                {project?.settings.status && (
                  <ProjectSettingsStatusBadge
                    status={project?.settings.status}
                    label={
                      <Typography.Text type="secondary">
                        {t(
                          `media.components.MediaStatus.${project?.settings.status}`
                        )}
                      </Typography.Text>
                    }
                  />
                )}
              </Flex>
              {/* </Space> */}

              {/* <Descriptions.Item>
              {project?.assignee && <DateField value={project.assignee} />}
            </Descriptions.Item>
            <Descriptions.Item label="Target Delivery Date">
              {project?.deadline && <DateField value={project.deadline} />}
            </Descriptions.Item> */}
            </Flex>
          }
          headerButtons={headerButtons}
        >
          <ProgressHelp project={project} projectId={projectId} />
          <PanelGroup
            direction="horizontal"
            autoSaveId={"project_detail"}
            style={{ marginTop: -24 }}
          >
            <Panel
              defaultSize={65}
              minSize={40}
              style={{ paddingTop: 12, paddingRight: 12 }}
            >
              {project && (
                <Flex vertical gap={50}>
                  {/* <Descriptions layout="vertical">
            <Descriptions.Item label="Brief">
              <Typography.Paragraph
                editable={{ autoSize: { minRows: 4 } }}
              >
                {project?.description}
              </Typography.Paragraph>
            </Descriptions.Item>
          </Descriptions> */}
                  <ProjectStoryboards project={project} />
                  <Flex vertical gap={20}>
                    <Flex gap={20} align="center" justify="space-between">
                      <Space align="center" size={"middle"}>
                        <AiStarIcon
                          height={50}
                          width={50}
                          color="url(#color1)"
                          defs={
                            <linearGradient
                              id="color1"
                              x1="37.5"
                              y1="7.37501"
                              x2="37.5"
                              y2="63.625"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#333333" />
                              <stop offset="1" stopColor="#999999" />
                            </linearGradient>
                          }
                        />
                        <Flex vertical gap={0}>
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {t("media.projects.detail.aiReadyMedia")}
                          </Typography.Title>
                          {/* <Typography.Text
              type="secondary"
              style={{ fontWeight: "normal" }}
            >
              We convert your documents to videos according to
              your settings and your storyboards
            </Typography.Text> */}
                        </Flex>
                      </Space>

                      {/* <Badge
              showZero={true}
              count={project.media?.length}
              color="#999"
            /> */}
                    </Flex>
                    {editingMedia && editingMedia.length === 0 ? (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t(
                          "media.projects.detail.noExistingMediaGenerated"
                        )}
                      />
                    ) : (
                      <Flex vertical gap={30}>
                        <ProjectMedias
                          refetchProjectDetail={refetchProjectDetail}
                          medias={editingMedia}
                          project={project}
                          onEditCuePoints={handleEditCuePoints}
                        />
                      </Flex>
                    )}
                    <Space>
                      {missingMultilanguageMedia.length > 0 && (
                        <Popconfirm
                          onConfirm={handleGenerateMultilanguageMedia}
                          title={t(
                            "media.projects.detail.generateMissingMediaConfirm",
                            { count: missingMultilanguageMedia.length }
                          )}
                        >
                          <Button
                            loading={isCreatingMedia}
                            shape="round"
                            size="middle"
                            icon={
                              <span className="anticon">
                                <Sparkle />
                              </span>
                            }
                          >
                            {t("media.projects.detail.generateMissingMedia")}
                          </Button>
                        </Popconfirm>
                      )}
                    </Space>

                    {/* not sure if we need this button, checking with seb, returns 500 now */}
                    {/* <Popconfirm
            title="Are you sure?"
            onConfirm={handleGenerateMedias}
          >
            <Button
              loading={isLoadingGenerateMedias}
              size="middle"
              shape="round"
              style={{ marginTop: 30 }}
              icon={<PlaySquareOutlined />}
            >
              Generate Medias
            </Button>
          </Popconfirm> */}
                  </Flex>
                  <Flex vertical gap={20}>
                    <Flex gap={20} align="center" justify="space-between">
                      <Space align="center" size={"middle"}>
                        <AiStarIcon
                          height={50}
                          width={50}
                          color="url(#color2)"
                          defs={
                            <linearGradient
                              id="color2"
                              x1="37.5"
                              y1="7.37501"
                              x2="37.5"
                              y2="63.625"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#C13BF1" />
                              <stop offset="1" stopColor="#00FFD1" />
                            </linearGradient>
                          }
                        />
                        <Flex vertical gap={0}>
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {t("media.projects.detail.publishedAiReady")}
                          </Typography.Title>
                          {/* <Typography.Text
              type="secondary"
              style={{ fontWeight: "normal" }}
            >
              We convert your documents to videos according to
              your settings and your storyboards
            </Typography.Text> */}
                        </Flex>
                      </Space>
                      {/* <Badge
              showZero={true}
              count={project.media?.length}
              color="#999"
            /> */}
                      {project && (
                        <ProjectChatHistory projectId={project?.id} />
                      )}
                    </Flex>
                    {project &&
                    publishedMedia &&
                    publishedMedia.length === 0 ? (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t(
                          "media.projects.detail.noExistingMediaPublished"
                        )}
                      />
                    ) : (
                      <Flex vertical gap={30}>
                        <ProjectMedias
                          refetchProjectDetail={refetchProjectDetail}
                          medias={publishedMedia}
                          project={project}
                          onEditCuePoints={handleEditCuePoints}
                        />
                      </Flex>
                    )}
                  </Flex>

                  {/* we don't have a better specific access to check */}
                  <CanAccess resource="media_generation_steps" action="show">
                    <ProjectAssets projectId={project.id} />
                    <Space>
                      <Button
                        icon={<PaperClipOutlined />}
                        onClick={() => openModal("AssetUpload")}
                      >
                        {t("media.projects.detail.attachAnAsset")}
                      </Button>
                    </Space>
                    <Modal
                      title={t("media.projects.detail.attachAnAsset")}
                      open={openedModal === "AssetUpload"}
                      onCancel={() => closeModal()}
                      footer={null}
                      styles={{ body: { padding: 30 } }}
                    >
                      <ProjectAssetUpload
                        projectData={project}
                        onUploadSuccess={async () => {
                          const refresh = async () => {
                            await invalidate({
                              resource: `media/projects/${project.id}/assets?size=50`,
                              invalidates: ["list"],
                            });
                          };
                          refresh();
                          setTimeout(() => {
                            refresh(); // for s3 uploads refresh again
                          }, 5000);
                          closeModal();
                        }}
                      />
                    </Modal>
                  </CanAccess>
                </Flex>
              )}

              <Space style={{ marginTop: 80 }}>
                {project && (
                  <CanAccess resource="media_projects" action="delete">
                    <DeleteButton
                      size="large"
                      shape="round"
                      resource="media/projects"
                      recordItemId={project.id}
                      accessControl={{ enabled: false }}
                      onSuccess={handleDeleteSuccess}
                    >
                      {t("media.projects.detail.deleteProject")}
                    </DeleteButton>
                  </CanAccess>
                )}
              </Space>
            </Panel>
            <PanelResizeHandle>
              <StyledVerticalPanelResizeHandle />
            </PanelResizeHandle>
            <Panel minSize={30}>
              <PanelGroup
                direction="vertical"
                autoSaveId={"project_detail_side"}
              >
                <Panel maxSize={25} style={{ position: "relative" }}>
                  <SimpleBar
                    style={{
                      padding: 24,
                      // paddingRight: 24,
                      paddingBottom: 0,
                      height: "100%",
                    }}
                  >
                    {project && (
                      <ProjectKB
                        project={project}
                        projectId={projectId}
                        openModal={openModal}
                        openedModal={openedModal}
                        closeModal={closeModal}
                      />
                    )}
                  </SimpleBar>
                  <Flex
                    justify="space-between"
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      padding: 24,
                      background: `linear-gradient(to bottom, ${colorBgBase6}00,${colorBgBase6}ff)`,
                    }}
                  >
                    <Button
                      shape="round"
                      size="middle"
                      icon={<PaperClipOutlined />}
                      onClick={() => openModal("DocumentUpload")}
                    >
                      {t("media.projects.detail.uploadDocument")}
                    </Button>
                    <Button
                      size="middle"
                      shape="round"
                      // type="primary"
                      icon={
                        <span className="anticon">
                          <ChatTeardropText size={16} weight="fill" />
                        </span>
                      }
                      onClick={() => {
                        go({ to: `./chat` });
                      }}
                      disabled={!areDocumentsReady}
                    >
                      {t("media.projects.detail.askAi")}
                    </Button>
                  </Flex>
                </Panel>
                <PanelResizeHandle style={{ width: "100%" }}>
                  <StyledHorizontalPanelResizeHandle />
                </PanelResizeHandle>
                <Panel minSize={70}>
                  <SimpleBar
                    style={{
                      height: "100%",
                      padding: 24,
                      paddingRight: 24,
                      paddingBottom: 0,
                    }}
                  >
                    {project && <ProjectSettingsPanel project={project} />}
                  </SimpleBar>
                </Panel>
              </PanelGroup>
            </Panel>
          </PanelGroup>
        </Show>
        <svg width="0" height="0" style={{ height: 0 }}>
          <linearGradient
            id="purple-gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop stopColor="#C13BF1" offset="0%" />
            <stop stopColor="#4900E5" offset="100%" />
          </linearGradient>
        </svg>
      </StyledWrapper>
    </Authenticated>
  );
};

const StyledWrapper = styled.div<{
  theme: GlobalToken;
}>`
  margin: -24px;
  padding-top: 24px;
  .ant-page-header-content {
    padding-top: 5px;
    .ant-card-body {
      // padding: 0 !important;
    }
  }
  .ant-page-header-content .ant-spin-container > .ant-card > .ant-card-body {
    // padding-left: 0;
    padding-right: 0; // right panel until frame
  }

  .ant-breadcrumb {
    padding-left: 24px;
  }
  // header buttons
  .ant-page-header-heading-extra {
    padding-right: 24px;
    display: flex;
  }

  div[data-resize-handle-active="keyboard"],
  div[data-resize-handle-active="pointer"] {
    width: 3px;
    margin: 0;
    background: ${({ theme }) => theme.colorPrimary};
    > div {
      background: none;
    }
  }
  .ant-card-actions > li:not(:last-child) {
    border: 0;
  }

  .ant-card-hoverable .ant-card-actions li {
    opacity: 0;
  }
  .ant-card-hoverable:hover .ant-card-actions li {
    opacity: 1;
  }
`;
