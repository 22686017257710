import { useTranslate } from "@refinedev/core";
import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useApiUrl, useCustom } from "@refinedev/core";
import {
  Typography,
  Empty,
  Space,
  Collapse,
  Avatar,
  Badge,
  Table,
  Descriptions,
  Flex,
  Row,
  Col,
} from "antd";
import { components as apiComponents } from "../../api";
import { Show } from "@refinedev/antd";
import { useAntTheme } from "hooks/useAntTheme";
import {
  NewspaperIcon,
  ChartBarSquareIcon,
  DocumentChartBarIcon,
} from "components/icons";
import { StarFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { startCase } from "lodash";
import { TableProps } from "antd/es/table";
import { gold } from "@ant-design/colors";
import OrganizationTickerList from "../../components/OrganizationTickerList";
import TickerAutoComplete from "../../components/TickerAutoComplete";
import { autoFormatValue } from "helpers/utils";
import { useParams } from "react-router-dom";
import CheckAssetdata from "../media/components/CheckAssetsData";
const { Panel } = Collapse;

const AssetOverview = () => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const { id } = useParams();
  const [showAllNews, setShowAllNews] = useState(false);
  const [allNews, setAllNews] = useState<any[]>([]);
  const [ticker, setTicker] = useState<string | undefined>(id);
  const { theme } = useAntTheme();
  const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
  const today = dayjs().format("YYYY-MM-DD");
  const isLatestNews = (date: string) => date === today || date === yesterday;

  const { data, isLoading, isFetching } = useCustom<
    apiComponents["schemas"]["AssetOverview"]
  >({
    url: `${apiUrl}/api/assets/Equity/${ticker}/overview`,
    method: "get",
    queryOptions: {
      enabled: Boolean(ticker),
    },
  });
  const handleShowAsset = React.useCallback((value: string) => {
    setTicker(value);
  }, []);

  const asset = data?.data;
  const currency = asset?.asset_detail.currency ?? "USD";

  const descriptions = !asset
    ? []
    : [
        ...Object.entries(
          asset.earnings_calendar as Record<string, string | number>
        ).map(([key, value]) => ({
          key,
          label: startCase(key),
          children: (
            <Typography.Text>
              {key !== "revenue_estimate"
                ? value
                : autoFormatValue(key, value, theme, currency)}
            </Typography.Text>
          ),
        })),
      ];

  const revenueColumns: TableProps<
    apiComponents["schemas"]["CompanyRevenue"]
  >["columns"] = [
    {
      title: t("pages.assets.detail.year"),
      dataIndex: "year",
      key: "year",
      render: (value: number) => value,
    },
    {
      title: startCase("net_income"),
      dataIndex: "net_income",
      key: "net_income",
      render: (value: number) =>
        autoFormatValue("net_income", value, theme, currency),
    },
    {
      title: t("pages.assets.detail.revenue"),
      dataIndex: "revenue",
      key: "revenue",
      render: (value: number) =>
        autoFormatValue("revenue", value, theme, currency),
    },
  ];

  const revenueData = asset?.revenue_history;
  return (
    <Row gutter={30}>
      <Col
        span={5}
        style={{ maxHeight: "95vh", overflowY: "auto", marginLeft: -16 }}
      >
        <OrganizationTickerList onTickerSelect={handleShowAsset} />
      </Col>
      <Col span={19}>
        <Flex vertical gap={20}>
          <TickerAutoComplete onChange={handleShowAsset} />
          <CheckAssetdata />
          {asset && (
            <Show
              isLoading={isLoading || isFetching}
              title={""}
              headerButtons={() => <></>}
            >
              <Flex vertical gap={"large"}>
                <Space align="center" size={"middle"}>
                  <Avatar src={asset.asset_detail.banner} size={"large"} />
                  <Typography.Title level={1} style={{ margin: 0 }}>
                    {asset.asset_detail.friendly_name}
                  </Typography.Title>
                  <Typography.Text type="secondary">
                    {asset.asset_detail.name}
                  </Typography.Text>
                </Space>
                <Title
                  icon={NewspaperIcon}
                  title={t("pages.assets.detail.news")}
                />
                {Object.values(asset.news ?? {}).filter(Boolean).length > 0 ? (
                  <Collapse accordion size="middle">
                    {Object.entries(asset.news ?? {})
                      .filter(([, item]) => Boolean(item))
                      .map(([date, item], index) => (
                        <Panel
                          header={
                            <Space>
                              <Typography.Text type="secondary">
                                {dayjs(date).format("dddd LL")}
                              </Typography.Text>
                              <Badge
                                color={
                                  isLatestNews(date) ? "red" : "transparent"
                                }
                                status="processing"
                                text={item.news_summary?.title}
                              />
                            </Space>
                          }
                          key={date}
                        >
                          <>
                            <Typography.Text type="secondary">
                              {t("pages.assets.detail.source")}{" "}
                              <a href={item.original_chosen_news?.url}>
                                {item.original_chosen_news?.source}
                              </a>
                            </Typography.Text>
                            <p>{item.news_summary?.article}</p>

                            <Collapse accordion size="small">
                              {item.all_ranked_news?.map((newsItem, index) => (
                                <Panel
                                  header={
                                    <Space>
                                      <Typography.Text type="secondary">
                                        {index + 1} •
                                      </Typography.Text>
                                      <Typography.Text type="secondary">
                                        {dayjs(newsItem.date).format("ddd LL")}
                                      </Typography.Text>
                                      <Typography.Text
                                        strong={
                                          newsItem.id ===
                                          item.original_chosen_news?.id
                                        }
                                      >
                                        {newsItem.headline}
                                      </Typography.Text>
                                      {newsItem.id ===
                                        item.original_chosen_news?.id && (
                                        <StarFilled
                                          style={{ color: gold[4] }}
                                        />
                                      )}
                                    </Space>
                                  }
                                  key={newsItem.id}
                                >
                                  <p>
                                    <Flex justify="space-between">
                                      <Typography.Text type="secondary">
                                        {t("pages.assets.detail.source")}{" "}
                                        <a
                                          href={newsItem.url}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {newsItem.source}
                                        </a>
                                      </Typography.Text>
                                      <Typography.Text
                                        copyable
                                        type="secondary"
                                      >
                                        {newsItem.id}
                                      </Typography.Text>
                                    </Flex>
                                  </p>
                                  {newsItem.news_content && (
                                    <p>{newsItem.news_content}</p>
                                  )}
                                </Panel>
                              ))}
                            </Collapse>
                          </>
                        </Panel>
                      ))}
                  </Collapse>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t("pages.assets.detail.noNewsAvailable")}
                  />
                )}
                <Title
                  icon={NewspaperIcon}
                  title={t("pages.assets.detail.pressRelease")}
                />
                {asset.press_release &&
                Object.entries(asset.press_release).filter(
                  ([, release]) => release
                ).length > 0 ? (
                  <>
                    <Collapse accordion size="middle">
                      {Object.entries(asset.press_release).map(
                        ([date, pressRelease]) =>
                          pressRelease && (
                            <Panel
                              header={
                                <Space>
                                  <Typography.Text type="secondary">
                                    {dayjs(date).format("dddd LL")}
                                  </Typography.Text>
                                  <Badge
                                    color={
                                      isLatestNews(date) ? "red" : "transparent"
                                    }
                                    status="processing"
                                    text={pressRelease.headline}
                                  />
                                </Space>
                              }
                              key={date}
                            >
                              <p>{pressRelease.summary}</p>
                            </Panel>
                          )
                      )}
                    </Collapse>
                  </>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t("pages.assets.detail.noPressReleases")}
                  />
                )}
                <Title
                  icon={DocumentChartBarIcon}
                  title={t("pages.assets.detail.earningsCalendar")}
                />
                <Descriptions items={descriptions} size="middle" />
                <Title
                  icon={ChartBarSquareIcon}
                  title={t("pages.assets.detail.revenueHistory")}
                />
                <div style={{ height: 300, width: "100%" }}>
                  <ResponsiveBar
                    margin={{ top: 60, right: 0, bottom: 30, left: 100 }}
                    groupMode="grouped"
                    data={
                      revenueData as {
                        ticker: string;
                        year: number;
                        revenue?: number;
                        net_income?: number;
                      }[]
                    }
                    theme={{
                      text: { fill: "#999" },
                      grid: { line: { stroke: "#99999933" } },
                    }}
                    keys={["revenue", "net_income"]}
                    indexBy="year"
                    colors={["#40d4cc", "#2f9eaa"]}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: "middle",
                      legendOffset: -40,
                      format: (value: number) =>
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency,
                          notation: "compact",
                        }).format(value),
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    legendLabel={(value) => startCase(String(value.id))}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "top",
                        direction: "row",
                        // justify: true,
                        translateX: 0,
                        translateY: -15,
                        itemsSpacing: 10,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 5,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    enableLabel={false}
                    borderColor={{
                      from: "color",
                      modifiers: [["brighter", 1.6]],
                    }}
                    borderWidth={1}
                    innerPadding={5}
                    enableGridX={false}
                    tooltip={({ id, value, color }) => (
                      <div
                        style={{
                          background: "rgba(0, 0, 0, 0.8)",
                          color: "white",
                          padding: "9px 12px",
                          border: `1px solid ${color}`,
                        }}
                      >
                        <strong>{startCase(String(id))}</strong>
                        <br />
                        <strong>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency,
                          }).format(value)}
                        </strong>
                      </div>
                    )}
                  />
                </div>
                <Table
                  dataSource={revenueData}
                  columns={revenueColumns}
                  size="small"
                />
              </Flex>
            </Show>
          )}
        </Flex>
      </Col>
    </Row>
  );
};

const Title = ({ title, icon }: { title: string; icon: React.ElementType }) => {
  const Icon = icon;
  return (
    <Flex align="center" gap={5} style={{ marginTop: 30 }}>
      <Icon height={20} />
      <Typography.Text
        type="secondary"
        style={{ textTransform: "uppercase", letterSpacing: 1, fontSize: 12 }}
      >
        {title}
      </Typography.Text>
    </Flex>
  );
};

export { AssetOverview };
export default AssetOverview;

