import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createProjectsSlice } from "pages/media/projects/projectsSlice";
import { createAppSlice } from "components/appSlice";
import { createOnboardingSlice } from "pages/onboarding/onboardingSlice";
import { CombinedSlice } from "./types";

// Use this to reset ALL state,
// and use individual reset<SliceName> functions to reset
// only that slice.
export const sliceResetFns = new Set<() => void>();

export const resetAllSlices = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

export const useBoundStore = create<CombinedSlice>()(
  devtools(
    persist(
      immer((...a) => ({
        ...createProjectsSlice(...a),
        ...createAppSlice(...a),
        ...createOnboardingSlice(...a),
      })),
      { name: "state" }
    )
  )
);

