import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Authenticated, useApiUrl, useCustom } from "@refinedev/core";
import { useGo, useList } from "@refinedev/core";
import { useEffect, useState } from "react";
import {
  Button, DatePicker,
  Flex, Space,
  TimeRangePickerProps,
  Typography
} from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

// import { RangeValue } from "rc-picker/lib/interface";
import { List } from "antd";
import VirtualList from "rc-virtual-list";
import relativeTime from "dayjs/plugin/relativeTime";

import {
  ArrowRightOutlined,
  CloudSyncOutlined
} from "@ant-design/icons";
import { autoFormatValue, sortByDate } from "helpers/utils";
import { AssetOverview, CompanyEarningsCalendar } from "types";
import { useAntTheme } from "hooks/useAntTheme";
import type { BadgeProps, CalendarProps } from "antd";
import { Badge, Calendar } from "antd";
import { useOrganization } from "hooks/useOrganization";

dayjs.extend(relativeTime);
// No more rangevalue from lib
type RangeValue<T> = any;
const { RangePicker } = DatePicker;

const rangePresets: TimeRangePickerProps["presets"] = [
  {
    label: t("pages.assets.calendars.upcoming14Days"),
    value: [dayjs().add(14, "d"), dayjs()],
  },
  {
    label: t("pages.assets.calendars.last30Days"),
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: t("pages.assets.calendars.last3Months"),
    value: [dayjs().add(-3, "M"), dayjs()],
  },
  {
    label: t("pages.assets.calendars.last6Months"),
    value: [dayjs().add(-6, "M"), dayjs()],
  },
];

const getListData = (value: Dayjs) => {
  let listData;
  switch (value.date()) {
    case 8:
      listData = [
        { type: "warning", content: "This is warning event." },
        { type: "success", content: "This is usual event." },
      ];
      break;
    case 10:
      listData = [
        { type: "warning", content: "This is warning event." },
        { type: "success", content: "This is usual event." },
        { type: "error", content: "This is error event." },
      ];
      break;
    case 15:
      listData = [
        { type: "warning", content: "This is warning event" },
        { type: "success", content: "This is very long usual event......" },
        { type: "error", content: "This is error event 1." },
        { type: "error", content: "This is error event 2." },
        { type: "error", content: "This is error event 3." },
        { type: "error", content: "This is error event 4." },
      ];
      break;
    default:
  }
  return listData || [];
};

const getMonthData = (value: Dayjs) => {
  if (value.month() === 8) {
    return 1394;
  }
};

const AssetsEarningCalendar: React.FC = () => {
  const t = useTranslate();
  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>{t("pages.assets.calendars.backlogNumber")}</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge
              status={item.type as BadgeProps["status"]}
              text={item.content}
            />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (
    date: Dayjs,
    info
  ) => {
    if (info.type === "date") return dateCellRender(date);
    if (info.type === "month") return monthCellRender(date);
    return info.originNode;
  };

  return <Calendar cellRender={cellRender} />;
};

export const AssetListItem = ({
  calendar,
  index,
  needsCheck,
}: {
  calendar: CompanyEarningsCalendar;
  index: number;
  needsCheck: boolean;
}) => {
  const t = useTranslate();
  const { theme } = useAntTheme();
  const { getAsset } = useOrganization({});
  const go = useGo();
  const apiUrl = useApiUrl();
  const gotoAsset = (id: string) => {
    go({ to: { resource: "assets", action: "show", id } });
  };
  const [check, setCheck] = useState(needsCheck === true);

  useEffect(() => {
    setCheck(needsCheck);
  }, [needsCheck]);

  const { data, isLoading, isFetching, isSuccess } = useCustom<AssetOverview>({
    url: `${apiUrl}/api/assets/Equity/${calendar.ticker}/overview`,
    method: "get",
    queryOptions: {
      enabled: check,
    },
  });
  const asset = getAsset(calendar.ticker);
  const checked = isSuccess;
  const currentYear = new Date().getFullYear();
  const latestRevenueYear = data?.data?.revenue_history?.[0]?.year;
  const hasLatestData =
    check &&
    isSuccess &&
    data &&
    Math.abs(currentYear - (latestRevenueYear ?? 0)) <= 1;

  return (
    <List.Item
      //   style={{ cursor: "pointer" }}
      key={index}
      actions={[
        <Button
          loading={isFetching}
          icon={<CloudSyncOutlined />}
          onClick={() => setCheck(true)}
          type="text"
        />,
        <Button
          icon={<ArrowRightOutlined />}
          onClick={() => gotoAsset(calendar.ticker)}
          type="text"
        />,
      ]}
    >
      <List.Item.Meta
        title={
          <Typography.Text type={hasLatestData ? "success" : "warning"}>
            {asset?.name && <>{asset?.name} • </>}
            {calendar.ticker} {calendar.year}
            {t("pages.assets.calendars.q")}
            {calendar.quarter}
          </Typography.Text>
        }
        description={
          <>
            {dayjs(calendar.date).format("LLLL")} •{" "}
            {dayjs(calendar.date).fromNow()}
          </>
        }
      />
      <Flex vertical>
        <Space>
          <Typography.Text>
            {t("pages.assets.calendars.revenueEstimate")}
          </Typography.Text>
          {autoFormatValue("", calendar.revenue_estimate, theme)} •
          <Typography.Text>
            {t("pages.assets.calendars.epsEstimate")}
            {calendar.eps_estimate}
          </Typography.Text>
        </Space>
        {checked && (
          <Typography.Text>
            {t("pages.assets.calendars.latestRevenue")}
            {latestRevenueYear}
          </Typography.Text>
        )}
      </Flex>
    </List.Item>
  );
};
export const AssetsEarningCalendarList = () => {
  const t = useTranslate();
  const [checkAll, setCheckAll] = useState(false);
  const [dateRange, setDateRange] = useState<RangeValue<Dayjs>>([
    // const [dateRange, setDateRange] = useState<any>([
    dayjs().add(-1, "M"),
    dayjs().add(7, "d"),
  ]);
  const { data, isFetching, error } = useList<CompanyEarningsCalendar>({
    resource: `api/assets/earnings_calendar?from_date=${dateRange?.[0]?.format("YYYY-MM-DD")}&to_date=${dateRange?.[1]?.format("YYYY-MM-DD")}`,
    queryOptions: {
      enabled: dateRange !== null,
    },
  });

  const handleDateRangeChange = (values: RangeValue<Dayjs> | null) => {
    setDateRange(values);
  };

  const sortedData = sortByDate<CompanyEarningsCalendar, "date">(
    data?.data as CompanyEarningsCalendar[],
    "date",
    "DESC"
  ).map((x, idx) => ({
    ...x,
    id: idx, // fake unique id to avoid key warnings in List
  }));
  // .filter((x) =>
  //   !onlyMissing ? true : !(x.eps_estimate && x.revenue_estimate)
  // );

  return (
    <Flex vertical gap={10} style={{ width: "100%" }}>
      <Space align="center">
        <RangePicker
          size="middle"
          defaultValue={dateRange}
          format="YYYY/MM/DD"
          presets={rangePresets}
          onChange={handleDateRangeChange}
        />
        {/* <RangePicker
          size="middle"
          showTime
          defaultValue={dateRange}
          format="YYYY/MM/DD HH:mm:ss"
          presets={rangePresets}
          onChange={handleDateRangeChange}
        /> */}
        {/* <Checkbox
          value={onlyMissing}
          onChange={(v) => setOnlyMissing(v.target.checked)}
        >
          Show without values
        </Checkbox> */}
        <Button onClick={() => setCheckAll(true)}>
          {t("pages.assets.calendars.checkAllValues")}
        </Button>
      </Space>
      {/* <AssetsEarningCalendar /> */}
      <List size="small" loading={isFetching}>
        <VirtualList data={sortedData} height={600} itemKey={"id"}>
          {(item: CompanyEarningsCalendar, index) => (
            <AssetListItem
              calendar={item}
              index={index}
              needsCheck={checkAll}
            />
          )}
        </VirtualList>
      </List>
    </Flex>
  );
};

export const AssetsEarningCalendarsPage = () => {
  return (
    <Authenticated key="asset_earning_calendars">
      <Flex>
        <AssetsEarningCalendarList />
      </Flex>
    </Authenticated>
  );
};

