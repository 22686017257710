import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useAntTheme } from "hooks/useAntTheme";

type ButtonSize = "small" | "middle" | "large";

interface DownloadButtonProps {
  url?: string;
  size?: ButtonSize;
  width?: number | string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  url,
  size = "small",
}) => {
  const t = useTranslate();
  const { theme } = useAntTheme();

  return (
    <Button
      disabled={!url}
      key="download"
      type="link"
      size={size}
      icon={<DownloadOutlined style={{ color: theme.colorText }} />}
      href={url}
      download
    >
      {t("projects.components.DownloadButton.download")}
    </Button>
  );
};

export default DownloadButton;

