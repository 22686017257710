// Use this to reset ALL state,
// and use individual reset<SliceName> functions to reset
// only that slice.

export const sliceResetFns = new Set<() => void>();

export const resetAllSlices = () => {
  if (!sliceResetFns.size) return;
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};
