import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  Authenticated,
  CanAccess,
  useCustomMutation,
  useShow,
} from "@refinedev/core";
import { useParams } from "react-router-dom";
import { MediaStatus } from "../components/MediaStatusComponent";
import { Button, Card, Flex, Skeleton, Typography } from "antd";
import { DateField, Show } from "@refinedev/antd";
import { useReducer, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { InteractiveHighlighter } from "react-interactive-highlighter";

import { Organization } from "types";
import { MediaDocument, Message } from "../types";
import { useAntTheme } from "hooks/useAntTheme";
import { BuildOutlined, EditOutlined } from "@ant-design/icons";
import { Chat } from "../components/chat/Chat";
import { addDateTz } from "../utils";
import PdfView from "components/PdfView";

const DocumentSummaries = ({ document }: { document: any | undefined }) => {
  const t = useTranslate();
  const { theme } = useAntTheme();
  const [activeTabKey1, setActiveTabKey1] = useState<string>("texts");

  const onTab1Change = (key: string) => {
    setActiveTabKey1(key);
  };

  const tabList = [
    {
      key: "texts",
      tab: "Texts",
    },
    {
      key: "tables",
      tab: "Tables",
    },
    {
      key: "custom",
      tab: (
        <Typography.Text>
          <EditOutlined />
          {t("media.documents.detail.custom")}
        </Typography.Text>
      ),
    },
  ];

  const reducer = (
    state: {
      text: string;
      startIndex: number;
      numChars: number;
      group: string;
    }[],
    action: any
  ) => {
    switch (action.type) {
      case "update":
        const exists = state.findIndex(
          ({ startIndex, numChars, group }) =>
            (group === action.payload.group &&
              startIndex >= action.payload.startIndex &&
              startIndex <
                action.payload.startIndex + action.payload.numChars) ||
            (group === action.payload.group &&
              action.payload.startIndex >= startIndex &&
              action.payload.startIndex < startIndex + numChars)
        );
        console.debug(
          action.payload.startIndex,
          action.payload.startIndex + action.payload.numChars,
          { exists }
        );
        if (exists > -1) {
          const copy = [...state];
          copy.splice(exists, 1);
          return copy;
        } else {
          return [...state, action.payload];
        }
      default:
        throw new Error();
    }
  };

  const [summaryHighlights, dispatch] = useReducer(reducer, []);
  const selectionHandler = (
    group: string,
    text: string,
    startIndex: number,
    numChars: number
  ) => {
    // console.debug({
    //   group,
    //   text,
    //   startIndex,
    //   numChars,
    // });
    dispatch({
      type: "update",
      payload: {
        startIndex,
        numChars,
        text,
        group,
      },
    });
  };
  const contentList: Record<string, React.ReactNode> = {
    texts: (
      <>
        {document?.summaries?.text_summaries?.map(
          (summary: string, index: number) => {
            const group = `text-${index}`;
            return (
              <p>
                <Typography.Text copyable>
                  <InteractiveHighlighter
                    text={summary}
                    highlights={summaryHighlights.filter(
                      (x) => x.group === group
                    )}
                    customClass="highlighted"
                    selectionHandler={(...params) =>
                      selectionHandler(group, ...params)
                    }
                  />
                </Typography.Text>
              </p>
            );
          }
        )}
      </>
    ),
    tables: (
      <>
        {document?.summaries?.table_summaries?.map(
          (summary: string, index: number) => {
            const group = `table-${index}`;
            return (
              <p>
                <Typography.Text copyable>
                  <InteractiveHighlighter
                    text={summary}
                    highlights={summaryHighlights.filter(
                      (x) => x.group === group
                    )}
                    customClass="highlighted"
                    selectionHandler={(...params) =>
                      selectionHandler(group, ...params)
                    }
                  />
                </Typography.Text>
              </p>
            );
          }
        )}
      </>
    ),
    custom: (
      <p>
        <Typography.Text editable={{ autoSize: { minRows: 10 } }} copyable>
          {summaryHighlights.map((highlight: any) => (
            <>
              {highlight.text}
              <br />
            </>
          ))}
        </Typography.Text>
      </p>
    ),
  };

  return (
    <Card
      tabList={tabList}
      activeTabKey={activeTabKey1}
      onTabChange={onTab1Change}
      title={
        <Typography.Text style={{ color: theme.colorPrimary, margin: 0 }}>
          {t("media.documents.detail.synthesis")}
        </Typography.Text>
      }
      // extra={<AutoAwesome style={{ color: theme.colorPrimary }} />}
      // headStyle={{ borderColor: theme.colorPrimary, margin: 0 }}
      style={{ marginBottom: 10, borderColor: theme.colorPrimary }}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
    >
      {!document?.summaries?.text_summaries && <Skeleton active />}
      {contentList[activeTabKey1]}
    </Card>
  );
};
export const MediaDocumentDetail = ({
  organization,
}: {
  organization?: Organization;
}) => {
  const t = useTranslate();
  const params = useParams();
  const id = params?.id;
  const { theme } = useAntTheme();
  const [prompt, setPrompt] = useState("");
  const [promptIsLoading, setPromptIsLoading] = useState(false);
  const messages = useRef<Array<Message>>([]);

  const { queryResult } = useShow<MediaDocument>({
    resource: `media/documents/${organization?.id}`,
    id,
    queryOptions: {
      enabled: !!organization,
    },
  });
  const { isLoading } = queryResult;
  const { data: document } = queryResult.data || {};

  const { mutate: mutatePrompt } = useCustomMutation<{
    id: string;
    promptId: string;
    completion: string;
  }>();

  const { mutate: mutateReindex, isLoading: reindexIsLoading } =
    useCustomMutation();

  const handlePromptChange = (prompt: string) => {
    const promptId = new Date().toISOString();
    messages.current.push({ id: promptId, prompt });
    setPromptIsLoading(true);
    mutatePrompt(
      {
        url: `media/documents/${organization?.id}/${id}/query`,
        method: "post",
        values: {
          prompt,
          id: promptId,
        },
      },
      {
        onError: (error, variables, context) => {
          // An error occurred!
          setPromptIsLoading(false);
        },
        onSuccess: (data, variables, context) => {
          messages.current.push(data.data);
          setPromptIsLoading(false);
        },
      }
    );
  };

  const handleReindexClick = () => {
    if (document?.id && organization) {
      mutateReindex({
        url: `media/documents/${organization.id}/${document.id}/reindexing`,
        method: "post",
        values: {},
      });
    }
  };
  const headerButtons = [
    <CanAccess resource="media_documents" action="document_index">
      <Button
        key="reindex"
        loading={reindexIsLoading}
        onClick={handleReindexClick}
        icon={<BuildOutlined />}
      >
        {t("media.documents.detail.reindex")}
      </Button>
    </CanAccess>,
  ];
  return (
    <Authenticated key="documentDetail">
      <div style={{ position: "relative" }}>
        <Show
          title={document?.name}
          isLoading={isLoading}
          headerButtons={headerButtons}
        >
          <Flex style={{ marginBottom: 30 }}>
            {document?.status && <MediaStatus status={document?.status} />}
            <Typography.Text>
              {t("media.documents.detail.createdAt")}
              <DateField value={addDateTz(document?.created)} />
            </Typography.Text>
          </Flex>
          {/* <Row>
        <Col span={24}>
          <Typography.Text>
            ID : <Typography.Text code>{document?.id}</Typography.Text>
          </Typography.Text>
        </Col>
      </Row> */}

          <Flex vertical>
            <PanelGroup autoSaveId="example" direction="horizontal">
              <Panel defaultSize={40} minSize={20}>
                {document?.url && <PdfView url={document?.url} />}
              </Panel>
              <PanelResizeHandle
                style={{
                  width: 4,
                  background: `${theme.colorPrimary}30`,
                  borderRadius: 10,
                  marginLeft: 10,
                }}
              />
              <Panel defaultSize={60} minSize={20} style={{ paddingLeft: 10 }}>
                {document?.id && organization?.id && (
                  <Chat
                    documentIds={[document?.id]}
                    organizationId={organization?.id}
                  />
                )}
                {/* <div
          style={{
            overflowY: "auto",
            height: "calc(100vh - 300px)",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <DocumentSummaries document={document as Document} />
          <PromptHistory
            messages={messages.current}
            setPrompt={setPrompt}
          />
        </div> */}
              </Panel>
            </PanelGroup>
          </Flex>
        </Show>
        {/* <PromptForm
          disabled={isLoadingCompletion}
          onChange={handlePromptChange}
          value={prompt}
          style={{
            position: "fixed",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            // right: 0,
            maxWidth: 800,
            width: "calc(50vw)",
            // width: "calc(100% - 250px)", // remove sidebar width TODO improve
          }}
        /> */}
      </div>
    </Authenticated>
  );
};

