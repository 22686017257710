import { HttpError } from "@refinedev/core";
import { refreshAccessToken, TOKEN_KEY } from "authProvider";
import axios, { AxiosResponse } from "axios";

const MAX_REQUESTS_COUNT = 10;
const INTERVAL_MS = 100;
let PENDING_REQUESTS = 0;

export const API_URL = process.env.REACT_APP_API_BASE_URL || "";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const onRequestSuccess = (config) => {
  const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY) || "{}");
  // config.timeout = 10000;
  if (tokens?.access_token) {
    const { access_token } = tokens;
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + access_token,
    };
  }
  return config;
};

instance.interceptors.request.use(onRequestSuccess);

// Response interceptor for API calls
// Intercepts failed requests and replays them after refreshing the access token
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (originalRequest._retry) return;
    originalRequest._retry = true;
    if (error.response.status === 401) {
      try {
        const accessToken = await refreshAccessToken();
        if (!accessToken) {
          return Promise.reject(error.response);
        }
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        originalRequest.headers["Authorization"] = "Bearer " + accessToken;
        return await instance(originalRequest);
      } catch (error) {
        return Promise.reject((error as HttpError).response);
      }
    } else {
      return Promise.reject(error.response);
    }
  }
);

/**
 * Axios Request Interceptor
 */
instance.interceptors.request.use(
  function (config) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          PENDING_REQUESTS++;
          clearInterval(interval);
          resolve(config);
        }
      }, INTERVAL_MS);
    });
  },
  () => {
    return Promise.reject("Request rejected");
  }
);

/**
 * Axios Response Interceptor
 */
instance.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(adaptAxiosError(error));
  }
);

export default instance;

/**
 * This extends the Axios error object with HttpError properties
 * so simple-rest can correctly handle the error
 * @param response Axios response object
 * @returns
 */
function adaptAxiosError(
  response: AxiosResponse & { statusCode: number; message: string }
) {
  response.statusCode = response.status;
  response.message = response.data?.detail;
  return response;
}
