import React, { useState, useCallback, memo } from "react";
import { Flex } from "antd";
import { PresenterProfileResponse } from "../types";
import { CustomFormItemProps } from "types";
import { PresenterProfilePreviewView } from "./PresenterProfilePreviewView";

export const PresenterProfilePicker: React.FC<
  CustomFormItemProps<string> & { items: PresenterProfileResponse[] }
> = memo(({ items, value, onChange, onSuccess }) => {
  const [id, setId] = useState<string | undefined>(value);
  // useEffect(() => {
  //   onChange && onChange(id);
  // }, [id, onChange]);
  const handleChange = useCallback(
    (value: string | undefined) => {
      const newValue = id === value ? undefined : value;
      setId(newValue);
      onChange && onChange(newValue);
      onSuccess && onSuccess(newValue);
    },
    [id, onChange]
  );

  return (
    <Flex gap={16} wrap="wrap">
      {items.map((item) => (
        <PresenterProfilePreviewView
          key={item.id}
          presenter={item}
          checked={item.id === id}
          onClick={handleChange}
        />
      ))}
    </Flex>
  );
});
