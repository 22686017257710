import { useEffect, useState } from "react";
import styled from "styled-components";

const EllipsisContainer = styled.span`
  display: inline-block;
  width: 20px;
  text-align: left;
`;

export const EllipsisAnimated = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        switch (prevDots) {
          case "":
            return ".";
          case ".":
            return "..";
          case "..":
            return "...";
          default:
            return "";
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <EllipsisContainer>{dots}</EllipsisContainer>;
};
