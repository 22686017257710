import { Popover, Flex } from "antd";
import { ContactResponse, PresenterProfileResponse } from "pages/media/types";
import { ReactNode } from "react";
import { PresenterProfilePreview } from "./PresenterProfilePreview";

export const ContactsPopover = ({
  children,
  contacts,
}: {
  children: ReactNode;
  contacts: ContactResponse[] | undefined;
}) => {
  return (
    <Popover
      mouseEnterDelay={0.5}
      placement="bottomLeft"
      content={
        <Flex align="center" gap={20}>
          {contacts?.map((contact) => {
            return (
              <Flex
                vertical
                align="center"
                justify="center"
                gap={2}
                key={contact.id}
              >
                {contact && (
                  <PresenterProfilePreview
                    organizationId={contact.organization_id}
                    presenterId={contact.presenter_id}
                    contact={contact}
                  />
                )}
              </Flex>
            );
          })}
        </Flex>
      }
    >
      {children}
    </Popover>
  );
};
