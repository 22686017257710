import { useTranslate } from "@refinedev/core";
import { Flex, Typography, Button, Popconfirm, Space } from "antd";
import { ProjectProgress } from "./ProjectProgress";
import {
  LoadingOutlined,
  CheckOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { MediaProjectResponse } from "pages/media/types";
import { useCustomMutation, useGo, useInvalidate } from "@refinedev/core";
import { ProgressBarStatusSeparator } from "components/icons";
import { ReactNode, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useAntTheme } from "hooks/useAntTheme";
import { CheckFat, WarningDiamond } from "@phosphor-icons/react";
import { CascadingText } from "components/CascadingText";
import useOnboardingAnswers from "pages/onboarding/hooks/useOnboardingAnswers";

const ESTIMATED_DURATION_MEDIA = 10; //minutes
const ESTIMATED_DURATION_OTHER = 3; //minutes

export const ProgressHelp = ({
  project,
  projectId,
}: {
  project: MediaProjectResponse | undefined;
  projectId: string | undefined;
}) => {
  const t = useTranslate();
  const { mutateAsync: approveSettings } = useCustomMutation({});
  const invalidate = useInvalidate();
  const go = useGo();
  const { theme } = useAntTheme();

  // Statuses
  const settingsDraft = project?.settings.status === "Draft";
  const settingsApproved = project?.settings.status === "Approved";

  const documentStored = project?.documents?.[0]?.status === "Stored";
  const documentExpected = project?.documents?.[0]?.status === "Expected";
  const documentReady = project?.documents?.some(
    (document) => document.status === "ContentExtracted"
  );
  const documentInProgress =
    project?.documents?.[0]?.status === "ExtractingContent" && !documentReady;

  const storyboardPending = project?.storyboards?.[0]?.status === "Pending";
  const storyboardGenerating =
    project?.storyboards?.[0]?.status === "Generating";
  const storyboardReviewing = project?.storyboards?.[0]?.status === "Reviewing";
  const storyboardApproved = project?.storyboards?.[0]?.status === "Approved";

  const mediaInDraft = project?.media?.[0]?.status === "Draft";
  const mediaInPending = project?.media?.[0]?.status === "Pending";
  const mediaInProgress = project?.media?.[0]?.status === "Generating";
  const mediaReady = project?.media?.[0]?.status === "Ready";
  const mediaPublished = project?.media?.[0]?.status === "Published";
  const mediaPreview = project?.media?.[0]?.status === "Preview";
  const mediaError = project?.media?.[0]?.status === "Error";
  const mediaCancelled = project?.media?.[0]?.status === "Cancelled";

  const creationTime = documentInProgress
    ? (project?.created ?? "")
    : mediaInProgress
      ? (project?.media[0].created ?? "")
      : "";

  const processDuration: number = mediaInProgress
    ? ESTIMATED_DURATION_MEDIA
    : ESTIMATED_DURATION_OTHER;

  const [waitingForApprove, setWaitingForApprove] = useState(false);
  const hideProgressBar =
    (storyboardPending && documentReady) ||
    (storyboardApproved && mediaInDraft) ||
    mediaError ||
    mediaCancelled ||
    documentExpected ||
    documentStored ||
    mediaReady ||
    mediaPublished ||
    mediaPreview;

  useEffect(() => {
    setWaitingForApprove(
      storyboardReviewing || mediaReady || (!settingsApproved && documentReady)
    );
  }, [project]);

  const { shouldGoToOnboarding } = useOnboardingAnswers();
  const handleApprove = async () => {
    await approveSettings({
      url: `media/projects/${projectId}/settings/approve`,
      method: "post",
      values: {},
    });
    invalidate({
      resource: "media/projects",
      id: project?.id,
      invalidates: ["detail"],
    });
    if (shouldGoToOnboarding) {
      return go({
        to: "/onboarding",
      });
    }
  };

  const gotoStoryboard = (id: string) => {
    go({
      to: `./storyboard/${id}`,
    });
  };

  const gotoCuePoints = (id: string) => {
    go({
      to: `./media/${id}/animation`,
    });
  };

  if (!project || project.documents.length === 0) {
    return null;
  }

  return (
    <Flex
      vertical={false}
      style={{
        // backgroundColor: mediaError ? "#ff4d4f" : "transparent",
        borderBottom: "1px solid",
        borderColor: theme.colorBorder,
        // margin: -24,
        marginBottom: 24,
        marginTop: -24,
        marginLeft: -24,
        paddingRight: 20,
        paddingLeft: 10,
      }}
      justify="space-between"
      align="center"
    >
      <Flex vertical={false} style={{ padding: 10, height: 50 }} gap={20}>
        {documentInProgress && (
          <StatusMessage
            message={t("projects.components.ProgressHelp.analyzingDocuments")}
            loading={true}
          />
        )}
        {(documentExpected || documentStored) && (
          <StatusMessage
            message={t("projects.components.ProgressHelp.preparingDocuments")}
            loading={true}
          />
        )}
        {documentReady && settingsApproved && (
          <StatusMessage
            message={t("projects.components.ProgressHelp.documentsAnalyzed")}
            loading={false}
          />
        )}

        {documentReady && !settingsApproved && (
          <Space>
            <ProgressHelpStatus type="secondary">
              {t("projects.components.ProgressHelp.pleaseApproveTheSettings")}
              <StyledArrowIcon />
            </ProgressHelpStatus>
          </Space>
        )}

        {storyboardPending && settingsApproved && documentReady && (
          <StatusMessage
            message={t(
              "projects.components.ProgressHelp.preparingStoryboardFor"
            )}
            loading={true}
          />
        )}
        {settingsApproved && storyboardGenerating && (
          <StatusMessage
            message={t("projects.components.ProgressHelp.generatingStoryboard")}
            loading={true}
          />
        )}
        {(storyboardReviewing || storyboardApproved) && (
          <StatusMessage
            message={t("projects.components.ProgressHelp.storyboardGenerated")}
            loading={false}
          />
        )}

        {documentReady && storyboardReviewing && (
          <Space>
            <ProgressHelpStatus type="secondary">
              {t("projects.components.ProgressHelp.pleaseApproveTheStoryboard")}
              <StyledArrowIcon />
            </ProgressHelpStatus>
          </Space>
        )}
        {(mediaInDraft || mediaInPending) && storyboardApproved && (
          <StatusMessage
            message={
              <CascadingText>
                {t("projects.components.ProgressHelp.preparingMediaFor")}
              </CascadingText>
            }
            loading={true}
          />
        )}
        {storyboardApproved && mediaInProgress && (
          <StatusMessage
            message={
              <CascadingText>
                {t("projects.components.ProgressHelp.generatingMedia")}
              </CascadingText>
            }
            loading={true}
          />
        )}
        {(mediaReady || mediaPublished || mediaPreview) && (
          <StatusMessage
            message={t("projects.components.ProgressHelp.mediaGenerated")}
            loading={false}
          />
        )}
        {mediaError && (
          <StatusMessage
            message={
              <Typography.Text type="danger">
                {t("projects.components.ProgressHelp.anErrorOccurred")}
              </Typography.Text>
            }
            error={true}
          />
        )}
      </Flex>
      {/* show current processing : first doc or first storyboard */}
      {waitingForApprove && (
        <Space>
          {storyboardReviewing && project?.storyboards?.[0]?.asset_id && (
            <Button
              shape="round"
              size="middle"
              type="primary"
              // style={{ fontWeight: "bold", marginRight: 20 }}
              onClick={() =>
                project?.storyboards?.[0]?.asset_id &&
                gotoStoryboard(project?.storyboards?.[0]?.asset_id)
              }
            >
              {t("projects.components.ProgressHelp.reviewStoryboard")}
            </Button>
          )}
          {mediaReady && (
            <Button
              shape="round"
              size="middle"
              type="primary"
              // style={{ fontWeight: "bold", marginRight: 20 }}
              onClick={() =>
                project?.media?.[0]?.id &&
                gotoCuePoints(project?.media?.[0]?.id)
              }
            >
              {t("projects.components.ProgressHelp.reviewDocumentAnimation")}
            </Button>
          )}
          {!settingsApproved && documentReady && (
            <Popconfirm
              title={t("projects.components.ProgressHelp.areYouSure")}
              onConfirm={handleApprove}
            >
              <Button
                // style={{ fontWeight: "bold", marginRight: 20 }}
                size="middle"
                shape="round"
                type="primary"
                icon={<CheckOutlined />}
              >
                {t("projects.components.ProgressHelp.approveSettings")}
              </Button>
            </Popconfirm>
          )}
        </Space>
      )}
      {!hideProgressBar && !waitingForApprove && (
        <Flex style={{ width: "20%" }}>
          <ProjectProgress
            creationTime={creationTime}
            processDuration={processDuration}
          />
        </Flex>
      )}
    </Flex>
  );
};

const StatusMessage = ({
  message,
  loading,
  error,
}: {
  message: ReactNode;
  loading?: boolean;
  error?: boolean;
  color?: string;
}) => {
  const { theme } = useAntTheme();

  return loading ? (
    <Space>
      <ProgressHelpStatus strong>
        <Space>
          <LoadingOutlined />
          {message}
        </Space>
      </ProgressHelpStatus>
    </Space>
  ) : error ? (
    <Space>
      <ProgressHelpStatus>
        <Space>
          <span className="anticon">
            <WarningDiamond
              color={theme.colorError}
              size={20}
              weight="duotone"
            />
          </span>
          {message}
        </Space>
      </ProgressHelpStatus>
    </Space>
  ) : (
    <Space>
      <ProgressHelpStatus>
        <Space>
          <span className="anticon">
            <CheckFat size={20} color={theme.colorSuccess} weight="duotone" />
          </span>
          {message}
        </Space>
      </ProgressHelpStatus>
      <Flex justify="center" align="center">
        <ProgressBarStatusSeparator fill={theme.colorBorder} />
      </Flex>
    </Space>
  );
};

const ArrowIconAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px); /* Adjust distance as needed */
  }
  100% {
    transform: translateX(0);
  }
`;

const StyledArrowIcon = styled(ArrowRightOutlined)`
  margin-left: 10px;
  display: inline-block;
  animation: ${ArrowIconAnimation} 1s infinite;
`;

const ProgressHelpStatus = styled(Typography.Text)`
  // color: #000;
`;
