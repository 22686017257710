import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Card, Tooltip, Badge, Typography, Flex, Col } from "antd";
import dayjs from "dayjs";
import { useApiUrl, useCustom } from "@refinedev/core";
import { AssetOverview, CompanyEarningsCalendar } from "types";

interface AssetDetailsCardProps {
  ticker: string;
  showErrorsAndWarningsOnly: boolean;
  earningCalendar?: CompanyEarningsCalendar;
}

const AssetDetailsCard: React.FC<AssetDetailsCardProps> = ({
  ticker,
  showErrorsAndWarningsOnly,
  earningCalendar,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const {
    data: assetData,
    isLoading: assetLoading,
    isError: assetError,
  } = useCustom<AssetOverview>({
    url: `${apiUrl}/api/assets/Equity/${ticker}/overview`,
    method: "get",
    queryOptions: {
      enabled: Boolean(ticker),
    },
  });

  if (assetError) {
    return (
      <Typography.Text>
        {t("media.components.AssetDetailsCard.errorLoadingAsset")}
        {ticker}
      </Typography.Text>
    );
  }

  const asset = assetData?.data;
  const today = dayjs();
  const latestNews = Object.entries(asset?.news ?? {}).filter(([, item]) =>
    Boolean(item)
  )?.[0]?.[1];

  const calculateTickerColor = (lastNewsDate?: string) => {
    if (!lastNewsDate) {
      return "error";
    }
    const businessDaysDiff = (today as any).businessDiff(dayjs(lastNewsDate));
    if (businessDaysDiff >= 10) {
      return "error";
    } else if (businessDaysDiff >= 5) {
      return "warning";
    } else {
      return "success";
    }
  };

  const latestRevenueYear = asset?.revenue_history?.[0]?.year;

  const calculateEarningStatus = () => {
    if (!latestRevenueYear) return "error";
    if (!earningCalendar?.date) {
      return "processing";
    }
    //
    if (Math.abs(today.year() - latestRevenueYear) > 1) {
      return "error";
    }
    const earningCalendarDate = dayjs(earningCalendar?.date);
    const diffInDays = today.diff(earningCalendarDate, "day");

    // todo : check quarter
    if (diffInDays > 7 && latestRevenueYear !== earningCalendar?.year)
      return "error";

    if (diffInDays > 3 && latestRevenueYear !== earningCalendar?.year)
      return "warning"; // earnings published more than 3d ago

    return "success";
  };

  const color = calculateTickerColor(latestNews?.original_chosen_news?.date);
  const earningStatus = calculateEarningStatus();

  if (
    showErrorsAndWarningsOnly &&
    color === "success" &&
    earningStatus === "success"
  ) {
    return null;
  }

  return (
    <Col sm={12} md={6}>
      <Card
        size="small"
        title={
          <>
            {ticker} • {asset?.asset_detail?.friendly_name}
          </>
        }
      >
        <Flex vertical>
          <div>
            <Badge
              status={assetLoading ? "processing" : color}
              text={
                <Typography.Text
                  style={{ fontSize: 9, letterSpacing: 1, marginRight: 10 }}
                >
                  {t("media.components.AssetDetailsCard.news")}
                </Typography.Text>
              }
            />
            {!assetLoading && latestNews && (
              <Tooltip
                overlayStyle={{ minWidth: 300 }}
                title={
                  <>
                    <p>{latestNews?.news_summary?.article || ""}</p>
                    {t("media.components.AssetDetailsCard.source")}{" "}
                    <a
                      href={latestNews?.original_chosen_news?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {latestNews?.original_chosen_news?.source}
                    </a>
                  </>
                }
              >
                <Typography.Text>
                  {latestNews?.news_summary?.title}{" "}
                  <Typography.Text type="secondary">
                    • {latestNews?.original_chosen_news?.date}
                  </Typography.Text>
                </Typography.Text>
              </Tooltip>
            )}
          </div>
          <div>
            <Badge
              status={assetLoading ? "processing" : earningStatus}
              text={
                <Typography.Text
                  style={{ fontSize: 9, letterSpacing: 1, marginRight: 10 }}
                >
                  {t("media.components.AssetDetailsCard.earnings")}
                </Typography.Text>
              }
            />
            <Tooltip
              title={
                <>
                  <p>
                    {t("media.components.AssetDetailsCard.latestRevenueYear")}
                    {latestRevenueYear}
                  </p>
                  <p>
                    {t("media.components.AssetDetailsCard.earningCalendar")}
                    {earningCalendar?.date}
                    {t("media.components.AssetDetailsCard.q")}
                    {earningCalendar?.quarter} {earningCalendar?.year}
                  </p>
                </>
              }
            >
              <Typography.Text>
                {latestRevenueYear}{" "}
                <Typography.Text type="secondary">
                  • {earningCalendar?.date}
                </Typography.Text>
              </Typography.Text>
            </Tooltip>
          </div>
        </Flex>
      </Card>
    </Col>
  );
};

export default AssetDetailsCard;

