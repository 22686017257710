import { useTranslate } from "@refinedev/core";
// MediaDetail.tsx
import React, { useEffect, useState } from "react";
import {
  Authenticated,
  CanAccess,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
} from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Button, Flex, Popconfirm, Result, Typography } from "antd";
import { Show } from "@refinedev/antd";
import { BuildOutlined, FileProtectOutlined } from "@ant-design/icons";
import { useShow } from "@refinedev/core";
import { MediaAssets } from "./MediaAssets";
import { MediaStatus } from "../components/MediaStatusComponent";
import MediaControlList from "../components/MediaControlList";
import { t } from "i18next";

const POLLING_INTERVAL = 30000;

export const MediaDetail: React.FC = () => {
  const t = useTranslate();
  const params = useParams();
  const invalidate = useInvalidate();
  const id = params?.id;
  const apiUrl = useApiUrl();
  const [controlDrawerVisible, setControlDrawerVisible] = useState(false);

  const refresh = () => {
    invalidate({
      resource: "media/media",
      invalidates: ["detail"],
      id,
    });
  };

  const { mutateAsync, isLoading: isLoadingRegenerate } = useCustomMutation();

  const regenerate = async () => {
    await mutateAsync({
      url: `${apiUrl}/media/media/${id}/regenerate`,
      method: "post",
      values: {},
    });
    // restart polling of status
    refresh();
  };

  const { queryResult } = useShow({
    resource: "media/media",
    id,
  });
  const { data: media, isLoading } = queryResult;

  // poll media every 30s if still not ready
  useEffect(() => {
    let refreshInterval: NodeJS.Timeout;
    if (media?.data?.status !== "Ready") {
      refreshInterval = setInterval(refresh, POLLING_INTERVAL);
    }
    return () => {
      clearInterval(refreshInterval);
    };
  }, [media?.data]);

  return (
    <Authenticated key={"mediaDetail"}>
      <Show
        isLoading={isLoading}
        title={media?.data?.id}
        headerProps={{
          subTitle: <MediaStatus
                status={t(
                  `media.components.MediaStatus.${media?.data?.status}`
                )}
              />,
          extra: (
            <>
              <Button>{t("media.media.detail.media")}</Button>
              <Button onClick={refresh}>
                {t("media.media.detail.refresh")}
              </Button>
              <Popconfirm
                title={t("media.media.detail.regenerateTheMedia")}
                description={`Are you sure?`}
                onConfirm={regenerate}
                okText="Yes"
                cancelText="No"
              >
                <Button loading={isLoadingRegenerate} icon={<BuildOutlined />}>
                  {t("media.media.detail.regenerate")}
                </Button>
              </Popconfirm>
              <Button onClick={() => setControlDrawerVisible(true)}>
                <FileProtectOutlined />
                {t("media.media.detail.viewControls")}
              </Button>
            </>
          ),
        }}
      >
        <Flex vertical style={{ marginBottom: 20 }}>
          <Typography.Text>
            {t("media.media.detail.createdAt")}
            {media?.data?.created}
          </Typography.Text>
          <Typography.Text>
            {t("media.media.detail.parameters")}
            {media?.data?.parameter_set_id}
          </Typography.Text>
        </Flex>
        {id && media?.data && <MediaAssets mediaId={id} />}
        {id && (
          <MediaControlList
            mediaId={id}
            visible={controlDrawerVisible}
            onClose={() => setControlDrawerVisible(false)}
          />
        )}
      </Show>
    </Authenticated>
  );
};

export default MediaDetail;
