import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  ArrowUpOutlined,
  PauseCircleOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Form, Input, MenuProps } from "antd";
import { CSSProperties, useEffect } from "react";

const items: MenuProps["items"] = [
  {
    key: "1",
    type: "group",
    label: t("components.PromptHistory.PromptForm.promptTemplates"),
    children: [
      {
        key: "2",
        label: t("components.PromptHistory.PromptForm.equityResearch"),
        children: [
          {
            key: "2-1",
            label: t("components.PromptHistory.PromptForm.whatIsThe"),
          },
          {
            key: "2-2",
            label: t("components.PromptHistory.PromptForm.whatAreYou"),
          },
        ],
      },
      {
        key: "3",
        label: t("components.PromptHistory.PromptForm.bankResearch"),
        children: [
          {
            key: "3-1",
            label: t("components.PromptHistory.PromptForm.whatIsThe"),
          },
          {
            key: "3-2",
            label: t("components.PromptHistory.PromptForm.whatAreYou"),
          },
        ],
      },
    ],
  },
];

//  cf https://github.com/bcExpt1123/chatgpt-example/blob/main/frontend/src/components/ChatForm/index.js
export const PromptForm = ({
  onChange,
  style = {},
  disabled = false,
  value,
}: {
  onChange: (prompt: string) => void;
  style?: CSSProperties;
  disabled: boolean;
  value: string;
}) => {
  const t = useTranslate();
  const [form] = Form.useForm();
  const prompt = Form.useWatch("prompt", { form, preserve: true });
  const template = Form.useWatch("template", { form, preserve: true });

  const handleFinish = (values: any) => {
    if (disabled) return;
    onChange(values.prompt);
    form.setFieldValue("prompt", "");
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handlePickTemplate: MenuProps["onClick"] = (e) => {
    console.log("click", e);
  };

  useEffect(() => {
    if (value) form.setFieldValue("prompt", value);
  }, [value, form]);

  const menuProps = {
    items,
    onClick: handlePickTemplate,
  };

  return (
    <div style={style}>
      <Form
        // layout="inline"
        form={form}
        onFinish={handleFinish}
        style={{ position: "relative" }}
      >
        {/* <Form.Item
          style={{ position: "absolute", left: 9, top: 6, zIndex: 1 }}
          name="template"
        >
          <Dropdown menu={menuProps}>
            <Button
              type="primary"
              size="large"
              style={{ background: "none", border: 0 }}
              icon={<PlusSquareOutlined />}
            ></Button>
          </Dropdown>
        </Form.Item> */}
        <Form.Item name="prompt" initialValue={value}>
          <Input
            placeholder={t("components.PromptHistory.PromptForm.askYourQuery")}
            style={{
              height: 50,
              borderRadius: 15,
              boxShadow: "0px 0px 20px #00000020",
              paddingRight: 60,
              paddingLeft: 20,
            }}
          />
        </Form.Item>
        <Form.Item
          name="sendMsg"
          style={{ position: "absolute", right: 14, top: 8, zIndex: 1 }}
        >
          <Button
            onClick={handleSubmit}
            type="primary"
            size="small"
            icon={disabled ? <PauseCircleOutlined /> : <ArrowUpOutlined />}
            style={{
              height: 30,
              width: 30,
              borderRadius: 6,
              boxShadow: "none",
              border: 0,
              ...(disabled ? { background: "transparent" } : {}),
            }}
            disabled={!prompt && !disabled}
          ></Button>
        </Form.Item>
      </Form>
    </div>
  );
};

