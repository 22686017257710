import { t } from "i18next";
import React, { useState } from "react";
import { Tag } from "antd";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useOne } from "@refinedev/core";
import { Document } from "../types";

type DocumentStatusProps = {
  status: string;
  label?: React.ReactNode;
};

const getStatusLabel = (status: string) => {
  switch (status) {
    case "Waiting":
      return {
        label: t("media.components.DocumentStatus.waiting"),
        icon: <ClockCircleOutlined />,
        color: "default",
      };
    case "Uploaded":
      return {
        label: t("media.components.DocumentStatus.uploaded"),
        icon: <CheckOutlined />,
        color: "success",
      };
    case "ExtractingContent":
      return {
        label: t("media.components.DocumentStatus.processing"),
        icon: <SyncOutlined spin />,
        color: "processing",
      };
    case "ContentExtracted":
      return {
        label: t("media.components.DocumentStatus.ready"),
        icon: <CheckOutlined />,
        color: "success",
      };
    case "Error":
      return {
        label: t("media.components.DocumentStatus.error"),
        icon: <CloseCircleOutlined />,
        color: "error",
      };
    default:
      return { label: status, icon: <MinusCircleOutlined />, color: "default" };
  }
};

const DocumentStatus: React.FC<DocumentStatusProps> = ({ status, label }) => {
  const statusLabel = getStatusLabel(status);

  return (
    <Tag
      color={statusLabel.color}
      icon={statusLabel.icon}
      style={{
        border: 0,
        borderRadius: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {label || statusLabel.label}
    </Tag>
  );
};

export default DocumentStatus;

const POLLING_INTERVAL = 20000; // 20s
export const useDocumentStatus = (document?: Document) => {
  const finalState = "ContentExtracted";

  // start fetching on init ?
  const [enabled, setEnabled] = useState(
    document && document?.status !== finalState
  );

  const result = useOne<Document>({
    resource: `media/documents/${document?.organization}`,
    id: document?.id,
    queryOptions: {
      enabled, // to not request at hook init if document is ready
      refetchInterval(data) {
        return data?.data.status !== finalState ? POLLING_INTERVAL : false;
      },
    },
  });

  // to avoid refetch + enabled calling twice the api
  const startRefetch = () => {
    if (enabled) {
      result.refetch();
    } else {
      setEnabled(true);
    }
  };

  return { status: result.data?.data.status, refetch: startRefetch };
};

