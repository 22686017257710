import { Authenticated, useOne, useShow } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Flex, Spin, Tabs } from "antd";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Organization } from "types";
import { useAntTheme } from "hooks/useAntTheme";
import { Chat } from "../components/chat/Chat";
import { MediaDocument, MediaProjectResponse } from "../types";
import { DocumentViewer } from "components/DocumentViewer";
import { LoadingOutlined } from "@ant-design/icons";
import { PdfViewer } from "components/PdfViewer";

export const AskDocs = ({ organization }: { organization?: Organization }) => {
  const params = useParams();
  const projectId = params.id;
  const { theme } = useAntTheme();

  const { queryResult } = useShow<MediaProjectResponse>({
    resource: "media/projects",
    id: projectId,
  });
  const { data, isLoading, isError } = queryResult;
  const project = data?.data;
  const documents = data?.data.documents;

  const documentsTab = documents?.map((doc: any) => {
    return {
      key: doc.id,
      label: doc.name,
      children: project?.organization_id && (
        <DocumentWrapper
          documentId={doc.id}
          organizationId={project?.organization_id}
        />
      ),
    };
  });

  const docsIds = documents?.map((doc: any) => doc.id);

  const onChange = (key: string) => {
    // console.log("changed " + key);
  };

  return (
    <Authenticated key="projectDocumentsChat">
      {/* <Show title={project?.title} isLoading={isLoading}> */}
      <Flex vertical>
        <PanelGroup autoSaveId="project_documents_chat" direction="horizontal">
          <Panel defaultSize={60} minSize={20}>
            <Tabs
              defaultActiveKey="1"
              items={documentsTab}
              onChange={onChange}
            />
          </Panel>

          <PanelResizeHandle
            style={{
              width: 2,
              background: `${theme.colorBorder}50`,
              borderRadius: 10,
              marginLeft: 10,
              cursor: "col-resize",
            }}
          />
          <Panel defaultSize={40} minSize={20} style={{ paddingLeft: 10 }}>
            {project?.organization_id && docsIds && (
              <Chat
                documentIds={docsIds}
                organizationId={project?.organization_id}
              />
            )}
          </Panel>
        </PanelGroup>
      </Flex>
      {/* </Show> */}
    </Authenticated>
  );
};

const DocumentWrapper = ({
  documentId,
  organizationId,
}: {
  documentId: string;
  organizationId: string;
}) => {
  const { data } = useOne<MediaDocument>({
    resource: `media/documents/${organizationId}`,
    id: documentId,
    queryOptions: {
      enabled: !!organizationId,
    },
  });
  const document = data?.data;
  const isPdf = document?.name.toLocaleLowerCase().endsWith(".pdf");
  return (
    <div
      style={{
        overflowY: "auto",
        height: "calc(100vh - 120px)",
      }}
    >
      {!document?.url ? (
        <Flex
          justify="center"
          align="center"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Spin indicator={<LoadingOutlined />} />
        </Flex>
      ) : isPdf ? (
        <PdfViewer url={document.url} />
      ) : (
        <DocumentViewer url={document.url} />
      )}
    </div>
  );
};
