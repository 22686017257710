import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Create, SaveButton, useForm } from "@refinedev/antd";
import { Authenticated, useGo } from "@refinedev/core";
import { Form, Input, DatePicker, Typography } from "antd";
import { useOrganization } from "hooks/useOrganization";
import dayjs from "dayjs";
import { ProjectCreateWizard } from "./components/ProjectCreateWizard";

const { TextArea } = Input;
const { Text } = Typography;

type FormData = {
  document_type: string;
  sub_category: string;
  source_url: string;
  filename: string;
  description: string;
  deadline: dayjs.Dayjs | null;
};

export const ProjectCreate = ({ wizard = true }: { wizard?: boolean }) => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const go = useGo();

  const { formProps, saveButtonProps, form, onFinish } = useForm<FormData>({
    action: "create",
    resource: "media/projects",
    onMutationSuccess: async (data: any) => {
      setTimeout(() => {
        // not working without delay :(
        // go({ to: "/media/projects" });
        go({ to: { resource: "media_projects", action: "list" } });
      }, 500);
    },
  });

  const handleFinish = (values: any) => {
    onFinish({
      ...values,
      organization_id: organization?.id,
      type: "new_video",
    });
  };

  const minimumDate = dayjs().add(7, "day");

  if (wizard) {
    return <ProjectCreateWizard />;
  }
  return (
    <Authenticated key="projectCreate">
      <Create
        saveButtonProps={saveButtonProps}
        title={t("media.projects.create.createProject")}
        footerButtons={({ saveButtonProps }) => (
          <>
            <SaveButton {...saveButtonProps} type="primary">
              {t("media.projects.create.submit")}
            </SaveButton>
          </>
        )}
      >
        <div style={{ marginBottom: 40 }}>
          <Text>{t("media.projects.create.organization")}</Text>
          <Text strong>{organization?.name}</Text>
        </div>
        <Form
          form={form}
          {...formProps}
          layout="vertical"
          onFinish={handleFinish}
        >
          <Form.Item
            label={t("media.projects.create.projectTitle")}
            name="title"
            rules={[
              {
                required: true,
                message: t("media.projects.create.pleaseInputProjectTitle"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("media.projects.create.description")}
            name="description"
            rules={[
              {
                required: true,
                message: t(
                  "media.projects.create.pleaseInputProjectDescription"
                ),
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item
            label={t("media.projects.create.deadline")}
            name="deadline"
          >
            <DatePicker
              defaultValue={minimumDate}
              disabledDate={(current) =>
                current && current < minimumDate.startOf("day")
              }
            />
          </Form.Item>
        </Form>
      </Create>
    </Authenticated>
  );
};


