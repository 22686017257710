import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useState, useRef, useEffect } from "react";
import { Flex, Popover, Typography } from "antd";
import { PresenterProfileResponse } from "../types";
import { FaceProfilePreview } from "../components/FaceProfilePreview";
import { useAntTheme } from "hooks/useAntTheme";
import { Stop, Waveform } from "@phosphor-icons/react";
import { useHover } from "usehooks-ts";
import { UUID } from "components/UUID";
import { MiniLabel } from "components/MiniLabel";
import { timestampPresenter } from "./ContactWithPresenter/helpers";

const TooltipPart = ({ id, text }: { id: string; text: string }) => {
  return (
    <div>
      <MiniLabel type="secondary">{text}</MiniLabel>{" "}
      <UUID id={id} tooltip={false} />
    </div>
  );
};

export const PresenterProfilePreviewView = ({
  presenter,
  checked,
  onClick,
  style,
}: {
  // const { organization } = useOrganization({});
  // const { data, isLoading } = useOne<FaceProfileResponse>({

  //   resource: `media/${organization?.id}/face_profiles`,
  //   id: presenter.face_profile?.id,
  //   queryOptions: {
  //     enabled: !!organization?.id && !!presenter.face_profile?.id,
  //   },
  // });
  presenter: Partial<PresenterProfileResponse>;
  checked?: boolean;
  onClick?: (value: string | undefined) => void;
  style?: React.CSSProperties;
}) => {
  const t = useTranslate();
  const { colorPrimary } = useAntTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const ref = useRef(null);
  const isHovered = useHover(ref);

  useEffect(() => {
    setIsPlaying(false);
  }, [presenter]);

  const handlePlayClick = () => {
    if (audioRef.current && audioRef.current.src) {
      setIsPlaying((prev) => !prev);
      if (!isPlaying) {
        audioRef.current.currentTime = 0; // restart
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    } else {
      console.error("Audio source is not set or invalid.");
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("ended", () => setIsPlaying(false));
    }
    return () => {
      audioRef.current?.remove();
    };
  }, []);

  const assetPath =
    presenter.face_profile?.thumbnail_asset_path ??
    presenter.face_profile?.extracted_asset_path ??
    presenter.face_profile?.custom_asset_path;

  return (
    <Popover
      autoAdjustOverflow
      mouseEnterDelay={2}
      content={
        presenter?.id ? (
          <Flex vertical>
            <TooltipPart
              id={presenter.id}
              text={t("media.components.PresenterProfilePreviewView.presenter")}
            />
            {presenter.voice_profile && (
              <TooltipPart
                id={presenter.voice_profile?.id}
                text={t("media.components.PresenterProfilePreviewView.voice")}
              />
            )}
            {presenter.face_profile && (
              <TooltipPart
                id={presenter.face_profile?.id}
                text={t("media.components.PresenterProfilePreviewView.face")}
              />
            )}
          </Flex>
        ) : null
      }
      trigger="hover"
    >
      <div style={{ ...style, position: "relative", display: "inline-block" }}>
        <FaceProfilePreview
          assetPath={assetPath}
          style={{
            cursor: "pointer",
            padding: 2,
            borderColor: checked ? colorPrimary : undefined,
            boxSizing: "border-box",
          }}
          onClick={() => onClick && onClick(presenter.id)}
          ghost={!presenter.face_profile}
        />
        {presenter.voice_profile?.preview_asset_path ? (
          <>
            <div
              ref={ref}
              onClick={handlePlayClick}
              style={{
                position: "absolute",
                bottom: -8,
                right: -8,
                cursor: "pointer",
                zIndex: 10,
              }}
            >
              {isHovered || isPlaying ? (
                isPlaying ? (
                  <Stop size={20} weight="fill" />
                ) : (
                  <Waveform size={20} />
                )
              ) : (
                <Waveform size={20} style={{ opacity: 0.6 }} />
              )}
            </div>
            <audio
              ref={audioRef}
              src={presenter.voice_profile.preview_asset_path}
            />
          </>
        ) : (
          <Typography.Text
            type="danger"
            style={{ display: "none" }}
          ></Typography.Text>
        )}
        <Typography.Text style={{ display: "none" }}>
          {presenter?.name
            ? presenter?.created
              ? timestampPresenter(presenter.name, presenter.created)
              : presenter.name
            : "N/A"}
        </Typography.Text>
      </div>
    </Popover>
  );
};

