import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React from "react";
import {
  CreateButton,
  List,
  MarkdownField,
  DeleteButton,
} from "@refinedev/antd";
import { useList, useGo, Authenticated, CanAccess } from "@refinedev/core";
import { Table, Typography, Space, Alert, Flex } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { useOrganization } from "hooks/useOrganization";
import styled from "styled-components";
import { BrandKit, BrandKitAsset } from "../types";
import { Swatches } from "@phosphor-icons/react";
import { StyledMarkdownFieldWrapper } from "components/StyledMarkdownFieldWrapper";

const { Text } = Typography;

export const colorKeys = [
  "primary_color",
  "secondary_color",
  "title_color",
  "title_stroke_color",
  "subtitle_color",
  "subtitle_stroke_color",
  "highlight_color",
];

const ColorRect = styled.div<{
  color: string;
}>`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  border: 1px solid #d9d9d9;
`;

export const BrandKitList: React.FC = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const organizationId = organization?.id;
  const go = useGo();

  const { data, isLoading } = useList<BrandKit>({
    resource: `media/${organizationId}/brand_kits`,
  });

  const columns: ColumnsType<BrandKit> = [
    {
      title: t("media.brandkit.index.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("media.brandkit.index.primaryColor"),
      dataIndex: ["palette", "primary_color"],
      key: "primary_color",
      render: (color: string) => (
        <Space align="center">
          <ColorRect color={color} />
          <Text>{color}</Text>
        </Space>
      ),
    },
    {
      title: t("media.brandkit.index.secondaryColor"),
      dataIndex: ["palette", "secondary_color"],
      key: "secondary_color",
      render: (color: string | undefined) =>
        color ? (
          <Space align="center">
            <ColorRect color={color} />
            <Text>{color}</Text>
          </Space>
        ) : null,
    },
    {
      title: t("media.brandkit.index.assets"),
      dataIndex: "assets",
      key: "assets",
      render: (assets: BrandKitAsset[]) => assets.length,
    },
    {
      title: t("media.brandkit.index.actions"),
      key: "actions",
      render: (_: any, record: BrandKit) => (
        <CanAccess resource="media_brandkits" action="delete">
          <Space>
            <DeleteButton
              size="small"
              shape="round"
              resource={`media/${organizationId}/brand_kits`}
              recordItemId={record.id}
              accessControl={{ enabled: false }}
            >
              {t("media.brandkit.index.delete")}
            </DeleteButton>
          </Space>
        </CanAccess>
      ),
    },
  ];

  return (
    <Authenticated key="brandkits">
      <List
        title={t("media.brandkit.index.brandKits")}
        headerButtons={({ createButtonProps }) => (
          <>
            {createButtonProps && (
              <CreateButton
                {...createButtonProps}
                icon={<PlusOutlined />}
                shape="round"
              >
                {t("media.brandkit.index.createABrandkit")}
              </CreateButton>
            )}
          </>
        )}
      >
        <Flex vertical gap={30}>
          <Alert
            icon={<Swatches size={60} />}
            showIcon
            message={t("media.brandkit.index.aBrandKit")}
            description={
              <StyledMarkdownFieldWrapper>
                <MarkdownField
                  value={t("media.brandkit.index.brandKitDescription")}
                />
              </StyledMarkdownFieldWrapper>
            }
          />
          <Table<BrandKit>
            dataSource={data?.data}
            columns={columns}
            rowKey="id"
            loading={isLoading}
            onRow={(record) => ({
              onClick: (event) => {
                const target = event.target as HTMLElement;
                if (!target.closest(".ant-btn")) {
                  go({
                    to: {
                      resource: "media_brandkits",
                      id: record.id,
                      action: "show",
                    },
                  });
                }
              },
              style: { cursor: "pointer" },
            })}
          />
        </Flex>
      </List>
    </Authenticated>
  );
};
