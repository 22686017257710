import { useTranslate } from "@refinedev/core";
import { GoogleDriveLogo } from "@phosphor-icons/react";
import { Button, Result } from "antd";
import { useEffect, useState } from "react";

export const DocumentViewer = ({
  url,
  allow = false,
}: {
  url: string;
  allow?: boolean;
}) => {
  const t = useTranslate();
  const viewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(url)}`;
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);
  const [allowExternalPreview, setAllowExternalPreview] = useState(allow);

  useEffect(() => {
    if (allowExternalPreview && iframeRef?.contentWindow) {
      // hack but not always working also
      iframeRef.contentWindow.location.href = viewerUrl;
    }
  }, [allowExternalPreview, iframeRef?.contentWindow, url, viewerUrl]);

  return (
    <>
      {!allowExternalPreview ? (
        <Result
          icon={<GoogleDriveLogo size="100" />}
          subTitle={t("src.components.DocumentViewer.allowGoogle")}
          extra={
            <Button
              onClick={() => setAllowExternalPreview(true)}
              shape="round"
              size="large"
            >
              {t("src.components.DocumentViewer.allowAccessTo")}
            </Button>
          }
        />
      ) : (
        <iframe
          key={url}
          // sometimes not working ..weird
          src={viewerUrl}
          ref={setIframeRef}
          title={t("src.components.DocumentViewer.preview")}
          height={"100%"}
          width={"100%"}
          style={{ border: 0 }}
        />
      )}
    </>
  );
};

