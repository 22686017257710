import "pdfjs-dist/webpack.mjs";
import { FixedSizeList as List } from "react-window";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { pdfjs, Document, Page as PDFPage, PageProps } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist";
import { Flex, Spin } from "antd";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { debounce } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
const Page = ({ height, width }: PageProps) =>
  function Page({ index, style }: { index: number; style: CSSProperties }) {
    return (
      <div style={style}>
        <PDFPage pageNumber={index + 1} height={height} width={width} />
      </div>
    );
  };
const PdfView = ({
  url,
  paddingX = 0,
  width: containerWidth = "100%",
}: {
  url: string;
  width?: number | string;
  paddingX?: number;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number>(1);
  const [pageWidth, setPageWidth] = useState<number>(1);
  const [pageHeight, setPageHeight] = useState<number>(600);
  const documentRef = useRef<PDFDocumentProxy | null>(null);
  const debouncedHandleResize = debounce(async () => {
    if (containerRef.current && documentRef.current) {
      const { width: origWidth } = (
        await documentRef.current.getPage(1)
      ).getViewport({
        scale: 1,
      });
      const widthInPixels = containerRef.current?.clientWidth - scrollBarWidth;
      const scale = widthInPixels / origWidth;
      const { width, height } = (
        await documentRef.current.getPage(1)
      ).getViewport({
        scale,
      });
      setPageHeight(height);
      setPageWidth(width - 1);
    }
  }, 300);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(debouncedHandleResize);
    resizeObserver.observe(containerRef.current as Element);
    return () => {
      resizeObserver.disconnect();
    };
  }, [pageWidth]);

  // TODO: Might not work in a rare case when there's no scrollbar on the body
  const scrollBarWidth = window.innerWidth - window.document.body.clientWidth;
  async function onDocumentLoadSuccess(document: PDFDocumentProxy) {
    const { numPages } = document;
    documentRef.current = document;
    setNumPages(document.numPages);
    // Can we fix the problem with uneven page widths (MED-605)
    // like this somehow?
    let widthInPixels = 0;
    if (typeof containerWidth === "string" && containerWidth.endsWith("vw")) {
      widthInPixels =
        window.document.documentElement.clientWidth *
          (parseInt(containerWidth) / 100) -
        paddingX -
        scrollBarWidth;
    }
    if (
      typeof containerWidth === "string" &&
      containerWidth.endsWith("%") &&
      containerRef.current
    ) {
      widthInPixels =
        containerRef.current?.clientWidth * (parseInt(containerWidth) / 100) -
        paddingX -
        scrollBarWidth;
    }
    const { width: origWidth } = (await document.getPage(1)).getViewport({
      scale: 1,
    });
    const scale = (widthInPixels as number) / origWidth;
    const { width, height } = (await document.getPage(1)).getViewport({
      scale,
    });
    setNumPages(numPages);
    setPageHeight(height);
    // Subpixel fix?
    setPageWidth(width - 1);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Document
        loading={
          <Flex
            justify="center"
            align="center"
            style={{ width: "100%", height: "90vh" }}
          >
            <Spin indicator={<LoadingOutlined />} />
          </Flex>
        }
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <List
          height={containerRef.current?.clientHeight || 0}
          itemCount={numPages}
          itemSize={pageHeight}
          width={"100%"}
        >
          {Page({ height: pageHeight, width: pageWidth })}
        </List>
      </Document>{" "}
    </div>
  );
};
export default PdfView;
