import { useTranslate } from "@refinedev/core";
import {
  Button,
  Card,
  Input,
  Typography,
  Form,
  MenuProps,
  Tooltip,
  Tabs,
  Dropdown,
  Space,
  Flex,
  Popover,
} from "antd";
import { CheckCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { ArrowUpOutlined, PauseCircleOutlined } from "@ant-design/icons";
import React, { CSSProperties, useEffect, useState } from "react";

import type { DocumentExtract, TDetailedScript } from "pages/media/types";
import { useAntTheme } from "hooks/useAntTheme";
import { countWords } from "pages/media/utils";
import { Label } from "./Label";
import { MagicWand, StackMinus, StackPlus, TextT } from "@phosphor-icons/react";
import { useStoryboard } from "../context/StoryboardContext";
import styled from "styled-components";
import { MiniLabel } from "components/MiniLabel";

type ScriptProps = {
  changeScript: (id: number, keyInsight: Partial<TDetailedScript>) => void;
  improveScript: Function;
  script: TDetailedScript;
  index: number;
  sectionId: string;
  editable: boolean;
  sources: DocumentExtract[];
};

export const Script: React.FC<ScriptProps> = ({
  changeScript,
  improveScript,
  sectionId,
  script,
  index,
  editable,
  sources,
}) => {
  const t = useTranslate();
  const { Paragraph } = Typography;
  const { theme } = useAntTheme();
  const isSelected = script.selected === true;
  const toggleScriptSelection = () => {
    changeScript(index, {
      ...script,
      selected: !script.selected,
    });
  };

  const [scriptContent, setScriptContent] = useState<string[]>([]);
  const [citationChunks, setCitationChunks] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const {
    handleDisplaySource,
    highlightedSource,
    setHighlightedSource,
    highlights,
  } = useStoryboard();

  const sortArrayByString = (array: string[], str: string) => {
    return array.sort((a, b) => {
      return str.indexOf(a) - str.indexOf(b);
    });
  };

  // Split content string into parts
  useEffect(() => {
    if (script && sources && sources.length > 0) {
      const contentArray: string[] = [];
      let workString: string = script.content;
      const unsortedCitationChunks: string[] = sources.map(
        (source) => source.text
      );

      const citationChunks = sortArrayByString(
        unsortedCitationChunks,
        script.content
      );

      citationChunks.forEach((substring: string, index: number) => {
        if (workString === undefined) return;
        if (substring === citationChunks[index - 1]) return;

        const chunk: string[] = workString.split(substring);
        contentArray.push(chunk[0]);
        contentArray.push(substring);
        workString = chunk[1];

        if (citationChunks.length === index + 1) {
          contentArray.push(chunk[1]);
        }
      });

      setScriptContent(contentArray);
      setCitationChunks(citationChunks);
    }
  }, [sources]);

  const getScriptInnerContent = () => {
    if (!sources || (sources && !sources.length) || isEditing) {
      return script?.content;
    } else if (sources && sources.length > 0) {
      return scriptContent?.map((scriptContentChunk, index) => {
        const source = sources.find(
          (source) => source.text === scriptContentChunk
        );
        let isHighlighted: boolean;

        if (source) {
          isHighlighted =
            highlightedSource === `${sectionId}-${sources.indexOf(source)}`;
        }

        const duplicatedSources = sources.filter(
          (source) => source.text === scriptContentChunk
        );

        return scriptContentChunk &&
          citationChunks &&
          citationChunks.includes(scriptContentChunk) ? (
          <span
            key={index}
            style={{
              position: "relative",
            }}
          >
            <span
              style={{
                backgroundColor: theme.colorPrimary + "44",
                borderRadius: 3,
                paddingRight: 1,
                paddingLeft: 1,
              }}
            >
              {scriptContentChunk}
            </span>
            {duplicatedSources?.map((source, index) => (
              <Popover
                autoAdjustOverflow
                content={
                  <Flex vertical>
                    <MiniLabel type="secondary">
                      {t("storyboard.editor.Script.factCheck")}
                    </MiniLabel>
                    <Typography.Text style={{ maxWidth: 250 }}>
                      {source.occurrences[0].chunk}
                    </Typography.Text>
                  </Flex>
                }
                key={"source" + index}
              >
                <CitationContainer
                  onClick={() => {
                    if (source) {
                      handleDisplaySource(source);
                      if (!isHighlighted) {
                        setHighlightedSource(
                          `${sectionId}-${sources.indexOf(source)}`
                        );
                      } else {
                        setHighlightedSource(undefined);
                      }
                    }
                  }}
                >
                  <CitationNumber style={{ color: theme.colorPrimary }}>
                    {highlights &&
                      `[${
                        highlights.findIndex(
                          (highlight) =>
                            highlight.id ===
                            `${sectionId}-${sources.indexOf(source)}`
                        ) + 1
                      }]`}
                  </CitationNumber>
                </CitationContainer>
              </Popover>
            ))}
          </span>
        ) : (
          scriptContentChunk
        );
      });
    }
  };

  return (
    <Card
      title={
        <Label
          type="secondary"
          style={{ color: isSelected ? "#43C0A0" : undefined }}
        >
          #{index + 1} •
          {t("storyboard.editor.Script.words", {
            count: countWords(script.content ?? ""),
          })}
        </Label>
      }
      size="small"
      // type="inner"
      // bordered={false}
      style={{
        borderRadius: 0,
        background: "none",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
        marginTop: index === 0 ? 5 : 0,
      }}
      styles={{
        header: { borderRadius: 0, border: 0 },
        body: { paddingTop: 0, paddingBottom: 0 },
      }}
      extra={[
        // <Label type="secondary">

        // </Label>,
        <Tooltip title={!script.selected ? t("choose") : t("unchoose")}>
          <Button
            size="middle"
            shape="circle"
            icon={
              !script.selected ? (
                <CheckOutlined />
              ) : (
                <CheckCircleOutlined style={{ color: theme.colorPrimary }} />
              )
            }
            type="text"
            onClick={toggleScriptSelection}
          />
        </Tooltip>,
      ]}
    >
      {(script.revisions?.length ?? 0) === 0 && (
        <Paragraph
          style={{
            width: "100%",
            margin: "5px",
            boxSizing: "border-box",
          }}
          editable={{
            onChange: (value) => {
              changeScript(index, {
                content: value,
              });
            },
            onStart: () => setIsEditing(true),
            onEnd: () => setIsEditing(false),
          }}
        >
          {getScriptInnerContent()}
        </Paragraph>
      )}

      {(script.revisions?.length ?? 0) > 0 && (
        <Tabs
          size="small"
          items={[
            ...(script.revisions?.map((x, revisionIndex) => {
              const isOriginal = x.prompt.length === 0;
              const isLatest = revisionIndex === 0;
              return {
                label: (
                  <Space align="center">
                    {!isOriginal ? (
                      x.prompt
                    ) : (
                      <>
                        <TextT size={16} />
                        {t("storyboard.editor.Script.original")}
                      </>
                    )}
                  </Space>
                ),
                key: String(revisionIndex),
                children: (
                  <Paragraph
                    style={{
                      width: "100%",
                    }}
                    editable={
                      // latest update
                      isLatest
                        ? {
                            onChange: (value) => {
                              changeScript(index, {
                                content: value,
                              });
                            },
                          }
                        : undefined
                    }
                  >
                    {isLatest ? script.content : x.content}
                  </Paragraph>
                ),
              };
            }) ?? []),
          ]}
        />
      )}

      <PromptForm
        disabled={!editable}
        value=""
        onChange={(value) => {
          const { selected, ...cleanScript } = script;
          improveScript(sectionId, cleanScript, value, () => {
            return { sectionId, scriptIndex: index };
          });
          return value;
        }}
      />
    </Card>
  );
};

const CitationNumber = styled.sup`
  display: inline-block;
  margin-left: 4px;
`;
const CitationContainer = styled.span`
  position: relative;
  margin-right: 2px;
  cursor: pointer;
`;

//  cf https://github.com/bcExpt1123/chatgpt-example/blob/main/frontend/src/components/ChatForm/index.js
export const PromptForm = ({
  onChange,
  style = {},
  busy = false,
  disabled = false,
  value,
}: {
  onChange: (prompt: string) => void;
  style?: CSSProperties;
  busy?: boolean;
  disabled?: boolean;
  value: string;
}) => {
  const t = useTranslate();
  const [form] = Form.useForm();
  const prompt = Form.useWatch("prompt", { form, preserve: true });
  // const template = Form.useWatch("template", { form, preserve: true });
  const promptTemplates: MenuProps["items"] = [
    {
      key: "Shorten",
      label: (
        <Space align="center">
          <StackMinus size={24} />
          {t("storyboard.editor.Script.shorten")}
        </Space>
      ),
    },
    {
      key: "Elaborate",
      label: (
        <Space align="center">
          <StackPlus size={24} />
          {t("storyboard.editor.Script.elaborate")}
        </Space>
      ),
    },
    // children: [
    //   {
    //     key: "2",
    //     label: "Equity Research",
    //     children: [
    //       {
    //         key: "2-1",
    //         label: "What is the best stock ?",
    //       },
    //       {
    //         key: "2-2",
    //         label: "What are you doing ?",
    //       },
    //     ],
    //   },
    //   {
    //     key: "3",
    //     label: "Bank Research",
    //     children: [
    //       {
    //         key: "3-1",
    //         label: "What is the best stock ?",
    //       },
    //       {
    //         key: "3-2",
    //         label: "What are you doing ?",
    //       },
    //     ],
    //   },
    // ],
    // },
  ];
  const handleFinish = (values: any) => {
    if (busy) return;
    onChange(values.prompt);
    form.setFieldValue("prompt", "");
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handlePickTemplate: MenuProps["onClick"] = (e) => {
    // not clean with i18n  ... todo
    onChange(e.key);
  };

  useEffect(() => {
    if (value) form.setFieldValue("prompt", value);
  }, [value, form]);

  const menuProps = {
    items: promptTemplates,
    onClick: handlePickTemplate,
  };

  return (
    <div style={style}>
      <Form
        // layout="inline"
        form={form}
        onFinish={handleFinish}
        style={{ position: "relative" }}
      >
        <Form.Item
          style={{ position: "absolute", left: 9, top: 6, zIndex: 1 }}
          name="template"
        >
          <Dropdown menu={menuProps}>
            <Button
              type="text"
              size="large"
              style={{ opacity: 8 }}
              icon={<MagicWand size={20} />}
            ></Button>
          </Dropdown>
        </Form.Item>
        <Form.Item name="prompt" initialValue={value}>
          <Input
            disabled={disabled}
            placeholder={t("storyboard.editor.Script.askAiTo")}
            style={{
              height: 50,
              //   borderRadius: 15,
              boxShadow: "0px 0px 20px #00000020",
              paddingRight: 60,
              paddingLeft: 50,
            }}
          />
        </Form.Item>
        <Form.Item
          name="sendMsg"
          style={{ position: "absolute", right: 14, top: 8, zIndex: 1 }}
        >
          <Button
            onClick={handleSubmit}
            type="primary"
            size="small"
            icon={busy ? <PauseCircleOutlined /> : <ArrowUpOutlined />}
            style={{
              height: 30,
              width: 30,
              borderRadius: 6,
              boxShadow: "none",
              border: 0,
              ...(busy ? { background: "transparent" } : {}),
            }}
            disabled={disabled || (!prompt && !busy)}
          ></Button>
        </Form.Item>
      </Form>
    </div>
  );
};
