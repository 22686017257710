// @ts-nocheck
import {
  IResourceComponentsProps,
  CrudFilters,
  useList,
} from "@refinedev/core";
// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import {
  CheckCircleOutlined,
  EditOutlined,
  RocketOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import {
  List,
  useTable,
  ShowButton,
  EditButton,
  DateField,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Table, Space, Form, Input, Button, Tag, FormProps } from "antd";
import React, { useEffect, useState } from "react";
import { addDateTz } from "pages/media/utils";

interface DataType {
  key: React.Key;
  ticker: string;
  name: string;
  category: string;
  subCat: string;
  holders: string;
  aum: string;
  status: string;
  exchanges: string | Array<string>;
  createdAt: string;
  actions: number;
}

export const FundList: React.FC<IResourceComponentsProps> = () => {
  const [dataTable, setDataTable] = useState<DataType[]>([]);
  const { tableProps, searchFormProps, filters, setFilters } = useTable({
    resource: "api/funds/list",
    onSearch: (params: { q: string }) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });
      setFilters(filters);
      return filters;
    },
  });

  const { data: dataCategory } = useList({
    resource: "api/funds/categories?depth=1",
  });

  const getFundExchanges = (fund: any) => {
    const exchanges: Set<string> = (fund.allocation.tokens as []).reduce(
      (acc: Set<string>, value: any) => {
        acc.add(value?.exchange);
        return acc;
      },
      new Set()
    );
    return Array.from(exchanges.values());
  };
  const renderStatus = (value: string, label?: string) => {
    let color, Icon;
    switch (value) {
      case "public":
        color = "green";
        Icon = CheckCircleOutlined;
        break;
      case "private":
        color = "red";
        Icon = EyeInvisibleOutlined;
        break;
      case "draft":
        color = "blue";
        Icon = EditOutlined;
        break;
      case "premium":
        color = "lime";
        Icon = RocketOutlined;
        break;
      default:
        color = "";
        Icon = CheckCircleOutlined;
        break;
    }
    return (
      <Tag color={color} icon={<Icon />}>
        {label ?? value}
      </Tag>
    );
  };

  useEffect(() => {
    if (tableProps?.dataSource) {
      const newData: DataType[] = [];
      let dataFiltered: any = [...tableProps.dataSource];

      if (filters?.length) {
        dataFiltered = tableProps.dataSource?.filter(
          (row) =>
            row.strategy.ticker
              .toLowerCase()
              .includes(filters?.[0]?.value.toLowerCase()) ||
            row.strategy.name
              .toLowerCase()
              .includes(filters?.[0]?.value.toLowerCase())
        );
      }

      dataFiltered
        .sort(
          (a: any, b: any) =>
            Date.parse(b?.strategy?.created) - Date.parse(a?.strategy?.created)
        )
        .forEach((fund: any) => {
          newData.push({
            key: fund.strategy.id,
            ticker: fund.strategy.ticker || "-",
            name: fund.strategy.name || "-",
            category: fund.strategy.categories || [],
            subCat: fund.strategy.subcategories || [],
            holders: fund.holders || 0,
            aum: fund.aum || 0,
            status: fund.status || "draft",
            exchanges: fund.exchanges, //getFundExchanges(fund),
            createdAt: fund.strategy.created || "-",
            actions: fund.strategy.id,
          });
        });

      setDataTable([...newData]);
    }
  }, [tableProps.dataSource, filters]);

  return (
    <List>
      <Filter formProps={searchFormProps} />
      <Table
        {...tableProps}
        scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
        pagination={{ pageSize: 20 }}
        dataSource={dataTable}
        loading={tableProps?.loading}
      >
        <Table.Column
          dataIndex="actions"
          render={(value, record) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={value} />
              <EditButton hideText size="small" recordItemId={value} />
            </Space>
          )}
        />
        <Table.Column dataIndex="ticker" title="Ticker" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="category"
          title="Category"
          render={(value) =>
            dataCategory?.data
              .filter((category: any) => value.includes(category.id))
              .map((cat) => cat.name)
              .join(", ")
          }
        />
        <Table.Column
          dataIndex="subCat"
          title="Sub-Cat"
          render={(value) => {
            const listSubCat: string[] = [];
            dataCategory?.data.forEach((cat) =>
              cat.subcategories.forEach(
                (subCat: any) =>
                  value.includes(subCat.id) && listSubCat.push(subCat.name)
              )
            );

            return listSubCat
              .filter((value, index, self) => self.indexOf(value) === index)
              .join(", ");
          }}
        />
        <Table.Column dataIndex="holders" title="Holders" />
        <Table.Column dataIndex="aum" title="AUM $" />
        <Table.Column
          dataIndex="exchanges"
          title="Exchanges"
          render={(exchanges) => {
            return exchanges.map((exchange: any) => {
              return (
                <span key={exchange.exchange}>
                  {renderStatus(exchange.status, exchange.exchange)}
                </span>
              );
            });
          }}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => (
            <DateField format="LLL" value={addDateTz(value)} />
          )}
        />
      </Table>
    </List>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item name="q">
        <Input
          placeholder="Search"
          suffix={
            <Button htmlType="submit" type="text" style={{ padding: 0 }}>
              <Icons.SearchOutlined />
            </Button>
          }
          style={{ width: 200 }}
        />
      </Form.Item>
    </Form>
  );
};
