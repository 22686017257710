import { useTranslate } from "@refinedev/core";
import React from "react";
import { Flex, Form, FormInstance, GlobalToken, Input, Typography } from "antd";
import { Card } from "antd";
import styled from "styled-components";
import { AddressBook } from "@phosphor-icons/react";
import { useAntTheme } from "hooks/useAntTheme";
import { MiniLabel } from "components/MiniLabel";
import { useContactPage } from "./hooks/useContactPage";
import { isValidPhoneNumber } from "helpers/utils";
const FIELD_REQUIRED = "components.ContactWithPresenter.Identity.requiredField";
const StyledCard = styled(Card)`
  ${({ theme }) => `
  width: 370px;
  & .ant-form-item-label {
    padding: 0;
  }
  border-radius: 20px;
  border: 1px solid ${theme.colorPrimary};
  background-origin: border-box;
  background-clip: contentBox, border-box;
  paddingBottom: 0;
  color: ${theme.colorPrimary};
  background-image: linear-gradient(to top, ${theme.colorPrimary}00, ${theme.colorPrimary}88);
`}
`;
const StyledInput = styled(Input)<{
  theme: GlobalToken;
}>`
  // border-radius: 10px;
  // padding-top: 10px;
  // padding-bottom: 10px;
  font-weight: bold;
  // font-size: 16px;
  color: ${({ theme }) => theme.colorText};
  &:focus {
    background: ${({ theme }) => theme.colorPrimaryBg};
  }
  &.ant-input-status-error:not(.ant-input-disabled) {
    background: ${({ theme }) => theme.colorErrorBg} !important;
  }
`;
export interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  calendar: string;
  phone: string;
}

interface IdentityCardProps {
  form: FormInstance<FormValues>;
  children: React.ReactNode;
}

const { Title, Text } = Typography;
const Identity: React.FC<IdentityCardProps> = ({ form, children }) => {
  const t = useTranslate();
  const { onChangeForm } = useContactPage();
  const { theme } = useAntTheme();
  return (
    <StyledCard
      styles={{
        body: {},
      }}
      bordered={false}
    >
      <Title level={3} style={{ margin: 0 }}>
        <Flex gap={10} align="center">
          <span className="anticon">
            <AddressBook size={32} />
          </span>
          {t("components.ContactWithPresenter.Identity.identity")}
        </Flex>
      </Title>
      <Text style={{ paddingTop: 0 }} type="secondary">
        {t("components.ContactWithPresenter.Identity.defineTheContact")}
      </Text>
      <Flex vertical>
        <Flex
          justify="center"
          align="center"
          style={{ marginBottom: theme.marginMD, marginTop: theme.marginMD }}
        >
          {children}
        </Flex>
        <StyledForm
          onValuesChange={onChangeForm}
          form={form}
          layout="vertical"
          colon={false}
          requiredMark={false}
          variant="filled"
        >
          <Form.Item
            name="firstname"
            validateDebounce={500}
            required
            label={
              <MiniLabel>
                {t("components.ContactWithPresenter.Identity.firstName")}
              </MiniLabel>
            }
            rules={[{ required: true, message: t(FIELD_REQUIRED) }]}
          >
            <StyledInput />
          </Form.Item>
          <Form.Item
            name="lastname"
            validateDebounce={500}
            required
            label={
              <MiniLabel>
                {t("components.ContactWithPresenter.Identity.lastName")}
              </MiniLabel>
            }
            rules={[{ required: true, message: t(FIELD_REQUIRED) }]}
          >
            <StyledInput />
          </Form.Item>
          <Form.Item
            name="email"
            validateDebounce={500}
            label={
              <MiniLabel>
                {t("components.ContactWithPresenter.Identity.email")}
              </MiniLabel>
            }
            rules={[
              {
                type: "email",
                message: t(
                  "components.ContactWithPresenter.Identity.invalidEmail"
                ),
              },
            ]}
          >
            <StyledInput />
          </Form.Item>
          <Form.Item
            name="calendar"
            validateDebounce={500}
            label={
              <MiniLabel>
                {t("components.ContactWithPresenter.Identity.calendar")}
              </MiniLabel>
            }
            rules={[
              {
                type: "url",
                message: t(
                  "components.ContactWithPresenter.Identity.pleaseEnterA"
                ),
              },
            ]}
          >
            <StyledInput />
          </Form.Item>
          <Form.Item
            name="phone"
            validateDebounce={500}
            label={
              <MiniLabel>
                {t("components.ContactWithPresenter.Identity.phone")}
              </MiniLabel>
            }
            style={{
              margin: 0,
            }}
            rules={[
              {
                type: "string",
                validator: (_, value: string) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (isValidPhoneNumber(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "components.ContactWithPresenter.Identity.pleaseEnterAValid"
                  );
                },
                message: t(
                  "components.ContactWithPresenter.Identity.pleaseEnterAValid"
                ),
              },
            ]}
          >
            <StyledInput />
          </Form.Item>
        </StyledForm>
      </Flex>
    </StyledCard>
  );
};

export default Identity;

const StyledForm = styled(Form<FormValues>)``;
