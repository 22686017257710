import { t } from "i18next";
import React, {
  createContext,
  useContext,
  ReactNode,
  ChangeEventHandler,
} from "react";

// Define the type for the context value
interface ContactPageContextType {
  id?: string;
  onChangeForm: ChangeEventHandler<HTMLFormElement>;
}

// Create the context with a default value
const ContactPageContext = createContext<ContactPageContextType | undefined>(
  undefined
);

// Create a provider component
export const ContactPageProvider: React.FC<{
  children: ReactNode;
  value: ContactPageContextType;
}> = ({ children, value }) => {
  return (
    <ContactPageContext.Provider value={value}>
      {children}
    </ContactPageContext.Provider>
  );
};

// Create a custom hook to use the context
export const useContactPage = (): ContactPageContextType => {
  const context = useContext(ContactPageContext);
  if (!context) {
    throw new Error(
      t("ContactWithPresenter.hooks.useContactPage.useContactPageMustBe")
    );
  }
  return context;
};

