import { Typography } from "antd";
import { ReactNode } from "react";

export const InputHelperText = ({ children }: { children: ReactNode }) => {
  return (
    <Typography.Text style={{ fontSize: "0.8rem" }} type="secondary">
      {children}
    </Typography.Text>
  );
};
