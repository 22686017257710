// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Image,
  Space,
  Typography,
  Table,
} from "antd";
import { useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";

const { Title } = Typography;

interface DataType {
  name: string;
}

const columnsDefault: ColumnsType<DataType> = [
  {
    title: "Category List",
    className: "column-name",
    dataIndex: "name",
    align: "center",
  },
];

const SubCategoryDetail: React.FC<IResourceComponentsProps> = () => {
  const params = useParams();
  const { queryResult } = useShow({
    resource: "api/funds/categories",
    id: params?.id,
  });

  const { data: dataSubCategory, isFetching } = queryResult;

  return (
    <div>
      <Form name="dynamic_rule" layout="horizontal">
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={6}>
                <Form.Item
                  name="Sub Category Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    disabled
                    defaultValue={dataSubCategory?.data?.name}
                    key={dataSubCategory?.data?.name}
                    autoFocus
                    size="large"
                    placeholder="Sub Category Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name="sub category status"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    disabled
                    placeholder="Sub Category Status"
                    key={dataSubCategory?.data?.status}
                    defaultValue={dataSubCategory?.data?.status}
                    options={[
                      { value: "publish", label: "Public" },
                      { value: "unpublish", label: "Private" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col lg={10} xs={24}>
                <Title level={5}>Sub-category included in:</Title>
                <Table
                  columns={columnsDefault}
                  dataSource={dataSubCategory?.data?.parent_categories}
                  bordered
                  loading={isFetching}
                  pagination={{ defaultPageSize: 5 }}
                />
              </Col>
              <Col lg={14} xs={24}>
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item name="logo">
                      <Space style={{ maxWidth: 200 }} direction="vertical">
                        <Title level={5}>Logo</Title>
                        <Image width="100%" src={dataSubCategory?.data?.logo} />
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="banner">
                      <Space style={{ maxWidth: 200 }} direction="vertical">
                        <Title level={5}>Banner</Title>
                        <Image
                          width="100%"
                          src={dataSubCategory?.data?.banner}
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="banner mobile">
                      <Space style={{ maxWidth: 200 }} direction="vertical">
                        <Title level={5}>Banner Mobile</Title>
                        <Image
                          width="100%"
                          src={dataSubCategory?.data?.banner_mobile}
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SubCategoryDetail;
