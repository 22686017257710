import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  Button,
  Col,
  Drawer,
  Form,
  Row,
  Select,
  Space,
  ColorPicker,
  Skeleton,
  Card,
  InputNumber,
  Input,
  Switch,
  Collapse,
} from "antd";
import { memo, useEffect, useState } from "react";
import { RoughNotation } from "react-rough-notation";
import { Cuepoint, IRoughNotation } from "./types";

const { Panel } = Collapse;
const DEFAULT_COLOR = "#1677FF"; // blue

interface PreviewProps {
  roughNotation?: IRoughNotation;
}

const Preview: React.FC<PreviewProps> = memo(({ roughNotation }) => {
  console.log(roughNotation);
  return (
    <RoughNotation
      key={JSON.stringify(roughNotation)}
      show={true}
      animate={roughNotation?.animate}
      type={roughNotation?.type || "box"}
      animationDelay={roughNotation?.animationDelay}
      animationDuration={roughNotation?.animationDuration}
      strokeWidth={roughNotation?.strokeWidth}
      padding={roughNotation?.padding}
      color={roughNotation?.color || "#1677FF"}
      brackets={roughNotation?.brackets}
      iterations={roughNotation?.iterations}
      multiline={roughNotation?.multiline}
    >
      <Skeleton>
        <Card />
      </Skeleton>
    </RoughNotation>
  );
});

export const BinderDrawerConfig: React.FC<{
  currentCuepointToConf?: Cuepoint;
  open: boolean;
  onClose: (hgl?: Cuepoint) => void;
  updateCuepoint: (cuepoint: Cuepoint) => void;
}> = ({ currentCuepointToConf, open, onClose, updateCuepoint }) => {
  const t = useTranslate();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [activePanel, setActivePanel] = useState<string>();
  const [latestColor, setLatestColor] = useState<string>(DEFAULT_COLOR);
  const [presetColors, setPresetColors] = useState<Set<string>>(new Set());

  function collapseSelected(key: string | string[]) {
    if (key.length === 0) {
      return;
    }
    setActivePanel(key as string);
  }

  const roughNotationDefaults = {
    animate: true,
    type: "underline",
    animationDelay: 500,
    animationDuration: 800,
    strokeWidth: 1,
    padding: 5,
    color: latestColor,
    brackets: ["left"],
    iterations: 2,
    multiline: true,
  } as IRoughNotation;
  // console.debug("currentCuepointToConf", currentCuepointToConf)
  const [currentCuepoint, setCurrentCuepoint] = useState<Cuepoint>();

  useEffect(() => {
    if (!currentCuepointToConf) {
      return;
    }
    setCurrentCuepoint({
      roughNotation: roughNotationDefaults,
      ...currentCuepointToConf,
    });
    console.debug("currentCuepointToConf", currentCuepointToConf);
  }, [currentCuepointToConf]);

  useEffect(() => {
    form.setFieldsValue({
      animate: currentCuepoint?.roughNotation?.animate ?? true,
      type: currentCuepoint?.roughNotation?.type ?? "underline",
      animationDelay: currentCuepoint?.roughNotation?.animationDelay ?? 500,
      animationDuration:
        currentCuepoint?.roughNotation?.animationDuration ?? 800,
      strokeWidth: currentCuepoint?.roughNotation?.strokeWidth ?? 1,
      padding: currentCuepoint?.roughNotation?.padding ?? 5,
      color: currentCuepoint?.roughNotation?.color ?? latestColor,
      brackets: currentCuepoint?.roughNotation?.brackets ?? ["left"],
      iterations: currentCuepoint?.roughNotation?.iterations ?? 2,
      multiline: currentCuepoint?.roughNotation?.multiline ?? true,
      display: true,
      title: currentCuepoint?.title ?? "",
      zoom: currentCuepoint?.zoom ?? undefined,
    });

    setActivePanel(currentCuepoint?.zoom ? "zoom" : "roughNotation");

    console.debug("currentCuepoint", currentCuepoint);
  }, [currentCuepoint, form]);

  const handleChange = (update: Partial<Cuepoint>) => {
    console.debug("update", update);
    if (update.roughNotation) {
      if (update.roughNotation.color) {
        setLatestColor(update.roughNotation.color);
      }

      setCurrentCuepoint((current) => {
        // console.debug("current",current)

        if (!current) return current;
        if (!current.roughNotation)
          return {
            ...current,
            roughNotation: {
              type: "underline",
              ...update.roughNotation,
            },
          };
        const annotation = {
          ...current.roughNotation,
          ...update.roughNotation,
        };
        // console.debug("annotation",annotation)

        return { ...current, ...update, roughNotation: { ...annotation } };
      });
    } else {
      setCurrentCuepoint((cuepoint) => {
        return {
          documentId: cuepoint!.documentId, // Garantit que `documentId` n'est pas `undefined`.
          highlightId: cuepoint!.highlightId,
          start: cuepoint!.start,
          title: cuepoint!.title ?? "",
          roughNotation: cuepoint!.roughNotation,
          ...update,
        };
      });
    }
  };

  const handleClose = () => {
    onClose(currentCuepoint);
  };

  const handleSave = () => {
    if (!!currentCuepoint?.zoom && !!currentCuepoint?.roughNotation) {
      // keep only zoom config
      delete currentCuepoint.roughNotation;
    }
    updateCuepoint(currentCuepoint!);
    setPresetColors((colors) => new Set([...colors, latestColor]));
    onClose(currentCuepoint);
  };

  const zoomDrawer = (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("components.binder.BinderDrawerConfig.zoom")}
            name="zoom"
            valuePropName="checked"
          >
            <Switch
              onChange={(checked) =>
                handleChange({
                  ...currentCuepoint,
                  // todo dynamic scale

                  zoom: checked ? { scale: 2 } : undefined,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const roughNotationDrawer = (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="type"
            label={t("components.binder.BinderDrawerConfig.type")}
            rules={[
              {
                required: true,
                message: t(
                  "components.binder.BinderDrawerConfig.pleaseChooseThe"
                ),
              },
            ]}
          >
            <Select
              placeholder={t(
                "components.binder.BinderDrawerConfig.pleaseChooseThe"
              )}
              onChange={(value) =>
                handleChange({ roughNotation: { type: value } })
              }
            >
              <Option value="underline">
                {t("components.binder.BinderDrawerConfig.underline")}
              </Option>
              <Option value="box">
                {t("components.binder.BinderDrawerConfig.box")}
              </Option>
              <Option value="circle">
                {t("components.binder.BinderDrawerConfig.circle")}
              </Option>
              <Option value="highlight">
                {t("components.binder.BinderDrawerConfig.highlight")}
              </Option>
              <Option value="strike-through">
                {t("components.binder.BinderDrawerConfig.strikeThrough")}
              </Option>
              <Option value="crossed-off">
                {t("components.binder.BinderDrawerConfig.crossedOff")}
              </Option>
              <Option value="bracket">
                {t("components.binder.BinderDrawerConfig.bracket")}
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="animate"
            label={t("components.binder.BinderDrawerConfig.animation")}
            rules={[
              {
                required: true,
                message: t(
                  "components.binder.BinderDrawerConfig.pleaseSelectAnimated"
                ),
              },
            ]}
          >
            <Select
              placeholder={t(
                "components.binder.BinderDrawerConfig.pleaseSelect"
              )}
              onChange={(value) =>
                handleChange({
                  ...currentCuepoint,
                  roughNotation: {
                    type: currentCuepoint?.roughNotation?.type || "underline",
                    ...currentCuepoint!.roughNotation,
                    animate: value,
                  },
                })
              }
            >
              <Option value={true}>
                {t("components.binder.BinderDrawerConfig.animated")}
              </Option>
              <Option value={false}>
                {t("components.binder.BinderDrawerConfig.notAnimated")}
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="animationDuration"
            label={t("components.binder.BinderDrawerConfig.animationDuration")}
            rules={[
              {
                required: false,
                message: t(
                  "components.binder.BinderDrawerConfig.pleaseEnterDuration"
                ),
              },
            ]}
          >
            <InputNumber
              placeholder={t(
                "components.binder.BinderDrawerConfig.pleaseEnterADuration"
              )}
              step={100}
              onChange={(value) =>
                handleChange({
                  ...currentCuepoint,
                  roughNotation: {
                    type: currentCuepoint?.roughNotation?.type || "underline",
                    ...currentCuepoint!.roughNotation,
                    animationDuration: value as number,
                  },
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="color"
            label={t("components.binder.BinderDrawerConfig.color")}
            rules={[
              {
                required: true,
                message: t("components.binder.BinderDrawerConfig.pleasePickA"),
              },
            ]}
          >
            <ColorPicker
              showText
              value={currentCuepoint?.roughNotation?.color || latestColor}
              presets={[
                {
                  label: t("components.binder.BinderDrawerConfig.palette"),
                  colors: Array.from(presetColors),
                },
              ]}
              onChange={(value) =>
                handleChange({
                  ...currentCuepoint,
                  roughNotation: {
                    type: currentCuepoint?.roughNotation?.type || "underline",
                    ...currentCuepoint!.roughNotation,
                    color: value.toHexString(),
                  },
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="strokeWidth"
            label={t("components.binder.BinderDrawerConfig.strokeWidth")}
            rules={[
              {
                required: false,
                message: t(
                  "components.binder.BinderDrawerConfig.pleaseChooseAStroke"
                ),
              },
            ]}
          >
            <InputNumber
              placeholder={t(
                "components.binder.BinderDrawerConfig.pleaseEnterAStroke"
              )}
              onChange={(value) =>
                handleChange({
                  ...currentCuepoint,
                  roughNotation: {
                    type: currentCuepoint?.roughNotation?.type || "underline",
                    ...currentCuepoint!.roughNotation,
                    strokeWidth: value as number,
                  },
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="padding"
            label={t("components.binder.BinderDrawerConfig.padding")}
            rules={[
              {
                required: false,
                message: t(
                  "components.binder.BinderDrawerConfig.pleaseChooseAPadding"
                ),
              },
            ]}
          >
            <InputNumber
              placeholder={t(
                "components.binder.BinderDrawerConfig.pleaseEnterAPadding"
              )}
              onChange={(value) =>
                handleChange({
                  ...currentCuepoint,
                  roughNotation: {
                    type: currentCuepoint?.roughNotation?.type || "underline",
                    ...currentCuepoint!.roughNotation,
                    padding: value as number,
                  },
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="multiline"
            label={t("components.binder.BinderDrawerConfig.multiline")}
            rules={[
              {
                required: true,
                message: t(
                  "components.binder.BinderDrawerConfig.pleaseSelectMultiline"
                ),
              },
            ]}
          >
            {/* onChange={(value) => handleChange("multiline", value)} */}

            <Select
              placeholder={t(
                "components.binder.BinderDrawerConfig.pleaseSelect"
              )}
            >
              <Option value={true}>
                {t("components.binder.BinderDrawerConfig.multiline")}
              </Option>
              <Option value={false}>
                {t("components.binder.BinderDrawerConfig.notMultiline")}
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="iterations"
            label={t("components.binder.BinderDrawerConfig.iterations")}
            rules={[
              {
                required: false,
                message: t(
                  "components.binder.BinderDrawerConfig.pleaseChooseAn"
                ),
              },
            ]}
          >
            <InputNumber
              placeholder={t(
                "components.binder.BinderDrawerConfig.pleaseEnterAn"
              )}
              onChange={(value) =>
                handleChange({
                  ...currentCuepoint,
                  roughNotation: {
                    type: currentCuepoint?.roughNotation?.type || "underline",
                    ...currentCuepoint!.roughNotation,
                    iterations: value as number,
                  },
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="brackets"
            label={t("components.binder.BinderDrawerConfig.brackets")}
            rules={[
              {
                required: false,
                message: t(
                  "components.binder.BinderDrawerConfig.pleaseSelectWhich"
                ),
                type: "array",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={t(
                "components.binder.BinderDrawerConfig.pleaseSelectThe"
              )}
              onChange={(value) =>
                handleChange({
                  ...currentCuepoint,
                  roughNotation: {
                    type: currentCuepoint?.roughNotation?.type || "underline",
                    ...currentCuepoint!.roughNotation,
                    brackets: value,
                  },
                })
              }
            >
              <Option value="left">
                {t("components.binder.BinderDrawerConfig.left")}
              </Option>
              <Option value="right">
                {t("components.binder.BinderDrawerConfig.right")}
              </Option>
              <Option value="top">
                {t("components.binder.BinderDrawerConfig.top")}
              </Option>
              <Option value="bottom">
                {t("components.binder.BinderDrawerConfig.bottom")}
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="delay"
            label={t("components.binder.BinderDrawerConfig.delay")}
            rules={[
              {
                required: false,
                message: t(
                  "components.binder.BinderDrawerConfig.pleaseChooseAStrock"
                ),
              },
            ]}
          >
            <InputNumber
              placeholder={t(
                "components.binder.BinderDrawerConfig.pleaseEnterADelay"
              )}
              step={100}
              onChange={(value) =>
                handleChange({
                  ...currentCuepoint,
                  roughNotation: {
                    type: currentCuepoint?.roughNotation?.type || "underline",
                    ...currentCuepoint!.roughNotation,
                    animationDelay: value as number,
                  },
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Preview roughNotation={currentCuepoint?.roughNotation} />
    </>
  );

  return (
    <Drawer
      title={t("components.binder.BinderDrawerConfig.cuePointSettings")}
      width={720}
      onClose={handleClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={handleClose}>
            {t("components.binder.BinderDrawerConfig.cancel")}
          </Button>
          <Button onClick={handleSave} type="primary">
            {t("components.binder.BinderDrawerConfig.submit")}
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" initialValues={currentCuepoint} form={form}>
        <Row gutter={1}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("components.binder.BinderDrawerConfig.chapterTitle")}
              rules={[
                {
                  required: false,
                  message: t(
                    "components.binder.BinderDrawerConfig.enterATitle"
                  ),
                },
              ]}
            >
              <Input
                placeholder={t(
                  "components.binder.BinderDrawerConfig.enterATitle"
                )}
                onChange={(e) =>
                  handleChange({
                    ...currentCuepoint,
                    title: e.currentTarget.value,
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Collapse onChange={collapseSelected} activeKey={activePanel} accordion>
          <Panel header="Zoom" key="zoom">
            {zoomDrawer}
          </Panel>
          <Panel header="Rough Notation" key="roughNotation">
            {roughNotationDrawer}
          </Panel>
        </Collapse>
      </Form>
    </Drawer>
  );
};
