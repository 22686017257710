import { StateCreator } from "zustand";
import { TablePaginationConfig } from "antd/es/table";
import { FilterValue, SorterResult } from "antd/es/table/interface";

import { MediaProject } from "pages/media/types";
import { sliceResetFns } from "store/utils";
import { CombinedSlice } from "store/types";
import { ProjectTableViewMode } from "./types";

export interface ProjectsSlice<T = MediaProject> {
  setProjectsState: (state: Partial<ProjectsState<MediaProject>>) => void;
  resetProjectsState: () => void;
  projectsState: ProjectsState<T>;
}

interface ProjectsState<T> {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null>;
  sorter: SorterResult<T> | SorterResult<T>[];
  searchText: string;
  viewMode: ProjectTableViewMode;
}

const initialState = {
  pagination: { current: 1, pageSize: 50 },
  filters: {},
  sorter: { field: "", order: null },
  searchText: "",
  viewMode: "table" as ProjectTableViewMode,
};

export const createProjectsSlice: StateCreator<
  CombinedSlice,
  //https://github.com/pmndrs/zustand/issues/980#issuecomment-1162289836
  [
    ["zustand/devtools", never],
    ["zustand/persist", unknown],
    ["zustand/immer", never],
  ],
  [],
  ProjectsSlice
> = (set) => {
  sliceResetFns.add(() => set({ projectsState: initialState }));
  return {
    projectsState: initialState,
    resetProjectsState: () => set({ projectsState: initialState }),
    setProjectsState: (newState) =>
      set((state) => ({
        projectsState: { ...state.projectsState, ...newState },
      })),
  };
};
