import { useInvalidate, useTranslate } from "@refinedev/core";
import { Badge, Flex, Modal, Typography } from "antd";
import { MediaProjectResponse } from "pages/media/types";
import React, { useState, useRef, useEffect } from "react";
import "simplebar-react/dist/simplebar.min.css";
import styled from "styled-components";
import ProjectDocumentsList from "./ProjectDocumentsList";
import { ProjectDocumentUpload } from "./ProjectDocumentUpload";

type ProjectModal = "AssetUpload" | "DocumentUpload" | "MediaUpload";

export const ProjectKB = ({
  project,
  projectId,
  openModal,
  openedModal,
  closeModal,
}: {
  project: MediaProjectResponse;
  projectId?: string;
  openModal: (modal: ProjectModal) => void;
  openedModal?: ProjectModal;
  closeModal: () => void;
}) => {
  const t = useTranslate();
  // const go = useGo();
  const invalidate = useInvalidate();
  // For forcing rerender on the modal, otherwise it gets stuck with
  // the previous upload
  const [modalKey, setModalKey] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedFile, setDraggedFile] = useState<File | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const uploadComponentRef = useRef<any>(null);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      containerRef.current &&
      !containerRef.current.contains(e.relatedTarget as Node)
    ) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setDraggedFile(file);
      openModal("DocumentUpload");
    }
  };

  const handleModalClose = () => {
    closeModal();
    setDraggedFile(null);
  };

  useEffect(() => {
    if (
      openedModal === "DocumentUpload" &&
      draggedFile &&
      uploadComponentRef.current
    ) {
      const fileInput =
        uploadComponentRef.current.querySelector('input[type="file"]');
      if (fileInput) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(draggedFile);
        fileInput.files = dataTransfer.files;
        const event = new Event("change", { bubbles: true });
        fileInput.dispatchEvent(event);
        const submitButton = uploadComponentRef.current.querySelector(
          'button[type="submit"]'
        );
        if (submitButton) {
          submitButton.click();
        }
      }
    }
  }, [openedModal, draggedFile]);

  return (
    <KBContainer
      ref={containerRef}
      onDragEnter={handleDragEnter}
      onDragOver={handleDrag}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      $isDragging={isDragging}
    >
      <Flex vertical={true} gap={10} style={{ paddingBottom: 100 }}>
        <Typography.Title
          level={4}
          style={{
            margin: 0,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
        >
          {t("projects.components.ProjectKB.knowledgeBase")}
          <Badge
            showZero={true}
            count={project.documents?.length}
            color="#999"
          />
        </Typography.Title>
        {/* <Typography.Text
                type="secondary"
                style={{ fontWeight: "normal" }}
                >
                Attach documents to provide context for your AI
                assistant
                </Typography.Text> */}
        {/* <SimpleBar
          style={{
            paddingRight: 0,
            paddingBottom: 0,
/*!--empty-line--!*/}
        {projectId && (
          <ProjectDocumentsList
            documents={project.documents}
            primaryDocumentId={project.settings.primary_document_id}
            projectId={projectId}
          />
        )}
      </Flex>

      {isDragging && <DropOverlay/>}

      <Modal
        key={modalKey}
        title={t("projects.components.ProjectKB.attachADocument")}
        open={openedModal === "DocumentUpload"}
        onCancel={handleModalClose}
        footer={null}
        styles={{ body: { padding: 30 } }}
      >
        <div ref={uploadComponentRef}>
          <ProjectDocumentUpload
            projectId={project.id}
            onUploadSuccess={async () => {
              await invalidate({
                resource: "media/projects",
                id: project.id,
                invalidates: ["detail"],
              });
              handleModalClose();
              setModalKey(modalKey + 1);
            }}
          />
        </div>
      </Modal>
    </KBContainer>
  );
};

const KBContainer = styled(Flex)<{ $isDragging?: boolean }>`
  flex-direction: column;
  gap: 20px;
  position: relative;
  min-height: 200px;
  border: 2px dashed
    ${(props) => (props.$isDragging ? "#1890ff" : "transparent")};
  transition: all 0.3s ease;

  .ant-list-item {
    border: 0;
  }

  .ant-list-item-meta-title {
  }
`;

const DropOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 144, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
