import { useTranslate } from "@refinedev/core";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Button, Flex, Typography } from "antd";
import { AuthPage } from "@refinedev/antd";
import { AiStarIcon, LibertifyLogo } from "components/icons";
import { useGo } from "@refinedev/core";
import { useAntTheme } from "hooks/useAntTheme";
import { AppContext } from "appContext";
import { ALLOW_REGISTRATION } from "../../constants";

const StyledLogo = styled.div``;
const LoginPageWrapper = styled.div`
  height: 100vh;
  //   background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  //   header {
  //     text-align: left;
  //   }

  #logo {
    position: absolute;
    top: 20px;
    left: 20px;
  }
`;

const CreateAccountButton = styled(Button)`
  // position: absolute;
  // top: 20px;
  // right: 20px;
  background: #fff;
  color: #000;
  width: 300px;
  margin-left: 30px;
  // border-radius: 20px;
  // padding: 0 20px;
  // height: 40px;
  // display: flex;
  // align-items: center;
`;

const Title = styled(Typography.Title)``;

const Subtitle = styled(Typography.Title)`
  background: linear-gradient(to right, #38d4ca, #241df3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const AuthForm = styled.div`
  text-align: left;
  .ant-row {
    height: auto !important;
  }
  .ant-card {
    box-shadow: none !important;
    padding: 0 !important;
    border: 0;
    width: 400px;
    max-width: 800px;
    background-color: transparent !important;
    .ant-card-head {
      display: none;
    }
  }
  .ant-card-body > * {
    display: none;
  }
  .ant-card-body > form {
    margin-top: 30px;
    display: block;
    label[for="email"],
    label[for="password"] {
      display: none;
    }
    .ant-form-item-control-input-content:last-child {
      text-align: center;
    }
    button[type="submit"] {
      border-radius: 100px;
      max-width: 300px;
      color: #000;
    }
  }
`;

export const LoginPage: React.FC = () => {
  const t = useTranslate();
  const go = useGo();
  const { theme } = useAntTheme();
  const { dispatch } = useContext(AppContext);
  // decode base64 email in url (cf authProvider login or email deeplink)
  let email;
  try {
    email = atob(
      new URLSearchParams(document.location.search).get("email") ?? ""
    );
  } catch (e) {
    email = "";
  }

  const redirectToSignup = () => {
    go({
      to: "/register",
    });
  };

  useEffect(() => {
    // make sure context is reset
    dispatch({ type: "accesses", payload: {} });
    dispatch({ type: "setUser", payload: null });
  }, []);

  return (
    <LoginPageWrapper>
      <div id="logo">
        <LibertifyLogo
          color={theme.colorText}
          fill={theme.colorText}
          height={40}
        />
      </div>

      <header>
        <AiStarIcon width={30} style={{ marginRight: 10 }} />
        <AiStarIcon width={50} />
        {/* <AiReadyMediaBrain/> */}
        <Title style={{ marginBottom: -30 }} level={1}>
          {t("studio.pages.Login.libertify")}
        </Title>
        <Subtitle level={2}>{t("studio.pages.Login.aiReadyMedia")}</Subtitle>
      </header>
      <AuthForm>
        <Flex justify="center" align="center" vertical>
          <AuthPage
            title={<></>}
            type="login"
            formProps={{ initialValues: { email } }}
          />
          {ALLOW_REGISTRATION && (
            <CreateAccountButton
              type="primary"
              onClick={redirectToSignup}
              shape="round"
              size="large"
            >
              {t("studio.pages.Login.createAnAccount")}
            </CreateAccountButton>
          )}
        </Flex>
      </AuthForm>
    </LoginPageWrapper>
  );
};

