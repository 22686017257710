import { Business, Department, Source, Usage } from "./types";

export const FIRST_STEP = "department";
export const LAST_STEP = "finish";
export const SINGLE_ANSWER_STEPS = [
  "department",
  "source",
  "business",
  "website",
];

export const EMPTY_ANSWER_ALLOWED = ["business"];
export const MULTIPLE_ANSWER_STEPS = ["usage"];
export const FREE_TEXT_ANSWERS = ["website", "organization"];

// That's the list of questions that aren't going to be sent to the
// onboarding endpoint: ATM that's only finish (which is there to facilitate uniform i18n string retrieval).
export const NON_STEP_QUESTIONS = ["finish"];

export const ORDERED_BUTTONS = {
  department: Object.values(Department),
  usage: Object.values(Usage),
  business: Object.values(Business),
  source: Object.values(Source),
};
export const I18N_PREFIX = "pages.onboarding";
