import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  Button,
  Descriptions,
  Flex,
  Grid,
  Image,
  Popconfirm,
  Space,
  Spin,
  Typography,
} from "antd";
import { MediaAsset } from "../types";
import { DescriptionsProps } from "antd/lib";
import {
  DownloadOutlined,
  SwapOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  LikeOutlined,
  DislikeTwoTone,
  EditOutlined,
  SaveOutlined,
  DeleteFilled,
  QuestionCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { JsonPreview } from "./JsonPreview";
import { createRef, useEffect, useState } from "react";
import { DateField } from "@refinedev/antd";
import {
  CanAccess,
  useApiUrl,
  useCustomMutation,
  useUpdate,
} from "@refinedev/core";
import { CDN_URL } from "../../../constants";
import { addDateTz } from "../utils";

const MediaAssetComponent = ({
  asset,
  mediaId,
  handleReview,
}: {
  asset: MediaAsset;
  mediaId?: string;
  handleReview?: (id: string, status: boolean) => void;
}) => {
  const t = useTranslate();
  const isJson = ["Script", "Keywords", "SpeechTimestamps"].includes(
    asset.asset_type
  );
  const isText = ["Text"].includes(asset.asset_type);

  const [data, setData] = useState<any>();
  const [updatedData, setUpdatedData] = useState<null | unknown>();

  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(true);
  const audioLinkRef = createRef<HTMLAnchorElement>();
  const { mutateAsync: mutateJson, isLoading: isUpdatingJsonAsset } = useUpdate(
    {}
  );
  const { mutateAsync, isLoading: isUpdatingAsset } = useCustomMutation({});
  const apiUrl = useApiUrl();

  const fetchJson = async () => {
    setLoading(true);
    const response = await (await fetch(asset.path)).json();
    setData(response);
    setLoading(false);
  };
  const fetchText = async () => {
    setLoading(true);
    // we store in s3 serialized json with unicode chars
    // cf https://stackoverflow.com/a/12869914
    const response = JSON.parse(await (await fetch(asset.path)).text());
    setData(response);
    setLoading(false);
  };

  const patchJson = async (value: any) => {
    const roots = {
      Keywords: "keywords",
      SpeechTimestamps: "timestamps",
      Script: "script",
    };
    const root =
      roots[asset.asset_type as "Keywords" | "SpeechTimestamps" | "Script"];
    await mutateJson({
      resource: "media/media/assets",
      meta: {
        method: "patch",
        urlParams: mediaId && new URLSearchParams({ media_id: mediaId }),
      },
      values: { [root]: value },
      id: asset.id,
    });
  };

  const handleJsonEdit = async (newValue: any) => {
    console.debug("updated", { newValue });
    setUpdatedData(newValue);
  };
  const startEdition = () => {
    setUpdatedData(null);
    setEditable(true);
  };
  const cancelEdition = () => {
    setUpdatedData(null);
    setEditable(false);
  };
  const saveEdition = () => {
    patchJson(updatedData);
  };
  const onDownload = () => {
    // if (asset.asset_type === "Speech") {
    //   return audioLinkRef.current?.click();
    // }
    window.open(asset.path, "_blank");
  };
  const handleRejectAsset = async () => {
    if (handleReview) {
      return handleReview(asset.id, false);
    }
    const mediaIdQuery = mediaId ? `?media_id=${mediaId}` : "";
    await mutateAsync({
      url: `${apiUrl}/media/media/assets/${asset.id}/reject${mediaIdQuery}`,
      method: "post",
      values: {},
    });
  };
  const handleAcceptAsset = async () => {
    if (handleReview) {
      return handleReview(asset.id, true);
    }
    const mediaIdQuery = mediaId ? `?media_id=${mediaId}` : "";
    await mutateAsync({
      url: `${apiUrl}/media/media/assets/${asset.id}/validate${mediaIdQuery}`,
      method: "post",
      values: {},
    });
  };

  useEffect(() => {
    setLoading(true);
    if (isJson) {
      fetchJson();
    } else if (isText) {
      fetchText();
    } else {
      setLoading(false);
    }
  }, [asset.path, isJson, isText]);

  if (loading) return <Spin />;

  return (
    <>
      {/* actions */}
      <Flex gap="small" justify="flex-start" align="flex-start" wrap="wrap">
        <Button
          icon={<DownloadOutlined />}
          size={"middle"}
          shape="round"
          onClick={() => onDownload()}
        >
          {t("media.components.MediaAssetPreview.download")}
        </Button>
        <Button
          icon={<CheckOutlined />}
          loading={isUpdatingAsset}
          size={"middle"}
          shape="round"
          onClick={handleAcceptAsset}
          disabled={asset.approved === true}
        >
          {t("media.components.MediaAssetPreview.accept")}
        </Button>
        <Popconfirm
          title={t("media.components.MediaAssetPreview.rejectTheAsset")}
          description={t("media.components.MediaAssetPreview.confirmReject")}
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={handleRejectAsset}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon={<DeleteFilled />}
            loading={isUpdatingAsset}
            size={"middle"}
            shape="round"
            danger
            disabled={asset.approved === false}
          >
            {t("media.components.MediaAssetPreview.reject")}
          </Button>
        </Popconfirm>

        {isJson && (
          <>
            {/* <Button
              disabled
              icon={<ThunderboltFilled />}
              size={"middle"}
              shape="round"
            >
              Reprocess
            </Button> */}
            {!editable && (
              <CanAccess resource="media_generation_steps" action="show">
                <Button
                  icon={<EditOutlined />}
                  size={"middle"}
                  shape="round"
                  type={editable ? "primary" : "default"}
                  onClick={startEdition}
                >
                  {t("media.components.MediaAssetPreview.edit")}
                </Button>
              </CanAccess>
            )}
            {editable && (
              <>
                <Button
                  icon={<SaveOutlined />}
                  size={"middle"}
                  shape="round"
                  onClick={saveEdition}
                  type={"primary"}
                  loading={isUpdatingJsonAsset}
                  disabled={updatedData === null}
                >
                  {updatedData !== null ? t("save") : t("editing")}
                </Button>
                <Button
                  icon={<EditOutlined />}
                  size={"middle"}
                  shape="round"
                  onClick={cancelEdition}
                >
                  {t("media.components.MediaAssetPreview.cancel")}
                </Button>
              </>
            )}
          </>
        )}
      </Flex>
      {isJson && (
        <div style={{ marginTop: 30, rowGap: 10, flexDirection: "column" }}>
          <JsonPreview
            data={data}
            editable={editable}
            onEdit={handleJsonEdit}
          />
        </div>
      )}
      {isText && data && <JsonPreview data={data} editable={false} />}
      {["Speech", "Music"].includes(asset.asset_type) && (
        <>
          {/* <a
              ref={audioLinkRef}
              style={{ display: "none" }}
              download={`${asset.id}_audio.wav`}
              href={`data:audio/wav; base64,${data.sound}`}
            >
              Download audio
            </a> */}
          <audio controls>
            <source src={asset.path} />
          </audio>
        </>
      )}
      {(asset.asset_type === "Video" || asset.asset_type === "Avatar") && (
        <div style={{ marginTop: 30 }}>
          <video controls height={400} preload="auto">
            <source src={asset.path} type="video/mp4" />
          </video>
        </div>
      )}
      {asset.asset_type === "Picture" && (
        <Image
          placeholder={
            asset.thumbnail ? (
              <Image
                preview={false}
                src={`${CDN_URL}/${asset.thumbnail}`}
                style={{
                  maxHeight: "calc(100vh - 350px)",
                  objectFit: "contain",
                  marginTop: 10,
                  cursor: "pointer",
                }}
              />
            ) : null
          }
          preview={{
            mask: null,
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                },
              }
            ) => (
              <Space
                size={40}
                className="toolbar-wrapper"
                style={{
                  backgroundColor: "rgba(0,0,0,.3)",
                  borderRadius: 100,
                  padding: 10,
                }}
              >
                <DownloadOutlined
                  onClick={onDownload}
                  style={{ fontSize: 30 }}
                />
                <SwapOutlined
                  rotate={90}
                  onClick={onFlipY}
                  style={{ fontSize: 30 }}
                />
                <SwapOutlined onClick={onFlipX} style={{ fontSize: 30 }} />
                <RotateLeftOutlined
                  onClick={onRotateLeft}
                  style={{ fontSize: 30 }}
                />
                <RotateRightOutlined
                  onClick={onRotateRight}
                  style={{ fontSize: 30 }}
                />
                <ZoomOutOutlined
                  disabled={scale === 1}
                  onClick={onZoomOut}
                  style={{ fontSize: 30 }}
                />
                <ZoomInOutlined
                  disabled={scale === 50}
                  onClick={onZoomIn}
                  style={{ fontSize: 30 }}
                />
              </Space>
            ),
          }}
          src={asset.path}
          style={{
            maxHeight: "calc(100vh - 350px)",
            objectFit: "contain",
            marginTop: 10,
            cursor: "pointer",
          }}
        />
      )}
    </>
  );
};

export const MediaAssetPreview = ({
  asset,
  mediaId,
  handleReview,
}: {
  asset: MediaAsset;
  mediaId?: string; // to pass when showing media detail
  handleReview?: (id: string, status: boolean) => void;
}) => {
  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const items: DescriptionsProps["items"] = [
    {
      key: "2",
      label: t("media.components.MediaAssetPreview.id"),
      children: (
        <Typography.Text copyable style={{ fontSize: 13 }}>
          {asset.id}
        </Typography.Text>
      ),
    },
    {
      key: "0",
      label: t("media.components.MediaAssetPreview.category"),
      children: <Typography.Text>{asset.category}</Typography.Text>,
    },
    {
      key: "1",
      label: t("media.components.MediaAssetPreview.subCategory"),
      children: (
        <Typography.Text copyable strong style={{ fontSize: 18 }}>
          {asset.sub_category}
        </Typography.Text>
      ),
    },

    {
      key: "3",
      label: t("media.components.MediaAssetPreview.source"),
      children: asset.source,
    },
    {
      key: "4",
      label: t("media.components.MediaAssetPreview.sourceType"),
      children: asset.source_type,
    },
    {
      key: "introduction",
      label: t("media.components.MediaAssetPreview.introduction"),
      children: asset.introduction,
    },
    {
      key: "aspect_ratio",
      label: t("media.components.MediaAssetPreview.aspectRatio"),
      children: asset.aspect_ratio,
    },
    {
      key: "height",
      label: t("media.components.MediaAssetPreview.height"),
      children: asset.height,
    },
    {
      key: "width",
      label: t("media.components.MediaAssetPreview.width"),
      children: asset.width,
    },
    // {
    //   key: "7",
    //   label: "Logo",
    //   children: (
    //     <img
    //       src={`https://cdn.libertify.com/env/images/tokens/${asset.sub_category}.png`}
    //     />
    //   ),
    // },
    // {
    //   key: "5",
    //   label: "Description",
    //   children: <Typography.Text copyable>{asset.description}</Typography.Text>,
    // },
    {
      key: "6",
      label: t("media.components.MediaAssetPreview.review"),
      children: (
        <Typography.Text>
          <Flex gap={"small"}>
            {asset.approved && asset.reviewed && <LikeOutlined />}
            {!asset.approved && asset.reviewed && <DislikeTwoTone />}
            {!asset.approved && !asset.reviewed && "Not reviewed"}
            {asset.reviewed && (
              <DateField value={addDateTz(asset.reviewed)} format="LLL" />
            )}
          </Flex>
        </Typography.Text>
      ),
    },
  ];

  const itemsMobile = [...items];
  itemsMobile.pop();

  return (
    <Flex gap="small" vertical align="flex-start" style={{ width: "100%" }}>
      {!isMobile && (
        <Descriptions
          title=""
          items={items}
          bordered
          layout="vertical"
          size="small"
          style={{ width: "100%" }}
        />
      )}
      <MediaAssetComponent
        asset={asset}
        mediaId={mediaId}
        handleReview={handleReview}
      />
      {isMobile && (
        <Typography.Text copyable>{asset.description}</Typography.Text>
      )}
      {isMobile && (
        <Descriptions title="" items={itemsMobile} bordered size="small" />
      )}
    </Flex>
  );
};
