import { LoadingOutlined } from "@ant-design/icons";
import { Flex, GlobalToken, Spin } from "antd";
import { positionToXY } from "helpers/utils";
import { useAntTheme } from "hooks/useAntTheme";
import { DocumentExtract } from "pages/media/types";
import { forwardRef, useImperativeHandle, useRef } from "react";
import {
  Highlight,
  IHighlight,
  NewHighlight,
  PdfHighlighter,
  PdfLoader,
  Popup,
  Tip,
} from "react-pdf-highlighter";
import styled, { CSSProperties } from "styled-components";

const HighlightPopup = ({
  comment,
}: {
  comment: { text: string; emoji: string };
}) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

export const PdfViewer = forwardRef(function PdfViewer(
  {
    highlights = [],
    url,
    withTip = false,
    onHighlightClick,
    activeHighlightId,
    scrollToHighlightYOffset = 0,
  }: {
    highlights?: IHighlight[];
    url: string;
    withTip?: boolean;
    onHighlightClick?: (sourceId: string) => void;
    activeHighlightId?: string;
    scrollToHighlightYOffset?: number;
  },
  ref: React.Ref<void>
) {
  const getHighlightById = (id: string) => {
    return highlights?.find((highlight) => highlight.id === id);
  };

  // const getNextId = () => String(Math.random()).slice(2);
  const { theme } = useAntTheme();
  const addHighlight = (highlight: NewHighlight) => {
    console.log("Saving highlight", highlight);
    // todo
    // setHighlights((value) => [{ ...highlight, id: getNextId() }, ...value]);
  };

  const scrolledPage = useRef(0);
  const scrolledPages = useRef<number[]>([]);
  const scrollViewerTo = useRef<(highlight: IHighlight) => void>(() => {});
  useImperativeHandle(ref, () => {
    return {
      scrollViewerTo(source: DocumentExtract) {
        const highlight = {
          position: {
            pageNumber: source.occurrences[0].page_number + 1,
            usePdfCoordinates: true,
            boundingRect: {
              ...positionToXY(source.occurrences[0].coordinates),
              width: 1000,
              height: 300,
              pageNumber: source.occurrences[0].page_number + 1,
            },
            rects: [],
          },
        };

        scrolledPage.current = highlight.position.pageNumber;
        scrolledPages.current.push(highlight.position.pageNumber);
        // highlightedIds.current.push(highlight.id);
        // console.debug(
        //   'scrolling to',
        //   highlight,
        //   highlight.position.pageNumber,
        //   scrolledPages.current
        // );
        scrollViewerTo.current({
          ...highlight,
          position: {
            ...highlight.position,
            boundingRect: {
              ...highlight.position.boundingRect,
              y1: highlight.position.boundingRect.y1 + scrollToHighlightYOffset,
            },
          },
        } as never);
        // console.debug(scrollViewerTo)
      },
    };
  }, [scrollViewerTo]);

  const parseIdFromHash = () =>
    global.document.location.hash.slice("#highlight-".length);

  const resetHash = () => {
    global.document.location.hash = "";
  };

  const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());

    if (highlight) {
      scrollViewerTo.current(highlight);
    }
  };

  return (
    <PdfLoader url={url} beforeLoad={<Spin indicator={<LoadingOutlined />} />}>
      {(pdfDocument) => (
        <PdfHighlighter
          pdfDocument={pdfDocument}
          enableAreaSelection={(event) => event.altKey}
          onScrollChange={resetHash}
          // pdfScaleValue="page-width"
          scrollRef={(scrollTo) => {
            scrollViewerTo.current = scrollTo;

            scrollToHighlightFromHash();
          }}
          onSelectionFinished={(
            position,
            content,
            hideTipAndSelection,
            transformSelection
          ) => (
            <Tip
              onOpen={transformSelection}
              onConfirm={(comment) => {
                addHighlight({ content, position, comment });
                hideTipAndSelection();
              }}
            />
          )}
          highlightTransform={(
            highlight,
            index,
            setTip,
            hideTip,
            viewportToScaled,
            screenshot,
            isScrolledTo
          ) => {
            const citationStylesAbsolute = {
              position: "absolute",
              zIndex: 1000,
              left: highlight?.position.boundingRect.left,
              top: highlight?.position.boundingRect.top - 15,
              color: "#000",
              background: "#22FF0B",
              fontSize: 12,
              padding: 3,
              borderRadius: 30,
              opacity: 1,
              fontWeight: "bold",
            };

            let highlightIndex = 0;

            if (highlights) {
              highlightIndex = highlights?.findIndex(
                (h) => h.id === highlight.id
              );
            }

            const component = (
              <HighlightWrapper
                theme={theme} // not working if not passing the theme again
                className={
                  activeHighlightId === highlight.id ? "highlight-selected" : ""
                }
              >
                <Highlight
                  onClick={() =>
                    onHighlightClick ? onHighlightClick(highlight.id) : {}
                  }
                  isScrolledTo={isScrolledTo}
                  position={highlight.position}
                  comment={highlight.comment}
                />
                <div style={citationStylesAbsolute as CSSProperties}>
                  {highlightIndex + 1}
                </div>
              </HighlightWrapper>
            );

            return withTip ? (
              <Popup
                popupContent={<HighlightPopup {...highlight} />}
                onMouseOver={(popupContent) =>
                  setTip(highlight, () => popupContent)
                }
                onMouseOut={hideTip}
                key={index}
              >
                {component}
              </Popup>
            ) : (
              component
            );
          }}
          highlights={highlights}
        />
      )}
    </PdfLoader>
  );
});
const HighlightWrapper = styled(Flex)<{ theme: GlobalToken }>`
  position: relative;
  &.highlight-selected .Highlight__part {
    background-color: ${({ theme }) => theme.colorPrimary}66 !important;
  }
`;
