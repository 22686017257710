import { List, Typography } from "antd";
import { ReactNode } from "react";

export const BinderClickable: React.FC<{
  id: string | undefined;
  value: ReactNode;
  onClick: (n: string) => void;
  actions?: React.ReactNode[];
}> = ({ id, value, onClick, actions }) => {
  const handleClick = () => {
    id && onClick && onClick(id);
  };

  return (
    <List.Item
      onClick={handleClick}
      key={id}
      style={{
        cursor: "pointer",
      }}
      actions={actions}
    >
      <Typography.Text

      //   style={{
      //     width: "100%",
      //     cursor: "pointer",
      //     border: "1px solid #ddd",
      //     padding: "8px",
      //     whiteSpace: "pre-wrap",
      //   }}
      >
        {value}
      </Typography.Text>
    </List.Item>
  );
};
