import styled from "styled-components";

export const StyledMarkdownFieldWrapper = styled.div`
  p {
    margin: 0;
    margin-bottom: 10px;
  }
  ul {
    padding: 0;
    padding-left: 10px;
  }

  ul li::marker {
    color: ${({ color }) => color};
    font-size: 1.2em;
  }
`;
