import { useTranslate } from "@refinedev/core";
import {
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useList,
  useOne,
} from "@refinedev/core";
import {
  Alert,
  Button,
  Flex,
  Form,
  GlobalToken,
  Input,
  List,
  Modal,
  Segmented,
  Select,
  SelectProps,
  Slider,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import {
  BrandKit,
  ContactResponse,
  MediaAsset,
  MediaProjectResponse,
  PresenterProfileResponse,
  ToneResponse,
  Transitions,
} from "pages/media/types";
import { secToHHMMSS, validateSelectedPages } from "pages/media/utils";
import {
  MAXIMUM_STORYBOARD_VIDEO_DURATION,
  SPEECH_WORDS_PER_MINUTE,
} from "pages/media/constants";
import { TONES } from "data/tones";
import styled from "styled-components";
import { LightbulbFilament } from "@phosphor-icons/react";
import { SimpleCreateContactWithoutLayout } from "pages/media/components/ContactWithPresenter/PageComponent";
import { BrandKitPreview } from "pages/media/components/BrandKitPreview";
import { useSupportedLanguages } from "../../../../hooks/useSupportedLanguages";
import { PresenterProfilePreview } from "pages/media/components/PresenterProfilePreview";
import { PlusOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { BrandKitEditWithoutLayout } from "pages/media/brandkit/edit";
import { EditOutlined } from "@ant-design/icons";

export type ProjectSettingsItemType =
  | "CallToActions"
  | "Duration"
  | "BrandKit"
  | "Tone"
  | "Brief"
  | "Language"
  | "Contacts"
  | "NewStoryboard"
  | "Disclaimers"
  | "PreserveDocument"
  | "Pages"
  | "Transitions";

export function ProjectSettingForm<T>(props: {
  value: T | undefined;
  options?: {
    label: string;
    value: T;
  }[];
  onClose: () => void;
  title: string;
  subtitle?: ReactNode;
  icon?: ReactNode;
  type: ProjectSettingsItemType;
  projectId: string;
  submitButtonLabel?: ReactNode;
  cancelButtonLabel?: ReactNode;
  onCancel?: () => void;
  cancellable?: boolean;
  onClickContactsButton?: () => void;
  show?: Record<"transitions", boolean>;
}) {
  const t = useTranslate();
  const API_URL = useApiUrl();
  const [value, setValue] = useState<T | undefined>(props.value);
  const { mutateAsync, isLoading: isMutateLoading } = useCustomMutation();
  const invalidate = useInvalidate();
  const {
    data: projectData,
    isSuccess: projectDataSuccess,
    isLoading: projectDataLoading,
  } = useOne<MediaProjectResponse>({
    resource: "media/projects",
    id: props.projectId,
    queryOptions: {
      enabled: !!props.projectId,
    },
  });
  const locked = projectData?.data
    ? projectData?.data?.settings.status === "Approved" &&
      [
        "Tone",
        "Duration",
        "Brief",
        "Language",
        "Pages",
        "PreserveDocument",
      ].includes(props.type)
    : true;
  const isValid = value !== null;
  const { supportedLanguagesOptions } = useSupportedLanguages();

  const requestNewMedia = async (
    projectId: string,
    contactIds: string[],
    language: string | undefined
  ) => {
    await mutateAsync({
      url: `${API_URL}/media/projects/${projectId}/media`,
      method: "post",
      values: contactIds.map((contactId) => ({
        language,
        contact_id: contactId,
      })),
    });
  };

  const handleSave = async () => {
    let values = {};
    let url = `${API_URL}/media/projects/${props.projectId}/settings`;

    if (props.type === "Duration") {
      values = {
        duration: value,
      };
    } else if (props.type === "CallToActions") {
      values = {
        cta_group_id: value,
      };
    } else if (props.type === "Brief") {
      values = { description: value };
      url = `${API_URL}/media/projects/${props.projectId}`;
    } else if (props.type === "BrandKit") {
      values = { brand_kit_id: value };
    } else if (props.type === "Language") {
      values = { language: value };
    } else if (props.type === "Tone") {
      values = { tone_id: value };
    } else if (props.type === "PreserveDocument") {
      values = { preserve_original_doc: value };
    } else if (props.type === "Pages") {
      //(value as string)?.length > 0 ? value : null
      values = { selected_pages: value };
    } else if (props.type === "Transitions") {
      values = { transitions: value };
    }

    if (props.type === "Contacts") {
      const language = projectData?.data.settings.language;
      if (language) {
        // when settings are approved, we can make media creation requests
        if (
          projectData.data?.settings.status === "Approved" &&
          Array.isArray(value)
        ) {
          const existingMedia = projectData.data.media ?? [];
          const existingContacts = existingMedia.map((media) => ({
            contactId: media.contact_id,
            language: media.language,
          }));
          const newContacts = value.filter(
            (contactId) =>
              !existingContacts.some(
                (media) =>
                  media.contactId === contactId && media.language === language
              )
          );

          await requestNewMedia(
            props.projectId,
            newContacts as string[],
            language
          );
        } else {
          // MED-1044: we can't get contacts from media, so we get them
          // from the select (i.e. we're creating a new project)
          await mutateAsync({
            url,
            method: "patch",
            values: { contact_id: value },
          });
        }
      } else {
        console.error("Language is undefined in projectData.settings");
      }
    } else if (props.type === "NewStoryboard") {
      await mutateAsync({
        url: `${API_URL}/media/projects/${props.projectId}/storyboards/${value}`,
        method: "post",
        values: {},
      });
    } else {
      // other settings
      await mutateAsync({
        url,
        method: "patch",
        values,
      });
    }

    invalidate({
      resource: "media/projects",
      id: props.projectId,
      invalidates: ["detail"],
    });

    props.onClose();
  };

  const handleChange = (value: T) => {
    setValue(value);
  };

  const [form] = Form.useForm();
  return (
    <Form onFinish={handleSave} form={form}>
      <Flex vertical gap={0}>
        <Space size={"middle"} align="center">
          {props.icon}
          <Typography.Text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            {props.title}
          </Typography.Text>
        </Space>
        <Flex vertical gap={30}>
          <Typography.Text type="secondary">{props.subtitle}</Typography.Text>
          {props.type === "CallToActions" && (
            <ProjectSettingsFormCallToActions<T>
              value={value}
              onChange={handleChange}
              project={projectData?.data}
            />
          )}
          {props.type === "Duration" && (
            <ProjectSettingsFormDuration<T>
              value={value}
              onChange={handleChange}
            />
          )}
          {props.type === "Tone" && (
            <ProjectSettingsFormTone<T> value={value} onChange={handleChange} />
          )}
          {props.type === "BrandKit" && (
            <ProjectSettingsFormBrandKit<T>
              project={projectData?.data}
              value={value}
              onChange={handleChange}
              showTransitions={props.show?.transitions === true}
            />
          )}
          {props.type === "Transitions" && (
            <ProjectSettingsFormTransitions<T>
              name={"transitions"}
              valuePropName="checked"
              value={value as T} // Assert value is of type T
              initialValue={value as T}
              onChange={handleChange}
            />
          )}
          {props.type === "Brief" && (
            <ProjectSettingsFormBrief<T>
              value={value}
              onChange={handleChange}
            />
          )}
          {props.type === "Language" && (
            <ProjectSettingsFormLanguage<T>
              value={value}
              onChange={handleChange}
              options={supportedLanguagesOptions}
            />
          )}
          {props.type === "Contacts" && (
            <ProjectSettingsFormContacts<T>
              project={projectData?.data}
              value={value}
              onChange={handleChange}
              items={[]}
              initialValue={props.value}
            />
          )}
          {props.type === "Pages" && (
            <ProjectSettingsFormSelectedPages<T>
              value={value}
              onChange={handleChange}
            />
          )}
          {props.type === "PreserveDocument" && (
            <ProjectSettingsFormPreserveDocument<T>
              label={t(
                "projects.components.ProjectSettingForm.contentExtraction"
              )}
              name={"preserve_document"}
              valuePropName="checked"
              value={value as T} // Assert value is of type T
              initialValue={value as T}
              onChange={handleChange}
            />
          )}

          {props.type === "NewStoryboard" && projectData?.data && (
            <ProjectSettingsFormLanguage<T>
              value={value}
              onChange={handleChange}
              options={supportedLanguagesOptions.filter(
                (language: { value: string }) =>
                  !projectData?.data?.storyboards
                    .map((sb) => sb.language)
                    .includes(language.value)
              )}
            />
          )}

          <Flex justify="space-between">
            {locked ? (
              // dummy to keep cancel on right side
              <div></div>
            ) : (
              <Button
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                loading={isMutateLoading}
                disabled={isMutateLoading || !isValid}
              >
                {props.submitButtonLabel ?? t("buttons.save")}
              </Button>
            )}
            {props.cancellable !== false && (
              <Button
                onClick={props.onCancel ?? props.onClose}
                type="text"
                shape="round"
                size="large"
              >
                {props.cancelButtonLabel ?? t("buttons.cancel")}
              </Button>
            )}
            {/* {props.secondaryButton} */}
          </Flex>
        </Flex>
      </Flex>
    </Form>
  );
}

function ProjectSettingsFormCallToActions<T>(props: {
  value: T | undefined;
  onChange: (value: T) => void;
  project?: MediaProjectResponse;
}) {
  const {
    organizationAssets: { data, isLoading },
  } = useMediaAssetsStorage({
    organizationId: props.project?.organization_id,
    assetType: "CallToActions",
    enabled: Boolean(props.project?.organization_id),
  });

  return (
    <StyledListWrapper>
      <List<MediaAsset>
        split={false}
        size="small"
        loading={isLoading}
        dataSource={data?.data}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            onClick={() => props?.onChange(item.id as T)}
            className={props.value === item.id ? "active" : ""}
            style={{
              borderRadius: 6,
              marginBottom: 10,
              paddingLeft: 10,
            }}
          >
            {item.description || item.id}
          </List.Item>
        )}
      />
    </StyledListWrapper>
  );
}
function ProjectSettingsFormBrandKit<T>(props: {
  project: MediaProjectResponse;
  value: T | undefined;
  onChange: (value: T) => void;
  showTransitions?: boolean;
}) {
  const [brandKitModal, setBrandKitModal] = useState(false);
  const [saveCallback, setSaveCallback] = useState<() => Promise<void>>(
    () => () => Promise.resolve()
  );
  const { data, isLoading } = useList<BrandKit>({
    resource: `media/${props.project?.organization_id}/brand_kits`,
    queryOptions: {
      enabled: !!props.project?.organization_id,
    },
  });
  const t = useTranslate();

  const {
    mutateAsync: setProjectsTransitionType,
    isLoading: isLoadingProjectTransitionType,
  } = useCustomMutation();

  const invalidate = useInvalidate();
  const handleTransitionChange = async (value: string) => {
    await setProjectsTransitionType({
      url: `media/projects/${props.project.id}/settings`,
      method: "patch",
      values: {
        transitions: value,
      },
    });
    invalidate({
      resource: "media/projects",
      id: props.project.id,
      invalidates: ["detail"],
    });
  };

  return (
    <>
      <StyledListWrapper>
        <List
          size="small"
          split={false}
          loading={isLoading}
          dataSource={data?.data}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              onClick={() => props?.onChange(item.id as T)}
              className={props.value === item.id ? "active" : ""}
              style={{
                borderRadius: 6,
                marginBottom: 10,
                paddingLeft: 10,
              }}
              actions={[
                <Button
                  key="edit"
                  onClick={() => {
                    setBrandKitModal(true);
                  }}
                  type="text"
                  icon={<EditOutlined />}
                ></Button>,
              ]}
            >
              <List.Item.Meta
                avatar={<BrandKitPreview brandkit={item} showName={false} />}
                title={item.name || item.id}
              />
            </List.Item>
          )}
        />
        {/* custom UI to add transition flags (Steve) */}
        {props.showTransitions && (
          <Flex vertical style={{ marginTop: 40 }} gap={10}>
            <Flex vertical>
              <Typography.Text>
                {t("projects.components.ProjectSettings.transition")}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t("projects.components.ProjectSettings.chooseTransition")}
              </Typography.Text>
            </Flex>
            <ProjectSettingsFormTransitions<Transitions>
              loading={isLoadingProjectTransitionType}
              onChange={handleTransitionChange}
              value={props.project.settings.transitions ?? "Basic"}
              name={"transitions"}
              valuePropName="checked"
              initialValue={props.project.settings.transitions ?? "Basic"}
            />
          </Flex>
        )}
      </StyledListWrapper>
      <Modal
        onCancel={() => setBrandKitModal(false)}
        afterOpenChange={(open) => {
          if (!open) {
            setBrandKitModal(false);
          }
        }}
        okText={t("buttons.save")}
        onOk={async () => {
          try {
            await saveCallback();
          } catch {
            return;
          }
          setBrandKitModal(false);
        }}
        title={t("projects.components.ProjectSettingForm.editBrandkit", {
          brandkitName: data?.data.find(
            (brandkit) => brandkit.id === props.value
          )?.name,
        })}
        width={"85vw"}
        open={brandKitModal}
        styles={{
          body: {
            display: "flex",
          },
        }}
      >
        <BrandKitEditWithoutLayout
          brandKitId={props.value as string}
          organizationId={props.project?.organization_id}
          onSuccess={() => {}}
          setSaveCallback={setSaveCallback}
          // https://stackoverflow.com/a/15885486/7459528
          style={{
            paddingRight: "unset",
            flex: "1 0 65%",
          }}
          mockupStyle={{
            transform: "translateZ(0) scale(0.9)",
            width: "unset",
            maxHeight: "100%",
            top: 140,
            position: "sticky",
            margin: "0 auto",
            flex: "0 1 380px",
            marginRight: -24,
          }}
        />
      </Modal>
    </>
  );
}

function ProjectSettingsFormTone<T>(props: {
  value: T | undefined;
  onChange: (value: T) => void;
}) {
  const t = useTranslate();
  const { data, isLoading } = useList<ToneResponse>({
    resource: `media/tones`,
  });

  const items = data?.data as {
    id: string;
    description: string;
  }[];
  const knownTones = Object.keys(TONES);
  const sortedItems = items?.sort(function (a, b) {
    return knownTones.indexOf(a.id) - knownTones.indexOf(b.id);
  });

  return (
    <StyledListWrapper>
      <List<{
        id: string;
      }>
        size="small"
        split={false}
        loading={isLoading}
        dataSource={sortedItems}
        renderItem={(item) => {
          const Icon = TONES[item.id]?.icon;
          const toneData = t(`src.data.tones.${item.id}`, {
            returnObjects: true,
            defaultValue: {},
          }) as unknown as { name: string; characteristics: string[] };

          return (
            <List.Item
              key={item.id}
              onClick={() => props.onChange(item.id as T)}
              className={props.value === item.id ? "active" : ""}
              style={{
                borderRadius: 6,
                marginBottom: 10,
                paddingLeft: 10,
              }}
            >
              <List.Item.Meta
                avatar={Icon ? <Icon size={26} /> : null}
                title={toneData ? toneData?.name : item.id}
                description={toneData?.characteristics?.join(" • ") ?? ""}
              />
            </List.Item>
          );
        }}
      />
    </StyledListWrapper>
  );
}

function ProjectSettingsFormBrief<T>(props: {
  value: T | undefined;
  onChange: (value: T) => void;
}) {
  const t = useTranslate();
  return (
    <Flex vertical gap={60}>
      <Input.TextArea
        value={props.value as string}
        onChange={(e) => props.onChange(e.target.value as T)}
        rows={4}
      />
      <Alert
        type="info"
        showIcon
        icon={<LightbulbFilament size={40} />}
        description={
          <Flex vertical gap={10}>
            <Typography.Text>
              {t("projects.components.ProjectSettingForm.example")}
            </Typography.Text>
            <Typography.Text type="secondary" copyable>
              {t("projects.components.ProjectSettingForm.weWantTo")}
            </Typography.Text>
          </Flex>
        }
      />
    </Flex>
  );
}
function ProjectSettingsFormLanguage<T>(props: {
  value: T | undefined;
  onChange: (value: T) => void;
  options: {
    value: string;
    label: string;
    description?: string;
  }[];
}) {
  // const items = languages;
  return (
    <StyledListWrapper>
      <List
        size="small"
        split={false}
        dataSource={props.options}
        renderItem={(item) => (
          <List.Item
            onClick={() => props.onChange(item.value as T)}
            className={props.value === item.value ? "active" : ""}
            style={{
              borderRadius: 6,
              marginBottom: 10,
              paddingLeft: 10,
              cursor: "pointer",
            }}
          >
            {/* avatar={item.icon} */}
            <List.Item.Meta title={item.label} description={item.description} />
          </List.Item>
        )}
      />
    </StyledListWrapper>
  );
}

const ProjectSettingsFormSelectedPages = <T,>({
  onChange,
  value,
}: {
  onChange: (value: T) => void;
  value?: T;
}) => {
  const t = useTranslate();
  const validator = (_: any, value: string) => {
    // accept empty values to keep all pages
    if (!value || value.length === 0) return Promise.resolve(true);

    const result = validateSelectedPages(value);
    if (result === false) {
      return Promise.reject(
        new Error(
          t("projects.components.ProjectCreateWizard.enterValidNumbers")
        )
      );
    }
    return Promise.resolve(result);
  };
  return (
    <>
      <Form.Item
        initialValue={value as string}
        style={{ marginBottom: 0 }}
        name="selectedPages"
        rules={[{ validator }]}
      >
        <Input
          size="large"
          onChange={(event) => onChange(event.target.value as T)}
          placeholder={t(
            "projects.components.ProjectCreateWizard.numberOfPages"
          )}
        />
      </Form.Item>
      <Alert
        type="info"
        showIcon
        icon={<LightbulbFilament size={40} />}
        description={
          <Flex vertical gap={10}>
            <Typography.Text>
              {t("projects.components.ProjectSettingForm.keepEmptyTo")}
            </Typography.Text>
            <Typography.Text type="secondary">
              {t("projects.components.ProjectSettingForm.example12510Numbers")}
            </Typography.Text>
          </Flex>
        }
      />
    </>
  );
};

function ProjectSettingsFormDuration<T>(props: {
  value: T | undefined;
  onChange: (value: T) => void;
}) {
  const t = useTranslate();
  return (
    <div style={{ marginTop: 30, marginBottom: 60 }}>
      <Slider
        value={props.value as number}
        max={MAXIMUM_STORYBOARD_VIDEO_DURATION}
        min={30}
        step={10}
        onChange={(value) => props.onChange(value as T)}
        tooltip={{
          placement: "bottom",
          color: "transparent",
          getPopupContainer: (triggerNode) => {
            return triggerNode;
          },
          overlayStyle: { background: "none" },
          open: true,
          formatter: (value) => (
            <Flex vertical style={{ textAlign: "center" }}>
              <Typography.Text strong>
                {value ? secToHHMMSS(value) : ""}
              </Typography.Text>
              {value && (
                <Typography.Text type="secondary">
                  ≈ {Math.floor((value / 60) * SPEECH_WORDS_PER_MINUTE)}{" "}
                  {t("projects.components.ProjectSettingForm.words")}
                </Typography.Text>
              )}
            </Flex>
          ),
        }}
      />
    </div>
  );
}

type TagRender = SelectProps["tagRender"];

function ProjectSettingsFormContacts<T>(props: {
  value: T | undefined;
  initialValue: T | undefined;
  onChange: (value: T) => void;
  items: {
    value: string;
    name: string;
    description: string;
  }[];
  project?: MediaProjectResponse;
}) {
  const t = useTranslate();
  const { data, isLoading } = useList<ContactResponse>({
    resource: `media/${props.project?.organization_id}/contacts`,
    queryOptions: {
      enabled: !!props.project?.organization_id,
    },
  });
  const [newContact, setNewContact] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>();

  const tagRender: TagRender = (propsTag) => {
    const { label, value, closable, onClose } = propsTag;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        style={{ fontSize: 14 }}
        onMouseDown={onPreventMouseDown}
        closable={
          // prevent removing current values
          closable && !(props.initialValue as string[])?.includes(value)
        }
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };
  const options =
    data?.data.map((contact) => {
      return {
        label: `${contact.firstname} ${contact.lastname}`,
        value: contact.id,
        // already selected
        disabled: (props.initialValue as string[])?.includes(contact.id),
      };
    }) ?? [];
  const { data: presenter, isLoading: isLoadingPresenter } =
    useOne<PresenterProfileResponse>({
      resource: `media/${props.project?.organization_id}/presenter_profiles`,
      id: data?.data?.[0].presenter_id,
      queryOptions: {
        enabled: !!data?.data,
      },
    });
  useEffect(() => {
    if (isLoadingPresenter) return;
    const hasOnlyOneContact = data.data.length === 1;
    const presenterIsDefault =
      presenter.data?.organization_id !== props?.project.organization_id;
    const lacksValidContact = hasOnlyOneContact && presenterIsDefault;
    setContactsModal(!!lacksValidContact);
  }, [presenter?.data]);
  const canSelectMultiple = props.project?.settings.status === "Approved";
  const [contactsModal, setContactsModal] = useState(false);
  const [newContactSaveCallback, setNewContactSaveCallback] = useState<
    () => void
  >(() => {});
  const selectedContact = data?.data.find(
    (contact) => contact.id === (selectedContactId ?? props.value)
  );

  return (
    <>
      {!newContact && (
        <Flex vertical gap={30}>
          <Flex vertical justify="start" gap={10}>
            <Select
              size="large"
              loading={isLoading}
              options={options}
              tagRender={tagRender}
              mode={canSelectMultiple ? "multiple" : undefined}
              showSearch
              value={props.value}
              onChange={(value) => {
                props.onChange(value as unknown as T);
                setSelectedContactId(value as string);
              }}
              style={{ width: "100%" }}
              optionFilterProp="label"
            />
            <Space>
              <Button
                size="small"
                onClick={() => setContactsModal(true)}
                shape="round"
                icon={<PlusOutlined />}
                type="text"
              >
                {t("projects.components.ProjectSettingForm.orCreateA")}
              </Button>
            </Space>
          </Flex>
          <ContactPreviewWrapper contact={selectedContact} />
        </Flex>
      )}
      <Modal
        onCancel={() => setContactsModal(false)}
        afterOpenChange={(open) => {
          if (!open) {
            setContactsModal(false);
          }
        }}
        okText={t("buttons.save")}
        onOk={() => {
          setContactsModal(false);
          return newContactSaveCallback();
        }}
        title={t("projects.components.ProjectSettingForm.addANew")}
        width={"85vw"}
        open={contactsModal}
      >
        <SimpleCreateContactWithoutLayout
          onSuccess={(contact) => props.onChange(contact as unknown as T)}
          setSaveCallback={setNewContactSaveCallback}
        />
      </Modal>
    </>
  );
}

const ContactPreviewWrapper = ({ contact }: { contact?: ContactResponse }) => {
  if (!contact) return;

  return (
    <PresenterProfilePreview
      presenterId={contact.presenter_id}
      organizationId={contact.organization_id}
      contact={contact}
      showContactInfo={true}
    />
  );
};

function ProjectSettingsFormPreserveDocument<T>(props: {
  label: string;
  name: string;
  valuePropName: string;
  value: T;
  initialValue?: T;
  onChange: (value: T) => void;
}) {
  const t = useTranslate();
  const handleChange = (value: boolean) => {
    props.onChange(value as T);
  };
  return (
    <StyledSegmentedWrapper>
      <Form.Item
        name={props.name}
        valuePropName={props.valuePropName}
        initialValue={props.initialValue}
      >
        <Segmented<boolean>
          block
          options={[
            {
              label: t("projects.components.ProjectSettings.extractive"),
              value: true,
            },
            {
              label: t("projects.components.ProjectSettings.abstractive"),
              value: false,
            },
          ]}
          onChange={handleChange}
          value={Boolean(props.value)} // converts also null (default backend) to false
        />
      </Form.Item>
    </StyledSegmentedWrapper>
  );
}

function ProjectSettingsFormTransitions<T>(props: {
  name: string;
  valuePropName: string;
  value: T;
  initialValue?: T;
  onChange: (value: T) => void;
  loading?: boolean;
}) {
  const t = useTranslate();
  const handleChange = (value: string) => {
    props.onChange(value as T);
  };
  return (
    <StyledSegmentedWrapper>
      <Form.Item
        name={props.name}
        valuePropName={props.valuePropName}
        initialValue={props.initialValue}
      >
        <Spin spinning={props.loading === true}>
          <Segmented<Transitions>
            block
            options={[
              {
                label: t("projects.components.ProjectSettings.Basic"),
                value: "Basic",
              },
              {
                label: t("projects.components.ProjectSettings.NoTransition"),
                value: "NoTransition",
              },
            ]}
            onChange={handleChange}
            value={props.value as Transitions}
          />
        </Spin>
      </Form.Item>
    </StyledSegmentedWrapper>
  );
}

const StyledListWrapper = styled.div<{
  theme: GlobalToken;
}>`
  .ant-list-item {
    border: 2px solid transparent;
    box-sizing: border-box;
    .ant-list-item-meta-description {
      padding-right: 5px;
    }
    &:hover {
      cursor: pointer;
      transition: 500ms;
      background-color: ${(props) => props.theme.colorBgElevated};
    }
    &.active {
      border-color: ${(props) => props.theme.colorPrimary};
      background-color: ${(props) => props.theme.colorPrimaryBg};
    }
  }
`;

const StyledSegmentedWrapper = styled.div<{
  theme: GlobalToken;
}>`
  .ant-segmented {
    background: ${({ theme }) => theme.colorBgContainer};
    border: 1px solid ${({ theme }) => theme.colorBorder};
    padding: 0;
  }
  .ant-segmented-item {
    padding: 2px 0;
    color: ${({ theme }) => theme.colorTextSecondary};
    border: 1px solid transparent;
  }
  .ant-segmented-thumb {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
  }
  .ant-segmented-item-selected {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    // transition: color 0.2s;
    color: ${({ theme }) => theme.colorText};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
  }
`;
