import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Authenticated, CanAccess, useList, useOne } from "@refinedev/core";
import { Divider, Form, Input, Result, Select, Space } from "antd";
import { Create, ShowButton, useForm, useSelect } from "@refinedev/antd";
import { useState } from "react";
import { useDebounce } from "use-debounce";

interface StringByString {
  [key: string]: string;
}

interface IMedia {
  template_id: string;
  inputs: StringByString;
}
interface ITemplate {
  id: string;
  name: string;
}

const AssetInput = ({ name }: { name: string }) => {
  const t = useTranslate();
  const [assetTickerQuery, setAssetTickerQuery] = useState<
    string | undefined
  >();
  const [debouncedAssetTickerQuery] = useDebounce(assetTickerQuery, 500);
  const [assetClass, setAssetClass] = useState<string>("Crypto");

  const handleSearchAsset = (query: string) => {
    setAssetTickerQuery(query);
  };
  const onSearchAsset = (value: React.SetStateAction<string>) => {
    value && setAssetTickerQuery(value as string);
  };

  const { data: assetsData, isFetching: isFetchingAssets } = useList<ITemplate>(
    {
      resource: `api/assets/${assetClass}/like/${debouncedAssetTickerQuery}`,
      queryOptions: {
        enabled: !!debouncedAssetTickerQuery,
        retry: !!debouncedAssetTickerQuery,
      },
    }
  );

  const assetsOptions = assetsData?.data
    ? (assetsData?.data as any)?.items?.map((x: any) => ({
        value: x.ticker,
        label: x.name,
        image: x.image,
      }))
    : [];

  return (
    <>
      <Divider orientation="left">{name}</Divider>
      <Form.Item
        // label="Asset Class"
        name={`${name}_class`}
        initialValue={"Crypto"}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder={t("media.media.create.selectAnAsset")}
          style={{ width: 300 }}
          options={[
            { value: "Equity", label: t("media.media.create.equity") },
            { value: "Crypto", label: t("media.media.create.crypto") },
          ]}
          onChange={(value) => setAssetClass(value)}
        />
      </Form.Item>
      <Form.Item
        // label="Asset Ticker"
        name={`${name}_ticker`}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          onSearch={onSearchAsset}
          onChange={({ value }) => handleSearchAsset(value)}
          placeholder={t("media.media.create.assetTicker")}
          style={{ width: 300 }}
          key={"asset" + assetClass}
          options={assetsOptions}
          showSearch
          allowClear
          loading={isFetchingAssets}
          optionRender={(option: any) => {
            return (
              <Space>
                <span role="img" aria-label={option.data.label}>
                  <img
                    alt={option.data.label}
                    src={option.data.image}
                    height={22}
                    style={{ marginRight: 10 }}
                  />
                </span>
                {option.label}
              </Space>
            );
          }}
        />
      </Form.Item>
    </>
  );
};
export const MediaCreate: React.FC = () => {
  const t = useTranslate();
  const [templateId, setTemplateId] = useState<any | undefined>();
  const [mediaId, setMediaId] = useState<string | undefined>();

  const { data: template } = useOne<ITemplate>({
    resource: "media/templates",
    id: templateId,
  });
  const { selectProps: selectTemplateProps } = useSelect<ITemplate>({
    resource: "media/templates",
    optionLabel: "name",
  });

  const { formProps, onFinish, formLoading, form, saveButtonProps } =
    useForm<IMedia>({
      action: "create",
      redirect: false, // "show",
      meta: {
        method: `post`,
      },
      resource: "media/media",
      onMutationSuccess: (data: any) => {
        setMediaId(data?.data?.id);
      },
      // onMutationError: (data, variables, context) => {
      //   open?.({
      //     key: "create-fund",
      //     type: "error",
      //     message: data?.response?.data?.detail?.message,
      //     description: `Error ${data?.response?.status}`,
      //     undoableTimeout: 5,
      //     cancelMutation: () => {
      //       // when undo button is clicked, run this callback
      //       close?.("create-fund");
      //     },
      //   });
      // },
      // errorNotification: false,
    });

  const inputs = (template?.data as any)?.parameters?.inputs ?? {};
  // const inputs = {
  //   asset: {
  //     name: "asset",
  //     type: "Field",
  //     input_type: "Asset",
  //   },
  //   asset2: {
  //     name: "asset2",
  //     type: "Field",
  //     input_type: "Asset",
  //   },
  // };

  const handleSubmit = (values: any): void => {
    // add dynamic inputs values
    const inputsValues = Object.entries(inputs).map(
      ([key, input]: [string, any]) => {
        if (input.input_type === "Asset") {
          return [
            key,
            {
              type: input.input_type,
              ticker: values[`${input.name}_ticker`],
              asset_class: values[`${input.name}_class`],
            },
          ];
        }
        return [key, { type: input.input_type }];
      }
    );
    const request = {
      template_id: values.template_id,
      inputs: Object.fromEntries(inputsValues),
    };

    onFinish(request);
  };

  return (
    <Authenticated key="media_create">
      <CanAccess
        resource="media_media"
        action="create"
        fallback={
          <Result
            status={403}
            title={t("media.media.create.accessForbidden")}
          />
        }
      >
        <Create saveButtonProps={saveButtonProps}>
          <Form
            {...formProps}
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item
              label={t("media.media.create.template")}
              name="template_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder={t("media.media.create.selectATemplate")}
                style={{ width: 300 }}
                {...selectTemplateProps}
                onChange={(value) => setTemplateId(value)}
              />
            </Form.Item>

            {/* dynamic inputs */}
            {Object.entries(inputs).map(([key, input]: [string, any]) => {
              if (input.input_type === "Asset") {
                return <AssetInput name={input.name} />;
              }
              return (
                <Form.Item
                  label={input.name}
                  name={input.name}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              );
            })}
          </Form>
          {mediaId && (
            <>
              <Divider></Divider>
              <ShowButton size="large" recordItemId={mediaId}>
                {t("media.media.create.showMedia")}
              </ShowButton>
            </>
          )}
        </Create>
      </CanAccess>
    </Authenticated>
  );
};
