import { useList } from "@refinedev/core";
import {
  FaceProfileCreationResponse,
  FaceProfileResponse,
  PresenterProfileResponse,
} from "pages/media/types";
import { useOrganization } from "hooks/useOrganization";
import { useEffect, useMemo, useRef } from "react";
import { sortByCreated } from "helpers/utils";
import { useContactPage } from "./useContactPage";
const useFaceSelector = (
  selectedFace?: FaceProfileResponse,
  theClonedFace?: FaceProfileResponse,
  clonedFace?: FaceProfileCreationResponse,
  existingPresenter?: PresenterProfileResponse | null
) => {
  const { organization } = useOrganization({});
  const isEdit = useContactPage().id !== undefined;
  const { data, isLoading } = useList<FaceProfileResponse>({
    resource: `media/${organization?.id}/face_profiles`,
  });
  const origSelectedFace = useRef<{
    face?: FaceProfileResponse;
    inserted: boolean;
  }>({ face: selectedFace, inserted: false });

  useEffect(() => {
    if (!origSelectedFace.current.face) {
      origSelectedFace.current = { face: selectedFace, inserted: false };
    }
  }, [selectedFace]);

  const { data: premadeFacesWithoutOrg, isLoading: isLoadingPremadeFaces } =
    useList<FaceProfileResponse>({
      resource: `media/face_profiles/premade`,
    });

  const faces = data?.data ?? [];

  const { premadeFaces, customFaces } = useMemo(
    () => splitByCategoryAndSortByCreated(faces),
    [data?.data.length]
  );

  if (!data?.data && !premadeFacesWithoutOrg?.data)
    return {
      isLoading: isLoading || isLoadingPremadeFaces,
      customFaces: [],
      premadeFaces: [],
    };

  // On cloning face insert dummy face, which will show a portrait with a
  // spinner and start polling. Once we have the cloned asset, replace the spinner with the preview with the actual image. Used to avoid refetching the whole list. Could be refactored to poll inside preview instead like voice.
  if (clonedFace && clonedFace.face_profile_id !== customFaces[0]?.id) {
    customFaces.unshift({
      id: clonedFace.face_profile_id,
      name: "",
      category: "Custom",
      custom_asset_path: "",
      created: "new", // Special case to always sort this on top
      organization_id: "",
      owner_id: 0,
    });
  } else if (
    theClonedFace?.custom_asset_path &&
    customFaces[0]?.custom_asset_path === ""
  ) {
    customFaces[0] = theClonedFace;
  }

  const allPremadeFaces = [
    ...premadeFaces,
    ...(premadeFacesWithoutOrg?.data ?? []),
  ];
  const unshiftedPremadeFaces =
    selectedFace &&
    selectedFace?.category === "Premade" &&
    !origSelectedFace.current?.inserted
      ? unshiftFaceFromCurrentlyEditedContact({
          list: allPremadeFaces,
          origSelectedFace,
          existingPresenter,
          isEdit,
        })
      : allPremadeFaces;
  const unshiftedCustomFaces =
    selectedFace &&
    selectedFace?.category === "Custom" &&
    !origSelectedFace.current?.inserted
      ? unshiftFaceFromCurrentlyEditedContact({
          list: customFaces,
          origSelectedFace,
          existingPresenter,
          isEdit,
        })
      : customFaces;
  return {
    isLoading: isLoading || isLoadingPremadeFaces,
    items: {
      customFaces: unshiftedCustomFaces,
      premadeFaces: unshiftedPremadeFaces,
    },
  };
};

export default useFaceSelector;
/**
 * Insert the face from the currently edited contact to the top of the list.
 * Done only once on load.
 *
 * @param premadeFaces A list of premade faces
 * @param premadeFacesWithoutOrg A list of premade faces without the
 * organization
 * @param customFaces A list of custom faces
 * @param origSelectedFace So that we don't do it more than once
 * @returns
 */
interface UnshiftFaceParams {
  list: FaceProfileResponse[];
  origSelectedFace?: React.MutableRefObject<
    { face?: FaceProfileResponse; inserted: boolean } | undefined
  >;
  existingPresenter?: PresenterProfileResponse | null;
  isEdit?: boolean;
}

// Modifies the passed list to insert the currently selected or cloned face on
// top of the list. This is done only once on load.
function unshiftFaceFromCurrentlyEditedContact({
  list,
  origSelectedFace,
  existingPresenter,
  isEdit,
}: UnshiftFaceParams) {
  if (
    isEdit &&
    origSelectedFace?.current?.face &&
    existingPresenter !== null &&
    existingPresenter?.face_profile
  ) {
    const selectedFaceIndex = list?.findIndex(
      (face: FaceProfileResponse) =>
        origSelectedFace.current?.face?.id === face.id
    );
    if (selectedFaceIndex !== -1) {
      list.splice(selectedFaceIndex, 1);
      list.unshift(origSelectedFace.current.face);
      origSelectedFace.current.inserted = true;
    }
  }
  return list;
}

function splitByCategoryAndSortByCreated(faces: FaceProfileResponse[]) {
  const premadeFaces: FaceProfileResponse[] = [];
  const customFaces: FaceProfileResponse[] = [];
  faces.forEach((face) => {
    if (face.category === "Premade") {
      premadeFaces.push(face);
    } else {
      customFaces.push(face);
    }
  });
  return {
    premadeFaces: sortByCreated({ items: premadeFaces }),
    customFaces: sortByCreated({ items: customFaces }),
  };
}
