import { Flex, FormInstance } from "antd";
import { PresenterProfilePreviewView } from "../PresenterProfilePreviewView";
import Identity, { FormValues } from "./Identity";
import {
  PresenterProfileResponse,
  FaceProfileResponse,
  VoiceProfileResponse,
} from "pages/media/types";
import { Optional } from "utility-types";

type IdentityCardProps = {
  presenter?: Optional<
    PresenterProfileResponse,
    "voice_profile" | "face_profile"
  >;
  form: FormInstance<FormValues>;
  selectedFace?: FaceProfileResponse;
  selectedVoice?: VoiceProfileResponse;
  isLoading: boolean;
};

const IdentityCard = (props: IdentityCardProps) => {
  return (
    <Flex style={{ flex: 1 }}>
      <Identity {...props}>
        <Flex vertical>
          <PresenterProfilePreviewView
            style={{ width: 150, height: 150 }}
            presenter={{
              ...props.presenter,
              face_profile: props.selectedFace,
              voice_profile: props.selectedVoice,
            }}
          />
        </Flex>
      </Identity>
    </Flex>
  );
};
export default IdentityCard;
