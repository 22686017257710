// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  useList,
  useShow,
  useGetIdentity,
  useNotification,
} from "@refinedev/core";
import { useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
  Form,
  Input,
  Select,
  Upload,
  Table,
  Button,
  Row,
  Col,
  Spin,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { MenuOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

const { PlusOutlined, SearchOutlined } = Icons;

interface DataType {
  key: string;
  name: string;
}

const columnsDefault: ColumnsType<DataType> = [
  {
    key: "sort",
    title: "Position",
    align: "center",
  },
  {
    title: "Name",
    className: "column-name",
    dataIndex: "name",
    align: "center",
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const RowComponent = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && { ...transform, scaleY: 1 }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children?.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const CategoryCreate: React.FC<IResourceComponentsProps> = () => {
  const params = useParams();
  const { open, close } = useNotification();
  const isEditPage = !!params?.id;

  const API_URL = useApiUrl();
  const { data: dataIdentity } = useGetIdentity<User>();
  const accessToken = dataIdentity.token.access_token;

  const imageUploadHeaders = {
    Authorization: `Bearer ${accessToken}`,
  };

  const [subCategorySelected, setSubCategorySelected] = useState([]);
  const [listSubCategoriesSearched, setListSubCategoriesSearched] = useState(
    []
  );
  const [valueSearch, setValueSearch] = useState<string>("");
  const [debouncedStringSearch] = useDebounce(valueSearch, 500);
  const [optionsSearchSubCate, setOptionsSearchSubCate] = useState([]);
  const [optionsSubCate, setOptionsSubCate] = useState([]);

  const {
    queryResult: { data: dataCategory },
  } = useShow({
    resource: "api/funds/categories",
    id: params?.id,
    queryOptions: {
      enabled: !!params?.id,
      retry: !!params?.id,
    },
  });

  const { selectProps: subCategorySelectProps } = useSelect({
    resource: `api/funds/categories?is_subcategory=true`,
    optionLabel: "name",
    optionValue: "id",

    pagination: {
      mode: "server",
    },
  });

  const { data: selectListSubCategoriesSearched, isFetching: fetchingSearch } =
    useList({
      resource: `api/funds/categories?name=${debouncedStringSearch}&is_subcategory=true`,
      queryOptions: {
        enabled: !!debouncedStringSearch,
        retry: !!debouncedStringSearch,
      },
    });

  const onSearchSubCate = (value) => {
    setValueSearch(value);
  };

  const onHandleSearchSubCate = (value) => {
    const newListSubCateSearched =
      selectListSubCategoriesSearched?.data?.filter((subCate) =>
        value.includes(subCate.id)
      );
    setListSubCategoriesSearched([...newListSubCateSearched]);
  };

  const [data, setData] = useState<DataType[]>([]);

  const [formValues, setFormValues] = useState({
    name: "",
    is_parent: true,
    image: "",
    logo: "",
    banner: "",
    banner_mobile: "",
    status: null,
    subcategory_ids: [],
  });

  const onChangeField = (key, data) => {
    setFormValues({
      ...formValues,
      [key]: data,
    });
  };

  const onUploadedImage = (files: any) => {
    const objLinkImage: any = {};
    files?.forEach((file: any) => {
      if (file.key === "original") {
        objLinkImage["image"] = file.url;
      } else {
        objLinkImage[file.key] = file.url;
      }
    });
    setFormValues({
      ...formValues,
      ...objLinkImage,
    });
  };

  const getParamsUploadImages = (
    purposeParam: string[] = ["logo", "banner", "banner_mobile"]
  ) => {
    const decodedParam = purposeParam.map((value) => decodeURIComponent(value));
    const searchParams = new URLSearchParams();
    decodedParam.forEach((value) => searchParams.append("purpose", value));
    return searchParams.toString();
  };

  const handleOptionsSubCat = (optionsFirst, optionsSecond) => {
    const data = [...optionsFirst, ...optionsSecond];
    const dataFiltered = [
      ...new Map(data.map((item) => [item.value, item])).values(),
    ];
    return dataFiltered;
  };

  const { formProps, onFinish, formLoading, form } = useForm({
    redirect: false,
    meta: {
      method: `${isEditPage ? "put" : "post"}`,
    },
    resource: `${
      isEditPage ? `api/funds/categories/${params?.id}` : "api/funds/categories"
    }`,
    onMutationError: (data, variables, context) => {
      open?.({
        key: "create-fund-cate",
        type: "error",
        message: data?.response?.data?.detail?.message,
        description: `Error ${data?.response?.status}`,
        undoableTimeout: 5,
        cancelMutation: () => {
          // when undo button is clicked, run this callback
          close?.("create-fund-cate");
        },
      });
    },
    errorNotification: false,
  });

  const handleSubmit = () => {
    onFinish(formValues);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setData((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    if (selectListSubCategoriesSearched?.data) {
      const newOptions = [];
      selectListSubCategoriesSearched?.data?.forEach((subCate) => {
        newOptions.push({
          value: subCate.id,
          label: subCate.name,
        });
      });
      setOptionsSearchSubCate([...newOptions]);
    }
  }, [selectListSubCategoriesSearched]);

  useEffect(() => {
    if (subCategorySelectProps?.options) {
      const listSub = [];
      // sort table
      const newOptions = handleOptionsSubCat(
        subCategorySelectProps.options,
        optionsSubCate
      );
      subCategorySelected.forEach((idSelected) => {
        newOptions?.forEach((subFundCate) => {
          subFundCate.value === idSelected &&
            listSub.push({
              key: subFundCate.value,
              name: subFundCate.label,
            });
        });
      });
      listSubCategoriesSearched.forEach(
        (subCateSearched) =>
          !subCategorySelected.includes(subCateSearched.id) &&
          listSub.push({
            key: subCateSearched.id,
            name: subCateSearched.name,
          })
      );
      setData([...listSub]);
    }
  }, [
    subCategorySelected,
    subCategorySelectProps?.options,
    listSubCategoriesSearched.length,
    optionsSubCate,
  ]);

  useEffect(() => {
    const newDataIds = [];
    data.forEach((item) => {
      newDataIds.push(item.key);
    });
    onChangeField("subcategory_ids", [...newDataIds]);
  }, [data]);

  useEffect(() => {
    if (dataCategory?.data) {
      const subcategory_ids = [];
      const newOptions = [];
      dataCategory?.data?.subcategories.forEach((item) => {
        subcategory_ids.push(item.id);
        newOptions.push({
          label: item.name,
          value: item.id,
        });
      });
      setOptionsSubCate([...newOptions]);
      setSubCategorySelected([...subcategory_ids]);
      setFormValues({
        name: dataCategory?.data?.name,
        logo: dataCategory?.data?.logo,
        is_parent: true,
        banner: dataCategory?.data?.banner,
        banner_mobile: dataCategory?.data?.banner_mobile,
        status: dataCategory?.data?.status,
        subcategory_ids: [...subcategory_ids],
      });
      form.setFieldsValue({
        name: dataCategory?.data?.name,
        logo: dataCategory?.data?.logo,
        banner: dataCategory?.data?.banner,
        banner_mobile: dataCategory?.data?.banner_mobile,
        status: dataCategory?.data?.status,
        "Sub-Category": [...subcategory_ids],
      });
    }
  }, [dataCategory, form]);

  return (
    <div>
      <Form
        {...formProps}
        form={form}
        name="dynamic_rule"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col lg={12} xs={24}>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    allowClear
                    maxLength={64}
                    key={formValues.name}
                    defaultValue={formValues.name}
                    onChange={(e) => onChangeField("name", e.target.value)}
                    autoFocus
                    size="large"
                    placeholder="Category Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="status"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    key={formValues.status}
                    defaultValue={formValues.status}
                    onChange={(value) => onChangeField("status", value)}
                    size="large"
                    placeholder="Category Status"
                    options={[
                      { value: "publish", label: "Public" },
                      { value: "unpublish", label: "Private" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="Sub-Category">
                  <Select
                    mode="multiple"
                    size="large"
                    onChange={(value) => {
                      setSubCategorySelected(value);
                    }}
                    {...subCategorySelectProps}
                    options={handleOptionsSubCat(
                      subCategorySelectProps?.options,
                      optionsSubCate
                    )}
                    placeholder="Sub-Category"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="Search Sub-Category">
                  <Select
                    size="large"
                    mode="multiple"
                    style={{ width: "100%" }}
                    onSearch={onSearchSubCate}
                    notFoundContent={
                      fetchingSearch ? (
                        <Spin
                          size="small"
                          style={{ justifyContent: "center", display: "flex" }}
                        />
                      ) : null
                    }
                    options={optionsSearchSubCate}
                    loading={fetchingSearch}
                    optionFilterProp="label"
                    placeholder="Search Sub Category"
                    suffixIcon={<SearchOutlined />}
                    onChange={(value) => onHandleSearchSubCate(value)}
                    showSearch
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="table-tokens">
                  <DndContext onDragEnd={onDragEnd}>
                    <SortableContext
                      // rowKey array
                      items={data?.map((i) => i.key)}
                      strategy={verticalListSortingStrategy}
                    >
                      <Table
                        components={{
                          body: {
                            row: RowComponent,
                          },
                        }}
                        // loading={isLoadingListCurrencies}
                        columns={columnsDefault}
                        rowKey="key"
                        dataSource={data}
                        bordered
                        pagination={{ defaultPageSize: 20 }}
                      />
                    </SortableContext>
                  </DndContext>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xs={24}>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item name="logo">
                  <Upload
                    action={`${API_URL}/api/files/upload/category?${getParamsUploadImages()}`}
                    key="uploadAllImages"
                    maxCount={1}
                    onChange={({ file }) =>
                      file.status === "done" &&
                      onUploadedImage(file.response.files)
                    }
                    listType="picture-card"
                    headers={imageUploadHeaders}
                  >
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>
                        Upload Image
                        <br />
                        <Typography.Text type="secondary">
                          &lt; 10Mo
                        </Typography.Text>
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              {formValues?.logo ? (
                <Col span={8}>
                  <Form.Item
                    name="logo"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Upload
                      action={`${API_URL}/api/files/upload/category?${getParamsUploadImages(
                        ["logo"]
                      )}`}
                      key={formValues?.logo}
                      defaultFileList={
                        formValues?.logo ? [{ url: formValues?.logo }] : []
                      }
                      maxCount={1}
                      onChange={({ file }) =>
                        file.status === "done" &&
                        onUploadedImage(file.response.files)
                      }
                      listType="picture-card"
                      headers={imageUploadHeaders}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          Upload Logo
                          <br />
                          <Typography.Text type="secondary">
                            100x100
                          </Typography.Text>
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              ) : null}
              {formValues?.banner ? (
                <Col span={8}>
                  <Form.Item name="banner">
                    <Upload
                      action={`${API_URL}/api/files/upload/category?${getParamsUploadImages(
                        ["banner"]
                      )}`}
                      key={formValues?.banner}
                      defaultFileList={
                        formValues?.banner ? [{ url: formValues?.banner }] : []
                      }
                      maxCount={1}
                      onChange={({ file }) =>
                        file.status === "done" &&
                        onUploadedImage(file.response.files)
                      }
                      listType="picture-card"
                      headers={imageUploadHeaders}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          Upload Banner Desktop
                          <br />
                          <Typography.Text type="secondary">
                            1600x900
                          </Typography.Text>
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              ) : null}
              {formValues?.banner_mobile ? (
                <Col span={8}>
                  <Form.Item name="banner_mobile">
                    <Upload
                      key={formValues?.banner_mobile}
                      action={`${API_URL}/api/files/upload/category?${getParamsUploadImages(
                        ["banner_mobile"]
                      )}`}
                      defaultFileList={
                        formValues?.banner_mobile
                          ? [{ url: formValues?.banner_mobile }]
                          : []
                      }
                      maxCount={1}
                      onChange={({ file }) =>
                        file.status === "done" &&
                        onUploadedImage(file.response.files)
                      }
                      listType="picture-card"
                      headers={imageUploadHeaders}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          Upload Banner Mobile
                          <br />
                          <Typography.Text type="secondary">
                            752x836
                          </Typography.Text>
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>

        <Button
          loading={formLoading}
          type="primary"
          size="large"
          htmlType="submit"
          style={{ display: "flex", margin: "auto" }}
        >
          {isEditPage ? "Update Category" : "Create Category"}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryCreate;
