import { Authenticated, useOne } from "@refinedev/core";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Organization } from "types";
import { AppContext } from "appContext";
import { StoryboardStudio } from "../components/storyboard/StoryboardStudio";
import { MediaProjectResponse } from "../types";

// export const DocumentStoryboardEditor = ({
//   organization,
// }: {
//   organization?: Organization;
// }) => {
//   const { dispatch: appDispatch } = useContext(AppContext);

//   const params = useParams();
//   const id = params?.id;
//   const { queryResult } = useShow<any>({
//     resource: `media/documents/${organization?.id}`,
//     id,
//     queryOptions: {
//       enabled: !!organization,
//     },
//   });
//   const { data: document } = queryResult.data || {};

//   useEffect(() => {
//     appDispatch({ type: "setSidebarCollapsed", payload: true });

//     return () => {
//       appDispatch({ type: "setSidebarCollapsed", payload: false });
//     };
//   }, []);

//   return (
//     <>
//       {document && (
//         <StoryboardStudio document={document} organization={organization} />
//       )}
//     </>
//   );
// };

export const ProjectStoryboardEditor = ({
  organization,
}: {
  organization?: Organization;
}) => {
  const { dispatch: appDispatch } = useContext(AppContext);

  const params = useParams<{ id: string; storyboardId?: string }>();
  const id = params?.id;
  const storyboardId = params?.storyboardId;

  const { data } = useOne<MediaProjectResponse>({
    resource: `media/projects`,
    id,
  });
  const project = data?.data;

  useEffect(() => {
    appDispatch({ type: "setSidebarCollapsed", payload: true });

    return () => {
      appDispatch({ type: "setSidebarCollapsed", payload: false });
    };
  }, []);

  return (
    <Authenticated key="project_storyboard">
      {project && (
        <StoryboardStudio project={project} storyboardId={storyboardId} />
      )}
    </Authenticated>
  );
};
