import React, { useEffect, useRef } from "react";
import { Play, Stop } from "@phosphor-icons/react";
import { List, Space, Spin } from "antd";
import { VoiceProfileResponse } from "/pages/media/types";
import { LoadingOutlined } from "@ant-design/icons";
import { useAntTheme } from "hooks/useAntTheme";
import { useOne } from "@refinedev/core";
import { useOrganization } from "hooks/useOrganization";
export interface VoiceItemProps {
  children?: React.ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  currentlyPlayedVoice?: VoiceProfileResponse;
  onClickPlay: (voice?: VoiceProfileResponse) => void;
  id: string;
  voice: VoiceProfileResponse;
  pollingInterval?: number | false;
}

const VoiceItem: React.FC<VoiceItemProps> = ({
  children,
  onClick: onClickItem,
  isSelected = false,
  onClickPlay: onClickPlayProp,
  currentlyPlayedVoice,
  id,
  voice: currentVoice,
  pollingInterval = 3000,
}) => {
  const [playing, setPlaying] = React.useState(false);
  const Icon = playing ? Stop : Play;
  const { theme } = useAntTheme();

  const { organization } = useOrganization({});
  const { data: updatedVoice } = useOne<VoiceProfileResponse>({
    resource: `media/${organization?.id}/voice_profiles`,
    id,
    queryOptions: {
      enabled: !!id && !currentVoice?.preview_asset_path,
      refetchInterval: (voice) => {
        return voice?.data.preview_asset_path ? false : pollingInterval;
      },
    },
    errorNotification: false,
  });

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const voice = updatedVoice?.data ?? currentVoice;

  useEffect(() => {
    // stop playing if another starts
    if (currentlyPlayedVoice?.id && currentlyPlayedVoice?.id !== id) {
      // console.debug("force stop", { id, playing, currentlyPlayedVoice });
      setPlaying(false); // force stop
    }
  }, [currentlyPlayedVoice]);

  useEffect(() => {
    if (playing) {
      // forward to parent
      onClickPlayProp(voice);
      if (!audioRef.current) {
        // init audio player
        audioRef.current = new Audio(voice?.preview_asset_path);
        audioRef.current.addEventListener("ended", () => setPlaying(false));
      } else {
        // already used , rewind audio
        audioRef.current.currentTime = 0;
      }
      audioRef.current?.play(); // start playing!
    } else {
      audioRef?.current?.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  return (
    <List.Item className={isSelected ? "active" : ""} onClick={onClickItem}>
      <List.Item.Meta
        avatar={
          !voice?.preview_asset_path ? (
            <Spin
              style={{ marginLeft: 8 }}
              spinning
              size="small"
              indicator={
                <LoadingOutlined
                  style={{ color: theme.colorText, opacity: 0.5 }}
                />
              }
            />
          ) : (
            <Icon
              onClick={(e) => {
                e.stopPropagation();
                setPlaying((prev) => !prev);
              }}
              weight="fill"
              size={24}
              // style={{ flex: "0 1 24px" }}
            />
          )
        }
        title={children}
      />
    </List.Item>
  );
};

export default VoiceItem;
