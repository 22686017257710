import dayjs from "dayjs";
import { addDateTz } from "pages/media/utils";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import UTC from "dayjs/plugin/utc";
export function timestampPresenter(
  name: string,
  date: string,
  { utc, locale }: { utc: boolean; locale: string } = {
    utc: true,
    locale: "en",
  }
): string {
  dayjs.extend(LocalizedFormat);
  dayjs.extend(UTC);
  let day = dayjs(addDateTz(date)).locale(locale);
  if (utc) {
    day = day.utc();
  }
  const formattedTime = day.format("L LT");
  return `${name} ${formattedTime}`;
}
