import { Flex, Typography } from "antd";
import { useState } from "react";
import ReactPlayer from "react-player/lazy";
import type { ProgressState } from "./types";
import { secToHHMMSS } from "pages/media/utils";
import { ProjectMediaResponse } from "pages/media/types";

export const BinderVideo: React.FC<{
  media: ProjectMediaResponse;
  setTs: (timestamp: number) => void;
}> = ({ setTs, media }) => {
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const handleProgress = (progress: ProgressState) => {
    setPlayedSeconds(progress.playedSeconds);
    setTs(progress.playedSeconds);
  };

  return (
    <Flex vertical>
      <Flex justify="center">
        <Typography.Title
          level={3}
          type="secondary"
          style={{ margin: 0, marginBottom: 3 }}
        >
          {secToHHMMSS(Math.floor(playedSeconds))}
        </Typography.Title>
      </Flex>
      <ReactPlayer
        progressInterval={200} // more reactive UI
        url={media.url}
        controls={true}
        width="400px"
        height="707px"
        onProgress={handleProgress}
      />
    </Flex>
  );
};
