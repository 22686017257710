// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useShow,
  useNotification,
} from "@refinedev/core";
import { useForm } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  Row,
  Col,
  Typography,
} from "antd";
import { useParams } from "react-router-dom";
import { User } from "authProvider";

const { PlusOutlined } = Icons;

const SubCategoryCreate: React.FC<IResourceComponentsProps> = () => {
  const params = useParams();
  const { open, close } = useNotification();
  const isEditPage = !!params?.id;

  const API_URL = useApiUrl();
  const { data: dataIdentity } = useGetIdentity<User>();
  const accessToken = dataIdentity.token.access_token;

  const imageUploadHeaders = {
    Authorization: `Bearer ${accessToken}`,
  };

  const [formValues, setFormValues] = useState({
    name: "",
    is_parent: false,
    image: "",
    logo: "",
    banner: "",
    banner_mobile: "",
    status: null,
  });

  const onChangeField = (key, data) => {
    setFormValues({
      ...formValues,
      [key]: data,
    });
  };

  const onUploadedImage = (files: any) => {
    const objLinkImage: any = {};
    files?.forEach((file: any) => {
      if (file.key === "original") {
        objLinkImage["image"] = file.url;
      } else {
        objLinkImage[file.key] = file.url;
      }
    });
    setFormValues({
      ...formValues,
      ...objLinkImage,
    });
  };

  const getParamsUploadImages = (
    purposeParam: string[] = ["logo", "banner", "banner_mobile"]
  ) => {
    const decodedParam = purposeParam.map((value) => decodeURIComponent(value));
    const searchParams = new URLSearchParams();
    decodedParam.forEach((value) => searchParams.append("purpose", value));
    return searchParams.toString();
  };

  const { formProps, onFinish, formLoading, form } = useForm({
    redirect: false,
    meta: {
      method: `${isEditPage ? "put" : "post"}`,
    },
    resource: `${
      isEditPage ? `api/funds/categories/${params?.id}` : "api/funds/categories"
    }`,
    onMutationError: (data, variables, context) => {
      open?.({
        key: "create-fund-subcate",
        type: "error",
        message: data?.response?.data?.detail?.message,
        description: `Error ${data?.response?.status}`,
        undoableTimeout: 5,
        cancelMutation: () => {
          // when undo button is clicked, run this callback
          close?.("create-fund-subcate");
        },
      });
    },
    errorNotification: false,
  });

  const {
    queryResult: { data: dataSubCategory },
  } = useShow({
    resource: `api/funds/categories/${params?.id}?is_subcategory=true`,
    queryOptions: {
      enabled: !!params?.id,
      retry: !!params?.id,
    },
  });

  const handleSubmit = () => {
    onFinish(formValues);
  };

  useEffect(() => {
    if (dataSubCategory?.data) {
      setFormValues({
        name: dataSubCategory?.data?.name,
        is_parent: false,
        logo: dataSubCategory?.data?.logo,
        banner: dataSubCategory?.data?.banner,
        banner_mobile: dataSubCategory?.data?.banner_mobile,
        status: dataSubCategory?.data?.status,
      });
      form.setFieldsValue({
        name: dataSubCategory?.data?.name,
        logo: dataSubCategory?.data?.logo,
        banner: dataSubCategory?.data?.banner,
        banner_mobile: dataSubCategory?.data?.banner_mobile,
        status: dataSubCategory?.data?.status,
      });
    }
  }, [dataSubCategory, form]);

  return (
    <div>
      <Form
        {...formProps}
        form={form}
        name="dynamic_rule"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={6}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    allowClear
                    maxLength={64}
                    key={formValues.name}
                    defaultValue={formValues.name}
                    onChange={(e) => onChangeField("name", e.target.value)}
                    autoFocus
                    size="large"
                    placeholder="Sub Category Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name="status"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    key={formValues.status}
                    defaultValue={formValues.status}
                    onChange={(value) => onChangeField("status", value)}
                    placeholder="Sub Category Status"
                    options={[
                      { value: "publish", label: "Public" },
                      { value: "unpublish", label: "Private" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item name="logo">
                  <Upload
                    action={`${API_URL}/api/files/upload/category?${getParamsUploadImages()}`}
                    key="uploadAllImages"
                    maxCount={1}
                    onChange={({ file }) =>
                      file.status === "done" &&
                      onUploadedImage(file.response.files)
                    }
                    listType="picture-card"
                    headers={imageUploadHeaders}
                  >
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>
                        Upload Image
                        <br />
                        <Typography.Text type="secondary">
                          &lt; 10Mo
                        </Typography.Text>
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              {formValues?.logo ? (
                <Col span={8}>
                  <Form.Item
                    name="logo"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Upload
                      action={`${API_URL}/api/files/upload/category?${getParamsUploadImages(
                        ["logo"]
                      )}`}
                      key={formValues?.logo}
                      defaultFileList={
                        formValues?.logo ? [{ url: formValues?.logo }] : []
                      }
                      maxCount={1}
                      onChange={({ file }) =>
                        file.status === "done" &&
                        onUploadedImage(file.response.files)
                      }
                      listType="picture-card"
                      headers={imageUploadHeaders}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          Upload Logo
                          <br />
                          <Typography.Text type="secondary">
                            100x100
                          </Typography.Text>
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              ) : null}
              {formValues?.banner ? (
                <Col span={8}>
                  <Form.Item
                    name="banner"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Upload
                      action={`${API_URL}/api/files/upload/category?${getParamsUploadImages(
                        ["banner"]
                      )}`}
                      key={formValues?.banner}
                      defaultFileList={
                        formValues?.banner ? [{ url: formValues?.banner }] : []
                      }
                      maxCount={1}
                      onChange={({ file }) =>
                        file.status === "done" &&
                        onUploadedImage(file.response.files)
                      }
                      listType="picture-card"
                      headers={imageUploadHeaders}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          Upload Banner Desktop
                          <br />
                          <Typography.Text type="secondary">
                            1600x900
                          </Typography.Text>
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              ) : null}
              {formValues?.banner_mobile ? (
                <Col span={8}>
                  <Form.Item
                    name="banner_mobile"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Upload
                      key={formValues?.banner_mobile}
                      action={`${API_URL}/api/files/upload/category?${getParamsUploadImages(
                        ["banner_mobile"]
                      )}`}
                      defaultFileList={
                        formValues?.banner_mobile
                          ? [{ url: formValues?.banner_mobile }]
                          : []
                      }
                      maxCount={1}
                      onChange={({ file }) =>
                        file.status === "done" &&
                        onUploadedImage(file.response.files)
                      }
                      listType="picture-card"
                      headers={imageUploadHeaders}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          Upload Banner Mobile
                          <br />
                          <Typography.Text type="secondary">
                            752x836
                          </Typography.Text>
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>

        <Button
          loading={formLoading}
          type="primary"
          size="large"
          htmlType="submit"
          style={{ display: "flex", margin: "auto" }}
        >
          {isEditPage ? "Update Sub Category" : "Create Sub Category"}
        </Button>
      </Form>
    </div>
  );
};

export default SubCategoryCreate;
