// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
  Form,
  Input,
  Select,
  Table,
  Row,
  Col,
  Image,
  Space,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useParams } from "react-router-dom";

const { Title } = Typography;
interface DataType {
  name: string;
}

const columnsDefault: ColumnsType<DataType> = [
  {
    title: "Name",
    className: "column-name",
    dataIndex: "name",
    align: "center",
  },
];

const CategoryDetail: React.FC<IResourceComponentsProps> = () => {
  const params = useParams();

  const { queryResult } = useShow({
    resource: "api/funds/categories",
    id: params?.id,
  });

  const { data: dataCategory, isLoading } = queryResult;

  return (
    <div>
      <Form name="dynamic_rule" layout="horizontal">
        <Row gutter={[16, 0]}>
          <Col lg={12} xs={24}>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item name="Category Name">
                  <Input
                    disabled
                    key={dataCategory?.data?.name}
                    defaultValue={dataCategory?.data?.name}
                    autoFocus
                    size="large"
                    placeholder="Category Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="category status"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    disabled
                    placeholder="Category Status"
                    key={dataCategory?.data?.status}
                    defaultValue={dataCategory?.data?.status}
                    options={[
                      { value: "publish", label: "Public" },
                      { value: "unpublish", label: "Private" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="table-tokens">
                  <Table
                    columns={columnsDefault}
                    dataSource={dataCategory?.data?.subcategories}
                    bordered
                    loading={isLoading}
                    pagination={{ defaultPageSize: 5 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xs={24}>
            <Row gutter={[16, 0]}>
              <Col span={8}>
                <Form.Item name="logo">
                  <Space style={{ maxWidth: 200 }} direction="vertical">
                    <Title level={5}>Logo</Title>
                    <Image width="100%" src={dataCategory?.data?.logo} />
                  </Space>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="banner">
                  <Space style={{ maxWidth: 200 }} direction="vertical">
                    <Title level={5}>Banner</Title>
                    <Image width="100%" src={dataCategory?.data?.banner} />
                  </Space>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="banner mobile">
                  <Space style={{ maxWidth: 200 }} direction="vertical">
                    <Title level={5}>Banner Mobile</Title>
                    <Image
                      width="100%"
                      src={dataCategory?.data?.banner_mobile}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CategoryDetail;
