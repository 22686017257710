import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { useCustom } from "@refinedev/core";
import { Checkbox, Grid, Image, Space, Spin, Tooltip, Typography } from "antd";
import { Descriptions } from "antd";
import type { DescriptionsProps } from "antd";
import { Button, Flex } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  GlobalOutlined,
  GoogleOutlined,
  YahooOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { MediaAsset } from "../types";
import { UUID } from "components/UUID";

export const MediaAssetReview = ({
  asset,
  handleReview,
  isUpdating,
  compact = false,
}: {
  asset: MediaAsset;
  handleReview: (id: string, status: boolean) => void;
  isUpdating: boolean;
  compact?: boolean;
}) => {
  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();
  const stockAssetsLookup = useCustom<{
    items: {
      ticker: string;
      name: string;
    }[];
  }>({
    url: `api/assets/Equity/like/${asset.sub_category}`,
    method: "get",
  });
  const stockAsset = stockAssetsLookup.data?.data.items.find(
    (x) => x.ticker === asset.sub_category
  );

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: t("media.components.MediaAssetReview.subCategory"),
      children: (
        <Typography.Text copyable strong>
          {stockAsset?.name
            ? `${stockAsset?.name} (${asset.sub_category})`
            : asset.sub_category}
          <Button
            target="_blank"
            icon={<GoogleOutlined />}
            type="link"
            href={`https://www.google.com/search?q=${asset.sub_category}`}
          />
          {stockAsset && (
            <Button
              target="_blank"
              icon={<GlobalOutlined />}
              type="link"
              href={`https://en.wikipedia.org/w/index.php?search=${encodeURIComponent(stockAsset?.name)}`}
            />
          )}
          <Button
            target="_blank"
            icon={<YahooOutlined />}
            type="link"
            href={`https://finance.yahoo.com/quote/${asset.sub_category}`}
          />
        </Typography.Text>
      ),
    },
    // {
    //   key: "1",
    //   label: "Name",
    //   children: (
    //     <Typography.Text copyable strong style={{ fontSize: 18 }}>
    //       {stockAsset?.name}
    //     </Typography.Text>
    //   ),
    // },
    {
      key: "3",
      label: t("media.components.MediaAssetReview.source"),
      children: asset.source,
    },
    {
      key: "4",
      label: t("media.components.MediaAssetReview.sourceType"),
      children: asset.source_type,
    },

    // {
    //   key: "2",
    //   label: "ID",
    //   children: (
    //     <Typography.Text copyable style={{ fontSize: 11 }}>
    //       {asset.id}
    //     </Typography.Text>
    //   ),
    // },
    // {
    //   key: "7",
    //   label: "Logo",
    //   children: (
    //     <img
    //       src={`https://cdn.libertify.com/env/images/tokens/${asset.sub_category}.png`}
    //     />
    //   ),
    // },
    {
      key: "5",
      label: t("media.components.MediaAssetReview.description"),
      children: (
        <Typography.Text
          copyable
          style={{
            fontFamily: "serif",
            fontStyle: "italic",
            fontSize: 18,
            lineHeight: "1.4em",
          }}
        >
          {asset.description}
        </Typography.Text>
      ),
    },
  ];

  const idDesc = {
    key: "id",
    label: t("media.components.MediaAssetReview.id"),
    children: <UUID id={asset.id} />,
  };
  const techDescriptions = compact
    ? [idDesc]
    : [
        idDesc,
        {
          key: "review",
          label: t("media.components.MediaAssetReview.review"),
          children: (
            <Typography.Text>
              {asset.approved ? t("approved") : t("rejected")}
            </Typography.Text>
          ),
        },
        {
          key: "reviewer",
          label: t("media.components.MediaAssetReview.reviewer"),
          children: <Typography.Text>{asset.reviewer}</Typography.Text>,
        },
        {
          key: "review_date",
          label: t("media.components.MediaAssetReview.reviewDate"),
          children: <Typography.Text>{asset.reviewed}</Typography.Text>,
        },
        {
          key: "introduction",
          label: t("media.components.MediaAssetReview.introduction"),
          children: <Checkbox value={asset.introduction} disabled />,
        },
        {
          key: "aspect_ratio",
          label: t("media.components.MediaAssetReview.aspectRatio"),
          children: asset.aspect_ratio,
        },
        {
          key: "height",
          label: t("media.components.MediaAssetReview.height"),
          children: asset.height,
        },
        {
          key: "width",
          label: t("media.components.MediaAssetReview.width"),
          children: asset.width,
        },
      ];

  const itemsMobile = [...items];
  itemsMobile.pop();

  const onDownload = () => {
    window.open(asset.path, "_blank");
  };

  return (
    <Flex gap="small" vertical align="flex-start" style={{ width: "100%" }}>
      <Flex gap="large" justify="flex-start" align="flex-start" wrap="wrap">
        <Tooltip title={t("media.components.MediaAssetReview.accept")}>
          <Button
            disabled={isUpdating}
            type="primary"
            icon={<CheckOutlined style={{ fontSize: 20 }} />}
            size={"large"}
            shape="circle"
            style={{
              height: 60,
              width: 60,
              backgroundColor: "#24BB75",
              marginLeft: 10,
            }}
            onClick={() => handleReview(asset.id, true)}
          ></Button>
        </Tooltip>
        <Tooltip title={t("media.components.MediaAssetReview.reject")}>
          <Button
            disabled={isUpdating}
            type="primary"
            icon={<CloseOutlined style={{ fontSize: 20 }} />}
            size={"large"}
            shape="circle"
            style={{
              height: 60,
              width: 60,
              backgroundColor: "#E01B36",
              marginLeft: 30,
            }}
            onClick={() => handleReview(asset.id, false)}
          ></Button>
        </Tooltip>
      </Flex>
      <Flex align="center" vertical>
        {!isMobile && (
          <Descriptions
            title=""
            items={items}
            bordered
            layout="vertical"
            size="small"
          />
        )}
        {asset.asset_type === "Video" && (
          <div style={{ marginTop: 30 }}>
            <video controls height={400} preload="auto">
              <source src={asset.path} type="video/mp4" />
            </video>
          </div>
        )}
        {asset.asset_type === "Picture" && (
          <Image
            preview={{
              mask: null,
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                  },
                }
              ) => (
                <Space
                  size={40}
                  className="toolbar-wrapper"
                  style={{
                    backgroundColor: "rgba(0,0,0,.3)",
                    borderRadius: 100,
                    padding: 10,
                  }}
                >
                  <DownloadOutlined
                    onClick={onDownload}
                    style={{ fontSize: 30 }}
                  />
                  {/* <SwapOutlined
                      rotate={90}
                      onClick={onFlipY}
                      style={{ fontSize: 30 }}
                    />
                    <SwapOutlined onClick={onFlipX} style={{ fontSize: 30 }} />
                    <RotateLeftOutlined
                      onClick={onRotateLeft}
                      style={{ fontSize: 30 }}
                    />
                    <RotateRightOutlined
                      onClick={onRotateRight}
                      style={{ fontSize: 30 }}
                    /> */}
                  <ZoomOutOutlined
                    disabled={scale === 1}
                    onClick={onZoomOut}
                    style={{ fontSize: 30 }}
                  />
                  <ZoomInOutlined
                    disabled={scale === 50}
                    onClick={onZoomIn}
                    style={{ fontSize: 30 }}
                  />
                </Space>
              ),
            }}
            src={asset.path}
            placeholder={<Spin style={{ marginTop: 20, marginLeft: 10 }} />}
            style={{
              maxHeight: "calc(100vh - 350px)",
              objectFit: "contain",
              marginTop: 10,
              cursor: "pointer",
            }}
          />
        )}
        {isMobile && (
          <Typography.Text copyable>{asset.description}</Typography.Text>
        )}
        {isMobile && (
          <Descriptions title="" items={itemsMobile} bordered size="small" />
        )}
        <Descriptions
          title=""
          items={techDescriptions}
          bordered
          size="small"
          style={{ marginTop: 30 }}
        />
      </Flex>
    </Flex>
  );
};

