import { Key, useCallback, useEffect, useState } from "react";
import { useCustom } from "@refinedev/core";
import {
  AlignLeftOutlined,
  AudioOutlined,
  DownOutlined,
  FieldTimeOutlined,
  FolderFilled,
  HighlightOutlined,
  SmileOutlined,
  UserOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Badge, Card, Flex, Spin, Tree, Typography } from "antd";
import type { DataNode } from "antd/es/tree";
import { MediaStep, MediaStepNode, MediaStepsResponse } from "../types";
import { MediaStepPreview } from "./MediaStepPreview";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useAntTheme } from "hooks/useAntTheme";
import { getStatusColor } from "../utils";
import { ListPlus, Queue } from "@phosphor-icons/react";

const getNodeIcon = (node: MediaStepNode) => {
  const getIcon = () => {
    switch (node.output_type) {
      case "CuePoints":
        if (node.step_type === "AggregateCuePoints") {
          return (
            <span className="anticon">
              <ListPlus size={18} />
            </span>
          );
        } else {
          return (
            <span className="anticon">
              <Queue size={18} />
            </span>
          );
        }
      case "Speech":
        return <AudioOutlined />;
      case "Script":
        return <AlignLeftOutlined />;
      case "Avatar":
        return <UserOutlined />;
      case "Video":
        if (node.step_type === "AggregateVideo") {
          return <VideoCameraAddOutlined />;
        } else {
          return <VideoCameraOutlined />;
        }
      case "SpeechTimestamps":
        return <FieldTimeOutlined />;
      case "Keywords":
        return <HighlightOutlined />;

      default:
        return <SmileOutlined />;
    }
  };
  const getBadgeColor = () => {
    return getStatusColor(node.status);
  };

  const icon = getIcon();
  if (node.status !== "Ready") {
    const color = getBadgeColor();
    return (
      <Badge dot status={color}>
        {icon}
      </Badge>
    );
  } else {
    return icon;
  }
};

// const MediaStepsGraph = ({ data }: { data: any[] }) => {
//   const deps = data
//     .filter((x) => x.depends_on?.length > 0)
//     .reduce((acc, cur) => {
//       return [
//         ...acc,
//         ...cur.depends_on.map((dep: any) => ({ from: cur.id, to: dep })),
//       ];
//     }, []);
//   console.debug({ deps });
//   const graph = {
//     nodes: data.map((step: any) => ({
//       id: step.id,
//       label: "123",
//       title: "123",
//       color: "#fff",
//     })),
//     edges: deps,
//   };

//   const options = {
//     layout: {
//       hierarchical: false,
//     },
//     edges: {
//       color: "#fff",
//     },
//     height: "200px",
//   };

//   const events = {
//     select: function (event: any) {
//       var { nodes, edges } = event;
//     },
//   };

//   return (
//     <Graph
//       graph={graph}
//       options={options}
//       events={events}
//       getNetwork={(network: any) => {
//         //  if you want access to vis.js network api you can set the state in a parent component using this property
//       }}
//     />
//   );
// };
const MediaAssetTree = ({
  nodes,
  allNodes,
  mediaId,
  chapters,
}: {
  nodes: MediaStepNode[];
  allNodes: MediaStep[];
  mediaId: string;
  chapters: string[];
}) => {
  const { theme } = useAntTheme();
  const [currentMediaStepId, setCurrentMediaStepId] = useState<MediaStep>();

  let currentChapter = "";

  const stepsToNode = (
    steps: MediaStepNode[],
    parentId: string,
    groupedChaptersLevel?: number
  ) => {
    const orderedSteps = steps;

    // if (steps?.[0]?.level === groupedChaptersLevel) {
    //   // sort top level steps according to chapters
    //   orderedSteps = chapters.map((chapter) =>
    //     steps.find((step) => step.chapter_name === chapter)
    //   ) as MediaStepNode[];
    // }

    let levelToGroupChapters = groupedChaptersLevel ?? 1; // default to level 1 for standar media

    return orderedSteps.map((step): DataNode => {
      // didn't find logic to not hardcode DLY 20240808
      if (step.step_type === "AddWatermark") {
        // additional level
        levelToGroupChapters = 2;
      } else if (step.step_type === "AggregateCuePoints") {
        // not working perfectly yet
        levelToGroupChapters = 2;
      }
      // groupedChaptersLevel ??
      // (step.chapter_name?.length && step.chapter_name !== currentChapter)
      //   ? step.level
      //   : undefined;

      const result = {
        title: (
          <>
            {/* {step.level}  */}
            <span>{step.step_type}</span>{" "}
            <Typography.Text type="secondary" code>
              {step.id.split("-")[0]}
            </Typography.Text>
          </>
        ),
        key: parentId + "__" + step.id,
        icon: getNodeIcon(step),
        children: step.children
          ? stepsToNode(
              step.children,
              parentId + "__" + step.id,
              levelToGroupChapters
            )
          : [],
      };

      if (
        step.chapter_name?.length &&
        step.chapter_name !== currentChapter &&
        step.level === levelToGroupChapters
      ) {
        // new chapter (folder)
        currentChapter = step.chapter_name;
        return {
          title: (
            <strong style={{ color: theme.colorPrimary }}>
              {step.chapter_name}
            </strong>
          ),
          key: parentId + "__" + step.chapter_name,
          icon: <FolderFilled style={{ color: theme.colorPrimary }} />,
          children: [result],
        };
      } else {
        return result;
      }
      return result;
    });
  };
  //   const treeData: DataNode[] = Object.entries(nodes ?? {}).map(
  //     ([key, value]) => {
  //       return {
  //         title: key,
  //         key: "0-0-" + key,
  //         icon: <FolderOutlined />,
  //         children: stepsToNode(value),
  //       };
  //     }
  //   );

  const handleSelectNode = useCallback(
    (selectedKeys: Key[]) => {
      const node = allNodes.find(
        ({ id }) => id === (selectedKeys[0] as string).split("__").at(-1)
      );
      node && setCurrentMediaStepId(node);
    },
    [allNodes]
  );

  const treeData: DataNode[] = stepsToNode(nodes, "root");
  console.debug({ treeData });
  const rootId = nodes?.length && nodes?.[0].id;

  // select first root by default
  useEffect(() => {
    if (!rootId) {
      return;
    }
    handleSelectNode([rootId]);
  }, [rootId, handleSelectNode]);

  // when errors
  if (nodes.length === 0) {
    return null;
  }

  return (
    <PanelGroup direction="horizontal">
      <Panel defaultSize={40} minSize={20}>
        <Tree.DirectoryTree
          showIcon
          showLine
          defaultExpandAll
          defaultSelectedKeys={[rootId]}
          expandAction={"doubleClick"}
          switcherIcon={<DownOutlined />}
          treeData={treeData}
          onSelect={handleSelectNode}
          style={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 250px)",
          }}
        />
      </Panel>
      <PanelResizeHandle
        style={{
          width: 10,
          background: `${theme.colorPrimary}30`,
          borderRadius: 10,
          marginLeft: 10,
        }}
      />
      <Panel defaultSize={70} minSize={20}>
        {currentMediaStepId && (
          <MediaStepPreview step={currentMediaStepId} mediaId={mediaId} />
        )}
      </Panel>
    </PanelGroup>
  );
};

const MediaAssetsTimeline = ({
  chapters,
  steps,
}: {
  chapters: string[];
  steps: MediaStep[];
}) => {
  return (
    <Flex style={{ marginBottom: 5, overflow: "auto", paddingBottom: 20 }}>
      {chapters.map((chapter) => {
        const audioUrl = steps.filter(
          (x) => x.chapter_name === chapter && x.output_type === "Speech"
        )?.[0]?.asset?.path;
        const videoUrl = steps.filter(
          (x) => x.chapter_name === chapter && x.output_type === "Video"
        )?.[0]?.asset?.path;
        return (
          <Card
            size="small"
            title={chapter}
            bodyStyle={{ justifyItems: "center" }}
            style={{ borderRight: 0, borderLeft: 0, borderRadius: 0 }}
          >
            {audioUrl && (
              <audio controls preload="none">
                <source src={audioUrl} />
              </audio>
            )}
            {videoUrl && (
              <video controls height={250} preload="none">
                <source src={videoUrl} type="video/mp4" />
              </video>
            )}
          </Card>
        );
      })}
    </Flex>
  );
};

export const MediaAssets = ({ mediaId }: { mediaId: string }) => {
  const { data } = useCustom<MediaStepsResponse>({
    url: `media/media/${mediaId}/assets`,
    method: "get",
  });
  const steps = data?.data.steps;
  const addChildren = (x: MediaStepNode, level: number): MediaStepNode => {
    const newLevel = level + 1;
    const children: MediaStepNode[] | undefined =
      //  steps
      // ?.filter((y) => x.depends_on?.includes(y.id))
      // .map((x) => addChildren(x!, newLevel));
      x.depends_on
        .map((y) => steps?.find((z) => z.id === y))
        .filter(Boolean)
        .map((z) => addChildren(z!, newLevel));
    return { ...x, level, children };
  };
  const parents = steps?.filter(
    (x) => !steps.find((y) => y.depends_on?.includes(x.id))
  );
  const tree = parents?.map((x) => addChildren(x, 0));
  console.debug({ steps, tree });
  // https://dmitripavlutin.com/javascript-array-group/
  //   const groupByChapter = tree?.reduce(
  //     (group: DictOf<Array<MediaStepNode>>, item) => {
  //       const { chapter_name } = item;
  //       group[chapter_name] = group[chapter_name] ?? [];
  //       group[chapter_name].push(item);
  //       return group;
  //     },
  //     {}
  //   );

  return (
    <>
      {/* {data?.data && <MediaStepsGraph data={data?.data} />} */}
      {!tree ? (
        <Spin />
      ) : (
        <>
          {data?.data?.chapters && (
            <MediaAssetTree
              nodes={tree}
              chapters={data.data.chapters}
              allNodes={steps as MediaStep[]}
              mediaId={mediaId}
            />
          )}
          {data?.data?.chapters && (
            <MediaAssetsTimeline
              chapters={data.data.chapters}
              steps={data.data.steps}
            />
          )}
        </>
      )}
    </>
  );
};
