import { useTranslate } from "@refinedev/core";
import { Button, Flex, Form, Input, Space, Typography } from "antd";

import { useMediaAssetUpload } from "hooks/useMediaAssetUpload";
import Dragger from "antd/es/upload/Dragger";
import { useOrganization } from "hooks/useOrganization";
import {
  FaceProfileCreationRequest,
  FaceProfileCreationResponse,
} from "../types";
import { CustomFormItemProps } from "types";
import { MagicWand } from "@phosphor-icons/react";
import { FaceProfilePreview } from "./FaceProfilePreview";
import { useAntTheme } from "hooks/useAntTheme";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";

const ACCEPTED_MIME_TYPES = "image/jpeg, image/png";

export const FaceProfileCreateForm: React.FC<
  CustomFormItemProps<string> & {
    name?: string;
    onChange?: (faceId: string) => void;
    onSuccess?: (face: FaceProfileCreationResponse) => void;
  }
> = ({ name = "", value, onChange, onSuccess }) => {
  const t = useTranslate();
  const { colorPrimary } = useAntTheme();
  const { organization } = useOrganization({});
  const [loading, setLoading] = useState(false);
  const [canCloneFace, setCanCloneFace] = useState(false);
  const { uploadFiles, customRequest } = useMediaAssetUpload({
    organization: organization?.id,
    assetType: "Picture",
    buildCreateAssetEndpoint(file: File) {
      return `/media/${organization?.id}/face_profiles`;
    },
    buildCreateAssetParams(file: File) {
      return {};
    },
    buildCreateAssetPayload(file: File): FaceProfileCreationRequest {
      return {
        name:
          form.getFieldValue("name") ??
          t("media.components.FaceProfileCreateForm.faceDefaultName"),
        category: "Custom",
        custom_image_filename: file.name,
      };
    },
    parseAsset(response: unknown) {
      return (response as any).asset;
    },
  });

  const handleFinish = async () => {
    setLoading(true);
    const responses = await uploadFiles<unknown, FaceProfileCreationResponse>();
    setLoading(false);
    // console.debug({ responses });
    const { face_profile_id } = responses[0] ?? {};
    onChange && onChange(face_profile_id);
    onSuccess && onSuccess(responses[0]);
  };

  const [form] = useForm();

  if (value) {
    return (
      <FaceProfilePreview
        style={{
          padding: 2,
          borderColor: colorPrimary,
        }}
        id={value}
      />
    );
  }

  return (
    // <Form onFinish={handleFinish}>
    // </Form>
    (<Flex vertical gap={30}>
      <Form form={form} initialValues={{ name }}>
        <Form.Item
          label={t("media.components.FaceProfileCreateForm.name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("media.components.FaceProfileCreateForm.pleaseEnterA"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
      <Dragger
        maxCount={1}
        accept={ACCEPTED_MIME_TYPES}

              name="file"

        multiple={false}
        iconRender={(_file) => null}
        customRequest={(...args) => {
          setCanCloneFace(true);
          customRequest(...args);
        }}
      >
        <Flex
          align="center"
          justify="center"
          vertical
          style={{ minHeight: 100 }}
        >
          <Typography.Text>
            {t("media.components.FaceProfileCreateForm.clickOrDrag")}
          </Typography.Text>
          <Typography.Text type="secondary">
            {t("media.components.FaceProfileCreateForm.acceptedFormats")}
          </Typography.Text>
          {/* todo */}
          {/* {isUploading && <Progress percent={completed} size="small" />} */}
        </Flex>
      </Dragger>
      <Space>
        <Button
          size="large"
          type="primary"
          shape="round"
          loading={loading}
          onClick={handleFinish}
          disabled={!canCloneFace}
          icon={
            <span className="anticon">
              <MagicWand size={18} />
            </span>
          }
        >
          {t("media.components.FaceProfileCreateForm.cloneFace")}
        </Button>
      </Space>
    </Flex>)
  );
};


