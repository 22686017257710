import { CHATBOT_WS_URL } from "../constants";
import { nanoid } from "nanoid";
import { ChatMessage } from "pages/media/components/chat/types";
import { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useAuth } from "./useAuth";

export const useChatbot = ({
  contentType,
  documentIds,
  organizationId,
  stock,
}: {
  contentType?: string[];
  documentIds: string[];
  organizationId: string;
  stock?: string;
}) => {
  const { accessToken } = useAuth();
  const socketUrl = `${CHATBOT_WS_URL}?token=${accessToken}&user_id=123&script_id=test`;
  const { sendJsonMessage, readyState } = useWebSocket(socketUrl, {
    onMessage: (event) => {
      handleMessage(event);
    },
  });
  const [messageHistory, setMessageHistory] = useState<ChatMessage[]>([]);
  const messageHistoryRef = useRef<ChatMessage[]>([]);
  const [isBusy, setIsBusy] = useState(false);

  // todo with backend support
  const prequery = "";
  // "Reply with an HTML formated text. For instance use <br/> for chariage return. Format lists or table with appropriate HTML tags. It is recommended to insert images when usefull.";

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const init = () => {
    const data = {
      action: "SelectDocument",
      organization_id: organizationId,
      content_type: contentType ?? [
        "texts_raw",
        "tables_raw",
        "imgs_base64_raw",
      ],
      document_ids: documentIds,
      stock,
    };
    addMessage({ queryId: "welcome", query: "", message: "", isFinal: false });
    sendJsonMessage(data);
  };

  const addMessage = (message: any) => {
    setMessageHistory((messagesList) => [...messagesList, message]);
    messageHistoryRef.current = [...messageHistoryRef.current, message];
  };

  const sendQuery = (query: string) => {
    const queryId = nanoid();
    const data = {
      action: "Query",
      query: query, // remove prequery disturbing summaries (transcript) + '----' + prequery,
      query_id: queryId,
    };
    const message = { ...data, queryId, query, message: "", isFinal: false };
    addMessage(message);

    sendJsonMessage(data);
    setIsBusy(true);
  };

  const sendSuggestionRequest = () => {
    // queryId: string
    const queryId = nanoid();
    const data = {
      action: "SuggestQueries",
      query_id: queryId,
    };
    sendJsonMessage(data);
    const message = { ...data, queryId, message: "", isFinal: false };
    addMessage(message);
    // keep chatbot not busy for new user queries
  };

  const handleMessage = (lastMessage: MessageEvent<any> | null) => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      const existingMessageIndex = messageHistoryRef.current.findIndex(
        (message) => message.queryId === data.query_id
      );
      if (existingMessageIndex >= 0) {
        const current = messageHistoryRef.current[existingMessageIndex];
        const updatedMessage = {
          ...current,
          ...data,
          message: current.message + data.chunk,
          // keep not empty latest user_query (for reformulated queries by backend)
          ...(current.user_query || data.user_query
            ? { user_query: current.user_query || data.user_query }
            : null),
          isFinal: data.is_final,
        };
        const newMessagesList = [...messageHistoryRef.current];
        newMessagesList[existingMessageIndex] = updatedMessage;

        messageHistoryRef.current = newMessagesList;
        setMessageHistory(messageHistoryRef.current);
        setIsBusy(messageHistoryRef.current.some((x) => !x.isFinal));
        if (
          !data.suggested_queries &&
          data.is_final &&
          data.query_id !== "welcome"
        ) {
          if (data.src_docs === undefined) {
            sendSuggestionRequest();
            // sendSrcRequest(data.query_id);
          } else {
            console.debug(data.src_docs);
          }
        }
      } else {
        // new id
      }
      // console.debug({ messageHistoryRef });
    }
  };

  const getConnectionStatus = () => {
    return connectionStatus;
  };
  const isConnected = getConnectionStatus() === "Open";

  return {
    init,
    sendQuery,
    messageHistory,
    getConnectionStatus,
    isConnected,
    isBusy,
    suggestions: [],
  };
};
