import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Flex, Popover, Row, Space, Tooltip } from "antd";
import { ReactNode } from "react";
import type { Maybe } from "types";
import type { DisplayMetrics } from "pages/media/types";
import { Label } from "./Label";

import styled from "styled-components";

const InfoTooltip = ({ children }: { children: ReactNode }) => {
  return (
    <Popover
      autoAdjustOverflow
      overlayInnerStyle={{ padding: 20, whiteSpace: "pre-line" }}
      overlayStyle={{
        maxWidth: 500,
      }}
      content={children}
      trigger="click"
    >
      <InfoCircleOutlined size={24} style={{ opacity: 0.6 }} />
    </Popover>
  );
};

const getMetricColor = (score: Maybe<number>) => {
  if (score === undefined || score === null || score < 4) {
    return "red";
  }
  if (score >= 8) {
    return "green";
  } else if (score >= 4) {
    return "orange";
  }
};

export const StoryboardMetrics = ({ metrics }: { metrics: DisplayMetrics }) => {
  const t = useTranslate();

  return (
    <Space size="middle">
      <Label>{t("storyboard.editor.StoryboardMetrics.score")}</Label>
      <ScoreElements size="small" wrap={true} align="center">
        <Tooltip
          title={
            <Flex vertical>
              <Row>
                {t("storyboard.editor.StoryboardMetrics.negative")}
                {metrics.polarity.negative}
              </Row>
              <Row>
                {t("storyboard.editor.StoryboardMetrics.neutral")}
                {metrics.polarity.neutral}
              </Row>
              <Row>
                {t("storyboard.editor.StoryboardMetrics.positive")}
                {metrics.polarity.positive}
              </Row>
            </Flex>
          }
        >
          {t("storyboard.editor.StoryboardMetrics.balance")}
        </Tooltip>
        <Space>
          <Badge
            showZero
            color={getMetricColor(metrics.polarity.score)}
            count={metrics.polarity.score}
          />
          <InfoTooltip>
            {t("storyboard.editor.StoryboardMetrics.polarity")}
          </InfoTooltip>
        </Space>
      </ScoreElements>
      <ScoreElements size="small" wrap={true}>
        <Tooltip
          title={
            <Flex vertical gap={0}>
              <Row>
                {t("storyboard.editor.StoryboardMetrics.recall")}
                {metrics.similarity.recall}
              </Row>
              <Row>
                {t("storyboard.editor.StoryboardMetrics.precision")}
                {metrics.similarity.precision}
              </Row>
              <Row>
                {t("storyboard.editor.StoryboardMetrics.semantic")}
                {metrics.similarity.semantic_similarity}
              </Row>
            </Flex>
          }
        >
          {t("storyboard.editor.StoryboardMetrics.accuracy")}
        </Tooltip>
        <Space>
          <Badge
            showZero
            color={getMetricColor(metrics.similarity.score)}
            count={metrics.similarity.score}
          />
          <InfoTooltip>
            {t("storyboard.editor.StoryboardMetrics.similarity")}
          </InfoTooltip>
        </Space>
      </ScoreElements>
      <ScoreElements size="small" wrap={true}>
        <Tooltip
          title={
            <Flex vertical>
              <Row>
                {t("storyboard.editor.StoryboardMetrics.ease")}
                {/* {metrics.readability.ease} */}
                {t(
                  `storyboard.editor.StoryboardMetrics.${metrics.readability.ease}`
                )}
              </Row>
              <Row>
                {t("storyboard.editor.StoryboardMetrics.level")}
                {/* {metrics.readability.grade_level} */}
                {t(
                  `storyboard.editor.StoryboardMetrics.${metrics.readability.grade_level}`
                )}
              </Row>
            </Flex>
          }
        >
          {t("storyboard.editor.StoryboardMetrics.clarity")}
        </Tooltip>
        <Space>
          <Badge
            showZero
            color={getMetricColor(metrics.readability.score)}
            count={metrics.readability.score}
          />
          <InfoTooltip>
            {t("storyboard.editor.StoryboardMetrics.readability")}
          </InfoTooltip>
        </Space>
      </ScoreElements>
    </Space>
  );
};

const ScoreElements = styled(Space)`
  margin-right: 10px;
`;
