import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Card, Typography } from "antd";

const { Title } = Typography;

type VideoPreviewProps = {
  video: File | string;
  width?: number;
  height?: number;
  fileName: string;
  actions: JSX.Element[];
  style?: React.CSSProperties;
};

const VideoPreview: React.FC<VideoPreviewProps> = ({
  video,
  width = "150px",
  height = "150px",
  fileName,
  actions,
  ...props
}) => {
  const t = useTranslate();
  const [videoSrc, setVideoSrc] = useState<string>("");
  const [videoName, setVideoName] = useState<string>("");

  useEffect(() => {
    if (video instanceof File) {
      const url = URL.createObjectURL(video);
      setVideoSrc(url);
      setVideoName(video.name);
      return () => URL.revokeObjectURL(url);
    } else if (typeof video === "string") {
      setVideoSrc(video);
      const name = video.split("/").pop() || "Video";
      setVideoName(name);
    }
  }, [video]);

  if (!videoSrc) {
    return null;
  }
  const { style: propStyle, ...restProps } = props;

  return (
    <Card
      size="small"
      styles={{
        actions: { padding: 10 },
      }}
      cover={
        <video
          style={{
            ...propStyle,
            width: "100%",
            height: 200,
          }}
          preload="none"
          controls
          src={videoSrc}
        >
          {t("brandkit.components.VideoPreview.yourBrowserDoes")}
        </video>
      }
      actions={actions}
    >
      <Card.Meta
        description={
          <Typography.Text style={{ width: "100%" }} ellipsis type="secondary">
            {fileName ?? videoName}
          </Typography.Text>
        }
      />
    </Card>
  );
};

export default VideoPreview;

