import { Card, ColorPicker, Flex, Form, FormInstance, Typography } from "antd";
import { ColorPickerProps } from "antd/lib";
import { t } from "i18n";
import { PaletteResponse } from "pages/media/types";

const colorPickerProps: ColorPickerProps = {
  showText: true,
  format: "hex",
  size: "large",
  disabledAlpha: true,
};

export const ColorPickerCard = ({
  colorKey,
  allowClear,
  defaultValue,
  form,
  setColors,
}: {
  colorKey: string;
  allowClear?: boolean;
  defaultValue?: string;
  form: FormInstance;
  setColors: React.Dispatch<React.SetStateAction<PaletteResponse>>;
}) => {
  const resetColor = (colorKey: string) => {
    form.setFieldValue(["colors", colorKey], null);

    setColors((prev) => ({
      ...prev,
      [colorKey]: undefined,
    }));
  };

  return (
    <Card
      style={{
        width: "100%",
      }}
      size="small"
    >
      <Typography.Paragraph>
        {t(`media.brandkit.create.${colorKey}`)}
      </Typography.Paragraph>
      <Flex gap={5}>
        <Form.Item name={["colors", colorKey]}>
          <ColorPicker
            {...colorPickerProps}
            allowClear={allowClear}
            defaultValue={defaultValue}
            size="large"
            onChange={(color) => {
              form.setFieldValue(["colors", colorKey], color.toHexString());
              setColors((prev) => ({
                ...prev,
                [colorKey]: color.toHexString(),
              }));
            }}
            onClear={() => resetColor(colorKey)}
          />
        </Form.Item>
      </Flex>
    </Card>
  );
};
