import { useTranslate } from "@refinedev/core";
import { Button, Space, Table, TableColumnsType } from "antd";
import { useGo, useList, CanAccess, Authenticated } from "@refinedev/core";
import type { VoiceProfileResponse } from "../types";
import { useOrganization } from "hooks/useOrganization";
import { UUID } from "../../../components/UUID";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { CreateButton, DateField, DeleteButton } from "@refinedev/antd";
import { List } from "@refinedev/antd";
import { addDateTz } from "../utils";
import { useState } from "react";
import VoiceItem from "../components/ContactWithPresenter/VoiceItem";

export const VoiceProfilesListPage = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const { data, isLoading, refetch, isRefetching } =
    useList<VoiceProfileResponse>({
      resource: `media/${organization?.id}/voice_profiles`,
      queryOptions: {
        enabled: Boolean(organization),
      },
    });

  const go = useGo();
  const [playingId, setPlayingId] = useState<string | null>(null);

  const columns: TableColumnsType<VoiceProfileResponse> = [
    {
      title: t("media.voices.index.id"),
      dataIndex: "id",
      key: "id",
      render: (id: string) => <UUID id={id} />,
    },
    {
      title: t("media.voices.index.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("media.voices.index.type"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("media.voices.index.creationDate"),
      dataIndex: "created",
      key: "created",
      render: (text) => <DateField value={addDateTz(text)} format="LLL" />,
    },
    {
      title: t("media.voices.index.preview"),
      key: "preview",
      width: 60,
      render: (record) => (
        <VoiceItem
          id={record.id}
          voice={record}
          onClick={() => {}}
          onClickPlay={(voice) => setPlayingId(voice?.id!)}
          currentlyPlayedVoice={data?.data?.find((v) => v.id === playingId)}
          pollingInterval={false}
        />
      ),
    },
    {
      title: t("media.voices.index.actions"),
      key: "actions",
      render: (record) => (
        <CanAccess resource="media_voices" action="delete">
          <DeleteButton
            size="small"
            shape="round"
            resource={`media/${organization?.id}/voice_profiles`}
            recordItemId={record.id}
            accessControl={{ enabled: false }}
          />
        </CanAccess>
      ),
    },
  ];

  return (
    <Authenticated key="voice_list">
      <List
        headerProps={{
          title: t("src.App.voices"),
        }}
        headerButtons={({ createButtonProps }) => (
          <Space>
            <Button
              icon={<SyncOutlined />}
              onClick={() => refetch()}
              disabled={isRefetching}
              shape="circle"
              type="text"
            />
            {createButtonProps && (
              <CreateButton
                {...createButtonProps}
                icon={<PlusOutlined />}
                shape="round"
              >
                {t("media.voices.index.createAVoice")}
              </CreateButton>
            )}
          </Space>
        )}
      >
        <Table<VoiceProfileResponse>
          loading={isLoading}
          dataSource={data?.data}
          columns={columns}
          pagination={{ pageSize: 50 }}
          onRow={(record) => ({
            onClick: (event) => {
              const target = event.target as HTMLElement;
              if (!target.closest(".ant-btn")) {
                go({
                  to: {
                    resource: "media_voices",
                    id: record.id,
                    action: "show",
                  },
                });
              }
            },
            style: { cursor: "pointer" },
          })}
        />
      </List>
    </Authenticated>
  );
};

export default VoiceProfilesListPage;
