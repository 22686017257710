import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  Button,
  Card,
  Col,
  Flex,
  Row,
  Space,
  Statistic,
  Typography,
} from "antd";
import {
  DashboardOutlined,
  DiffOutlined,
  FileTextOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import { Authenticated, useGo, useList } from "@refinedev/core";
import { ArrowExternal } from "components/icons";
import { useAntTheme } from "hooks/useAntTheme";
import { MediaAlertList } from "../components/MediaAlertList";
import { useOrganization } from "hooks/useOrganization";
import { AssetsEarningCalendarList } from "pages/assets/calendars";

const MediaDashboardStats = () => {
  const t = useTranslate();
  const go = useGo();
  const { data: changes, isLoading: isLoadingChanges } = useList({
    resource: `/api/assets/changes?list_status=Pending`,
  });

  return (
    <Statistic
      loading={isLoadingChanges}
      title={
        <Space>
          <DiffOutlined />
          {t("media.dashboard.index.pendingAssetsChanges")}
        </Space>
      }
      value={changes?.data?.length ?? 0}
      suffix={
        <Button
          onClick={() => go({ to: "/assets/changes" })}
          icon={<ArrowExternal style={{ zoom: 1.5, margin: 0, padding: 0 }} />}
          type="text"
        />
      }
    />
  );
};

const MediaAssetsToReview = () => {
  const t = useTranslate();
  const go = useGo();
  const {
    data: mediaMediaStepOutputToReview,
    isLoading: isLoadingMediaStepOutputsToReview,
  } = useList({
    resource: `/media/media/assets/to_review?category=MediaStepOutput`,
  });
  const { data: mediaScriptsToReview, isLoading: isLoadingScriptsToReview } =
    useList({
      resource: `/media/media/assets/to_review?category=Script`,
    });

  return (
    <>
      <Statistic
        loading={isLoadingMediaStepOutputsToReview}
        title={
          <Space>
            <FileTextOutlined />
            {t("media.dashboard.index.pendingMediaSteps")}
          </Space>
        }
        value={mediaMediaStepOutputToReview?.data?.length ?? 0}
        suffix={
          <Button
            onClick={() =>
              go({ to: "/media/assets/?category=MediaStepOutput&approved=-2" })
            }
            icon={
              <ArrowExternal style={{ zoom: 1.5, margin: 0, padding: 0 }} />
            }
            type="text"
          />
        }
      />
      <Statistic
        loading={isLoadingScriptsToReview}
        title={
          <Space>
            <FileTextOutlined />
            {t("media.dashboard.index.pendingMediaScripts")}
          </Space>
        }
        value={mediaScriptsToReview?.data?.length ?? 0}
        suffix={
          <Button
            onClick={() =>
              go({ to: "/media/assets/?category=Script&approved=-2" })
            }
            icon={
              <ArrowExternal style={{ zoom: 1.5, margin: 0, padding: 0 }} />
            }
            type="text"
          />
        }
      />
    </>
  );
};

const OrganizationAssetsStats = () => {
  const t = useTranslate();
  const { assets, isLoadingAssets } = useOrganization({});
  const go = useGo();

  return (
    <Statistic
      loading={isLoadingAssets}
      title={
        <Space>
          <FolderOutlined />
          {t("media.dashboard.index.assets")}
        </Space>
      }
      value={assets?.length}
      suffix={
        <Button
          onClick={() => go({ to: "/assets" })}
          icon={<ArrowExternal style={{ zoom: 1.5, margin: 0, padding: 0 }} />}
          type="text"
        />
      }
    />
  );
};

export const MediaDashboardPage = () => {
  const t = useTranslate();
  const theme = useAntTheme();

  return (
    <Authenticated key="media_dashboard">
      <Flex vertical style={{ paddingLeft: 50, paddingRight: 50 }} gap={30}>
        <Typography.Title level={3}>
          <Space>
            <DashboardOutlined />
            {t("media.dashboard.index.mediaDashboard")}
          </Space>
        </Typography.Title>
        <Row gutter={30}>
          <Col span={12}>
            <Card title={t("media.dashboard.index.alerts")} size="small">
              <MediaAlertList />
            </Card>
          </Col>
          <Col span={12}>
            <Flex vertical gap={30}>
              <Card
                title={t("media.dashboard.index.statistics")}
                size="small"
                style={{ borderColor: theme.theme.colorWarning }}
              >
                <Flex gap={40}>
                  <OrganizationAssetsStats />
                  <MediaDashboardStats />
                </Flex>
                <Flex gap={40}>
                  <MediaAssetsToReview />
                </Flex>
              </Card>
              <Card title={t("media.dashboard.index.shortcuts")} size="small">
                <a
                  rel="noreferrer"
                  href="https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#dashboards/dashboard/Media-dev?start=PT24H&end=null"
                  target="_blank"
                >
                  <Space>
                    {/* <img
      style={{ borderRadius: 4 }}
      alt="CloudWatch"
      height={32}
      src="https://d2q66yyjeovezo.cloudfront.net/icon/8f57ebd825a828e205b2dde223ba17e4-6af63a22dc297f8041286760ee8cd2c9.svg"
    /> */}
                    {t("media.dashboard.index.cloudWatchDashboard")}
                  </Space>
                </a>
              </Card>
            </Flex>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={24}>
            <Card
              title={t("media.dashboard.index.earningCalendars")}
              size="small"
            >
              <AssetsEarningCalendarList />
            </Card>
          </Col>
        </Row>
      </Flex>
    </Authenticated>
  );
};

