import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Button, Space, Table, TableColumnsType } from "antd";
import { Authenticated, CanAccess, useGo, useList } from "@refinedev/core";
import { type FaceProfileResponse } from "../types";
import { useOrganization } from "hooks/useOrganization";
import { UUID } from "../../../components/UUID";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { CreateButton, DateField, DeleteButton } from "@refinedev/antd";
import { List } from "@refinedev/antd";
import { FaceProfilePreview } from "../components/FaceProfilePreview";
import { addDateTz } from "../utils";

export const FaceProfilesListPage = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});

  const { data, isLoading, refetch, isRefetching } =
    useList<FaceProfileResponse>({
      resource: `media/${organization?.id}/face_profiles`,
    });
  const go = useGo();

  const columns: TableColumnsType<FaceProfileResponse> = [
    {
      title: t("media.faces.index.id"),
      dataIndex: "id",
      key: "id",
      render: (id: string) => <UUID id={id} />,
    },
    {
      title: t("media.faces.index.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("media.faces.index.type"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("media.faces.index.preview"),
      dataIndex: "extracted_asset_id",
      key: "extracted_asset_id",
      render: (value: string, record: FaceProfileResponse) => {
        return (
          <FaceProfilePreview
            assetPath={
              record.thumbnail_asset_path ??
              record.extracted_asset_path ??
              record.custom_asset_path
            }
            name={record.name}
            style={{
              height: 100,
              width: 100,
            }}
          />
        );
      },
    },
    {
      title: t("media.faces.index.creationDate"),
      dataIndex: "created",
      key: "created",
      render: (text) => <DateField value={addDateTz(text)} format="LLL" />,
    },
    {
      title: t("media.faces.index.actions"),
      key: "actions",
      render: (_: any, record: FaceProfileResponse) => (
        <CanAccess resource="media_faces" action="delete">
          <Space>
            <DeleteButton
              size="small"
              shape="round"
              resource={`media/${organization?.id}/face_profiles`}
              recordItemId={record.id}
              accessControl={{
                enabled: false,
              }}
            >
              {t("media.faces.index.delete")}
            </DeleteButton>
          </Space>
        </CanAccess>
      ),
    },
  ];

  return (
    <Authenticated key="face_list">
      <List
        headerButtons={({ createButtonProps }) => (
          <Space>
            <Button
              icon={<SyncOutlined />}
              onClick={() => refetch()}
              disabled={isRefetching}
              shape="circle"
              type="text"
            />
            {/* todo check accesses */}
            {createButtonProps && (
              <CreateButton
                {...createButtonProps}
                icon={<PlusOutlined />}
                shape="round"
              >
                {t("media.faces.index.createAFace")}
              </CreateButton>
            )}
          </Space>
        )}
      >
        <Table<FaceProfileResponse>
          loading={isLoading}
          dataSource={data?.data}
          columns={columns}
          pagination={{ pageSize: 50 }}
          onRow={(record) => ({
            onClick: (event) => {
              const target = event.target as HTMLElement;
              if (!target.closest(".ant-btn")) {
                go({
                  to: {
                    resource: "media_faces",
                    id: record.id,
                    action: "show",
                  },
                });
              }
            },
            style: { cursor: "pointer" },
          })}
        />
      </List>
    </Authenticated>
  );
};

export default FaceProfilesListPage;

