import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { useState } from "react";
// import ReactPlayer from "react-player";
import { Row, Col, Typography } from "antd";
import { useParams } from "react-router-dom";
import { Authenticated, useOne } from "@refinedev/core";
import type { MediaAsset, VoiceProfileDetail } from "../types";
import { useOrganization } from "hooks/useOrganization";
import { DateField, Show } from "@refinedev/antd";
import { addDateTz } from "../utils";

const VoiceProfileAudioAsset = ({ assetId }: { assetId: string }) => {
  const { data } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id: assetId,
  });

  if (!data) {
    return null;
  }

  return <audio src={data.data.path} controls />;
};

const FaceProfileDetailPage = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const { id } = useParams<{
    id: string;
  }>();
  const { data, isLoading } = useOne<VoiceProfileDetail>({
    resource: `media/${organization?.id}/face_profiles`,
    id,
  });

  const faceProfile = data?.data;
  const assetIds = Object.keys(faceProfile?.assets ?? {});

  return (
    <Authenticated key="face_detail">
      <Show
        title={faceProfile?.name}
        isLoading={isLoading}
        headerButtons={<></>}
      >
        <Row justify="center">
          <Col span={16}>
            {faceProfile && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Typography.Text strong>
                    {t("media.faces.detail.type")}
                  </Typography.Text>{" "}
                  <Typography.Text>{faceProfile.category}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>
                    {t("media.faces.detail.creationDate")}
                  </Typography.Text>{" "}
                  <DateField
                    value={addDateTz(faceProfile.created)}
                    format="LLL"
                  />
                </Col>
                {assetIds.map((assetId) => (
                  <Col span={24} key={assetId} style={{ marginBottom: 16 }}>
                    <VoiceProfileAudioAsset assetId={assetId} />
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </Show>
    </Authenticated>
  );
};

export default FaceProfileDetailPage;

