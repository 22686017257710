import { CSSProperties } from "react";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
// If dark mode is needed, import `dark.css`.
import "react18-json-view/src/dark.css";
import { WordListEditor } from "./WordListEditor";

export const JsonPreview = ({
  data,
  style,
  editable = false,
  onEdit = () => true,
}: {
  data?: object;
  style?: CSSProperties;
  editable?: boolean;
  onEdit?: (newValue: any) => void;
}) => {
  return (
    <div style={style}>
      {data && (
        <JsonView
          src={data}
          enableClipboard
          editable={editable}
          collapseStringsAfterLength={1000000}
          displaySize={"expanded"}
          onEdit={() => onEdit && onEdit(data)}
        />
      )}
      {/* to improve to sort list of data */}
      {/* {data &&
        (data as any)?.keywords &&
        Object.values<string[]>((data as any)?.keywords)?.map(
          (keywords: string[], idx: number) => {
            console.debug({ keywords });
            return (
              <WordListEditor
                key={idx}
                data={
                  keywords?.map((x: string, id: number) => {
                    return {
                      id,
                      text: x,
                    };
                  }) as { id: number; text: string }[]
                }
              />
            );
          }
        )} */}
    </div>
  );
};
