import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  Authenticated,
  useApiUrl,
  useCustomMutation,
  useList,
} from "@refinedev/core";
import {
  useState,
  CSSProperties,
  useEffect,
  createRef,
  useCallback,
} from "react";
import {
  Col,
  Divider,
  Drawer,
  Grid,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Switch,
  Tabs,
  Tooltip,
  Typography,
  theme as antdTheme,
} from "antd";
import { Button, Flex } from "antd";
import {
  CloseCircleFilled,
  CheckCircleFilled,
  DislikeOutlined,
  LikeOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Form } from "antd";
import { List, useForm } from "@refinedev/antd";
import { MediaAsset, MediaAssetCategory } from "../types";
import { useParams } from "react-router-dom";
import { RadioChangeEvent } from "antd/lib";
import { MediaAssetReview } from "../components/MediaAssetReview";
import { CDN_URL } from "../../../constants";
import { startCase } from "lodash";
import { MediaAssetPreview } from "../components/MediaAssetPreview";
import ThumbnailSizeSegmented, {
  ThumbnailSize,
} from "./components/ThumbnailSizeSegmented";
import { SegmentedValue } from "antd/es/segmented";
import TickerAutocomplete from "components/TickerAutoComplete";

const MediaAssets = ({
  data,
  setCurrentItem,
  currentIndex,
  height,
  thumbnailSize,
  thumbnailForce, // force thumbnail display even if no compressed thumbnail (showing heavy original image)
}: {
  data: Array<MediaAsset>;
  setCurrentItem: (id: string) => void;
  currentIndex: number;
  height: string;
  thumbnailForce: boolean;
  thumbnailSize: ThumbnailSize;
}) => {
  const { useToken } = antdTheme;
  const { token: theme } = useToken();
  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const Row = ({ index, style }: { index: number; style?: CSSProperties }) => {
    const asset = data[index];
    const handleItemClick = () => setCurrentItem(asset.id);
    const isUpdating = asset.reviewed === "-";
    const isApproved = !isUpdating && Boolean(asset.reviewed) && asset.approved;
    const isRejected =
      !isUpdating && Boolean(asset.reviewed) && !asset.approved;
    const ref = createRef<HTMLDivElement>();
    const isCurrent = currentIndex === index;

    useEffect(() => {
      if (isCurrent && ref.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, [isCurrent, ref]);

    // not all asset have thumbnail processed
    const thumbnailPath = asset.thumbnail
      ? `${CDN_URL}/${asset.thumbnail}`
      : thumbnailForce && asset.path;
    const background = thumbnailPath
      ? { background: `url("${thumbnailPath}")` }
      : {};
    const THUMBNAIL_SIZES: Record<
      ThumbnailSize,
      {
        height: number;
        width: number;
      }
    > = {
      small: { height: 100, width: 80 },
      medium: { height: 200, width: 160 },
      large: { height: 400, width: 320 },
    };
    // console.debug({ index });
    return (
      <div
        ref={ref}
        style={{
          borderWidth: 1,
          borderRadius: 6,
          borderStyle: "solid",
          borderColor: isCurrent ? theme.colorPrimary : "#999",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          // marginBottom: 10,
          height: THUMBNAIL_SIZES[thumbnailSize].height,
          width: isMobile ? 100 : THUMBNAIL_SIZES[thumbnailSize].width,
          position: "relative",
          cursor: "pointer",
        }}
        className={index % 2 ? "ListItemOdd" : "ListItemEven"}
        onClick={handleItemClick}
      >
        <div
          style={{
            borderRadius: 5,
            height: "100%",
            width: "100%",
            ...background,
            filter: isApproved || isRejected ? "brightness(70%)" : "none",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {asset.asset_type === "Video" && (
            <PlayCircleOutlined
              style={{
                color: theme.colorTextSecondary,
                fontSize: 30,
              }}
              height={100}
              width={100}
            />
          )}
        </div>
        <Typography.Text style={{ fontSize: 9 }}>
          {asset.source}
        </Typography.Text>
        {isUpdating && (
          <Spin
            style={{
              color: "#24BB75",
              position: "absolute",
              top: 10,
              left: 10,
            }}
          />
        )}
        {isApproved && (
          <CheckCircleFilled
            style={{
              color: "#24BB75",
              position: "absolute",
              top: 10,
              left: 10,
              fontSize: 20,
            }}
          />
        )}
        {isRejected && (
          <CloseCircleFilled
            style={{
              color: "#E01B36",
              position: "absolute",
              top: 10,
              left: 10,
              fontSize: 20,
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Flex wrap="wrap" gap="middle">
      {data.map((item, index) => (
        <Row key={item.id} index={index} />
      ))}
    </Flex>
  );
};

const MediaAssetListStats = ({
  data,
  loading,
}: {
  data: MediaAsset[];
  loading?: boolean;
}) => {
  const t = useTranslate();
  const stats = {
    approved: data.filter((x) => x.approved && x.reviewed).length,
    rejected: data.filter((x) => !x.approved && x.reviewed).length,
    reviewers: new Set(data.map((x) => x.reviewed && x.reviewer)).size,
    reviewerIds: [
      ...new Set(data.map((x) => x.reviewed && x.reviewer)).values(),
    ],
  };

  return (
    <Row gutter={2}>
      <Col span={4}>
        <Space size={"large"}>
          <Statistic
            title={t("media.assets.list.total")}
            value={stats.approved + stats.rejected}
          />
          {loading && <Spin size="large" />}
        </Space>
      </Col>
      <Col span={4}>
        <Statistic
          title={t("media.assets.list.approved")}
          value={stats.approved}
          prefix={<LikeOutlined />}
        />
      </Col>
      <Col span={4}>
        <Statistic
          title={t("media.assets.list.rejected")}
          value={stats.rejected}
          prefix={<DislikeOutlined />}
        />
      </Col>
      <Col span={4}>
        <Tooltip title={stats.reviewerIds.join(t("media.assets.list."))}>
          <Statistic
            title={t("media.assets.list.reviewers")}
            value={stats.reviewers}
          />
        </Tooltip>
      </Col>
      {stats.rejected > 0 && (
        <Col span={4}>
          <Statistic
            title={t("media.assets.list.rejectionRate")}
            value={Math.floor(
              (stats.rejected * 100) / (stats.rejected + stats.approved)
            )}
            suffix="%"
          />
        </Col>
      )}
    </Row>
  );
};

export const MediaAssetsList = ({ userId }: { userId?: number }) => {
  const t = useTranslate();
  const API_URL = useApiUrl();
  const [data, setData] = useState<Array<MediaAsset>>([]);
  const [currentItem, setCurrentItem] = useState<number>(0);
  const [currentItemId, setCurrentItemId] = useState<string | null>();
  const [lastUpdate, setLastUpdate] = useState(0);
  const params = useParams();
  const queryParameters = new URLSearchParams(window.location.search);

  const [category, setCategory] = useState<MediaAssetCategory>(
    (queryParameters.get("category") as MediaAssetCategory) ?? "AssetImage"
  );
  const [sub_category, setSubCategory] = useState(params.sub_category ?? "");
  const [aspect_ratio, setAspectRatio] = useState<string>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const { formProps } = useForm<{
    // category: string;
    sub_category: string;
  }>();
  const [approved, setApproved] = useState<number>(
    parseInt(queryParameters.get("approved") ?? "-1")
  );
  const [thumbnailSize, setThumbnailSize] = useState<ThumbnailSize>("medium");
  const [page, setPage] = useState(1);
  const [assetPreview, setAssetPreview] = useState(false);
  const [loadAll, setLoadAll] = useState(false);

  const urlParams = new URLSearchParams({
    category,
    ...(sub_category ? { sub_category } : null),
    // size: "50",
    ...(aspect_ratio ? { aspect_ratio } : null),
    ...(approved >= 0 ? { approved: approved ? "true" : "false" } : null),
  });
  const endpoint =
    approved === -2 ? "media/media/assets/to_review" : "media/media/assets";
  const {
    data: mediaAssetsData,
    isFetched,
    isSuccess,
    isFetching,
    isRefetching,
    refetch,
  } = useList<MediaAsset>({
    resource: `${endpoint}?${urlParams.toString()}`,
    queryOptions: {
      enabled:
        (category === "AssetImage" && sub_category?.length > 0) ||
        category !== "AssetImage", // only if has a ticker
    },
    pagination: {
      current: page,
    },
  });

  const fetchNextPage = () => {
    setPage((value) => value + 1);
  };

  const resetAndRefetch = () => {
    setData([]); // reset list
    setPage(1);
    refetch();
  };

  const handleFinish = (
    values: Record<string, string>,
    allValues?: Record<string, string>
  ) => {
    console.debug({ allValues, values });
    const data = allValues || values;
    setCategory(data.category as MediaAssetCategory);
    setSubCategory(data.sub_category);
    setAspectRatio(data.aspect_ratio);

    // force fetch in case it was same params
    resetAndRefetch();
  };

  const handleRefreshAssets = () => {
    resetAndRefetch();
  };

  const { mutate, isLoading: isUpdating } = useCustomMutation();
  const asset = data.find((x) => x.id === currentItemId);

  const handleReview = useCallback(
    (id: string, status: boolean) => {
      const index = data.findIndex((x) => x.id === id);
      const nextId = data[index + 1]?.id;
      console.debug({ nextId });

      const action = status ? "validate" : "reject";
      setData((current) =>
        current.map((x, idx) =>
          x.id === id
            ? {
                ...x,
                reviewed: "-",
                reviewer: userId,
              }
            : x
        )
      );
      mutate(
        {
          url: `${API_URL}/media/media/assets/${id}/${action}`,
          method: "post",
          values: {},
        },
        {
          onSuccess: (data, variables, context) => {
            const now = new Date();
            // update local data
            setData((current) =>
              current.map((x, idx) =>
                x.id === id
                  ? {
                      ...x,
                      reviewed: now.toISOString(),
                      approved: status,
                    }
                  : x
              )
            );
            setLastUpdate(now.getTime());
            // auto show next item to help reviewer
            nextId && setCurrentItemId(nextId);
          },
        }
      );
    },
    [data]
  );

  useEffect(() => {
    console.debug("new assets", mediaAssetsData);
    if (mediaAssetsData?.data) {
      setData((current) => {
        // there can be duplicates with pagination (sorted by date but items can have same date)
        const currentIds = current.map(({ id }) => id);
        return [
          ...current,
          ...mediaAssetsData.data.filter(({ id }) => !currentIds.includes(id)),
        ];
      });

      if (mediaAssetsData?.data?.length > 0 && loadAll) {
        fetchNextPage();
      }
    }
  }, [mediaAssetsData?.data]);

  // used in review page
  // useEffect(() => {
  //   // init to first new item
  //   setCurrentItem(data.findIndex((x) => !Boolean(x.reviewed)));
  // }, [data?.length]);

  // useEffect(() => {
  //   // all reviewed ?
  //   if (data.every((x) => Boolean(x.reviewed) && x.reviewed !== "-")) {
  //     refetch();
  //   }
  // }, [lastUpdate, refetch]);

  const approvedOptions = [
    { label: t("media.assets.list.approved"), value: 1 },
    { label: t("media.assets.list.all"), value: -1 },
    { label: t("media.assets.list.rejected"), value: 0 },
    { label: t("media.assets.list.toReview"), value: -2 },
  ];
  const handleApprovedChange = ({
    target: { value: newValue },
  }: RadioChangeEvent) => {
    setApproved(newValue);
    // cleanup existing list
    resetAndRefetch(); // make the page flick but works (TODO improve)
  };

  const handleThumbnailSizeChange = (newValue: SegmentedValue) => {
    setThumbnailSize(newValue as ThumbnailSize);
  };

  const sources = [...new Set(data.map((x) => x.source))];

  const sourceTabs = sources.map((source) => {
    const subData = data.filter((x) => x.source === source);
    return {
      key: source,
      label: source,
      children: (
        <Flex gap={10} vertical>
          <MediaAssetListStats data={subData} loading={isFetching} />
          <MediaAssets
            height="calc(100vh - 100px)"
            data={subData}
            setCurrentItem={setCurrentItemId}
            currentIndex={currentItem}
            thumbnailForce={assetPreview}
            thumbnailSize={thumbnailSize}
          />
        </Flex>
      ),
    };
  });

  const hasReview = !(
    asset?.asset_type === "Text" ||
    asset?.asset_type === "Music" ||
    asset?.asset_type === "Script"
  );

  // deprecated
  // const handleShowAsset = React.useCallback(
  //   (value: string) => {
  //     setCategory("AssetImage");
  //     setSubCategory(value);
  //     setPage(1);
  //     refetch();
  //   },
  //   [refetch]
  // );

  return (
    <Authenticated key={"mediaAssetsReview"}>
      <List>
        <Flex gap={isMobile ? "small" : "large"} vertical>
          <Form {...formProps} layout="inline" onFinish={handleFinish}>
            <Flex vertical gap={10}>
              <Flex>
                <Form.Item required name="category" initialValue={category}>
                  <Select
                    style={{ width: 250 }}
                    options={[
                      "AssetImage",
                      "MediaStepOutput",
                      "Script",
                      "CompanyResultPublication",
                      "Music",
                    ]
                      .sort()
                      .map((label) => ({
                        label: startCase(label),
                        value: label,
                      }))}
                  />
                </Form.Item>
                <Form.Item name="sub_category">
                  <TickerAutocomplete
                    onChange={setSubCategory}
                    size="middle"
                    placeholder={t("media.assets.list.subCategoryAapl")}
                    strict={false}
                  />
                </Form.Item>
                <Form.Item
                  label={t("media.assets.list.aspectRatio")}
                  name="aspect_ratio"
                >
                  <Select
                    style={{ width: 80 }}
                    options={[0.68, 0.69, 0.56, 1].map((value) => ({
                      label: String(value),
                      value: String(value),
                    }))}
                  />
                </Form.Item>
                <Form.Item name="approved" initialValue={approved}>
                  <Radio.Group
                    options={approvedOptions}
                    onChange={handleApprovedChange}
                    value={approved}
                    optionType="button"
                  />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("media.assets.list.apply")}
                </Button>
              </Flex>
              <Flex>
                <Form.Item name="thumbnailSize" initialValue={thumbnailSize}>
                  <ThumbnailSizeSegmented
                    value={thumbnailSize}
                    onChange={handleThumbnailSizeChange}
                  />
                </Form.Item>
                <Form.Item label={t("media.assets.list.showSourceImages")}>
                  <Switch
                    checked={assetPreview}
                    onClick={() => setAssetPreview((value) => !value)}
                  />
                </Form.Item>
                <Form.Item label={t("media.assets.list.autoloadAll")}>
                  <Switch
                    checked={loadAll}
                    onClick={() => setLoadAll((value) => !value)}
                  />
                </Form.Item>
                <Tooltip title={t("media.assets.list.reloadAssets")}>
                  <Button
                    style={{ marginRight: 20 }}
                    icon={<ReloadOutlined />}
                    shape="circle"
                    onClick={handleRefreshAssets}
                  />
                </Tooltip>
              </Flex>
            </Flex>
          </Form>
          <Divider style={{ margin: 0 }} />

          {/* {isSuccess && mediaAssetsData.data.length === 0 && (
        <Flex
          justify="center"
          vertical
          style={{ width: "80vw", height: "50vh" }}
        >
          <Empty
            description={
              <Typography.Text>No new assets to review</Typography.Text>
            }
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </Flex>
      )} */}

          <MediaAssetListStats data={data} loading={isFetching} />

          {sources?.length > 0 && (
            <Tabs defaultActiveKey={sources[0]} items={sourceTabs} />
          )}

          {/* </div> */}
          <Drawer
            open={Boolean(asset)}
            onClose={() => setCurrentItemId(null)}
            size="large"
          >
            {asset &&
              (!hasReview ? (
                <MediaAssetPreview asset={asset} handleReview={handleReview} />
              ) : (
                <MediaAssetReview
                  asset={asset}
                  handleReview={handleReview}
                  isUpdating={isUpdating}
                />
              ))}
          </Drawer>
        </Flex>
      </List>
    </Authenticated>
  );
};
