import { useTranslate } from "@refinedev/core";
import { Button, Table, Typography } from "antd";
import { List, getDefaultSortOrder, useTable } from "@refinedev/antd";

interface IVault {
  blockchain: string;
  address: string;
  version: string;
  created: string;
  closed: string;
}

export const VaultList: React.FC = () => {
  const t = useTranslate();
  const { tableProps, sorter } = useTable<IVault>({
    resource: "api/vaults",
    sorters: {
      mode: "off",
      initial: [
        {
          field: "version",
          order: "desc",
        },
      ],
    },
  });

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="address"
        // scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
        pagination={{ pageSize: 100 }}
      >
        <Table.Column
          dataIndex="blockchain"
          title={t("pages.vaults.list.blockchain")}
          render={(value: string) => (
            <Button
              size="large"
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
              icon={
                <img
                  style={{ height: 20, width: 20 }}
                  src={`https://cdn.libertify.com/env/images/networks/small/${value}.png`}
                />
              }
            >
              {value}
            </Button>
          )}
        />
        <Table.Column
          dataIndex="version"
          title={t("pages.vaults.list.version")}
          defaultSortOrder={getDefaultSortOrder("version", sorter)}
        />
        <Table.Column
          dataIndex="profile"
          title={t("pages.vaults.list.profile")}
        />
        <Table.Column
          dataIndex="address"
          title={t("pages.vaults.list.address")}
          render={(value: string) => (
            <Typography.Text code>{value}</Typography.Text>
          )}
        />
        <Table.Column
          dataIndex="master_token"
          title={t("pages.vaults.list.masterToken")}
          render={(value: string) => (
            <Button
              size="large"
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
              icon={
                <img
                  style={{ height: 20, width: 20 }}
                  src={`https://cdn.dev.libertify.com/env/images/tokens/${value}.png`}
                />
              }
            >
              {value}
            </Button>
          )}
        />
        <Table.Column
          dataIndex="created"
          title={t("pages.vaults.list.created")}
          // sorter={{ multiple: 1 }}
          defaultSortOrder={getDefaultSortOrder("created", sorter)}
        />
        <Table.Column
          dataIndex="closed"
          title={t("pages.vaults.list.closed")}
        />
      </Table>
    </List>
  );
};

