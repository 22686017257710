import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React from "react";
import {
  Authenticated,
  CanAccess,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useList,
  useOne,
} from "@refinedev/core";
import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  List,
  Result,
  Typography,
} from "antd";
import { JsonPreview } from "pages/media/components/JsonPreview";

const CacheReadForm = () => {
  const t = useTranslate();
  const [key, setKey] = React.useState<string | null>(null);
  const { data: cacheValues, isFetching } = useList({
    resource: `api/system/cache/like/${key}`,
    queryOptions: {
      enabled: !!key,
    },
  });

  const handleFinish = async (values: any) => {
    setKey(values.key);
  };

  return (
    <Card
      title={t("pages.system.detail.getCacheValues")}
      size="small"
      type="inner"
    >
      <Form onFinish={handleFinish}>
        <Form.Item
          name="key"
          rules={[
            { required: true, message: t("pages.system.detail.keyIsRequired") },
          ]}
        >
          <Input placeholder={t("pages.system.detail.enterKeyPattern")} />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={isFetching}>
          {t("pages.system.detail.get")}
        </Button>
      </Form>
      {cacheValues && cacheValues.data && cacheValues.data.length > 0 && (
        <List>
          {cacheValues.data.map((item: any, index: number) => (
            <List.Item key={index}>
              <Typography.Text copyable>{item}</Typography.Text>
            </List.Item>
          ))}
        </List>
      )}
    </Card>
  );
};

const CacheGetByIdForm = () => {
  const t = useTranslate();
  const [key, setKey] = React.useState<string>();

  const { data: cacheValue, isFetching } = useOne<any>({
    resource: `api/system/cache`,
    id: key,
    queryOptions: {
      enabled: Boolean(key),
    },
  });

  const handleFinish = (values: any) => {
    setKey(values.key);
  };

  return (
    <Card
      title={t("pages.system.detail.getCacheValue")}
      size="small"
      type="inner"
    >
      <Flex vertical gap={30}>
        <Form onFinish={handleFinish}>
          <Form.Item
            name="key"
            rules={[
              {
                required: true,
                message: t("pages.system.detail.keyIsRequired"),
              },
            ]}
          >
            <Input placeholder={t("pages.system.detail.enterKey")} />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            {t("pages.system.detail.get")}
          </Button>
        </Form>
        {cacheValue && <JsonPreview data={cacheValue.data} />}
      </Flex>
    </Card>
  );
};

const CacheDeleteForm = () => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const { mutateAsync: deleteCacheValue, isLoading } = useCustomMutation();
  const invalidate = useInvalidate();

  const handleFinish = async (values: any) => {
    await deleteCacheValue({
      url: `${apiUrl}/api/system/cache/like/${values.key}`,
      method: "delete",
      values: {},
      successNotification: {
        message: t("pages.system.detail.deleteCacheKey"),
        description: `Successfully deleted ${values.key}`,
        type: "success",
      },
    });
    invalidate({
      resource: "system/cache",
      invalidates: false,
    });
  };

  return (
    <Card
      title={t("pages.system.detail.deleteCacheValue")}
      size="small"
      type="inner"
    >
      <Form onFinish={handleFinish}>
        <Form.Item
          name="key"
          rules={[
            { required: true, message: t("pages.system.detail.keyIsRequired") },
          ]}
        >
          <Input placeholder={t("pages.system.detail.enterKey")} />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading} danger>
          {t("pages.system.detail.delete")}
        </Button>
      </Form>
    </Card>
  );
};

const SystemPage = () => {
  const t = useTranslate();
  return (
    <Authenticated key="system">
      <CanAccess
        resource="cache"
        action="read"
        fallback={
          <Result
            status={403}
            title={t("pages.system.detail.accessForbidden")}
          />
        }
      >
        <Flex gap={20} style={{ width: "100%" }}>
          <CacheReadForm />
          <CacheGetByIdForm />
          <CanAccess
            resource="cache"
            action="clear"
            fallback={
              <Result
                status={403}
                title={t("pages.system.detail.accessForbidden")}
              />
            }
          >
            <CacheDeleteForm />
          </CanAccess>
        </Flex>
      </CanAccess>
    </Authenticated>
  );
};

export default SystemPage;

