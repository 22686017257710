// @ts-nocheck
import { IResourceComponentsProps, CrudFilters } from "@refinedev/core";
// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import {
  CheckCircleOutlined,
  EditOutlined,
  RocketOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import {
  List,
  useTable,
  ShowButton,
  EditButton,
  DateField,
  DeleteButton,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Table, Space, Form, Input, Button, Tag, FormProps } from "antd";
import React, { useEffect, useState } from "react";
import { addDateTz } from "pages/media/utils";

interface DataType {
  key: React.Key;
  name: string;
  status: string;
  createdAt: string;
  actions: number;
}

export const SubCategoryList: React.FC<IResourceComponentsProps> = () => {
  const [dataTable, setDataTable] = useState<DataType[]>([]);
  const { tableProps, searchFormProps, filters, setFilters } = useTable({
    resource: "api/funds/categories?is_subcategory=true&size=10000",

    onSearch: (params: { q: string }) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });
      setFilters(filters);
      return filters;
    },
  });

  const renderStatus = (value: string, label?: string) => {
    let color, Icon;
    let prettyLabel = label;
    switch (value) {
      case "publish":
        color = "green";
        Icon = CheckCircleOutlined;
        prettyLabel = "Public";
        break;
      case "private":
      case "unpublish":
        color = "red";
        Icon = EyeInvisibleOutlined;
        prettyLabel = "Private";
        break;
      case "draft":
        color = "blue";
        Icon = EditOutlined;
        break;
      case "premium":
        color = "lime";
        Icon = RocketOutlined;
        break;
      default:
        color = "";
        Icon = CheckCircleOutlined;
        break;
    }
    return (
      <Tag color={color} icon={<Icon />}>
        {prettyLabel ?? value}
      </Tag>
    );
  };

  useEffect(() => {
    if (tableProps?.dataSource) {
      const newData: DataType[] = [];
      let dataFiltered: any = [...tableProps.dataSource];

      if (filters?.length) {
        dataFiltered = tableProps.dataSource?.filter((row) =>
          row.name.toLowerCase().includes(filters?.[0]?.value.toLowerCase())
        );
      }
      dataFiltered?.forEach((cat: any) => {
        newData.push({
          key: cat.id,
          name: cat.name || "-",
          status: cat.status || "draft",
          createdAt: cat.created || "-",
          actions: cat.id,
        });
      });

      setDataTable([...newData]);
    }
  }, [tableProps.dataSource, filters]);

  return (
    <List>
      <Filter formProps={searchFormProps} />
      <Table
        {...tableProps}
        scroll={{ x: 1200, scrollToFirstRowOnChange: true }}
        pagination={{ defaultPageSize: 20 }}
        dataSource={dataTable}
        loading={tableProps?.loading}
      >
        <Table.Column
          dataIndex="actions"
          render={(value, record) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={value} />
              <EditButton hideText size="small" recordItemId={value} />
              <DeleteButton
                hideText
                size="small"
                resource="api/funds/categories"
                recordItemId={value}
              />
            </Space>
          )}
        />
        {/* <Table.Column dataIndex="ticker" title="Ticker" /> */}
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => renderStatus(value)}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => (
            <DateField format="LLL" value={addDateTz(value)} />
          )}
        />
      </Table>
    </List>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item name="q">
        <Input
          placeholder="Search"
          suffix={
            <Button htmlType="submit" type="text" style={{ padding: 0 }}>
              <Icons.SearchOutlined />
            </Button>
          }
          style={{ width: 200 }}
        />
      </Form.Item>
    </Form>
  );
};
