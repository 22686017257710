import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { Flex, Input, InputProps, InputRef } from "antd";
import styled from "styled-components";

interface SearchInputProps {
  onSearch: (searchText: string) => void;
  value: string;
}

const SearchInput: React.FC<SearchInputProps & InputProps> = ({
  onSearch,
  value,
  ...rest
}) => {
  const t = useTranslate();
  const searchInput = useRef<InputRef>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onSearch(value);
  };

  useEffect(() => {
    const handleKeyPressShortcut = (e: KeyboardEvent) => {
      if (e.key === "/" || (e.ctrlKey && e.key === "f")) {
        e.preventDefault();
        if (searchInput.current) {
          searchInput.current.focus();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPressShortcut);
    return () => {
      document.removeEventListener("keydown", handleKeyPressShortcut);
    };
  }, []);

  return (
    <Input.Search
      ref={searchInput}
      size="large"
      placeholder={t("projects.components.SearchInput.typeTo")}
      value={value}
      onChange={handleChange}
      allowClear
      {...rest}
    />
  );
};

export default SearchInput;

