import { Flex } from "antd";

import { VoiceProfileCreateForm } from "../components/VoiceProfileCreateForm";
import { Authenticated } from "@refinedev/core";

export const VoiceProfileCreatePage = (props: any) => {
  return (
    <Authenticated key="voice_create">
      <Flex align="center" justify="center">
        <VoiceProfileCreateForm {...props} />
      </Flex>
    </Authenticated>
  );
};
