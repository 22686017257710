import { Tooltip, Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";

export const UUID = ({
  id,
  delimiter = "-",
  tooltip = true,
  asString = false,
  ...props
}: {
  id: string;
  delimiter?: string;
  tooltip?: boolean;
  asString?: boolean;
} & TextProps) => {
  const shortId = id.includes(delimiter)
    ? id.split(delimiter)[0]
    : id.slice(0, 8);

  // for highlighter editor
  if (asString) return <>{shortId}</>;

  return (
    <Tooltip title={tooltip ? id : null}>
      <Typography.Text copyable={{ text: id, tooltips: null }} {...props}>
        {shortId}
      </Typography.Text>
    </Tooltip>
  );
};
