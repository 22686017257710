import { Key } from "react";
import { Fade, FadeProps } from "react-awesome-reveal";

export const CascadingText = (props: FadeProps & { key?: Key }) => {
  return (
    <Fade delay={0} cascade damping={5e-2} {...props} key={props.key}>
      {props.children}
    </Fade>
  );
};
